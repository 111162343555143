import config from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const getListBudgetAccount = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/master/v1/BudgetAccount/list`,
    { params, headers }
  );
};

import React from "react";

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  TitleText,
} from "components";
import { useNavigate } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine, RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";

const ProposalEvaluatedDetailWorkplan = () => {
  const navigate = useNavigate();
  const links = [
    {
      label: "Pengajuan Dana",
      path: "/pengajuan-dana-program",
    },
    {
      label: "Detail",
    },
  ];
  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <div>Detail</div>
        </CardHeader>
        <CardBody>
          <div className="flex flex-col space-y-5">
            <div className="flex w-full">
              <span className="flex-1 font-bold">Nama Kegiatan</span>
              <span className="flex-1 font-bold">Rencana Waktu Pelaksanaan</span>
              <span className="flex-1 font-bold">penanggung Jawab</span>
            </div>
            <div className="flex">
              <span className="flex-1">Lorem ipsum</span>
              <span className="flex-1">Lorem ipsum</span>
              <span className="flex-1">Lorem ipsum</span>
            </div>
            <div className="flex w-full">
              <span className="flex-1 font-bold">Nama Kegiatan</span>
              <span className="flex-1 font-bold">Rencana Waktu Pelaksanaan</span>
              <span className="flex-1 font-bold">penanggung Jawab</span>
            </div>
            <div className="flex">
              <span className="flex-1">Lorem ipsum</span>
              <span className="flex-1">Lorem ipsum</span>
              <span className="flex-1">Lorem ipsum</span>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <div>Rekomendasi/Hasil Penilaian</div>
        </CardHeader>
        <CardBody>
          <div className="space-y-5">
            <div className="space-y-5">
              <TitleText className="font-bold">
                Rekomendasi/Hasil Penilaian
              </TitleText>
              <Input className={'w-[80%] h-24'} type={'textarea'}/>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate(-1)}
          >
            <RiArrowLeftLine/>
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          >
            Setujui & Lanjutkan
            <RiArrowRightLine className="text-white"/>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProposalEvaluatedDetailWorkplan;

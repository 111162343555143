import { convertMoney } from "helpers";

export const ProyekDonutOpt = {
  chart: {
    type: "donut",
    height: "290px",
  },

  colors: ["#12B76A", "#F79009", "#F04438", "#2E90FA"],
  plotOptions: {
    pie: {
      donut: {
        size: "50%",
      },
    },
  },

  series: [30000000, 36000000, 25000000, 59000000],
  labels: ["Thematic 1", "Thematic 2", "Thematic 3", "Thematic 4"],
  legend: {
    position: "right",
    formatter: function (val, opts) {
      return `${val}: Rp. ${convertMoney(
        opts.w.globals.series[opts.seriesIndex]
      )}`;
    },
  },
};

export const ProyekLineOpt = {
  chart: {
    type: "line",
    height: "323.5px",
  },
  colors: ["#00BD52", "#F04438", "#2970FF", "#7A5AF8", "#F79009"],
  series: [
    {
      name: "Thematic 1",
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
    },
    {
      name: "Thematic 2",
      data: [60, 70, 91, 30, 40, 35, 50, 49, 150],
    },
    {
      name: "Thematic 3",
      data: [21, 34, 80, 45, 47, 69, 72, 81, 105],
    },
    {
      name: "Thematic 4",
      data: [40, 35, 50, 49, 99, 60, 70, 91, 30],
    },
    {
      name: "Thematic 5",
      data: [70, 91, 30, 40, 47, 69, 72, 81, 105],
    },
  ],
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    categories: [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024],
  },
  markers: {
    size: 6,
  },
};

export const PenyaluranLineOpt = {
  chart: {
    type: "line",
    height: "300px",
  },
  colors: ["#00BD52", "#F04438"],

  series: [
    {
      name: "Penyaluran",
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
    },
    {
      name: "Pelaporan",
      data: [60, 70, 91, 30, 40, 35, 50, 49, 150],
    },
  ],
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    categories: [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024],
  },
  markers: {
    size: 6,
  },
};

export const PertumbuhanLineOpt = {
  chart: {
    type: "line",
    height: "300px",
  },

  colors: ["#00BD52", "#00BD52"],

  series: [
    {
      data: [192, 123, 120, 130, 104, 186, 133, 179, 196, 198, 157, 178],
    },
  ],
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  markers: {
    size: 6,
  },
};
export const IndicatorChartOpt = {
  chart: {
    type: "bar",
    height: "300px",
  },

  plotOptions: {
    bar: {
      horizontal: true,
      colors: {
        ranges: [
          {
            from: 0,
            to: 100,
            color: "#00BD52",
          },
        ],
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
      },
    },
  },
  series: [
    {
      data: [92, 23, 40, 30, 54, 86],
    },
  ],
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    categories: [
      "Indikator A",
      "Indikator B",
      "Indikator C",
      "Indikator D",
      "Indikator E",
      "Indikator F",
    ],
    labels: {
      formatter: function (val) {
        return val + "%";
      },
    },
  },

  markers: {
    size: 6,
  },
};

export const ProgressChartOpt = {
  chart: {
    height: 440,
    type: "radialBar",
  },
  series: [67],
  colors: ["#2E90FA"],
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: "#333",
        startAngle: -90,
        endAngle: 90,
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          fontSize: "24px",
          fontWeight: "600",
          fontFamily: "Poppins, Arial, sans-serif",
          show: true,
        },
      },
    },
  },

  stroke: {
    lineCap: "round",
  },
  labels: ["Progress"],
};

export const DanaOpt = {
  chart: {
    height: 440,
    type: "radialBar",
  },
  series: [88],
  colors: ["#2E90FA"],
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: "#333",
        startAngle: -90,
        endAngle: 90,
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          fontSize: "24px",
          fontWeight: "600",
          fontFamily: "Poppins, Arial, sans-serif",
          formatter: function (val) {
            return "Rp. " + convertMoney(10000000);
          },
          show: true,
        },
      },
    },
  },

  stroke: {
    lineCap: "round",
  },
  labels: ["Progress"],
};

export const OutcomeOpt = {
  series: [
    {
      name: "% Progress",
      data: [44, 55, 41, 64, 22],
    },
    {
      name: "Konsumsi Dana",
      data: [53, 32, 33, 52, 13],
    },
  ],
  chart: {
    type: "bar",
    height: 350,
    stacked: false,
  },
  plotOptions: {
    bar: {
      columnWidth: "45%",
      endingShape: "flat",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 1,
    colors: ["#fff"],
  },
  xaxis: {
    categories: [
      "Outcome 1",
      "Outcome 2",
      "Outcome 3",
      "Outcome 4",
      "Outcome 5",
    ],
  },
  yaxis: {
    max: 100,
    labels: {
      formatter: function (value) {
        return value + "%";
      },
    },
  },
  tooltip: {
    shared: true,
    intersect: false,
  },
  legend: {
    position: "bottom",
    offsetX: 40,
  },

  colors: ["#00BD52", "#F04438"],
};
export const OutputOpt = {
  colors: ["#00BD52", "#F04438"],
  series: [
    {
      name: "% Progress",
      data: [44, 55, 41, 64, 22],
    },
    {
      name: "Konsumsi Dana",
      data: [53, 32, 33, 52, 13],
    },
  ],
  chart: {
    type: "bar",
    height: 350,
    stacked: false,
  },
  plotOptions: {
    bar: {
      columnWidth: "45%",
      endingShape: "flat",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 1,
    colors: ["#fff"],
  },
  xaxis: {
    categories: [
      "Outcome 1",
      "Outcome 2",
      "Outcome 3",
      "Outcome 4",
      "Outcome 5",
    ],
  },
  yaxis: {
    max: 100,
    labels: {
      formatter: function (value) {
        return value + "%";
      },
    },
  },
  tooltip: {
    shared: true,
    intersect: false,
  },
  legend: {
    position: "bottom",
    offsetX: 40,
  },
};

export const KonsepNoteOpt = {
  chart: {
    type: "donut",
    height: "250px",
  },

  colors: ["#2E90FA", "#12B76A", "#F04438", "#F79009", "#F04438", "#2E90FA"],
  plotOptions: {
    pie: {
      donut: {
        size: "50%",
      },
    },
  },

  series: [30000000, 36000000, 25000000],
  labels: ["Perlu Verifikasi", "Disetujui", "Ditolak"],
  legend: {
    position: "right",
  },
};
export const FullProposalOpt = {
  chart: {
    type: "donut",
    height: "250px",
  },

  colors: ["#2E90FA", "#F79009", "#12B76A", "#F04438", "#2E90FA"],
  plotOptions: {
    pie: {
      donut: {
        size: "50%",
      },
    },
  },

  series: [30000000, 36000000, 25000000, 15000000],
  labels: ["Perlu Verifikasi", "Sedang Diverifikasi", "Disetujui", "Ditolak"],
  legend: {
    position: "right",
  },
};

export const TeamProyekOpt = {
  chart: {
    type: "donut",
    height: "250px",
  },

  colors: ["#2E90FA", "#F79009", "#12B76A", "#F04438", "#7A5AF8", "#15B79E"],
  plotOptions: {
    pie: {
      donut: {
        size: "50%",
      },
    },
  },

  series: [16, 10, 22, 24, 8, 16],
  labels: [
    "Project Director",
    "Project Manager",
    "Project Leader",
    "Project Admin",
    "Safeguard Specialist",
    "BMA",
  ],
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return parseInt(val);
    },
  },
  legend: {
    position: "right",
  },
};

export const BeneficiariesOpt = {
  colors: ["#2E90FA", "#F79009", "#12B76A", "#F04438", "#7A5AF8", "#15B79E"],
  series: [
    {
      data: [192, 123, 120, 130, 104, 186],
    },
  ],
  chart: {
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      distributed: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 1,
    colors: ["#fff"],
  },

  xaxis: {
    categories: [
      "Perorangan",
      "Masyarakat Hukum Adat",
      "Kelompok Masyarakat",
      "Lembaga Pemerintah",
      "Lembaga Non-Pemerintah",
      "Badan Usaha",
    ],
    labels: {
      show: true,
      rotate: -20,
      trim: false,
      style: {
        fontSize: "10px",
        fontFamily: "Poppins, Arial, sans-serif",
        fontWeight: 400,
      },
    },
  },
  legend: {
    position: "bottom",
    offsetX: 40,
  },
};

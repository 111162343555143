import { useMutation, useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import { enqueueSnackbar } from "notistack";
import { getTorRecap, submitTorRecap } from "services/danaProgram/torService";

export const useRecapMutations = (torId) => {
  const {
    data: dataDetailTor,
    isLoading: recapIsLoading,
    isFetching: recapIsFetching,
    refetch: refetchTorData,
  } = useQuery(["getTorRecap", { torId }], () => getTorRecap(torId), {
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const submitRecap = useMutation({
    mutationFn: (params) => {
      return submitTorRecap(params);
    },
  });

  return {
    submitRecap: submitRecap,
    lists: dataDetailTor?.data?.data || [],
    recapIsLoading,
    recapIsFetching,
    refetchDetailTor: refetchTorData,
  };
};

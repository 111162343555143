import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  RiAddLine,
  RiArrowLeftLine,
  RiArrowRightLine,
  RiDeleteBin6Line,
} from 'react-icons/ri';
import {
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';

import { getErrorMessage } from 'helpers';

import Approval2LevelActions, {
  Progress as Approval2LevelProgress,
} from 'components/organisms/Approval2Level';

import {
  AtomDatePicker,
  AtomButton,
  CardForm,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  Select as AtomSelect,
  Button,
} from 'components';
import { getSpecificTimeWorkPlanDetail } from 'services/danaProgram/specificTimeWorkPlanService';
import useWithdrawalWorkPlan, {
  useAWPMutations,
} from '../hooks/useWithdrawalWorkPlan';

const TimeWorkPlanForm = ({ title = {}, timeWorkPlanData = null }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isOnPageAdd = pathname.includes('add');
  const isOnPageVerification = pathname.includes('verification');

  const detailId = localStorage.getItem('withdrawalWorkPlan');
  const [workPlanList, setWorkPlanList] = useState([]);
  const [workPlanId, setWorkPlanId] = useState('');
  const [AWPDetailList, setAwpDetailList] = useState([]);
  const [totalFund, setTotalFund] = useState(0);

  const { fetchAWPDetail, submitMutation } = useAWPMutations();

  const { DetailWithdrawal, refetchDetailWithdrawal } = useWithdrawalWorkPlan({
    params: {
      detailId: detailId,
    },
  });

  const { data: timeWorkPlanDataDetail, refetch: refetchTimeWorkPlanData } =
    useQuery(
      [`detail-timeWorkPlan-${workPlanId}`, workPlanId],
      () => getSpecificTimeWorkPlanDetail(workPlanId),
      {
        onError: (error) => {
          enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
        },
        refetchOnWindowFocus: false,
        enabled: false,
      }
    );

  useEffect(() => {
    if (workPlanId) {
      refetchTimeWorkPlanData();
    }
  }, [workPlanId, refetchTimeWorkPlanData]);

  const validationSchema = yup.object().shape({
    work_plan_name: yup.mixed().required('wajib'),
  });

  const convertMoney = (value) => {
    let oriValue = String(value);
    oriValue = oriValue.includes('.') ? oriValue.split('.').join('') : oriValue;
    let modifValue = '';
    let count = 1;
    if (oriValue !== '' && oriValue.length > 3) {
      const splitValue = oriValue.split('');
      for (let i = splitValue.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0 && i !== 0) {
          modifValue = `.${splitValue[i]}${modifValue}`;
        } else {
          modifValue = `${splitValue[i]}${modifValue}`;
        }
        count += 1;
      }
    } else {
      modifValue = oriValue;
    }
    return modifValue;
  };

  const onCancel = () => {
    const previousRoute = !isOnPageVerification
      ? '/rencana-penarikan-dana/'
      : '/approval-rencana-penarikan-dana/';

    navigate(previousRoute);
  };

  const onSubmit = async () => {
    try {
      const formData = getValues();
      consumeAPI(formData);
    } catch (error) {
      console.error(error);
    }
  };

  const _mapTimeWorkPlanData = (data) => {
    setWorkPlanList(
      data?.map(({ _id, work_plan_name, ...rest }) => ({
        value: _id,
        label: work_plan_name,
        ...rest,
      }))
    );
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',

    defaultValues: { work_plan_period: '' },
  });

  const { setValue, getValues, control } = useFormContext();

  const {
    fields: awpFields,
    append: appendAwpFields,
    remove: removeAwpFields,
  } = useFieldArray({
    control,
    name: `awp_detail_activities`,
  });

  function formatNumber(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  function resettingAWPActivities() {
    awpFields?.map((index) => removeAwpFields(index));
  }

  useEffect(() => {
    resettingAWPActivities();
    if (!isOnPageAdd) {
      refetchDetailWithdrawal();
    }
    methods.register('reason');
  }, []);

  useEffect(() => {
    if (!isOnPageAdd) {
      for (let key in DetailWithdrawal) {
        let data = DetailWithdrawal[key];
        if (key === 'work_plan_name') {
          setValue('work_plan_name', {
            value: DetailWithdrawal.work_plan_id,
            label: data,
          });
        } else if (key === 'awp_project_name') {
          setValue('awp_project_name', {
            value: DetailWithdrawal.awp_project_id,
            label: data,
          });
        } else if (key === 'start_date_work_plan') {
          setValue(
            'work_plan_period',
            `${moment(data).format('DD/MM/YYYY')} - ${moment(
              DetailWithdrawal.end_date_work_plan
            ).format('DD/MM/YYYY')} `
          );
        } else if (key === 'total_awp_fund') {
          setValue('total_awp_fund', formatNumber(data));
        } else if (key === 'total_work_plan_fund') {
          setValue('total_work_plan_fund', formatNumber(data));
        } else if (key === 'total_withdrawal_fund') {
          setTotalFund(formatNumber(data));
        } else if (key === 'start_date_fund_usage') {
          setValue('period', [
            new Date(data),
            new Date(DetailWithdrawal.end_date_fund_usage),
          ]);
        } else if (key === 'detail_activities') {
          resettingAWPActivities();
          setValue('awp_project_activity_name', {
            label: data[0].awp_project_activity_name,
            value: data[0].awp_project_activity_id,
          });
          appendingAWPFields(data);
        } else if (key === 'reason') {
          setValue('reason', data);
        }
      }
    }
  }, [DetailWithdrawal]);

  const watchedValue = useWatch({
    control,
    name: 'work_plan_name',
  });

  const withdrawalFund = useWatch({
    control,
    name: 'awp_detail_activities',
  });

  useEffect(() => {
    _mapTimeWorkPlanData(timeWorkPlanData);
  }, [timeWorkPlanData]);

  const firstRender = useRef(true);

  useEffect(() => {
    if (isOnPageAdd) {
      if (!firstRender.current) {
        let totalWithdrawalFund = 0;

        withdrawalFund?.forEach((field, index) => {
          let withdrawalAmount = parseInt(
            field?.withdrawal_fund
              ? field?.withdrawal_fund.replace(/\D/g, '')
              : 0
          );
          totalWithdrawalFund += withdrawalAmount;
        });
        setTotalFund(totalWithdrawalFund);
      } else {
        firstRender.current = false;
      }
    }
  }, [withdrawalFund]);

  const mapOption = async (dataPromise) => {
    try {
      const data = await dataPromise;
      let temp = data?.map((obj) => ({
        value: obj._id,
        awp_id: obj.awp_id,
        awp_activity_id: obj.awp_activity_id,
        budget: obj.budget,
        execution: obj.execution,
        label: obj.name,
      }));
      setAwpDetailList(temp);

      data.forEach((obj, i) => {
        setValue(`awp_detail_activities.${i}.output`, {
          value: obj?.output?._id,
          label: obj?.output?.name,
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleWorkPlanChange = (temp) => {
    if (temp) {
      const {
        value,
        start_date,
        end_date,
        awp_project_name,
        awp_project_id,
        total_requirement_fund,
        total_awp_fund,
      } = temp;
      const periodTemp =
        start_date && end_date
          ? `${moment(start_date).format('DD/MM/YYYY')} - ${moment(
              end_date
            ).format('DD/MM/YYYY')}`
          : '';

      setValue(`awp_project_name`, {
        label: awp_project_name,
        value: awp_project_id,
      });
      setValue(`work_plan_period`, periodTemp);
      setValue(`total_work_plan_fund`, formatNumber(total_requirement_fund));
      setValue(`total_awp_fund`, formatNumber(total_awp_fund));
      setWorkPlanId(value);
      refetchTimeWorkPlanData();
    }
  };

  useEffect(() => {
    if (isOnPageAdd) {
      handleWorkPlanChange(watchedValue);
    }
  }, [watchedValue]);

  useEffect(() => {
    if (isOnPageAdd) {
      let temp = timeWorkPlanDataDetail?.data?.data;

      console.log('ini adalah temp', temp);

      if (temp?.awp_project_activity_id) {
        mapOption(
          fetchAWPDetail({ activity_id: temp?.awp_project_activity_id })
        );
        setValue('awp_project_activity_name', {
          label: temp?.awp_project_activity_name,
          value: temp?.awp_project_activity_id,
        });
        resettingAWPActivities();
        appendingAWPFields(
          timeWorkPlanDataDetail?.data?.data?.awp_detail_activities
        );
      }
    }
  }, [timeWorkPlanDataDetail]);

  function appendingAWPFields(datas) {
    if (isOnPageAdd) {
      datas?.map((dat, index) =>
        appendAwpFields({
          awp_detail_activity: {
            label: dat.awp_detail_activity_name,
            value: dat.awp_detail_activity_id,
          },
          work_plan_fund: formatNumber(datas[index].requirement_fund),
          output: {
            label: datas[index]?.output_name,
            value: datas[index]?.output_id,
          },
        })
      );
    } else {
      datas?.map((dat, index) =>
        appendAwpFields({
          awp_detail_activity: {
            label: dat.awp_detail_activity_name,
            value: dat.awp_detail_activity_id,
          },
          work_plan_fund: formatNumber(dat.work_plan_fund),
          withdrawal_fund: formatNumber(dat.withdrawal_fund),
          output: {
            label: datas[index]?.output_name,
            value: datas[index]?.output_id,
          },
        })
      );
    }
  }

  function parseDateRange(dateString) {
    const [startDate, endDate] = dateString.split(' - ');
    return { startDate, endDate };
  }

  const consumeAPI = (datas) => {
    const { startDate, endDate } = parseDateRange(datas?.work_plan_period);
    let params = {
      id: null,
      user_id: null,
      awp_project_id: datas?.awp_project_name?.value,
      awp_project_name: datas?.awp_project_name?.label,
      start_date_fund_usage: moment(new Date(datas?.period[0])).format(
        'DD/MM/YYYY'
      ),
      end_date_fund_usage: moment(new Date(datas?.period[1])).format(
        'DD/MM/YYYY'
      ),
      work_plan_id: datas?.work_plan_name?.value,
      work_plan_name: datas?.work_plan_name?.label,
      start_date_work_plan: startDate,
      end_date_work_plan: endDate,
      total_awp_fund: datas?.total_awp_fund
        ? parseInt(datas?.total_awp_fund.replace(/\./g, ''), 10)
        : null,
      total_work_plan_fund: parseInt(
        datas?.total_work_plan_fund.replace(/\./g, ''),
        10
      ),
      detail_activities: datas?.awp_detail_activities?.map((field, index) => ({
        awp_project_activity_id: datas?.awp_project_activity_name?.value,
        awp_project_activity_name: datas?.awp_project_activity_name?.label,
        awp_detail_activity_id: field?.awp_detail_activity?.value,
        awp_detail_activity_name: field?.awp_detail_activity?.label,
        output: field?.output?.label,
        work_plan_fund: parseInt(field?.work_plan_fund.replace(/\./g, ''), 10),
        withdrawal_fund: parseInt(
          field?.withdrawal_fund.replace(/\D/g, ''),
          10
        ),
      })),
      total_withdrawal_fund: parseInt(totalFund),
    };

    submitMutation.mutate(params);
  };

  if (submitMutation?.isSuccess) {
    return <Navigate to="/rencana-penarikan-dana" replace />;
  }

  return (
    <div className="">
      <div className="flex flex-col gap-4 mt-3">
        <Card>
          <CardHeader>
            <div>{title}</div>
          </CardHeader>
          <CardBody>
            <div className="flex flex-col gap-4">
              <div className="bg-[#F7FFFA] border-2 border-[#EAECF0] rounded-md p-[24px] flex flex-col gap-6">
                <span
                  className="text-[#01A24A] font-semibold text-lg space-y-[3px]
                "
                >
                  {' '}
                  Rencana Kerja Waktu Tertentu
                </span>
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-sm">
                    Rencana Kerja Waktu Tertentu
                    <span className="ml-1 text-error-500 text-sm">*</span>
                  </label>
                  <AtomSelect
                    controllerName={`work_plan_name`}
                    className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                    options={workPlanList ? workPlanList : []}
                    disable={!isOnPageAdd}
                  />
                </div>
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-sm">
                    Nama Proyek
                    <span className="ml-1 text-error-500 text-sm">*</span>
                  </label>
                  <AtomSelect
                    controllerName="awp_project_name"
                    className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                    disable={true}
                  />
                  <div className="flex gap-4 flex-row">
                    <div className="flex-1">
                      <InputForm
                        controllerName="work_plan_period"
                        className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                        label={'Periode'}
                        disabled
                      />
                    </div>
                    {/* TODO: hide this temporarily until flow is clear */}
                    {/* <div className="flex-1"> 
                      <InputForm
                        controllerName="total_withdrawal_fund"
                        className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                        label={'Nilai di AWP'}
                        prefix="Rp."
                        disabled
                      />
                    </div> */}
                    <div className="flex-1">
                      <InputForm
                        controllerName="total_work_plan_fund"
                        className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                        label={'Nilai di Rencana Kerja Waktu Tertentu'}
                        prefix="Rp."
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-[9px]">
                <AtomDatePicker
                  label="Periode Kebutuhan Penggunaan Dana"
                  controllerName={'period'}
                  className="!mt-0 flex-1 rounded-md border-gray-200 border-solid text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
                  {...methods.register('period')}
                  name="period"
                  placeholder="DD/MM/YYYY - DD/MM/YYYY"
                  isRangeDatePicker={true}
                  required={true}
                  disabled={!isOnPageAdd}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="flex flex-col gap-4 mt-3">
        <Card>
          <CardHeader info={`Total Penarikan: Rp. ${formatNumber(totalFund)}`}>
            <div>Rincian</div>
          </CardHeader>
          <CardBody>
            <CardForm
              label={`Kegiatan Utama`}
              buttonCollapse={true}
              childerForm={true}
            >
              <div className="flex flex-col gap-6">
                <div className="space-y-[9px]">
                  <AtomSelect
                    label="Kegiatan Utama"
                    controllerName={`awp_project_activity_name`}
                    className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                    // options={AWPList ? AWPList : []}
                    disable
                  />
                </div>
                {awpFields.length > 0 &&
                  awpFields.map((data, index) => (
                    <div className="flex gap-4 flex-row">
                      <div className="flex flex-1 pt-2 flex-col gap-5">
                        <div className="flex space-y-[9px] gap-4">
                          <AtomSelect
                            label="Detail Kegiatan"
                            controllerName={`awp_detail_activities.${index}.awp_detail_activity`}
                            className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                            options={AWPDetailList}
                            // disable="true">
                            disable={!isOnPageAdd}
                          />
                          {!isOnPageAdd && index !== 0 ? (
                            <button
                              className={`w-11 h-11 flex justify-center items-center p-3 ${
                                index !== 0 ? 'bg-[#D92D20]' : 'hidden'
                              } text-white rounded-md self-end`}
                              onClick={() => removeAwpFields(index)}
                            >
                              <RiDeleteBin6Line />
                            </button>
                          ) : (
                            <div className="w-11 h-11" />
                          )}
                        </div>
                        <div className="flex space-y-[9px] gap-4">
                          <AtomSelect
                            controllerName={`awp_detail_activities.${index}.output`}
                            className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                            label={'Output'}
                            disable
                          />
                          <div className="w-11 h-11" />
                        </div>
                        <div className="flex gap-4 flex-row">
                          <div className="flex-1">
                            <InputForm
                              controllerName={`awp_detail_activities.${index}.work_plan_fund`}
                              className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                              label={'Nilai di Rencana Kerja Waktu'}
                              disabled
                              prefix="Rp."
                            />
                          </div>
                          <div className="flex-1">
                            <InputForm
                              controllerName={`awp_detail_activities.${index}.withdrawal_fund`}
                              className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                              label={'Nilai Yang Akan Ditarik'}
                              prefix="Rp."
                              disabled={!isOnPageAdd}
                              onChangeInput={(e) => {
                                setValue(
                                  `awp_detail_activities.${index}.withdrawal_fund`,
                                  convertMoney(e.target.value)
                                );
                              }}
                            />
                          </div>
                          <div className="w-11 h-11" />{' '}
                        </div>
                      </div>
                    </div>
                  ))}
                {isOnPageAdd && (
                  <div className="space-y-[9px]">
                    <div className="flex flex-row">
                      <div className="flex-1 pt-2"></div>
                      <div className="flex-1 pt-2"></div>
                      <div className="flex-1 pt-2 text-end">
                        <AtomButton
                          className="p-4 border rounded-lg"
                          label={
                            <div className="flex items-center gap-2 font-[14px]">
                              <RiAddLine />
                              Tambah Detail Kegiatan
                            </div>
                          }
                          onClick={() =>
                            appendAwpFields({
                              awp_detail_activity: '',
                              output: '',
                              work_plan_fund: '',
                              withdrawal_fund: '',
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </CardForm>
          </CardBody>
        </Card>
      </div>

      <div className="mt-6">
        <Approval2LevelProgress
          module="rencana-penarikan-dana"
          credentials={DetailWithdrawal?.credentials}
          rejectReason={DetailWithdrawal?.reason}
        />
      </div>

      <div
        className={`block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10`}
      >
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={onCancel}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>

        <div className="flex gap-4 flex-row">
          {isOnPageAdd && (
            <div className="flex-1 block md:flex items-center justify-between gap-2">
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={onSubmit}
              >
                Submit
                <RiArrowRightLine className="text-white" />
              </Button>
            </div>
          )}

          {isOnPageVerification && (
            <Approval2LevelActions
              module="rencana-penarikan-dana"
              id={detailId}
              status={DetailWithdrawal?.status}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeWorkPlanForm;

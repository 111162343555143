import { useMutation, useQuery } from "@tanstack/react-query";
import { ToastContext } from "components/atoms/Toast";
import { getErrorMessage } from "helpers";
import { enqueueSnackbar } from "notistack";
import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { fileUpload } from "services/danaProgram/fileService";
import {
  getCities,
  getProvinces,
  getSubdistricts,
} from "services/danaProgram/masterDataService";
import { getOrganizationProfile, submitOrganizationProfile } from "services/danaProgram/profileOrganization";
import moment from "moment";

const useProfileOrganization = ({
  id = "",
}) => {

  const [temp, setTemp] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmBackModal, setConfirmBackModal] = useState(false);
  const [confirmDraftModal, setConfirmDraftModal] = useState(false);
  const [profileOrganizationDraftData, setProfileOrganizationDraftData] = useState()
  const { showToastMessage, showToastDescription } = useContext(ToastContext)
  const navigate = useNavigate();

  const {
    data: profileOrganizationData,
    isLoading: isLoadingProfileOrganization,
    isFetching: isFetchingProfileOrganization,
    refetch: refetchProfileOrganization,
  } = useQueryProfileOrganization(id)

  const formMutation = useFormProfileOrganization()

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  useEffect(() => {
    let flatData = profileOrganizationData?.data?.data
    if(flatData){
      // if (flatData?.institutional_plan?.hirarki_position?.length > 0 && !flatData?.institutional_plan?.hirarki_position?.[0]?.value){
      //   flatData['institutional_plan']['hirarki_position'] = flatData?.institutional_plan?.hirarki_position.map((e) => {
      //     return {
      //       label: e,
      //       value: e,
      //     }
      //   })
      // }
      if (flatData?.partnership?.partners?.length > 0){
        flatData['partnership']['partner'] = flatData?.partnership?.partners
      }
      if (flatData?.partnership?.partner?.length > 0){
        flatData?.partnership?.partner.map((e, i) => {
          // flatData['partnership']['partner'][i]['cooperation'] = flatData?.partnership?.partner[i].cooperation.map((e) => {
          //   return {
          //     label: e,
          //     value: e,
          //   }
          // })
          flatData['partnership']['partner'][i]['start_date'] = convert(flatData?.partnership?.partner[i].start_date)
          flatData['partnership']['partner'][i]['end_date'] = flatData?.partnership?.partner[i].end_date.split('T')[0]
        })
      }
      // if (flatData?.sub_grant?.partners?.length > 0){
      //   flatData?.sub_grant?.partners.map((e, i) => {
      //     flatData['sub_grant']['partners'][i]['partner_field'] = flatData?.sub_grant?.partners[i].partner_field.map((e) => {
      //       return {
      //         label: e,
      //         value: e,
      //       }
      //     })
      //   })
      // }
    }
    console.log("Data: ", flatData)
    setProfileOrganizationDraftData(flatData)
  }, [profileOrganizationData])

  const {
    data: ProvincesData,
    isLoading: isLoadingProvinces,
    isFetching: isFetchingProvinces,
    refetch: refetchProvinces,
  } = useQuery(["data-Provinces"], () => getProvinces(), {
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: CitiesData,
    isLoading: isLoadingCities,
    isFetching: isFetchingCities,
    refetch: refetchCities,
  } = useQuery(["data-Cities"], () => getCities(), {
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: DistrictsData,
    isLoading: isLoadingDistricts,
    isFetching: isFetchingDistricts,
    refetch: refetchDistricts,
  } = useQuery(["data-Districts"], () => getSubdistricts(), {
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });


  const onDraftSubmit = async (data) => {
    setTemp(data);
    setConfirmDraftModal(true);
  }

  const onBack = async (data) => {
    setTemp(data);
    setConfirmModal(true)
  }

  const onSubmit = async (data) => {
    setConfirmModal(true)
  }

  const consumeAPI = async (data) => {
    const { 
      partnership, 
      competence,
      profile_organization_document,
      created_at,
      current_step,
      profile_organization_id,
      score,
      structure_organization,
      updated_at,
      _id,
      ...restData 
    } = data
    // mapping partnership
    const { 
      partner,
      created_at: created_at_partnership,
      updated_at: updated_at_partnership,
      _id: _id_partnership,
      profile_organization_id: profile_organization_id_pertnership,
      ...restDataPartnership 
    } = partnership
    const newPartnership = {
      ...restDataPartnership,
      partners: partner
    };
    // mapping competence
    const newCompetence = competence.map((e, i) => {
      return {
        competency: e.competency,
        desc: e.desc
      }
    })
    // mapping profile_organization_document
    const {
      _id: _id_pod,
      created_at: created_at_pod,
      updated_at: updated_at_pod,
      profile_organization_id: profile_organization_id_pod,
      ...restDataProfileOrganizationDocument
    } = profile_organization_document
    // mapping structure_organization
    const {
      created_at: created_at_so,
      updated_at: updated_at_so,
      _id: _id_so,
      commissioners,
      directors,
      notes_approval_commisioners,
      profile_organization_id: profile_organization_id_so,
      ...restDataStructureOrganization
    } = structure_organization
    // mapping structure_organization -> commisioners
    const newCommisioners = commissioners.map((e, i) => {
      return {
        name: e.name,
        nik: e.nik,
        gender: e.gender,
        email: e.email,
      }
    })
    // mapping structure_organization -> directors
    const newDirectors = directors.map((e, i) => {
      return {
        name: e.name,
        nik: e.nik,
        gender: e.gender,
        email: e.email,
        position: e.position,
      }
    })
    const newStructureOrganization = {
      ...restDataStructureOrganization,
      commissioners: newCommisioners,
      directors: newDirectors,
    }

    const newParams = {
      ...restData,
      competence: newCompetence,
      partnership: newPartnership,
      profile_organization_document: restDataProfileOrganizationDocument,
      structure_organization: newStructureOrganization
    }
    // console.log(newParams)
    const res = await formMutation.mutateAsync(newParams)
    return res;
  }

  const onSubmitConfirmModal = async () => {
    const response = await consumeAPI(profileOrganizationDraftData)
    if (response.status === 200) {
      showToastMessage(
        "Data Berhasil Disimpan!",
        "Data yang anda masukan telah berhasil disimpan."
      )
      setConfirmModal(false);
      navigate("/verification/detail")
    } else {
      showToastMessage(
        "Data gagal Disimpan!",
        "Data yang anda masukan telah gagal1 disimpan."
      )
    }
  }

  const onSubmitConfirmBackModal = async () => {
    setConfirmBackModal(false);
    navigate("/verification/detail")
    showToastMessage(
      "Data Berhasil Disimpan!",
      "Data yang anda masukan telah berhasil disimpan."
    )
  }

  const onSubmitConfirmDraftModal = async () => {
    setConfirmDraftModal(false);
    navigate("/verification/detail")
    showToastMessage(
      "Data Berhasil Disimpan!",
      "Data yang anda masukan telah berhasil disimpan."
    )
  }

  return {
    profileOrganizationDraftData,
    setProfileOrganizationDraftData,
    onDraftSubmit,
    onBack,
    onSubmit,
    onSubmitConfirmModal,
    onSubmitConfirmBackModal,
    onSubmitConfirmDraftModal,
    temp,
    setTemp,
    confirmModal,
    setConfirmModal,
    confirmBackModal,
    setConfirmBackModal,
    confirmDraftModal,
    setConfirmDraftModal,
    ProvincesData: ProvincesData?.data?.data,
    refetchProvinces,
    CitiesData: CitiesData?.data?.data,
    refetchCities,
    DistrictsData: DistrictsData?.data?.data,
    refetchDistricts,
  }

}

export const useQueryProfileOrganization = (id) => {
  return useQuery(['list-profile-organization'], () => getOrganizationProfile({ id }), {
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      })
    },
    refetchOnWindowFocus: false,
  })
}

export const useFormProfileOrganization = () => {
  return useMutation(async (payload) => submitOrganizationProfile(payload),
  {
    onSuccess: () => {
      enqueueSnackbar({
        message: "Success",
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    }
  })
}

export default useProfileOrganization;
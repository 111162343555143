/* eslint-disable no-useless-computed-key */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AiOutlineEye } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { debounce } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import * as yup from "yup";
import _ from "lodash";
import axiosInstance from "app/interceptors";
import moment from "moment";

import { formattedValue } from "helpers";
import config from "app/config";

import {
  Breadcrumbs,
  Button,
  InputForm,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { ConfirmationModal } from "components/molecules/Modal";
import { SuccessToast, ToastContext } from "components/atoms/Toast";

import { useProcurements } from "./hooks";

const links = [
  {
    label: "Daftar Rencana Pengadaan",
  },
];

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

const StatusBadge = ({ children, status }) => {
  let defaultDotClass = "bg-gray-500";
  let defaultWrapperClass = "bg-warning-50 text-warning-700";
  let text = status;

  switch (status) {
    case "Selesai":
      defaultDotClass = "bg-primary-500";
      defaultWrapperClass = "bg-primary-50 text-primary-700";
      text = "Selesai";
      break;
    case "Rencana Baru":
      defaultDotClass = "bg-[#2E90FA]";
      defaultWrapperClass = "text-[#175CD3] bg-[#EFF8FF]";
      text = "Rencana Baru";
      break;
    case "Menunggu Pengadaan":
      defaultDotClass = "bg-warning-500";
      defaultWrapperClass = "bg-warning-50 text-warning-700";
      text = "Menunggu Pengadaan";
      break;
    default:
      defaultDotClass = "bg-gray-500";
      defaultWrapperClass = "bg-gray-50 text-gray-700";
      text = status;
      break;
  }
  return (
    <div
      className={`badge border-transparent p-2 text-xs rounded-2xl h-auto ${defaultWrapperClass}`}
    >
      <div
        className={`rounded-[50%] w-2 h-2 min-w-2 min-h-2 inline-block mr-1 ${defaultDotClass}`}
      />
      {text}
    </div>
  );
};

const FormModal = ({ open, onClose, onSubmit, title, children }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-20">
          <motion.div
            key="modal-confirmation"
            className={`bg-white rounded-xl w-1/2 p-6`}
            initial={{ scale: 0 }}
            animate={{ scale: 1, animation: 10 }}
            exit={{ scale: 0 }}
          >
            <div className="flex-1 flex items-center justify-between mb-6">
              <TitleText className="text-lg text-gray-900 font-semibold">
                {title}
              </TitleText>
              <CgClose className="text-xl cursor-pointer" onClick={onClose} />
            </div>
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

const DetailTableColumns = [
  {
    id: "qty",
    title: "Qty",
    dataIndex: "qty",
    columnClassName: "w-[80px]",
    sortable: true,
    render: (value) => value ?? "-",
  },
  {
    id: "unit",
    title: "Satuan",
    dataIndex: "unit",
    columnClassName: "w-[80px]",
    sortable: true,
    render: (value) => value ?? "-",
  },
  {
    id: "unit_price",
    title: "Harga Satuan",
    dataIndex: "unit_price",
    columnClassName: "w-[80px]",
    sortable: true,
    render: (value) => (value ? formattedValue(value) : "-"),
  },
  {
    id: "sub_total",
    title: "Total Harga",
    dataIndex: "sub_total",
    columnClassName: "w-[80px]",
    sortable: true,
    render: (value) => (value ? formattedValue(value) : "-"),
  },
];

const DetailContent = ({ data = null }) => {
  const [detailPage, setDetailPage] = useState(1);
  const [detailLimit, setDetailLimit] = useState(1);

  // just filled one item
  const dataSource = useMemo(() => {
    return [
      {
        qty: data.quantity,
        unit: data.unit,
        unit_price: data.unit_price,
        sub_total: data.sub_total,
      },
    ];
  }, [data]);

  const total = useMemo(() => dataSource.length, [dataSource]);
  const pageCount = useMemo(
    () => (total > 0 && detailLimit > 0 ? Math.ceil(total / detailLimit) : 1),
    [total, detailLimit]
  );
  const next_pages = useMemo(
    () => (detailPage < pageCount ? detailPage + 1 : null),
    [detailPage, pageCount]
  );
  const newData = useMemo(
    () => paginate(dataSource, detailLimit, detailPage),
    [dataSource, detailLimit, detailPage]
  );
  const pagination = useMemo(
    () => ({
      total,
      page: detailPage,
      perPage: detailLimit,
      lastPage: pageCount,
      previous_pages: detailPage - 1 === 0 ? null : detailPage - 1,
      next_pages,
    }),
    [dataSource, detailPage, detailLimit, pageCount, next_pages, total]
  );

  return (
    <div className="space-y-5 mb-5">
      <div className="flex gap-5">
        <div className="space-y-2 flex-1 w-1/2">
          <div className="text-gray-800 font-semibold">Proyek</div>
          <div className="text-gray-900">{data?.project_name}</div>
        </div>
        <div className="space-y-2 flex-1 w-1/2">
          <div className="text-gray-800 font-semibold">Status</div>
          <div className="text-gray-900">
            <StatusBadge status={data?.status} />
          </div>
        </div>
      </div>
      {/* <div className="space-y-2 flex-1">
        <div className="text-gray-800 font-semibold">Jenis Pengadaan</div>
        <div className="text-gray-900">Cangkul</div>
      </div> */}
      <div className="flex gap-5">
        <div className="space-y-2 flex-1 w-1/2">
          <div className="text-gray-800 font-semibold">
            Rencana Waktu Pengadaan
          </div>
          <div className="text-gray-900">
            {data.planned_date
              ? moment(data.planned_date).format("MMMM YYYY")
              : "-"}
          </div>
        </div>
        <div className="space-y-2 flex-1 w-1/2">
          <div className="text-gray-800 font-semibold">Deadline Pemenuhan</div>
          <div className="text-gray-900">
            {data.deadline ? moment(data.deadline).format("MMMM YYYY") : "-"}
          </div>
        </div>
        <div className="space-y-2 flex-1 w-1/2">
          <div className="text-gray-800 font-semibold">
            Tanggal Realisasi Pemenuhan
          </div>
          <div className="text-gray-900">
            {data.achive_date
              ? moment(data.achive_date).format("DD MMMM YYYY")
              : "-"}
          </div>
        </div>
      </div>
      <Table
        bordered
        stripped
        layout="fixed"
        className="mb-4"
        columns={DetailTableColumns}
        dataSource={dataSource}
        onChangePage={setDetailPage}
        onChangeRowsPerPage={setDetailLimit}
        pagination={pagination}
      />
    </div>
  );
};

const firstValidationSchema = yup.object().shape({
  planned_date: yup.string().required("Wajib disi"),
  deadline: yup.string().required("Wajib disi"),
});

const lastValidationSchema = yup.object().shape({
  planned_date: yup.string().required("Wajib disi"),
  deadline: yup.string().required("Wajib disi"),
  achive_date: yup.string().required("Wajib disi"),
});

const Procurements = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);
  const [modal, setModal] = useState({
    open: false,
    type: null,
    title: null,
    data: null,
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const {
    showToast,
    setShowToast,
    initialShowToast,
    showToastMessage,
    toastMessage,
    toastDescription,
  } = useContext(ToastContext);

  const methods = useForm({
    resolver: yupResolver(
      modal.type === "execution" ? lastValidationSchema : firstValidationSchema
    ),
    defaultValues: {
      planned_date: "",
      deadline: "",
      achive_date: "",
    },
  });

  const params = {
    page: page,
    limit: limit,
    name: keyword,
  };

  const {
    procurementData,
    refetch,
    paginationProcurementData,
    isLoadingProcurementData,
  } = useProcurements(params);

  const setNewPage = useCallback((val) => setPage(val), []);
  const setNewLimit = useCallback((val) => setLimit(val), []);

  useEffect(() => {
    refetch();
  }, [page, limit, keyword]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const procurementDataSource = useMemo(() => {
    return procurementData.map((proc) => {
      const subtotal = proc.sub_total ?? proc.unit_price * proc.quantity;

      return { ...proc, sub_total: subtotal };
    });
  }, [procurementData]);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "project",
        title: "Proyek",
        dataIndex: "project_name",
        sortable: true,
        className: "overflow-hidden text-ellipsis",
        columnClassName: "w-[180px]",
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
            {value ?? "-"}
          </div>
        ),
      },
      {
        id: "item_name",
        title: "Item Pengadaan",
        dataIndex: "item_name",
        sortable: true,
        className: "overflow-hidden text-ellipsis",
        columnClassName: "w-[180px]",
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
            {value ?? "-"}
          </div>
        ),
      },
      {
        id: "qty",
        title: "Qty",
        dataIndex: "quantity",
        columnClassName: "w-[80px]",
        sortable: true,
        render: (value) => value ?? "-",
      },
      {
        id: "planned_date",
        title: "Rencana Waktu Pengadaan",
        dataIndex: "planned_date",
        className: "overflow-hidden text-ellipsis",
        columnClassName: "w-[180px] whitespace-normal break-words",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
            {value ? moment(value).format("MMMM YYYY") : "-"}
          </div>
        ),
      },
      {
        id: "deadline",
        title: "Deadline Pemenuhan",
        dataIndex: "deadline",
        className: "overflow-hidden text-ellipsis",
        columnClassName: "w-[180px] whitespace-normal break-words",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
            {value ? moment(value).format("MMMM YYYY") : "-"}
          </div>
        ),
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        columnClassName: "text-center w-[220px]",
        className: "text-center",
        sortable: true,
        render: (value, data, index) => <StatusBadge status={value} />,
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        columnClassName: "text-center w-[180px]",
        fixed: "right",
        render: (value, data, index) => (
          <div
            className={classNames(
              "gap-3 flex items-center w-full justify-center",
              { ["flex-col"]: data?.status !== "done" }
            )}
          >
            <AiOutlineEye
              className="text-gray-600 cursor-pointer"
              size={20}
              onClick={() =>
                setModal((curr) => ({
                  ...curr,
                  open: true,
                  type: "detail",
                  title: "Detail",
                  data: data,
                }))
              }
            />
            {data?.status === "Menunggu Pengadaan" && (
              <>
                <BiPencil
                  className="text-gray-600 cursor-pointer"
                  size={20}
                  onClick={() =>
                    setModal((curr) => ({
                      ...curr,
                      open: true,
                      type: "edit",
                      title: "Edit Pengadaan dari AWP",
                      data: data,
                    }))
                  }
                />
                <Button
                  className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                  onClick={() =>
                    setModal((curr) => ({
                      ...curr,
                      open: true,
                      type: "execution",
                      title: "Eksekusi Pengadaan dari AWP",
                      data: data,
                    }))
                  }
                >
                  Eksekusi Pengadaan
                </Button>
              </>
            )}
            {data?.status === "Rencana Baru" && (
              <Button
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={() =>
                  setModal((curr) => ({
                    ...curr,
                    open: true,
                    type: "set-time",
                    title: "Set Waktu Pengadaan dari AWP",
                    data: data,
                  }))
                }
              >
                Set Waktu Pengadaan
              </Button>
            )}
          </div>
        ),
      },
    ];
  }, [page, limit]);

  //submit when status Rencana Baru but can't change status
  const onSubmitEdit = async (data) => {
    try {
      delete data["project"];
      delete data["item"];
      delete data["qty"];
      delete data["unit"];
      delete data["unit_price"];
      delete data["sub_total"];
      delete data["achive_date"];

      data.id = modal?.data?._id;
      data.status = "Rencana Baru";

      const response = await axiosInstance.post(
        `${config.BASE_URL}/api/dana-program/v1/cms/procurement/update`,
        data
      );

      if (response?.data?.code === 200) {
        setConfirmModal(false);
        refetch();
        showToastMessage(
          "Data berhasil diubah!",
          "Data yang anda masukan sudah berhasil diubah."
        );
        setShowToast((prev) => ({ ...prev, success: true }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  //submit when status Menunggu Pengadaan
  const onSubmitExecution = async (data) => {
    try {
      delete data["project"];
      delete data["item"];
      delete data["qty"];
      delete data["unit"];
      delete data["unit_price"];
      delete data["sub_total"];

      data.id = modal?.data?._id;
      data.status = "Selesai";

      const response = await axiosInstance.post(
        `${config.BASE_URL}/api/dana-program/v1/cms/procurement/update`,
        data
      );

      if (response?.data?.code === 200) {
        setConfirmModal(false);
        refetch();
        showToastMessage(
          "Data berhasil diubah!",
          "Data yang anda masukan sudah berhasil diubah."
        );
        setShowToast((prev) => ({ ...prev, success: true }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  // submit when status Rencana Baru
  const onSubmitTime = async (data) => {
    try {
      delete data["project"];
      delete data["item"];
      delete data["qty"];
      delete data["unit"];
      delete data["unit_price"];
      delete data["sub_total"];
      delete data["achive_date"];

      data.id = modal?.data?._id;
      data.status = "Menunggu Pengadaan";

      const response = await axiosInstance.post(
        `${config.BASE_URL}/api/dana-program/v1/cms/procurement/update`,
        data
      );

      if (response?.data?.code === 200) {
        setConfirmModal(false);
        refetch();
        showToastMessage(
          "Data berhasil diubah!",
          "Data yang anda masukan sudah berhasil diubah."
        );
        setShowToast((prev) => ({ ...prev, success: true }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const { open, data } = modal;
    if (!_.isEmpty(data) && open) {
      methods.reset({
        project: data?.project_name,
        item: data?.item_name,
        qty: data?.quantity,
        unit: data?.unit,
        unit_price: formattedValue(data?.unit_price ?? 0),
        sub_total: formattedValue(data?.sub_total ?? 0),
        planned_date: data?.planned_date?.slice(0, 7),
        deadline: data?.deadline?.slice(0, 7),
      });
    }
  }, [methods, modal]);

  const ModalContent = () => {
    const { type, data } = modal;

    if (type === "detail") {
      return <DetailContent data={data} />;
    } else if (type === "set-time" || type === "execution" || type === "edit") {
      return (
        <div className="space-y-5">
          <InputForm
            controllerName={`project`}
            className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 w-full`}
            label={"Proyek"}
            placeholder={"Proyek"}
            disabled={true}
          />
          <InputForm
            controllerName={`item`}
            className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 w-full`}
            label={"Item Pengadaan"}
            placeholder={"Item Pengadaan"}
            disabled={true}
          />
          <div className="flex gap-5">
            <InputForm
              controllerName={`qty`}
              className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 w-1/4 flex-1`}
              label={"Qty"}
              placeholder={"Qty"}
              disabled={true}
            />
            <InputForm
              controllerName={`unit`}
              className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 w-1/4 flex-1`}
              label={"Satuan"}
              placeholder={"Satuan"}
              disabled={true}
            />
            <InputForm
              controllerName={`unit_price`}
              className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 w-1/4 flex-1`}
              label={"Harga Satuan"}
              placeholder={"Harga Satuan"}
              disabled={true}
            />
            <InputForm
              controllerName={`sub_total`}
              className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 w-1/4 flex-1`}
              label={"Total Harga"}
              placeholder={"Total Harga"}
              disabled={true}
            />
          </div>
          <div className="flex gap-5">
            <InputForm
              controllerName={`planned_date`}
              className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
              label={"Rencana Waktu Pengadaan"}
              placeholder={"Rencana Waktu Pengadaan"}
              type="month"
              isDate
            />
            <InputForm
              controllerName={`deadline`}
              className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
              label={"Deadline Pemenuhan"}
              placeholder={"Deadline Pemenuhan"}
              min={methods.watch("planned_date")}
              type="month"
              isDate
            />
          </div>
          {type === "execution" && (
            <div className="pr-2 w-1/2">
              <InputForm
                controllerName={`achive_date`}
                className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
                label={"Tanggal Realisasi Pemenuhan"}
                placeholder={"Tanggal Realisasi Pemenuhan"}
                isDate
              />
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <Fragment>
      <div className="sm:max-w-[480px] md:max-w-[608px] lg:max-w-[864px] xl:max-w-[75vw] min-[1517px]:max-w-[78vw] min-[1700px]:max-w-[80vw] min-[1800px]:max-w-[100vw]">
        <Breadcrumbs items={links} />
        <div className="md:flex block items-center my-3">
          <TitleText className="flex-1 text-gray-900 text-lg font-semibold">
            Rencana Pengadaan
          </TitleText>
        </div>
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-3">
            <div className="space-y-4">
              <div className="flex">
                <div className="flex-1">
                  <SearchInput placeholder="Cari" onChange={onSearch} />
                </div>
              </div>
              <Table
                bordered
                stripped
                layout="fixed"
                className="mb-4"
                columns={tableColumns}
                dataSource={procurementDataSource}
                isLoading={isLoadingProcurementData}
                onChangePage={setNewPage}
                onChangeRowsPerPage={setNewLimit}
                pagination={paginationProcurementData}
              />
            </div>
          </div>
        </div>
      </div>
      {showToast.success && (
        <SuccessToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      <FormProvider {...methods}>
        <ConfirmationModal
          open={confirmModal}
          onClose={() => setConfirmModal(false)}
          onSubmit={() => {
            if (modal?.type === "edit") {
              methods.handleSubmit(onSubmitEdit)();
            } else if (modal?.type === "execution") {
              methods.handleSubmit(onSubmitExecution)();
            } else if (modal?.type === "set-time") {
              methods.handleSubmit(onSubmitTime)();
            }
          }}
        >
          <div className="text-gray-900 font-semibold text-lg">
            Apakah Anda yakin akan mengubah data ini?
          </div>
        </ConfirmationModal>
        <FormModal
          open={modal?.open}
          title={modal.title}
          onClose={() =>
            setModal((curr) => ({ ...curr, open: false, data: null }))
          }
        >
          <div className="flex-1">
            <ModalContent />
          </div>
          {modal?.type !== "detail" && (
            <div className="flex items-center mt-8 space-x-3">
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 flex-1"
                onClick={() =>
                  setModal((curr) => ({ ...curr, open: false, data: null }))
                }
              >
                Batal
              </Button>
              <Button
                type="button"
                disabled={!methods.formState.isValid}
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1"
                onClick={() => {
                  setModal((curr) => ({ ...curr, open: false }));
                  setConfirmModal(true);
                }}
              >
                Submit
              </Button>
            </div>
          )}
        </FormModal>
      </FormProvider>
    </Fragment>
  );
};

export default Procurements;

import { useNavigate, useSearchParams } from "react-router-dom";
import ProfitRasioValue from "./Profit";
import NonProfitRasioValue from "./NonProfit";
import { Breadcrumbs, Button } from "components";
import { RiArrowLeftLine } from "react-icons/ri";
import useRasioValue from "./hooks/useRasioValue";

const RasioValue = () => {
  const navigate = useNavigate()

  const {
    rasioData,
    refetch,
    isPreviousData,
  } = useRasioValue({
    id: sessionStorage.getItem('idVerification')
  })

  const links = [
    {
      label: "Daftar LEMTARA",
      path: "/verification",
    },
    {
      label: "Nilai Rasio"
    }
  ]

  return (
    <div className="sm:max-w-[480px] md:max-w-[608px] lg:max-w-[864px] xl:max-w-[75vw]">
      <Breadcrumbs items={links} />
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px] my-3">
        <div className="card-body p-3">
          <div className="space-y-4">
          {rasioData?.profitability_ratio ? 
              <ProfitRasioValue data={rasioData} />
            :
              <NonProfitRasioValue data={rasioData} />
          }
          </div>
        </div>
      </div>
      <div className="block md:flex items-center justify-between py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <dir>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => {
              navigate('/verification')
            }}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </dir>
      </div>
    </div>
  )
}

export default RasioValue;
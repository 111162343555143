import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  InputFormUpload,
} from "components";
import { RiArrowLeftLine, RiArrowRightLine, RiSave3Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import { useCreateStore } from "pages/Tor/Create/store";

import { fileUpload } from "services/danaProgram/fileService";
import FileDetail from "components/molecules/FileDetail";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { useAdditionalDocsMutations } from "../../hooks/useAdditionalDocsMutations";
import { useSelector } from "react-redux";
import { useRecapMutations } from "../../hooks/useRecapMutations";
import { enqueueSnackbar } from "notistack";

const AdditionalDocsForm = ({ setSavedStep, isEdit }) => {
  const { step, prevStep, torId } = useCreateStore();
  const TORID = sessionStorage.getItem("torId");
  const userSelector = useSelector((state) => state?.auth?.user || {});
  const navigate = useNavigate();
  const methods = useForm();

  const { lists, refetchDetailTor } = useRecapMutations(TORID ? TORID : null);

  const { submitDocs } = useAdditionalDocsMutations();

  const handleSubmitForm = async () => {
    try {
      let val = methods.getValues();
      let temp = {
        id: TORID,
        user_id: userSelector?.UserId,
      };

      let additional_document = [];

      for (let file of val?.files) {
        const fileFormData = new FormData();
        fileFormData.append("file", file);
        const resFile = await fileUpload(fileFormData);
        const resDataFile = await resFile.data.data;

        const payloadPerFile = {
          fileId: resDataFile.id,
          fileName: resDataFile.name,
          fileSize: resDataFile.size,
          mimeType: resDataFile.mimeType,
          path: resDataFile.url,
        };

        additional_document.push(payloadPerFile);
      }

      temp.files = additional_document;

      submitDocs.mutate(temp, {
        onSuccess: () => {
          methods.reset();
          sessionStorage.removeItem("step");
          sessionStorage.removeItem("torId");
          enqueueSnackbar("Data saved successfully!", {
            variant: "success",
            preventDuplicate: false,
          });
          navigate("/tor");
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [additionalDocs, setAdditionalDocs] = useState([]);

  useEffect(() => {
    if (Array.isArray(lists?.files)) {
      setAdditionalDocs(lists.files);
    }
  }, [lists]);

  useEffect(() => {
    refetchDetailTor();
  }, []);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-6">
        <Card>
          <CardHeader>Dokumen Pendukung</CardHeader>
          <CardBody>

            {/* {additionalDocs?.length > 0 ? (
              additionalDocs?.map((file, key) => (
                <FileDetail
                  key={"support-file-tor-" + key}
                  file={file}
                  hideDelete
                />
              ))
            ) : ( */}
              <InputFormUpload
                controllerName={"files"}
                maxSize={102400}
                label="Upload Dokumen Pendukung"
                isMulti
                hideSectionUpload={!isEdit}
                listFiles={additionalDocs ?? []}
              />
            {/* )} */}
            {/* {!isFilled && (
              <p className="mt-2 text-red-500">Dokumen harus diisi</p>
            )} */}
          </CardBody>
        </Card>
      </div>

      <div className="items-center justify-between block px-6 py-5 mt-4 mb-10 bg-white border border-gray-200 rounded-lg md:flex">
        <div>
          <Button
            type="button"
            className="px-8 text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
            onClick={() => {
              setSavedStep(2);
              sessionStorage.setItem("step", 2);
            }}
          >
            <RiArrowLeftLine className="text-white" />
            Kembali
          </Button>
        </div>

        <div className="items-center justify-between block gap-2 md:flex">
          {isEdit && (
            <Button
              type="button"
              className="text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
              onClick={() => {
                handleSubmitForm();
                navigate("/tor");
              }}
            >
              <RiSave3Line />
              Simpan & Draft
            </Button>
          )}
          <Button
            type="submit"
            disabled={submitDocs.isLoading}
            onClick={() => {
              if (!isEdit) return navigate("/tor");
              handleSubmitForm();
            }}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          >
            {submitDocs.isLoading ? "Tunggu Sebentar" : "Submit"}
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default AdditionalDocsForm;

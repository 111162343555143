import { BiPencil } from "react-icons/bi"
import OptionRadioVerificatorBadge from "../OptionRadioVerificatorBadge"
import { useFormContext } from "react-hook-form"
import { InputForm, TitleText } from "components"

const RadioVerificationBadge = ({
  className = "",
  nameNotes = "",
  name = "",
  isEditVerification = false,
  toogleIsEditVerification = () => {},
  errors = false,
}) => {

  const { watch, getValues } = useFormContext()
  return (
    <>
      <div className={`${className}`}>
        <div className="text-[14px] font-[600] w-3/12">Hasil Verifikasi</div>
        <div className="w-3/12 ml-[10px]">
          <OptionRadioVerificatorBadge
            name={name}
            type={"approve"}
            status={
              getValues(name) === "APPROVED" ||
              watch(name) === "APPROVED" ||
              getValues(name) === "SUBMITTED" ||
              watch(name) === "SUBMITTED" 
            }
            disabled={!isEditVerification}
          />
        </div>
        <div className="w-4/12 ml-[12px]">
          <OptionRadioVerificatorBadge
            name={name}
            type={"reject"}
            status={
              getValues(name) === "REJECTED" ||
              watch(name) === "REJECTED"
            }
            disabled={!isEditVerification}
          />
        </div>
        {isEditVerification ? (
          <div
            className="flex items-center justify-center border-[2px] bg-[#00BD52] hover:bg-[#00BD52] p-4 w-3/12 ml-[12px] gap-[8px] rounded-[12px] cursor-pointer text-[#ffffff]"
            onClick={toogleIsEditVerification}
          >
            <div className="text-[16px] font-[600]">Simpan</div>
          </div>
        ) : (
          <div
            className="flex items-center justify-center border-[2px] border-[#D0D5DD] p-4 w-3/12 ml-[12px] gap-[8px] rounded-[12px] cursor-pointer"
            onClick={toogleIsEditVerification}
          >
            <BiPencil />
            <div className="text-[14px]">Ubah Hasil Verifikasi</div>
          </div>
        )}
      </div>
      {errors && (
        <label className="label">
          <div className="text-xs mt-2 label-text-alt text-error text-[#F04438]">
            {errors?.message}
          </div>
        </label>
      )}
      {(getValues(name) === "REJECTED" ||
          watch(name) === "REJECTED") && (
          <div className="mt-[24px]">
            <TitleText className="font-bold">
              Jelaskan Kesalahan/ Ketidak Sesuaian Datanya
            </TitleText>
            <InputForm
              controllerName={nameNotes}
              className={`py-2 border w-full rounded-md flex-1`}
              label={""}
              placeholder={""}
              rows="5"
              textArea
            />
          </div>
        )}
    </>
  )
}

export default RadioVerificationBadge
import { AtomButton, Button, Input, InputForm } from "components";
import AtomSelect from "components/atoms/Select";
import { convertMoney, deconvertMoney, generateUUID } from "helpers";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CgChevronDown } from "react-icons/cg";
import { RiAddLine, RiDeleteBin6Line, RiPencilFill } from "react-icons/ri";

const ResourcePerson = ({
  setTotalFunding,
  methods,
  disabled,
  TORID,
  datas,
  activityDetails,
  budgetAccountOptions,
}) => {
  const [show, setShow] = useState({
    child: true,
  });
  const [Index, setIndex] = useState();
  const [activityOptions, setActivityOptions] = useState([]);

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "resource.required_items",
  });

  useEffect(() => {
    methods.register("total_cost");
    if (TORID) {
      methods.setValue(
        `resource.required_items`,
        datas?.resource?.required_items
      );
      methods.setValue(`total_cost`, datas?.resource?.total_cost);
      setTotalFunding(methods.watch("total_cost"));
    } else {
      methods.setValue("total_cost", 0);
      setTotalFunding(parseInt(methods.watch("total_cost")));
    }

    setActivityOptions(
      datas?.tor?.work_plan_name?.awp_detail_activities.map((data) => ({
        label: data?.awp_detail_activity_name,
        value: data?.awp_detail_activity_id,
        ...data,
      }))
    );
  }, [datas]);

  const onChangeItem = (e, key, method) => {
    const updateSubtotals = () => {
      fields.forEach((field, index) => {
        if (method !== "remove") {
          let v = methods.getValues(
            `resource.required_items.${index}.quantity`
          );
          let c = methods.getValues(`resource.required_items.${index}.cost`);
          let n = methods.watch("resource.total_person");
          const quantity = v !== undefined ? v : "1";
          const cost = c !== undefined ? c : "1";
          const totalPerson = n !== undefined ? n : "1";
          const subTotal =
            deconvertMoney(quantity) *
            deconvertMoney(cost) *
            deconvertMoney(totalPerson);
          methods.setValue(
            `resource.required_items.${index}.subtotal`,
            convertMoney(subTotal)
          );
        } else {
          let v = methods.getValues(
            `resource.required_items.${index !== key && index}.quantity`
          );
          let c = methods.getValues(
            `resource.required_items.${index !== key && index}.cost`
          );
          let n = methods.watch("resource.total_person");
          const quantity = v !== undefined ? v : "1";
          const cost = c !== undefined ? c : "1";
          const totalPerson = n !== undefined ? n : "1";
          const subTotal =
            deconvertMoney(quantity) *
            deconvertMoney(cost) *
            deconvertMoney(totalPerson);
          methods.setValue(
            `resource.required_items.${index !== key && index}.subtotal`,
            convertMoney(subTotal)
          );
        }
      });

      const otherAssets = methods.watch(`resource.required_items`) || [];
      const otherAssetSum = otherAssets
        .map((item) =>
          parseInt(item?.subtotal ? deconvertMoney(item?.subtotal) : 0)
        )
        .reduce((sum, subTotal) => sum + subTotal, 0);

      const formattedOtherAssetSum = convertMoney(otherAssetSum);
      methods.setValue("total_cost", formattedOtherAssetSum);
      setTotalFunding(formattedOtherAssetSum);
    };

    setIndex(key);
    updateSubtotals();
  };

  return (
    <div className="flex flex-col gap-5">
      <InputForm
        controllerName={"resource.total_person"}
        className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
        label={"Jumlah Narasumber"}
        placeholder={"Jumlah Narasumber"}
        required={false}
        onChangeInput={(e) => {
          onChangeItem(e, Index);
        }}
        disabled={disabled}
      />

      <div className={`flex rounded-lg border-[1px]`}>
        <div className={`flex flex-col w-full`}>
          <div
            className={`flex justify-between px-6 py-[11px] items-center bg-[#E1F1D6] rounded-md`}
          >
            <div className="flex">
              <span className={"text-[#01A24A] font-semibold text-lg"}>
                Daftar Item Kebutuhan
              </span>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-white flex items-center text-xs px-2 py-0.5 rounded-full bg-[#12B76A]">
                Total Kebutuhan :{" "}
                <b className="pl-1"> Rp. {methods.watch("total_cost")}</b>
              </div>
              <button onClick={() => setShow({ ...show, child: !show.child })}>
                <CgChevronDown
                  className={`text-2xl text-primary-700 ${
                    !show.child && "rotate-180"
                  }`}
                />
              </button>
            </div>
          </div>
          <div className="flex flex-col w-full divide-y">
            {show.child &&
              fields.map((field, key) => (
                <div key={field.id} className="flex flex-col gap-4 px-6 py-5">
                  {/* <div className="flex justify-end gap-3 text-end">
                <Button
                  type="button"
                  className="p-3 px-4 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                >
                  Pilih Item
                </Button>
                <button
                  className={`flex-none p-3 px-4 bg-[#D92D20] text-white rounded-md flex justify-center items-center`}
                >
                  <RiDeleteBin6Line />
                </button>
              </div> */}

                  <div className="flex items-center gap-3">
                    <div className="flex flex-col gap-2 w-full">
                      <InputForm
                        controllerName={`resource.required_items.${key}.item_name`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                        label={"Item Kebutuhan"}
                        placeholder={"Item Kebutuhan"}
                        required={false}
                        disabled={disabled}
                      />
                      <div className="flex gap-6">
                        <InputForm
                          controllerName={`resource.total_person`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                          label={"Jumlah Narasumber"}
                          placeholder={"Jumlah Narasumber"}
                          required={false}
                          disabled
                        />
                        <InputForm
                          controllerName={`resource.required_items.${key}.quantity`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                          label={"Volume/frekuensi"}
                          placeholder={"Volume/frekuensi"}
                          required={false}
                          onChangeInput={(e) => {
                            methods.setValue(
                              `resource.required_items.${key}.quantity`,
                              convertMoney(e.target.value)
                            );
                            onChangeItem(e, key, "add");
                          }}
                          disabled={disabled}
                        />

                        <InputForm
                          controllerName={`resource.required_items.${key}.cost`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                          label={"Satuan Biaya (Rp)"}
                          placeholder={"Satuan Biaya (Rp)"}
                          required={false}
                          onChangeInput={(e) => {
                            methods.setValue(
                              `resource.required_items.${key}.cost`,
                              convertMoney(e.target.value)
                            );
                            onChangeItem(e, key);
                          }}
                          disabled={disabled}
                        />
                        <InputForm
                          controllerName={`resource.required_items.${key}.subtotal`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                          label={"Sub Total (Rp)"}
                          placeholder={"Sub Total (Rp)"}
                          required={false}
                          disabled
                        />
                      </div>
                      <AtomSelect
                        className={`py-2 w-full rounded-md focus-within:border-primary-700`}
                        options={activityOptions}
                        controllerName={`resource.required_items.${key}.detail_activity_name`}
                        label={"Detail Kegiatan"}
                        placeholder={"Detail Kegiatan"}
                        required={false}
                        disable={disabled}
                      />
                      <AtomSelect
                        className={`py-2 w-full rounded-md focus-within:border-primary-700`}
                        options={budgetAccountOptions}
                        controllerName={`resource.required_items.${key}.budget_account_name`}
                        label={"Budget Account"}
                        placeholder={"Budget Account"}
                        required={false}
                        disable={disabled}
                      />
                    </div>
                    {key !== 0 && (
                      <button
                        onClick={() => {
                          remove(key);
                          onChangeItem(0, key, "remove");
                        }}
                        className={`flex-none text-xl p-3 px-4 bg-[#D92D20] h-max text-white rounded-md flex justify-center items-center`}
                      >
                        <RiDeleteBin6Line />
                      </button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="text-end">
        <AtomButton
          theme="ghost"
          onClick={() =>
            append({
              item_id: generateUUID(),
              item_name: "",
              quantity: "",
              cost: "",
              subtotal: "",
              detail_activity_id: generateUUID(),
              detail_activity_name: "",
              budget_account_id: generateUUID(),
              budget_account_name: "",
            })
          }
          disabled={disabled}
          className="p-4 normal-case border border-gray-300 rounded-lg"
          label={
            <div className="flex items-center gap-2 font-[14px]">
              <RiAddLine />
              Tambah item kebutuhan
            </div>
          }
        />
      </div>

      {/* end */}
    </div>
  );
};

export default ResourcePerson;

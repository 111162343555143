import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputFormRadio,
  InputFormUpload,
  TitleText,
  InputForm,
} from 'components';
import { useNavigate, useParams } from 'react-router';
import { RiArrowLeftLine, RiCheckboxCircleLine } from 'react-icons/ri';
import {
  getDetailProposal,
  postFinalReview,
} from 'services/danaProgram/callForProposalService';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'helpers';
import {
  FormProvider,
  useForm,
  Controller,
  useFormContext,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { fileUpload } from 'services/danaProgram/fileService';
import { useSelector } from 'react-redux';

const validationSchema = yup.object().shape({});

const ReviewDetail = ({onPrev}) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  let { id } = useParams();
  const userSelector = useSelector((state) => state?.auth?.user || {});
  const { control } = useFormContext();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const verifValue = methods.watch('verifikasi');

  const fetchDetailCfp = async (params) => {
    const res = await getDetailProposal(id, params);
    setData(res.data.data);
  };

  useEffect(() => {
    fetchDetailCfp({ category: 'review' });
  }, []);

  const onSubmit = async (data) => {
    try {
      let params;

      let reviewFile = null;
      if (data.reviewPmuFile && data.reviewPmuFile !== '') {
        const fileFormData = new FormData();
        fileFormData.append('file', data.reviewPmuFile);
        const resFile = await fileUpload(fileFormData);
        reviewFile = await resFile.data.data;
      }

      let safeguardFile = null;
      if (data.reviewSafeguardFile && data.reviewSafeguardFile !== '') {
        const fileFormData = new FormData();
        fileFormData.append('file', data.reviewSafeguardFile);
        const resFile = await fileUpload(fileFormData);
        safeguardFile = await resFile.data.data;
      }

      console.log(data);

      params = {
        cfp_id: `${id}`,
        user_id: `${userSelector?.UserId}`,
        review_result: data.verifikasi,
        reason: data.reason,
        doc_pmu: [
          {
            fileId: reviewFile?.id,
            fileName: reviewFile?.name,
            fileSize: reviewFile?.size,
            mimeType: reviewFile?.mimeType,
            path: reviewFile?.url,
          },
        ],
        // doc_safeguard: [
        //   {
        //     fileId: safeguardFile?.id,
        //     fileName: safeguardFile?.name,
        //     fileSize: safeguardFile?.size,
        //     mimeType: safeguardFile?.mimeType,
        //     path: safeguardFile?.url,
        //   },
        // ],
      };

      submitData.mutateAsync(params);
    } catch (err) {
      console.error(err);
    }
  };

  const submitData = useMutation({
    mutationFn: (params) => {
      return postFinalReview(params);
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: 'Success',
        variant: 'success',
      });
      window.scrollTo({ behavior: 'smooth', top: 0 });
      navigate(`/call-for-proposal`)
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: 'error',
      });
    },
  });

  return (
    <FormProvider {...methods}>
      <div className="space-y-6">
        <Card>
          <CardHeader>
            <div>Hasil Review</div>
          </CardHeader>
          <CardBody>
            <div className="space-y-5">
              <div className="space-y-5">
                <TitleText className="font-bold">
                  Upload Dokumen Review PMU
                </TitleText>
                <InputFormUpload
                  controllerName={'reviewPmuFile'}
                  maxSize={102400}
                  label="Upload Dokumen Review PMU"
                  hideSectionUpload={'hide'}
                />
                {/* <TitleText className="font-bold">
                  Upload Dokumen review Safeguard
                </TitleText>
                <InputFormUpload
                  controllerName={'reviewSafeguardFile'}
                  maxSize={102400}
                  label="Upload Dokumen review Safeguard"
                  hideSectionUpload={"hide"}
                /> */}
                <div className="flex items-center align-middle">
                  <label className="text-[#1D2939] font-bold">
                    Hasil Verifikasi
                  </label>
                  <InputFormRadio
                    controllerName={`verifikasi`}
                    className={`py-0 px-4border w-full rounded-md border-[#D0D5DD]`}
                    values={[
                      { value: 'APPROVE', label: 'Data Terverifikasi' },
                      {
                        value: 'REJECT',
                        label: 'Data Belum Sesuai / Ada Kesalahan',
                      },
                    ]}
                  />
                </div>

                {verifValue === 'REJECT' && (
                  <div className="flex flex-col gap-2">
                    <label className="text-[#1D2939] font-bold">Alasan</label>
                    <InputForm
                      controllerName={`reason`}
                      className={`py-2 border w-full rounded-md flex-1`}
                      label={''}
                      placeholder={''}
                      rows="5"
                      textArea
                    />
                  </div>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={onPrev}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-40"
              onClick={methods.handleSubmit(onSubmit)}
            >
              <RiCheckboxCircleLine className="text-white" />
              Submit
            </Button>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default ReviewDetail;

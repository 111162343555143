import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputFormUpload,
} from "components";
import { FormProvider, useForm } from "react-hook-form";
import { RiArrowLeftLine, RiSave3Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import { UseAnnualWorkPlanContext } from "../../../context/AnnualWorkPlanContext";
import { fileUpload } from "services/danaProgram/fileService";
import config from "app/config";
import axiosInstance from "app/interceptors";
import FileDetail from "components/molecules/FileDetail";
import { useEffect, useState } from "react";
import { ConfirmationModal } from "components/molecules/Modal";

const AddAWPDocument = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const methods = useForm();
  const [isFilled, setIsFilled] = useState();
  const [confirmationModal, setConfirmModal] = useState(false);
  const {
    idAwp,
    setData,
    setCurrentStep,
    currentStep,
    type,
    dataAnnualWorkPlan,
  } = UseAnnualWorkPlanContext();


  const onSubmit = async (data) => {
    if (type === "show") {
      navigate("/annual-work-plan");
    } else if (data?.additional_document !== "" && data?.additional_document?.length > 0) {
      try {
        setIsFilled(true);
        data.id = idAwp;
        data.finish = true;

        let additional_document = [];

        for (let file of data.additional_document) {
          const fileFormData = new FormData();
          fileFormData.append("file", file);
          const resFile = await fileUpload(fileFormData);
          const resDataFile = await resFile.data.data;

          const payloadPerFile = {
            fileId: resDataFile.id,
            fileName: resDataFile.name,
            fileSize: resDataFile.size,
            mimeType: resDataFile.mimeType,
            path: resDataFile.url,
          };

          additional_document.push(payloadPerFile);
        }

        data.additional_document = additional_document;

        const res = await axiosInstance.post(
          `${config.BASE_URL}/api/dana-program/v1/cms/awp/store/fifth-step`,
          data
        );

        if (res.data.code === 200) {
          navigate("/annual-work-plan");
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      setIsFilled(false);
    }
  };

  useEffect(() => {
    if (methods.watch("additional_document") !== "") {
      setIsFilled(true);
    }
  }, [methods.watch("additional_document")]);


  const renderModal = () => {
    return (
      <ConfirmationModal
        open={confirmationModal}
        onClose={() => setConfirmModal(!confirmationModal)}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        Apakah anda akan menyimpan sebagai draft?
      </ConfirmationModal>
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader>Dokumen Pendukung</CardHeader>
            <CardBody>
              {dataAnnualWorkPlan?.additional_document?.length > 0 ? (
                dataAnnualWorkPlan?.additional_document?.map((file, key) => (
                  <FileDetail
                    key={"support-file-annual-work-plan-" + key}
                    file={file}
                    hideDelete
                  />
                ))
              ) : (
                <InputFormUpload
                  controllerName={"additional_document"}
                  maxSize={5000}
                  label="Upload Dokumen Pendukung"
                  isMulti
                  hideSectionUpload={type === "show"}
                  listFiles={dataAnnualWorkPlan?.additional_document ?? []}
                />
              )}
              {!isFilled && (
                <p className="mt-2 text-red-500">Dokumen harus diisi</p>
              )}
            </CardBody>
          </Card>
        </div>

        {/* Button */}
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => setCurrentStep({ currentStep: "4" })}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            {type !== "show" && (
              <>
                {/* <Button
                  type="button"
                  // onClick={methods.handleSubmit(onSubmit)}
                  onClick={() => setConfirmModal(!confirmationModal)}
                  // className="text-[#D0D5DD] border-[#EAECF0]"
                >
                  <RiSave3Line />
                  Simpan & Draft
                </Button> */}
                <Button
                  type="button"
                  className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </div>
        {
          renderModal()
        }
      </div>
    </FormProvider>
  );
};

export default AddAWPDocument;

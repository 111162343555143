import React, { useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { Breadcrumbs } from "components";
import StructureOrganization from "./StructureOrganization";
import InstitutionalGovernance from "./InstitutionalGovernance";
import InstitutionalPlan from "./InstitutionalPlan";
import Partnership from "./Partnership";
import Subgrant from "./Subgrant";
import Competence from "./Competence";
import SupportingDocuments from "./SupportingDocument";
import { FormModal } from "components/molecules/Modal";
import { RiErrorWarningLine } from "react-icons/ri";
import useProfileOrganization from "./hooks/useProfileOrganization";
import { createPortal } from "react-dom";

const ProfileOrganization = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const links = [
    {
      label: "Daftar LEMTARA",
      path: "/verification",
    },
    {
      label: "Detail",
      path: "/verification/detail",
    },
    {
      label: "Profile Organisasi",
    },
  ];

  const TabList = [
    {
      label: "Dokumen Pendukung",
      id: 1,
    },
    {
      label: "Struktur Organisasi",
      id: 2,
    },
    {
      label: "Tata Kelola Kelembagaan",
      id: 3,
    },
    {
      label: "Rencana Kelembagaan",
      id: 4,
    },
    {
      label: "Kemitraan",
      id: 5,
    },
    {
      label: "Sub Grant",
      id: 6,
    },
    {
      label: "Kompetensi",
      id: 7,
    },
  ]

  const {
    profileOrganizationDraftData,
    setProfileOrganizationDraftData,
    onDraftSubmit,
    onBack,
    onSubmit,
    onSubmitConfirmModal,
    onSubmitConfirmBackModal,
    onSubmitConfirmDraftModal,
    temp,
    setTemp,
    confirmModal,
    setConfirmModal,
    confirmBackModal,
    setConfirmBackModal,
    confirmDraftModal,
    setConfirmDraftModal,
  } = useProfileOrganization({
    id: JSON.parse(sessionStorage.getItem('idData'))?.organization_profile
  })

  return (
    <>
      {createPortal(
        <FormModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={async () => await onSubmitConfirmModal()}
          size={"w-2/8"}
          positiveConfirm="Ya"
          negativeConfirm="Tidak"
          withHeader={false}
        >
          <div>
            <div className="p-[5px] bg-[#FCE4CF] w-[50px] h-[50px] flex justify-center items-center rounded-full">
              <RiErrorWarningLine className={"text-[#DC6803] text-[38px]"} />
            </div>
          </div>
          <div className="text-lg font-[600] mt-[10px]">Ubah Data Di Section</div>
          <div className="text-sm font-[400]">Apakah Anda yakin akan mengubah data di section ini?</div>
        </FormModal>,
        document.body
      )}
      {createPortal(
        <FormModal
          open={confirmDraftModal}
          onClose={setConfirmDraftModal}
          onSubmit={async () => await onSubmitConfirmDraftModal()}
          size={"w-2/8"}
          positiveConfirm="Ya"
          negativeConfirm="Tidak"
          withHeader={false}
        >
          <div>
            <div className="p-[5px] bg-[#FCE4CF] w-[50px] h-[50px] flex justify-center items-center rounded-full">
              <RiErrorWarningLine className={"text-[#DC6803] text-[38px]"} />
            </div>
          </div>
          <div className="text-lg font-[600] mt-[10px]">Simpan draft</div>
          <div className="text-sm font-[400]">Apakah Anda yakin menyimpan sebagai draft?</div>
        </FormModal>,
        document.body
      )}
      {createPortal(
        <FormModal
          open={confirmBackModal}
          onClose={setConfirmBackModal}
          onSubmit={async () => await onSubmitConfirmBackModal()}
          size={"w-2/8"}
          positiveConfirm="Ya"
          negativeConfirm="Tidak"
          withHeader={false}
        >
          <div>
            <div className="p-[5px] bg-[#FCE4CF] w-[50px] h-[50px] flex justify-center items-center rounded-full">
              <RiErrorWarningLine className={"text-[#DC6803] text-[38px]"} />
            </div>
          </div>
          <div className="text-lg font-[600] mt-[10px]">Kembali</div>
          <div className="text-sm font-[400]">Setelah anda kembali semua data akan disimpan pada draft</div>
        </FormModal>,
        document.body
      )}
      <div className="space-y-6">
        <Breadcrumbs items={links} />
        <div className="tabs items-center flex-nowrap w-[1150px] overflow-x-auto">
        {
          TabList.map((data) => (
            <a className={`tab flex-nowrap px-3 tab-bordered ${activeTab === data.id ? 'border-b-primary-600 text-primary-600 bg-primary-100 font-bold': ''} hover:text-primary-600 hover:border-primary-700`} onClick={()=> setActiveTab(data.id)}>
              {data.label}
            </a>
          ))
        }
        </div>
        <div>
          {activeTab === 1 && (
            <SupportingDocuments 
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              data={{profile_organization_document: profileOrganizationDraftData?.profile_organization_document}}
              allData={profileOrganizationDraftData}
              setData={setProfileOrganizationDraftData}
            />
          )}
          {activeTab === 2 && (
            <StructureOrganization
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              data={{
                structure_organization: profileOrganizationDraftData?.structure_organization
              }}
              allData={profileOrganizationDraftData}
              setData={setProfileOrganizationDraftData}
            />
          )}
          {activeTab === 3 && (
            <InstitutionalGovernance
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              data={{
                institutional_governance: profileOrganizationDraftData?.institutional_governance
              }}
              allData={profileOrganizationDraftData}
              setData={setProfileOrganizationDraftData}
            />
          )}
          {activeTab === 4 && (
            <InstitutionalPlan
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              data={{
                institutional_plan: profileOrganizationDraftData?.institutional_plan
              }}
              allData={profileOrganizationDraftData}
              setData={setProfileOrganizationDraftData}
            />
          )}
          {activeTab === 5 && (
            <Partnership
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              data={{
                partnership: profileOrganizationDraftData?.partnership
              }}
              allData={profileOrganizationDraftData}
              setData={setProfileOrganizationDraftData}
            />
          )}
          {activeTab === 6 && (
            <Subgrant
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              data={{
                sub_grant: profileOrganizationDraftData?.sub_grant
              }}
              allData={profileOrganizationDraftData}
              setData={setProfileOrganizationDraftData}
            />
          )}
          {activeTab === 7 && (
            <Competence
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              data={{
                competence: profileOrganizationDraftData?.competence,
                status_approval_competences: profileOrganizationDraftData?.status_approval_competences,
                notes_approval_competences: profileOrganizationDraftData?.notes_approval_competences,
              }}
              allData={profileOrganizationDraftData}
              setData={setProfileOrganizationDraftData}
              onSubmit={onSubmit}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileOrganization;

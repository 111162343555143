import React from "react";

const TabHeader = ({ tabs, activeTab, onTabClick }) => {
  return (
    <div className="tabs-header flex">
      {tabs.map((tab, index) => (
        <button
          key={index}
          type="button"
          className={`tab-item px-4 py-2 transition-colors duration-300 w-[90px] ${
            activeTab === index
              ? "border-b-2 border-primary-600 text-primary-600 font-bold"
              : `border-b-2 border-transparent   ${
                  !tab.disable
                    ? `text-gray-400 hover:text-primary-600 cursor-pointer`
                    : `text-gray-200`
                } font-normal`
          }`}
          onClick={() => onTabClick(index)}
          disabled={tab.disable}
        >
          {tab.nama || tab.label}
        </button>
      ))}
    </div>
  );
};

export default TabHeader;

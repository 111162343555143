import config from "app/config";
import axiosInstance from "app/interceptors";

export const getList = (params) => {
  return axiosInstance.get(
    `${config.DAPROG_API_URL}/implementing-partner/list`,
    {
      params,
    }
  );
};

export const getDetail = (params) => {
  return axiosInstance.get(
    `${config.DAPROG_API_URL}/implementing-partner/detail`,
    {
      params,
    }
  );
};

export const createImplementingPartner = (params) => {
  return axiosInstance.post(
    `${config.DAPROG_API_URL}/implementing-partner/submit`,
    params
  );
};

export const deleteImplementingPartner = (params) => {
  return axiosInstance.delete(
    `${config.DAPROG_API_URL}/implementing-partner/delete`,
    { data: params }
  );
};

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import { useEffect, useMemo } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import ProcurementChild from "./Form/ProcurementChild";
import { BiCheck } from "react-icons/bi";
import { useFinancialProfileContext } from "../FinancialProfileState";

const Procurement = ({
  setActiveTab,
  activeTab = 1,
  data = null,
  submitData,
}) => {
  const { FinancialProfileState, setFinancialProfileState } =
    useFinancialProfileContext();
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      tools: Yup.array().of(
        Yup.object().shape({
          result_verification: Yup.string().required(
            `Hasil Verifikasi wajib diisi`
          ),
          reason: Yup.string().nullable(true),
        })
      ),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const { control, trigger } = { ...methods };

  const {
    fields: fieldsTools,
    append: appendTools,
    remove: removeTools,
  } = useFieldArray({
    control,
    name: "tools",
  });

  useEffect(() => {
    appendTools({
      reason: data?.institutional_experience?.reason,
      result_verification: data?.institutional_experience?.result_verification,
      is_any_sop: data?.institutional_experience?.is_any_sop,
      formulate_strategic_plan:
        data?.institutional_experience?.formulate_strategic_plan,
      is_running_mechanism_procurement:
        data?.institutional_experience?.is_running_mechanism_procurement,
      minimum_value_procurement:
        data?.institutional_experience?.minimum_value_procurement,
      value_implementation_procurement:
        data?.institutional_experience?.value_implementation_procurement,
      procurement_experience:
        data?.institutional_experience?.procurement_experience,
      structure_member: data?.institutional_experience?.structure_member,
    });
    // for (let i = 0; i < fieldsTools.length; i++) {
    //   removeTools(i);
    // }
    removeTools(1);
    trigger();
  }, []);

  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <ProcurementChild
          tools={fieldsTools}
          data={data?.institutional_experience}
        />

        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => setActiveTab(activeTab - 1)}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={async () => {
                const isValid = await trigger();
                const formData = methods.getValues();
                setFinancialProfileState((prevState) => ({
                  ...prevState,
                  step_4: formData,
                }));
                submitData(formData);
                // navigate(`/verification/detail`);
              }}
            >
              <BiCheck className="text-[20px] text-[#FFFFFF]" />
              Verifikasi Selesai
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default Procurement;

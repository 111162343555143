import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { RiArrowLeftLine, RiArrowRightLine, RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  InputForm,
  TitleText,
} from "components";
import { BiPencil } from "react-icons/bi";
import InstitutionalFoundingGround from "./Form/FoundingGroundInsitutional";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useGeneralProfileContext } from "../hooks/generalProfileState";

const InstitutionalLegalBasis = ({
  setActiveTab,
  activeTab = 1,
  data = {},
  allData,
  setData,
}) => {
  const { generalProfileState, setGeneralProfileState } =
    useGeneralProfileContext();
  const navigate = useNavigate();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      legal_foundation: Yup.object().shape({
        no_nib: Yup.number()
          .typeError("Nomor NIB harus angka")
          .required("Nomor NIB wajib diisi"),
        no_deed: Yup.number()
          .typeError("Nomor Akta Pendirian/ SK kemenhukam harus angka")
          .required("Nomor Akta Pendirian/ SK Kemenhukam wajib diisi"),
        date_deed: Yup.string()
          .matches(
            /^\d{4}-\d{2}-\d{2}$/,
            'Tanggal harus diisi dengan format "YYYY-MM-DD"'
          )
          .test("is-valid-date", "Format Tanggal tidak Valid", (value) => {
            if (!value) return false;
            const date = new Date(value);
            return !isNaN(date.getTime());
          })
          .required("Tanggal Akta wajib diisi"),
        notary_public: Yup.string().trim().required("Nama Notaris wajib diisi"),
        additional_foundations: Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              .trim()
              .required(`Nama additional foundations wajib diisi`),
            desc: Yup.string()
              .trim()
              .required(`Description additional foundations wajib diisi`),
          })
        ),
        notes_approval: Yup.string().nullable(true),
        status_approval: Yup.string().required("status approval is required"),
      }),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      legal_foundation: {
        status_approval: "APPROVED",
      },
    },
  });

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    trigger,
  } = methods;

  const {
    fields: additionalFoundationsFields,
    append: appendadditionalFoundations,
    remove: removeadditionalFoundations,
  } = useFieldArray({
    control,
    name: "legal_foundation.additional_foundations",
  });

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((keys) => {
          setValue(`${key}.${keys}`, data[key][keys]);
          // console.log(`${key}.${keys}`, data[key][keys])
        });
      });
      trigger();
    }
  }, [data, setValue, methods]);

  const saveData = async () => {
    const isValid = await trigger();
    const allValues = getValues();
    allData.legal_foundation = allValues.legal_foundation;
    setGeneralProfileState((prevState) => ({
      ...prevState,
      legal_foundation: allValues.legal_foundation,
    }));
    setData(allData);
    return isValid;
  };

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <InstitutionalFoundingGround
          additionalFoundationsFields={additionalFoundationsFields}
          appendadditionalFoundations={appendadditionalFoundations}
          removeadditionalFoundations={removeadditionalFoundations}
        />
        {/* <Card>
        <CardHeader>
          <div>Rekomendasi Verificator</div>
        </CardHeader>
        <CardBody>
          <div className="space-y-5">
            <div className="space-y-2">
              <TitleText className="font-bold">
                Rekomendasi Verificator
              </TitleText>
              <InputForm
                controllerName={"verificatorRecomendation"}
                className={`py-2 border w-full rounded-md flex-1`}
                label={''}
                placeholder={'Verifikasi Remokendasi'}
                textArea
              />
            </div>
          </div>
        </CardBody>
      </Card> */}
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() =>
                activeTab === 1 ? navigate(-1) : setActiveTab(activeTab - 1)
              }
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          {sessionStorage.getItem("isLemtaraFormDisabled") !== "true" && (
            <div className="block md:flex items-center justify-between gap-2">
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={async () => {
                  const validData = await saveData();
                  if (validData) {
                    setActiveTab(activeTab + 1);
                  }
                }}
              >
                Selanjutnya
                <RiArrowRightLine />
              </Button>
            </div>
          )}
        </div>
      </FormProvider>
    </div>
  );
};

export default InstitutionalLegalBasis;

import { Card, CardBody, CardHeader } from "components";
import { useNavigate } from "react-router";
import KinerjaFiskal from "./KinerjaFiskal";
import DukunganPublik from "./DukunganPublik";
import EfisiensiProgram from "./EfisiensiProgram";
import EfisiensiPerhimpunanDana from "./EfisiensiPerhimpunanDana";

const NonProfitRasioValue = ({
  data = {}
}) => {
  const navigate = useNavigate()
  
  return (
    <div>
      <Card>
        <CardHeader>
          <div>Non Profit/ Nirlaba/ Tidak Mencari Keuntungan</div>
        </CardHeader>
        <CardBody>
          <div classNamee="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col gap-[20px]">
              <KinerjaFiskal data={data?.fiscal_performance_ratio} />
              <DukunganPublik data={data?.public_support_ratio} />
              <EfisiensiProgram data={data?.program_efficiency_ratio} />
              <EfisiensiPerhimpunanDana data={data?.fund_efficiency_ratio} />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default NonProfitRasioValue;
import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { APPROVAL_STATUSSES } from "components/organisms/Approval2Level/constant";
import { CustomSelect, SearchInput } from "components";

const ListFilter = ({ onFilterChange }) => {
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");

  const methods = useForm({
    resolver: yupResolver(yup.object().shape({})),
    mode: "onChange",
  });

  const onSearch = debounce((event) => {
    setName(event?.target?.value || "");
  }, 1000);

  const onChangeStatus = useCallback((obj) => {
    setStatus(obj.value || undefined);
  }, []);

  useEffect(() => {
    if (onFilterChange) onFilterChange({ name, status });
  }, [onFilterChange, name, status]);

  return (
    <FormProvider {...methods}>
      <div className="space-x-2 flex">
        <SearchInput
          controllerName={`search`}
          placeholder="Cari"
          onChange={onSearch}
        />

        <CustomSelect
          optionsData={APPROVAL_STATUSSES}
          placeholder="Status"
          inputClassName="5px"
          className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
          onChange={onChangeStatus}
        />
      </div>
    </FormProvider>
  );
};

export default ListFilter;

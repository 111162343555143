import { Button, SearchInput } from 'components';
import { TableWrapper } from 'components/molecules/ListPage';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { dummyLists } from './Dummy/lists';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { BsExclamationCircle } from 'react-icons/bs';
import {
  useDeleteImplementingPartner,
  useImplementingPartnerList,
} from './hooks/useImplementingPartnerServices';
import { debounce } from 'lodash';

const links = [
  {
    label: 'Daftar Implementing Partner',
  },
];

const ImplementingPartner = () => {
  const methods = useForm();
  const navigate = useNavigate();

  const [qParams, setQParams] = useState({
    name: '',
    limit: 10,
    page: 1,
    orderMethod: 'desc',
    orderBy: 'created_at',
  });
  const [showModal, setShowModal] = useState(false);
  const [dataId, setDataId] = useState('');
  const [isProjectExist, setIsProjectExist] = useState(false);

  const { list, pagination, refetch } = useImplementingPartnerList(qParams);
  const { deleteData } = useDeleteImplementingPartner();

  const tableColumns = useMemo(() => {
    return [
      {
        id: 'no',
        title: 'No',
        dataIndex: 'no',
        className: 'overflow-hidden',
        columnClassName: 'w-[50px]',
        render: (value, data, index) => {
          const no =
            parseInt(qParams.page) * parseInt(qParams.limit) -
            parseInt(qParams.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: 'name',
        title: 'Nama Implementing partner',
        dataIndex: 'name',
        className: 'overflow-hidden text-ellipsis',
        sortable: true,
        fixed: '',
        columnClassName: 'text-ellipsis overflow-hidden w-60',
        render: (value, row) => (
          <div className="overflow-hidden text-ellipsis">{value}</div>
        ),
      },
      {
        id: 'city',
        title: 'lokasi',
        dataIndex: 'city',
        className: '',
        fixed: '',
        headerClassName: 'text-left',
        columnClassName: 'text-ellipsis overflow-hidden',
        sortable: true,
        render: (value, row) => (
          <div className="w-full overflow-hidden text-ellipsis">
            {value.name}
          </div>
        ),
      },
      {
        id: 'institution',
        title: 'jenis lembaga/ perusahaan',
        dataIndex: 'institution',
        className: '',
        fixed: '',
        headerClassName: 'text-left',
        columnClassName: 'text-ellipsis overflow-hidden',
        sortable: true,
        render: (value, row) => (
          <div className="w-full overflow-hidden text-ellipsis">
            {value.name}, {row.province.name}
          </div>
        ),
      },
      // {
      //   id: "desc",
      //   title: "Jumlah Proyek",
      //   dataIndex: "total",
      //   className: "",
      //   fixed: "",
      //   headerClassName: "text-left",
      //   columnClassName: "text-ellipsis overflow-hidden",
      //   sortable: true,
      //   render: (value, row) => (
      //     <div className="w-full overflow-hidden text-ellipsis">
      //       {value ? value : "0"}
      //     </div>
      //   ),
      // },
      {
        id: 'actions',
        title: 'Aksi',
        dataIndex: '_id',
        className: '',
        sortable: true,
        columnClassName: 'w-[250px] text-center text-ellipsis overflow-hidden',
        fixed: 'center',
        render: (value, data, index) => {
          return value ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                {/* <Button
                  type="button"
                  // disabled={submitMutation.isLoading}
                  className="p-2 text-xs text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                  onClick={() => {
                    // handleSubmitForm();
                    navigate(`/implementing-partner/add-project/${value}`);
                  }}
                >
                  Tambah Proyek
                </Button> */}
                <div className="flex items-center justify-center w-full space-x-3">
                  <MdOutlineRemoveRedEye
                    className="text-gray-600 cursor-pointer"
                    size={20}
                    onClick={() => navigate(`/implementing-partner/${value}`)}
                  />
                  <FaRegEdit
                    className="text-gray-600 cursor-pointer"
                    size={20}
                    onClick={() =>
                      navigate(`/implementing-partner/edit/${value}`)
                    }
                  />
                  <FaRegTrashAlt
                    className={`text-red-600 cursor-pointer`}
                    size={20}
                    onClick={() => {
                      if (!data?.project_exist) {
                        setDataId(data._id);
                        setShowModal(!showModal);
                        setIsProjectExist(data?.project_exist);
                      } else {
                        setShowModal(!showModal);
                        setIsProjectExist(data?.project_exist);
                      }
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            ''
          );
        },
      },
    ];
  }, []);

  const onChangePage = useCallback(
    (val) => setQParams({ ...qParams, page: val }),
    []
  );

  const onChangeRowsPerPage = useCallback(
    (val) => setQParams({ ...qParams, limit: val }),
    []
  );

  const onSearch = debounce(
    useCallback(
      (event) => {
        setQParams({ ...qParams, name: event?.target?.value || '' });
      },
      [setQParams]
    ),
    1000
  );

  useEffect(() => {
    refetch();
  }, [qParams]);

  const handleDeleteData = async () => {
    try {
      deleteData.mutate(
        { id: dataId },
        {
          onSuccess: (res) => {
            setShowModal(!showModal);
            refetch();
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <TableWrapper
        links={links}
        title={'Daftar Implementing Partner'}
        btnSize={'md'}
        columns={tableColumns}
        dataSource={list}
        onCreate={() => navigate('/implementing-partner/create')}
        sortMethod={'desc'}
        setSortMethod={() => console.log('first')}
        setSortBy={() => console.log('first')}
        customTextAddButton="Tambah"
        pagination={pagination}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        customFilter={
          <FormProvider {...methods}>
            <div className="flex space-x-2">
              <SearchInput
                controllerName={`search`}
                placeholder="Cari"
                onChange={onSearch}
              />
            </div>
          </FormProvider>
        }
      />

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/70 backdrop-blur-sm">
          <div className="flex flex-col items-center justify-center gap-5 p-5 bg-white rounded-xl w-96">
            <BsExclamationCircle size={50} className="text-red-500" />
            {!isProjectExist ? (
              <>
                <div className="w-full font-semibold text-center">
                  Apakah anda yakin akan menghapus Data ini?
                </div>
                <div className="flex items-center justify-center w-full gap-5 text-sm">
                  <button
                    onClick={() => setShowModal(!showModal)}
                    className="px-5 py-2 border border-gray-500 rounded-lg"
                  >
                    Batal
                  </button>
                  <button
                    onClick={handleDeleteData}
                    disabled={deleteData.isLoading}
                    className="px-5 py-2 font-semibold text-white bg-red-500 border border-red-500 rounded-lg"
                  >
                    Ya
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="w-full font-semibold text-center">
                  Data tidak dapat dihapus karena sudah digunakan pada data lain
                </div>
                <div className="flex items-center justify-center w-full gap-5 text-sm">
                  <button
                    onClick={() => setShowModal(!showModal)}
                    className="px-5 py-2 border border-gray-500 rounded-lg"
                  >
                    Batal
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImplementingPartner;

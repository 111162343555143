export const SAVE_MASTER_DATA = "SAVE_MASTER_DATA";
export const RESET_MASTER_DATA = "RESET_MASTER_DATA";
export const UPDATE_MASTER_DATA = "UPDATE_MASTER_DATA";
export const SAVE_FINANCIAL_PROFILE = "SAVE_FINANCIAL_PROFILE";
export const RESET_FINANCIAL_PROFILE = "RESET_FINANCIAL_PROFILE";
export const UPDATE_FINANCIAL_PROFILE = "UPDATE_FINANCIAL_PROFILE";
export const SAVE_WORK_PLAN = "SAVE_WORK_PLAN";
export const RESET_WORK_PLAN = "RESET_WORK_PLAN";
export const UPDATE_WORK_PLAN = "UPDATE_WORK_PLAN";
export const ADD_OIDC_USER = "ADD_OIDC_USER";
export const REMOVE_OIDC_USER = "REMOVE_OIDC_USER";

export const addOidcUser = (user) => {
  console.log(user);
  console.log(user.profile);

  return {
    type: ADD_OIDC_USER,
    payload: user,
  };
};

export const removeOidcUser = () => {
  return {
    type: REMOVE_OIDC_USER,
  };
};

export const updateMasterData = (updatedData) => ({
  type: UPDATE_MASTER_DATA,
  payload: updatedData,
});
export const updateFinancialProfile = (updatedData) => ({
  type: UPDATE_FINANCIAL_PROFILE,
  payload: updatedData,
});
export const updateWorkPlan = (updatedData) => ({
  type: UPDATE_WORK_PLAN,
  payload: updatedData,
});

export const removeWorkPlan = () => {
  return {
    type: RESET_WORK_PLAN,
  };
};

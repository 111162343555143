import React, { useCallback, useContext, useEffect, useState } from "react";
import { removeToken } from "features/auth/authSlice";
import { useDispatch } from "react-redux";
import { RiNotification2Line } from "react-icons/ri";

import { AuthContext } from "providers/authProvider";

const Header = () => {
  const dispatch = useDispatch();

  const authContext = useContext(AuthContext);
  const [userData, setUserData] = useState();

  const user = JSON.parse(
    sessionStorage.getItem(
      "oidc.user:https://identity.bpldh-dev.groot.id:web_app_enduser"
    )
  );

  useEffect(() => {
    const getUserData = async () => {
      try {
        const user = await authContext?.getUser?.();
        setUserData(user);
      } catch (e) {
        console.log(e);
      }
    };
    getUserData();
  }, [authContext]);

  const handleLogout = useCallback(() => {
    if (window.confirm("Logout?")) {
      localStorage.clear();
      authContext.logout();
    }
  }, [authContext]);

  return (
    <nav className="navbar border-b top-0" style={{ zIndex: 99 }}>
      <div className="flex-1">
        <a className="btn btn-ghost normal-case rounded-lg border border-gray-400"
          href={process.env.REACT_APP_AUTH_URL + '/Portal/Bpdlh'}
        >
          Portal Menu E-Office
        </a>
      </div>
      <div className="flex-none gap-2">
        <div className="dropdown dropdown-end">
          <div className="flex items-center space-x-2">
            {/* <div className="text-sm text-gray-500">incubagong@gmail.com</div> */}
            <div>
              <RiNotification2Line />
            </div>
            {user}
            <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full" onClick={handleLogout}>
                <img src="https://placeimg.com/80/80/people" />
              </div>
            </label>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
} from "components";
import { kebabCase } from "lodash";
import { UploadImage } from "pages/Tor/assets";
import { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

const links = [
  {
    label: "Daftar Implementing Partner",
    path: "/implementing-partner",
  },
  {
    label: "Tambah data proyek",
  },
];

const ImplementingPartnerAddProject = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  const inputRef = useRef(null);
  const anotherInputRef = useRef(null);

  return (
    <div>
      <Breadcrumbs items={links} />
      <FormProvider {...methods}>
        <div className="flex flex-col gap-6 pt-8">
          <Card>
            <CardHeader>Data proyek</CardHeader>
            <CardBody>
              <div className="flex flex-col gap-5">
                <InputForm
                  controllerName={`name`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Nama Proyek"}
                  placeholder={"Nama Proyek"}
                  required={true}
                />
                <InputForm
                  controllerName={`name`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"KL Pengampu"}
                  placeholder={"KL Pengampu"}
                  required={true}
                />
                <InputForm
                  textArea={true}
                  controllerName={"description"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Judul Kegiatan"}
                  placeholder={"Judul Kegiatan"}
                  required={true}
                />
                <InputForm
                  controllerName={`name`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Lokasi Kegiatan"}
                  placeholder={"Lokasi Kegiatan"}
                  required={true}
                />
                <InputForm
                  controllerName={`name`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Periode Kegiatan"}
                  placeholder={"Periode Kegiatan"}
                  required={true}
                />
                <div className="grid grid-cols-2 gap-5">
                  <InputForm
                    controllerName={`name`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                    label={"Provinsi"}
                    placeholder={"Provinsi"}
                    required={true}
                  />
                  <InputForm
                    controllerName={`name`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                    label={"Kota/ Kab"}
                    placeholder={"Kota/ Kab"}
                    required={true}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <div className="pb-3">
                    <label
                      className="label font-semibold text-[14px]"
                      htmlFor={kebabCase(
                        "Upload Dokumen Proposal yang telah disetujui"
                      )}
                    >
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>
                          Upload Dokumen Proposal yang telah disetujui
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </div>
                    </label>
                  </div>
                  <div
                    onClick={() => inputRef.current.click()}
                    className="flex flex-col items-center justify-center p-4 border border-dashed cursor-pointer rounded-xl"
                  >
                    <input
                      type="file"
                      ref={inputRef}
                      style={{ display: "none" }}
                      multiple
                    />

                    <img src={UploadImage} alt="" />
                    <div className="flex flex-col items-center justify-center pt-3">
                      <p className="font-normal text-sm text-[#475467]">
                        <span className="font-semibold text-primary-700">
                          Klik untuk upload
                        </span>{" "}
                        atau drag and drop
                      </p>
                      <p className="font-normal text-xs text-[#475467] pt-1">
                        PNG, JPG, PDF,Docx, Xlsx up to 10MB
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <InputForm
                    controllerName={`name`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                    label={"No Perjanjian"}
                    placeholder={"No Perjanjian"}
                    required={true}
                  />
                  <InputForm
                    controllerName={`name`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                    label={"Tanggal Perjanjian"}
                    placeholder={"Tanggal Perjanjian"}
                    required={true}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <div className="pb-3">
                    <label
                      className="label font-semibold text-[14px]"
                      htmlFor={kebabCase(
                        "Upload Dokumen Perjanjian dan dokumen pendukung lainnya"
                      )}
                    >
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>
                          Upload Dokumen Perjanjian dan dokumen pendukung
                          lainnya
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </div>
                    </label>
                  </div>
                  <div
                    onClick={() => anotherInputRef.current.click()}
                    className="flex flex-col items-center justify-center p-4 border border-dashed cursor-pointer rounded-xl"
                  >
                    <input
                      type="file"
                      ref={anotherInputRef}
                      style={{ display: "none" }}
                      multiple
                    />

                    <img src={UploadImage} alt="" />
                    <div className="flex flex-col items-center justify-center pt-3">
                      <p className="font-normal text-sm text-[#475467]">
                        <span className="font-semibold text-primary-700">
                          Klik untuk upload
                        </span>{" "}
                        atau drag and drop
                      </p>
                      <p className="font-normal text-xs text-[#475467] pt-1">
                        PNG, JPG, PDF,Docx, Xlsx up to 10MB
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="items-center justify-between block px-6 py-5 mt-4 mb-10 bg-white border border-gray-200 rounded-lg md:flex">
          <div>
            <Button
              type="button"
              className="px-8 text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
              onClick={() => {
                navigate("/implementing-partner");
              }}
            >
              Batal
            </Button>
          </div>

          <div className="items-center justify-between block gap-2 md:flex">
            <Button
              type="button"
              // disabled={submitMutation.isLoading}
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={() => {
                // handleSubmitForm();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default ImplementingPartnerAddProject;

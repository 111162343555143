import { useMutation } from "@tanstack/react-query";
import { submitTorStepOne } from "services/danaProgram/torService";

export const useTorMutations = () => {
  const submitMutation = useMutation({
    mutationFn: (params) => {
      return submitTorStepOne(params);
    },
  });

  return {
    submitMutation,
  };
};

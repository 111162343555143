import React, { useState, useEffect } from "react";
import { map } from "lodash";
import { useLocation, useNavigate } from "react-router";
import image from "assets/Logo.png";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import _ from "lodash";
import { AnimatePresence, motion } from "framer-motion";
import danaProgramMenus from "constants/Menus/dana-program";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const Sidebar = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(true);

  return (
    <aside
      className="relative flex-col hidden min-h-screen overflow-hidden bg-gray-900 border-gray-500 sm:flex"
      style={{
        boxShadow: "1px 1px 5px 1px #0000001F",
        flex: `0 0 ${isOpen ? "20%" : "80px"}`,
        maxWidth: `${isOpen ? "20%" : "80px"}`,
        minWidth: `${isOpen ? "20%" : "80px"}`,
        width: `${isOpen ? "20%" : "80px"}`,
        transition: "all .2s",
      }}
    >
      <div className="flex-1">
        <div
          className={`${
            isOpen ? "ml-4" : "ml-8"
          } mt-8 flex relative items-center`}
        >
          <div className="flex-1">
            <img
              src={image}
              alt="logo"
              className="w-full h-auto max-w-[140px]"
            />
          </div>
          <button
            className="relative bg-gray-600 rounded-tl-lg rounded-bl-lg w-[34px] h-[44px]"
            onClick={() => setOpen((curr) => !curr)}
          >
            {isOpen ? (
              <AiOutlineArrowLeft className="text-white w-[14px] h-[14px] absolute translate-y-[-50%] translate-x-[-50%] top-[50%] left-[50%]" />
            ) : (
              <AiOutlineArrowRight className="text-white w-[14px] h-[14px] absolute translate-y-[-50%] translate-x-[-50%] top-[50%] left-[50%]" />
            )}
          </button>
        </div>
        <ul className="m-4 space-y-2 overflow-y-auto flex-2">
          {map(danaProgramMenus, ({ label, icon, path, id, child }, key) => (
            <MenuItem
              key={key}
              label={label}
              icon={icon}
              path={path}
              id={id}
              child={child}
              isOpenSidebar={isOpen}
            />
          ))}
        </ul>
      </div>
      <div
        className={`text-white font-normal text-sm flex-none flex items-end justify-center mb-4 ${
          !isOpen ? "px-2" : ""
        }`}
      >
        {`${isOpen ? "© BPDLH 2023, Version 1.0" : "© BPDLH"}`}
      </div>
    </aside>
  );
};

const MenuItem = ({
  label,
  icon,
  path,
  id,
  child,
  key,
  isOpenSidebar = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const handlePathIsActive = (path) => {
    if (_.isArray(path)) {
      return _.find(path, (list) =>
        list === location?.pathname ? true : false
      );
    } else {
      return location?.pathname.includes(path);
    }
  };

  useEffect(() => {
    handlePathIsActive(path) && setIsOpen(true);
  }, [location?.pathname]);

  return (
    <li key={key} className="space-y-2 cursor-pointer">
      <Link
        to={child?.length > 0 ? "#" : path}
        onClick={() => (child?.length > 0 ? setIsOpen(!isOpen) : null)}
        className={`flex items-center justify-between flex-1 rounded-lg p-2 ${
          !isOpenSidebar ? "justify-center" : ""
        } hover:bg-primary-700 ${handlePathIsActive(path) && "bg-primary-700"}`}
      >
        <div className="flex items-center space-x-1 text-sm font-semibold text-white">
          {icon}
          {isOpenSidebar && <span>{label}</span>}
        </div>
        {child?.length > 0 && (
          <span>
            {isOpen ? (
              <FiChevronUp className="text-xl text-white" />
            ) : (
              <FiChevronDown className="text-xl text-white" />
            )}
          </span>
        )}
      </Link>
      {isOpenSidebar && child?.length > 0 && isOpen && (
        <div className="space-y-2">
          {map(child, ({ label, path, id }, key) => (
            <Link
              to={path}
              className={`p-2 pl-6 font-bold text-sm ${
                location?.pathname === path ? "text-primary-600" : "text-white"
              } rounded-lg flex items-center ${
                !isOpenSidebar ? "justify-center" : ""
              } hover:bg-primary-900 hover:text-primary-600 ${
                location?.pathname === path && "bg-primary-900"
              }`}
            >
              {location?.pathname === path && (
                <>
                  <div className="w-2 h-2 mr-3 rounded-full bg-primary-600" />
                </>
              )}
              {isOpenSidebar && <span className="text-sm">{label}</span>}
            </Link>
          ))}
        </div>
      )}
    </li>
  );
};

export default Sidebar;

import { useMutation, useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import { useSnackbar } from "notistack";
import {
  createImplementingPartner,
  deleteImplementingPartner,
  getDetail,
  getList,
} from "services/danaProgram/implementingPartnerServices";
import {
  getCities,
  getCitiesByProvinceId,
  getInstitutionTypes,
  getProvinces,
  getSubdistricts,
  getVillages,
} from "services/danaProgram/masterDataService";
import { getBankList } from "services/danaProgram/procurementsService";

export const useImplementingPartnerList = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["implementing-partner-lists", params],
    () => getList(queryParams),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
    }
  );

  return {
    list: data?.data?.data?.data || [],
    pagination:
      {
        lastPage: data?.data?.data?.lastPage,
        page: data?.data?.data?.page,
        perPage: data?.data?.data?.perPage,
        total: data?.data?.data?.total,
      } || {},
    isLoading,
    isFetching,
    refetch,
  };
};

export const useImplementingPartnerDetail = (id) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isFetching, refetch } = useQuery(
    ["implementing-partner-detail", id],
    () => getDetail({ id: id }),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  return {
    detail: data?.data?.data,
    detailIsLoading: isFetching,
    refetch,
  };
};

export const useInstitutionTypeList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["institution-types-lists"],
    () => getInstitutionTypes(),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
    }
  );

  return {
    institutionList: data?.data?.data || [],
    institutionListIsLoading: isLoading,
    institutionListIsFetching: isFetching,
    refetch,
  };
};

export const useProvinceList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["province-lists"],
    () => getProvinces(),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
    }
  );

  return {
    provinceList: data?.data?.data || [],
    provinceListIsLoading: isLoading,
    provinceListIsFetching: isFetching,
    refetch,
  };
};

export const useCityList = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["city-lists", params],
    () => getCities(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: !!params,
    }
  );

  return {
    cityList: data?.data?.data || [],
    cityListIsLoading: isLoading,
    cityListIsFetching: isFetching,
    cityRefresh: refetch,
  };
};

export const useDistrictList = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["district-lists", params],
    () => getSubdistricts(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: !!params,
    }
  );

  return {
    districtList: data?.data?.data || [],
    districtListIsLoading: isLoading,
    districtListIsFetching: isFetching,
    refetch,
  };
};

export const useSubdistrictList = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["subdistrict-lists", params],
    () => getVillages(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: !!params,
    }
  );

  return {
    subdistrictList: data?.data?.data || [],
    subdistrictListIsLoading: isLoading,
    subdistrictListIsFetching: isFetching,
    refetch,
  };
};

export const useBankList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["bank-lists"],
    () => getBankList(),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
    }
  );

  return {
    bankList: data?.data?.data || [],
    bankListIsLoading: isLoading,
    bankListIsFetching: isFetching,
    refetch,
  };
};

export const useCreateImplementingPartner = () => {
  const submitMutation = useMutation({
    mutationFn: (params) => {
      return createImplementingPartner(params);
    },
  });

  return {
    submitMutation,
  };
};

export const useDeleteImplementingPartner = () => {
  const deleteData = useMutation({
    mutationFn: (params) => {
      return deleteImplementingPartner(params);
    },
  });

  return {
    deleteData,
  };
};

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import ApexCharts from 'apexcharts';

import {
  TitleText,
  Breadcrumbs,
  Card,
  CardHeader,
  CardBody,
  CardForm,
  Select,
  CustomSelect,
  AtomDatePicker,
} from 'components';
import { convertMoney } from 'helpers';
import {
  BeneficiariesOpt,
  DanaOpt,
  FullProposalOpt,
  IndicatorChartOpt,
  KonsepNoteOpt,
  OutcomeOpt,
  OutputOpt,
  PenyaluranLineOpt,
  PertumbuhanLineOpt,
  ProgressChartOpt,
  ProyekDonutOpt,
  ProyekLineOpt,
  TeamProyekOpt,
} from './dummy';
import { IoDocument } from 'react-icons/io5';
import { MdGroups, MdOutlineMan2, MdOutlineWoman2 } from 'react-icons/md';
import { GiMoneyStack, GiReceiveMoney, GiPayMoney } from 'react-icons/gi';
import AtomSelect from 'components/atoms/Select';
import { GrDocumentPerformance, GrDocumentStore } from 'react-icons/gr';
import { RiExchangeFundsLine } from 'react-icons/ri';
import ReactDatePicker from 'react-datepicker';
import useDashboard from './hooks/useDashboard';
import moment from 'moment';
import { isEmpty } from 'lodash';
import DonutChart from './charts/DonutChart';
import BarChart from './charts/BarChart';

const Verification = () => {
  const chartRef = useRef(null);
  const ProyekDonutChartRef = useRef(null);

  const Total1DonutChartRef = useRef(null);
  const Total3DonutChartRef = useRef(null);

  const ProyekLineChartRef = useRef(null);

  // const TahunanChartRef = useRef(null);
  // const BulananChartRef = useRef(null);
  const PertumbuhanLineRef = useRef(null);
  const IndicatorChartRef = useRef(null);

  // const ProgressChartRef = useRef(null);
  // const DanaRef = useRef(null);

  // const OutcomeRef = useRef(null);
  // const OutputRef = useRef(null);

  // const SubOutputRef = useRef(null);
  // const OutputProyekRef = useRef(null);

  const currentDate = new Date(),
    y = currentDate.getFullYear(),
    m = currentDate.getMonth();
  const firstDay = new Date(y, m, 1);

  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(currentDate);

  const [optionListPendonor, setOptionListPendonor] = useState([
    { label: null, value: null, commitment: {}, realization: {} },
  ]);
  const [filterPendonor, setFilterPendonor] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const links = [
    {
      label: 'Dashboard ToC',
    },
  ];

  const params = {
    start_date: startDate ? moment(startDate).format('DD/MM/YYYY') : null,
    end_date: endDate ? moment(endDate).format('DD/MM/YYYY') : null,
  };

  const {
    totalCommitmentData,
    refetchTotalCommitmentData,
    totalReportedFundData,
    refetchTotalReportedFundData,
    totalDistributionMechanismData,
    refetchTotalDistributionMechanismData,
    detailDistributionData,
    refetchDetailDistributionData,
    totalDonorData,
    refetchTotalDonorData,
    cfpConceptNotesData,
    refetchCfpConceptNotesData,
    userBeneficiariesData,
    refetchUserBeneficiariesData,
    projectTeamsData,
    refetchProjectTeamsData,
  } = useDashboard({
    params,
  });

  useEffect(() => {
    refetchTotalCommitmentData();
    refetchTotalReportedFundData();
    refetchTotalDistributionMechanismData();
    refetchDetailDistributionData();
    refetchTotalDonorData();
    refetchCfpConceptNotesData();
    refetchUserBeneficiariesData();
    refetchProjectTeamsData();
  }, []);

  useEffect(() => {
    if (!isEmpty(detailDistributionData?.commitment)) {
      const optionList = [];
      let commitment = {};
      let realization = {};
      detailDistributionData?.commitment.forEach((item) => {
        commitment = {};
        realization = {};
        item?.projects.forEach((proj) => {
          commitment = { ...commitment, [proj._id]: proj.sum };
        });

        detailDistributionData.realization
          .filter((e) => e.name === item.name)
          .forEach((item2) => {
            item2?.projects.forEach((proj) => {
              realization = { ...realization, [proj._id]: proj.sum };
            });
          });
        optionList.push({
          label: item.name,
          value: item.name,
          commitment: commitment,
          realization: realization,
        });
      });
      setOptionListPendonor(optionList);
    }
  }, [detailDistributionData]);

  useEffect(() => {
    refetchTotalCommitmentData();
    refetchTotalReportedFundData();
  }, [startDate, endDate]);

  useEffect(() => {
    const options = {
      chart: {
        type: 'line',
        height: '323.5px',
      },

      colors: ['#00BD52', '#F04438', '#2970FF'],

      series: [
        {
          name: 'Dana Hibah yg Masuk',
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
        },
        {
          name: 'Dana yang Udah Disalurkan',
          data: [60, 70, 91, 30, 40, 35, 50, 49, 150],
        },
        {
          name: 'Dana yang Sudah Dilaporkan',
          data: [21, 34, 80, 45, 47, 69, 72, 81, 105],
        },
      ],
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024],
      },
      markers: {
        size: 6,
      },
    };
    const DonutOptions = {
      chart: {
        type: 'donut',
        height: '290px',
      },
      colors: ['#12B76A', '#F79009', '#F04438', '#2E90FA'],
      plotOptions: {
        pie: {
          donut: {
            size: '50%',
          },
        },
      },

      series: [30000000, 36000000, 25000000, 59000000],
      labels: ['Thematic 1', 'Thematic 2', 'Thematic 3', 'Thematic 4'],
      legend: {
        position: 'bottom',
        formatter: function (val, opts) {
          return `${val}: Rp. ${convertMoney(
            opts.w.globals.series[opts.seriesIndex]
          )}`;
        },
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    const ProyekLineChart = new ApexCharts(
      ProyekLineChartRef.current,
      ProyekLineOpt
    );
    const donutProyekChart = new ApexCharts(
      ProyekDonutChartRef.current,
      ProyekDonutOpt
    );
    const donutTotalChart = new ApexCharts(
      Total1DonutChartRef.current,
      ProyekDonutOpt
    );

    const donutTotal3Chart = new ApexCharts(
      Total3DonutChartRef.current,
      ProyekDonutOpt
    );

    const PertumbuhanChart = new ApexCharts(
      PertumbuhanLineRef.current,
      PertumbuhanLineOpt
    );
    const IndicatorChart = new ApexCharts(
      IndicatorChartRef.current,
      IndicatorChartOpt
    );
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />
      <div className="py-1 bg-[#F9FAFB] w-full rounded-lg">
        <span className="text-2xl font-semibold">Dashboard ToC</span>
      </div>
      <Card>
        <CardHeader>
          <div>Theory of Changes</div>
        </CardHeader>
        <CardBody>
          <div className="w-full bg-[#01A24A] mb-4 p-4 rounded-xl text-white">
            <p className="text-lg font-semibold mb-3">Impact</p>
            <div className="flex flex-row gap-4">
              <div className="flex-1">
                <CardForm label={`% Progress Capaian`} className={`bg-white`}>
                  {cfpConceptNotesData?.note_concept?.length > 0 ? (
                    <DonutChart
                      data={cfpConceptNotesData?.note_concept.reduce(
                        (data, value) => {
                          data[value._id] = value.count;
                          return data;
                        },
                        {}
                      )}
                      params={{}}
                    />
                  ) : (
                    <span class="italic text-slate-400">
                      Data tidak tersedia
                    </span>
                  )}
                </CardForm>
              </div>
              <div className="flex-1">
                <CardForm label={`Konsumsi Dana`} className={`bg-white`}>
                  {cfpConceptNotesData?.note_concept?.length > 0 ? (
                    <DonutChart
                      data={cfpConceptNotesData?.note_concept.reduce(
                        (data, value) => {
                          data[value._id] = value.count;
                          return data;
                        },
                        {}
                      )}
                      params={{}}
                    />
                  ) : (
                    <span class="italic text-slate-400">
                      Data tidak tersedia
                    </span>
                  )}
                </CardForm>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-4">
              <div className="flex-1">
                <CardForm label={`Outcome`} className={`bg-white`}>
                  {userBeneficiariesData?.length > 0 ? (
                    <BarChart
                      data={userBeneficiariesData.reduce((data, value) => {
                        data[value._id] = value.count;
                        return data;
                      }, {})}
                    />
                  ) : (
                    <span class="italic text-slate-400">
                      Data tidak tersedia
                    </span>
                  )}
                </CardForm>
              </div>
              <div className="flex-1">
                <CardForm label={`Output`} className={`bg-white`}>
                  {userBeneficiariesData?.length > 0 ? (
                    <BarChart
                      data={userBeneficiariesData.reduce((data, value) => {
                        data[value._id] = value.count;
                        return data;
                      }, {})}
                    />
                  ) : (
                    <span class="italic text-slate-400">
                      Data tidak tersedia
                    </span>
                  )}
                </CardForm>
              </div>
            </div>

            <div className="flex flex-row gap-4">
              <div className="flex-1">
                <CardForm label={`Sub Output`} className={`bg-white`}>
                  {userBeneficiariesData?.length > 0 ? (
                    <BarChart
                      data={userBeneficiariesData.reduce((data, value) => {
                        data[value._id] = value.count;
                        return data;
                      }, {})}
                    />
                  ) : (
                    <span class="italic text-slate-400">
                      Data tidak tersedia
                    </span>
                  )}
                </CardForm>
              </div>
              <div className="flex-1">
                <CardForm label={`Output Proyek`} className={`bg-white`}>
                  {userBeneficiariesData?.length > 0 ? (
                    <BarChart
                      data={userBeneficiariesData.reduce((data, value) => {
                        data[value._id] = value.count;
                        return data;
                      }, {})}
                    />
                  ) : (
                    <span class="italic text-slate-400">
                      Data tidak tersedia
                    </span>
                  )}
                </CardForm>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Verification;

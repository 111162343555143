import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getAWPActivity,
  getAWPDetail,
  getSpecificTimeWorkPlan,
  getWithdrawalFundPlan,
  getWithdrawalFundPlanDetail,
  submitWithdrawalFundPlan,
} from "services/danaProgram/specificTimeWorkPlanService";
import { getErrorMessage } from "helpers";
import { useEffect } from "react";

const useSubmissionWithdrawalPlan = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  const {
    data: withdrawalFundData,
    isLoading: isLoadingWithdrawalFundData,
    isFetching: isFetchingWithdrawalFundData,
    refetch: refetchWithdrawalFundData,
  } = useQuery(
    ["list-withdrawalFund", params],
    () => getWithdrawalFundPlan(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: AWPActivityData,
    isLoading: isLoadingAWPActivityData,
    isFetching: isFetchingAWPActivityData,
    refetch: refetchAWPActivityData,
  } = useQuery(
    ["list-awp-activity"],
    () => getAWPActivity(`659cf12e7bae5c276083c3b7`),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: DetailWithdrawal,
    isLoading: isLoadingDetailWithdrawal,
    isFetching: isFetchingDetailWithdrawal,
    refetch: refetchDetailWithdrawal,
  } = useQuery(
    ["withdrawal-detail", params],
    () => getWithdrawalFundPlanDetail(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: timeWorkPlanData,
    isLoading: isLoadingTimeWorkPlanData,
    isFetching: isFetchingTimeWorkPlanData,
    refetch: refetchTimeWorkPlanData,
  } = useQuery(
    ["list-timeWorkPlan", params],
    () => getSpecificTimeWorkPlan(queryParams),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const randomDate = () => {
    const start = new Date(2023, 0, 1);
    const end = new Date(2024, 11, 31);
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
  };

  const randomString = (length) => {
    // You can modify this function based on your actual thematic_programmes structure
    return [
      "Musyawarah Nasional",
      "Rapat Koordinasi Besar",
      "Rapat Koordinasi Bulanan",
    ][Math.floor(Math.random() * 3)];
  };

  const getRandomThematicProgramme = () => {
    // You can modify this function based on your actual thematic_programmes structure
    return ["Environmental Conservation", "Community Development", "Education"][
      Math.floor(Math.random() * 3)
    ];
  };

  const getRandomStatus = () => {
    // You can modify this function based on your actual thematic_programmes structure
    return ["Ditolak", "Menunggu", "Disetujui"][Math.floor(Math.random() * 3)];
  };

  const getRandomProject = () => {
    // You can modify this function based on your actual thematic_programmes structure
    return [
      "Program Pangandaran Membiru",
      "Program Citarum Harum",
      "Program Bali Bersih",
      "Program Penghijauan Nasional",
      "Program Penanaman Mangrove",
      "Program Rehabilitasi Hutan",
      "Program Pengelolaan Sampah Berbasis Masyarakat",
      "Program Perlindungan Satwa Liar",
      "Program Penyelamatan Terumbu Karang",
      "Program Peningkatan Efisiensi Energi dan Penggunaan Energi Terbarukan",
    ][Math.floor(Math.random() * 10)];
  };

  function formatCurrency(amount) {
    if (isNaN(amount)) {
      return "Invalid input";
    }
    const [integerPart, decimalPart] = amount.toFixed(0).split(".");
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    const formattedCurrency = `Rp. ${formattedInteger}${
      decimalPart ? `,${decimalPart}` : ""
    }.000`;

    return formattedCurrency;
  }

  function randomizeRupiah() {
    const randomAmount = Math.floor(Math.random() * 50000) + 1000;

    const formattedAmount = formatCurrency(randomAmount);

    return formattedAmount;
  }

  const createRandomObject = () => {
    return {
      created_at: randomDate().toISOString(),
      current_step: Math.floor(Math.random() * 5) + 1,
      distribution_mechanism: "Rapat",
      total: randomizeRupiah(),
      end_period: randomDate().toISOString(),
      fund: Math.random() * 1000,
      fund_source: "BPDLH",
      name: randomString(10),
      start_period: randomDate().toISOString(),
      status: getRandomStatus(),
      actions: "",
      nameProject: getRandomProject(),
      step_1: {
        proposal_id: randomString(24),
        proposal_type: "test kontrak",
        fund_source: "BPDLH",
        project_name: randomString(5),
        thematic_programmes: [getRandomThematicProgramme()],
      },
      step_2: {},
      step_3: {},
      step_4: {},
      updated_at: randomDate().toISOString(),
      user_id: randomString(36),
      _id: randomString(24),
    };
  };

  let arr = Array.from({ length: 10 }, createRandomObject);

  return {
    withdrawalFundData: withdrawalFundData?.data?.data?.data || [],
    timeWorkPlanData: timeWorkPlanData?.data?.data?.data,
    AWPActivityData: AWPActivityData?.data?.data?.data,
    DetailWithdrawal: DetailWithdrawal?.data?.data,
    paginationtimeWorkPlanData:
      {
        lastPage: withdrawalFundData?.data?.data?.lastPage,
        page: withdrawalFundData?.data?.data?.page,
        perPage: withdrawalFundData?.data?.data?.perPage,
        total: withdrawalFundData?.data?.data?.total,
      } || {},
    isLoadingWithdrawalFundData,
    isFetchingWithdrawalFundData,
    refetch: refetchWithdrawalFundData,
    refetchSpecificWorkPlan: refetchTimeWorkPlanData,
    refetchAWPActivityData: refetchAWPActivityData,
    refetchDetailWithdrawal: refetchDetailWithdrawal,
  };
};

export default useSubmissionWithdrawalPlan;

export const useAWPMutations = () => {
  const getAWPDetailMutation = useMutation(async (params) => {
    const promise = await getAWPDetail(params);
    const arrayData = promise.data.data;
    return arrayData;
  });

  const submitMutation = useMutation({
    mutationFn: (params) => {
      return submitWithdrawalFundPlan(params);
    },
  });

  const fetchAWPDetail = async (params) => {
    try {
      const awpDetailData = await getAWPDetailMutation.mutateAsync(params);
      return awpDetailData;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    getAWPDetailMutation,
    fetchAWPDetail,
    submitMutation,
  };
};

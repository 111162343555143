import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { AiFillFilePdf } from 'react-icons/ai';
import { BsEyeFill } from 'react-icons/bs';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { getFile } from 'services/danaProgram/fileService';

const FileDetail = ({ file, onRemove, hideDelete = false }) => {
  const onPreview = useMutation({
    mutationKey: [`get-detail-file`],
    mutationFn: async (id) => {
      const res = await getFile(id);
      return res.data.data;
    },
    onSuccess: (res) => {
      window.open(res.url, `_blank`);
    },
  });

  const onFilePreview = (file) => {
    const fileReader = new FileReader();
    let previewImage;

    fileReader.addEventListener('load', () => {
      previewImage = fileReader?.result;
    });
    fileReader.readAsDataURL(file);

    previewImage = URL.createObjectURL(file);

    window.open(previewImage, `_blank`);
  };

  return (
    <div className="flex items-center p-4 border rounded-lg mt-2 w-full justify-between">
      <div className="flex gap-8">
        <AiFillFilePdf size={40} fill="#00BD52" />
        <div className="flex flex-col">
          <span className="text-base font-medium">
            {file?.fileName || file?.name || `-`}
          </span>
          <span className="text-xs">
            ({file?.fileSize || file?.size || 0}KB)
          </span>
        </div>
      </div>
      <div className="flex gap-2">
        <BsEyeFill
          size={18}
          fill="#667085"
          className="hover:fill-sky-600 cursor-pointer"
          onClick={() => {
            file instanceof File
              ? onFilePreview(file)
              : onPreview.mutate(file.fileId || file.id);
          }}
        />
        {!hideDelete && (
          <RiDeleteBin6Line
            onClick={onRemove}
            size={18}
            fill="#667085"
            className="hover:fill-red-600 cursor-pointer"
          />
        )}
      </div>
    </div>
  );
};

export default FileDetail;

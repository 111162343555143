import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getProject,
  getAWP,
  getAWPActivity,
  getAWPDetail,
  getSpecificTimeWorkPlan,
  submitSpecificTimeWorkPlan,
} from "services/danaProgram/specificTimeWorkPlanService";
import { getErrorMessage } from "helpers";
import { useEffect } from "react";

const useSpecificTimeWorkPlan = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  const {
    data: timeWorkPlanData,
    isLoading: isLoadingTimeWorkPlanData,
    isFetching: isFetchingTimeWorkPlanData,
    refetch: refetchTimeWorkPlanData,
  } = useQuery(
    ["list-timeWorkPlan", params],
    () => getSpecificTimeWorkPlan(queryParams),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const randomDate = () => {
    const start = new Date(2023, 0, 1);
    const end = new Date(2024, 11, 31);
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
  };

  const randomString = (length) => {
    // You can modify this function based on your actual thematic_programmes structure
    return [
      "Musyawarah Nasional",
      "Rapat Koordinasi Besar",
      "Rapat Koordinasi Bulanan",
    ][Math.floor(Math.random() * 3)];
  };

  const getRandomThematicProgramme = () => {
    // You can modify this function based on your actual thematic_programmes structure
    return ["Environmental Conservation", "Community Development", "Education"][
      Math.floor(Math.random() * 3)
    ];
  };

  const createRandomObject = () => {
    return {
      created_at: randomDate().toISOString(),
      current_step: Math.floor(Math.random() * 5) + 1,
      distribution_mechanism: "Rapat",
      end_period: randomDate().toISOString(),
      fund: Math.random() * 1000,
      fund_source: "BPDLH",
      name: randomString(10),
      start_period: randomDate().toISOString(),
      status: "draft",
      step_1: {
        proposal_id: randomString(24),
        proposal_type: "test kontrak",
        fund_source: "BPDLH",
        project_name: randomString(5),
        thematic_programmes: [getRandomThematicProgramme()],
      },
      step_2: {},
      step_3: {},
      step_4: {},
      updated_at: randomDate().toISOString(),
      user_id: randomString(36),
      _id: randomString(24),
    };
  };

  let arr = Array.from({ length: 10 }, createRandomObject);

  return {
    investmentPlanData: timeWorkPlanData?.data?.data.data || [],
    timeWorkPlanData: timeWorkPlanData?.data?.data?.data || [],
    paginationtimeWorkPlanData:
      {
        lastPage: timeWorkPlanData?.data?.data?.lastPage,
        page: timeWorkPlanData?.data?.data?.page,
        perPage: timeWorkPlanData?.data?.data?.perPage,
        total: timeWorkPlanData?.data?.data?.total,
      } || {},
    isLoadingTimeWorkPlanData,
    isFetchingTimeWorkPlanData,
    refetch: refetchTimeWorkPlanData,
  };
};

export default useSpecificTimeWorkPlan;

export const useAWPMutations = () => {
  const getProjectMutation = useMutation(async () => {
    const promise = await getProject();
    const arrayData = promise.data.data;
    return arrayData;
  });

  const getAWPMutation = useMutation(async (params) => {
    const promise = await getAWP(params);
    const arrayData = promise.data.data;
    return arrayData;
  });

  const getAWPActivityMutation = useMutation(async (params) => {
    const promise = await getAWPActivity(params);
    const arrayData = promise.data.data;
    return arrayData;
  });
  const getAWPDetailMutation = useMutation(async (params) => {
    const promise = await getAWPDetail(params);
    const arrayData = promise.data.data;
    return arrayData;
  });

  const submitMutation = useMutation({
    mutationFn: (params) => {
      return submitSpecificTimeWorkPlan(params);
    },
  });

  const fetchAWPActivity = async (params) => {
    try {
      const awpActivityData = await getAWPActivityMutation.mutateAsync(params);
      return awpActivityData;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAWPDetail = async (params) => {
    try {
      const awpDetailData = await getAWPDetailMutation.mutateAsync(params);
      return awpDetailData;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAWP = async (params) => {
    console.log(params)
    try {
      const promise = await getAWPMutation.mutateAsync(params);
      const awpData = promise;
      return awpData;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProject = async () => {
    try {
      const promise = await getProjectMutation.mutateAsync();
      const projectData = promise;
      return projectData;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    getProjectMutation,
    getAWPMutation,
    getAWPActivityMutation,
    getAWPDetailMutation,
    fetchProject,
    fetchAWP,
    fetchAWPActivity,
    fetchAWPDetail,
    submitMutation,
  };
};

import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { Button } from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Commisioner from "./Form/Commisioner";
import Directors from "./Form/Directors";
import { useSelector } from "react-redux";

const StructureOrganization = ({
  setActiveTab,
  activeTab = 1,
  data = {},
  allData = {},
  setData = () => {}
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const toogleIsEdit = () => setIsEdit(!isEdit)

  const masterData = useSelector((state) => state?.draftMasterData?.data || {});

  const validationSchema = useMemo(() => Yup.object().shape({
    structure_organization: Yup.object().shape({
      status_approval_commissioners: Yup.string().required('Hasil verifikasi wajib diisi'),
      status_approval_directors: Yup.string().required('Hasil verifikasi wajib diisi'),
      notes_approval_commissioners: Yup.string().nullable(true),
      notes_approval_directors: Yup.string().nullable(true),
      commissioners: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Nama wajib diisi'),
          nik: Yup.number().typeError('Nomer KTP harus angka').required('Nomer KTP wajib diisi'),
          gender: Yup.mixed().required('Jenis kelamin wajib diisi'),
          email: Yup.string().email().required('Email wajib diisi'),
        })
      ),
      directors: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Nama wajib diisi'),
          nik: Yup.number().typeError('Nomer KTP harus angka').required('Nomer KTP wajib diisi'),
          gender: Yup.mixed().required('Jenis kelamin wajib diisi'),
          email: Yup.string().email().required('Email wajib diisi'),
          position: Yup.mixed().required('Jabatan wajib diisi'),
        })
      ),
    })
  }));

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      structure_organization: {
        status_approval_commissioners: "APPROVED",
        status_approval_directors: "APPROVED"
      }
    }
  });

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    trigger,
  } = methods;


  const {
    fields: dataCommisioners,
    append: appendDataCommisioners,
    remove: removeDataCommisioners,
  } = useFieldArray({
    control,
    name: 'structure_organization.commissioners'
  })

  const {
    fields: dataDirectors,
    append: appendDataDirectors,
    remove: removeDataDirectors,
  } = useFieldArray({
    control,
    name: 'structure_organization.directors'
  })

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((keys) => {
          setValue(`${key}.${keys}`, data[key][keys]);
          // console.log(`${key}.${keys}`, data[key][keys])
        })
      });
      trigger();
    }
  }, [data, setValue, methods]);

  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const saveData = async () => {
    const isValid = await trigger()
    const allValues = getValues()
    allData['structure_organization'] = allValues.structure_organization
    setData(allData)
    return isValid;
  }

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        {/* <LegalBasis/> */}
        <Commisioner
          openModal={() => setIsOpenModal(true)}
          isEdit={isEdit}
          toogleIsEdit={toogleIsEdit}
          dataCommisioners={dataCommisioners}
          appendDataCommisioners={appendDataCommisioners}
          removeDataCommisioners={removeDataCommisioners}
        />
        <Directors
          openModal={() => setIsOpenModal(true)}
          isEdit={isEdit}
          toogleIsEdit={toogleIsEdit}
          dataDirectors={dataDirectors}
          appendDataDirectors={appendDataDirectors}
          removeDataDirectors={removeDataDirectors}
          masterData={masterData}
        />
        {/* <Card>
          <CardHeader>
            <div>Rekomendasi Verificator</div>
          </CardHeader>
          <CardBody>
            <div className="space-y-5">
              <div className="space-y-2">
                <TitleText className="font-bold">
                  Rekomendasi Verificator
                </TitleText>
                <InputForm
                  controllerName={"verificatorRecomendation"}
                  className={`py-2 border w-full rounded-md flex-1`}
                  label={''}
                  placeholder={'Verifikasi Remokendasi'}
                  textArea
                />
              </div>
            </div>
          </CardBody>
        </Card> */}
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => activeTab === 1 ? navigate(-1) : setActiveTab(activeTab - 1)}
            >
              <RiArrowLeftLine/>
              Kembali
            </Button>
          </div>
          {sessionStorage.getItem('isLemtaraFormDisabled') !== "true" && (
            <div className="block md:flex items-center justify-between gap-2">
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={async () => {
                  const validData = await saveData()
                  if (validData)
                    setActiveTab(activeTab + 1)
                }}
              >
                Selanjutnya
                <RiArrowRightLine/>
              </Button>
            </div>
          )}
        </div>
        {/* form modal */}
        {/* <FormModal 
          title={``}
          open={isOpenModal}
          onClose={() => setIsOpenModal(!isOpenModal)}
          onSubmit={() => {
            setIsOpenModal(!isOpenModal)
          }}
          positiveConfirm={`Ya`}
          negativeConfirm={`Tidak`}
          size={`w-3/12`}
        /> */}
      </FormProvider>
    </div>
  );
};

export default StructureOrganization;

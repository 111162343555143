import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import { useEffect, useMemo } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { BiCheck } from "react-icons/bi";
import TaxChild from "./Form";
import { useFinancialProfileContext } from "../FinancialProfileState";

const Pajak = ({ setActiveTab, activeTab = 1, data = null, setFormData }) => {
  const { FinancialProfileState, setFinancialProfileState } =
    useFinancialProfileContext();
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      result_verification: Yup.string().required(
        `Hasil Verifikasi wajib diisi`
      ),
      reason: Yup.string().nullable(true),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const { control, trigger } = { ...methods };

  const {
    fields: fieldsTools,
    append: appendsTools,
    remove: removeTools,
  } = useFieldArray({
    control,
    name: "tools",
  });

  useEffect(() => {
    for (let key in data.tax) {
      if (
        key.includes("is") ||
        key.includes("reason") ||
        key.includes("verification")
      ) {
        methods.setValue(key, data?.tax[key]);
      }
    }
    appendsTools({
      npwp_personal: data?.tax?.npwp_personal,
      npwp_address: data?.tax?.npwp_address,
      no_certificate_domicile: data?.tax?.no_certificate_domicile,
      expire_date: new Date(data?.tax?.expire_date).toLocaleDateString(
        "in-ID",
        {
          day: "numeric",
          month: "long",
          year: "numeric",
        }
      ),
      ppn_report: data?.tax?.ppn_report,
      spt_report: data?.tax?.spt_report,
      pph_report: data?.tax?.pph_report,
      tax_payment: data?.tax?.tax_payment,
    });
    for (let i = 0; i < fieldsTools.length; i++) {
      if (fieldsTools[i]?.is_pkp === "") {
        removeTools(i);
      }
    }
    trigger();
  }, [fieldsTools.length < 1]);

  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <TaxChild tools={fieldsTools} data={data?.tax} />
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => setActiveTab(activeTab - 1)}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={async () => {
                const isValid = await trigger();
                let formData = methods.getValues();
                setFormData((prevData) => ({
                  ...prevData,
                  step_4: formData,
                }));
                setFinancialProfileState((prevState) => ({
                  ...prevState,
                  step_3: formData,
                }));
                if (isValid) setActiveTab(activeTab + 1);
              }}
            >
              Selanjutnya
              <RiArrowRightLine />
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default Pajak;

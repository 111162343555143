import { IoIosWatch, IoMdWatch } from 'react-icons/io';
import {
  RiPagesLine,
  RiFileList3Line,
  RiTodoLine,
  RiSurveyLine,
  RiMoneyEuroCircleLine,
  RiBarChartBoxLine,
} from 'react-icons/ri';
import { FaMoneyBillTransfer } from 'react-icons/fa6';
import { TbReportMoney } from 'react-icons/tb';
import { BsSuitcaseLg } from 'react-icons/bs';
import { PiHandCoinsBold } from 'react-icons/pi';
import { FaRegCheckSquare, FaSuitcase } from 'react-icons/fa';
import { AiOutlineTeam } from 'react-icons/ai';
import { IoDocumentsOutline } from 'react-icons/io5';
import { CgFileDocument } from 'react-icons/cg';

const MENU_ITEMS = [
  {
    icon: <RiBarChartBoxLine className="text-white w-[18px] h-[20px]" />,
    id: 'dashboard',
    label: 'Dashboard',
    path: '/dashboard',
    child: [],
  },
  {
    icon: <RiBarChartBoxLine className="text-white w-[18px] h-[20px]" />,
    id: 'dashboard-toc',
    label: 'Dashboard ToC',
    path: '/dashboard/toc',
    child: [],
  },
  {
    icon: <RiBarChartBoxLine className="text-white w-[18px] h-[20px]" />,
    id: 'dashboard_donor',
    label: 'Dashboard Donor',
    path: '/donor',
    child: [],
  },
  {
    icon: <RiFileList3Line className="text-white w-[18px] h-[20px]" />,
    id: 'verification',
    label: 'Daftar LEMTARA',
    path: '/verification',
    child: [],
  },
  // {
  //   icon: <RiTodoLine className="text-white w-[18px] h-[20px]" />,
  //   id: '2',
  //   label: 'Pengajuan Dana Program',
  //   path: '/pengajuan-dana-program',
  //   child: [],
  // },
  // {
  //   icon: <RiSurveyLine className="text-white w-[18px] h-[20px]" />,
  //   id: '3',
  //   label: 'Menilai Proposal',
  //   path: '/menilai-proposal',
  //   child: [],
  // },
  // {
  //   icon: <RiPagesLine className="text-white w-[18px] h-[20px]" />,
  //   id: '4',
  //   label: 'Daftar Program',
  //   path: '/daftar-program',
  //   child: [],
  // },
  {
    icon: <RiPagesLine className="text-white w-[18px] h-[20px]" />,
    id: '5',
    label: 'Daftar Investment Plan',
    path: '/investment-plan',
    child: [],
  },
  {
    icon: <AiOutlineTeam className="text-white w-[18px] h-[20px]" />,
    id: '5',
    label: 'Tim Pengelola Proyek',
    path: '/project-management-team',
    child: [],
  },
  {
    icon: <RiPagesLine className="text-white w-[18px] h-[20px]" />,
    id: '6',
    label: 'Annual Work Plan',
    path: '/annual-work-plan',
    child: [],
  },
  {
    icon: <RiSurveyLine className="text-white w-[18px] h-[20px]" />,
    id: '8',
    label: 'Rencana Pengadaan',
    path: '/procurements',
  },
  {
    icon: <RiSurveyLine className="text-white w-[18px] h-[20px]" />,
    id: '7',
    label: 'Laporan Kegiatan',
    path: '/activity-report',
  },
  {
    icon: <RiSurveyLine className="text-white w-[18px] h-[20px]" />,
    id: '7',
    label: 'Laporan Kemajuan',
    path: '/progress-report',
  },
  {
    icon: <RiSurveyLine className="text-white w-[18px] h-[20px]" />,
    id: '7',
    label: 'Laporan Implementasi Safeguard',
    path: '/safeguard-implementation',
  },
  {
    icon: <RiSurveyLine className="text-white w-[18px] h-[20px]" />,
    id: '8',
    label: 'Pembayaran Resources',
    path: '/pembayaran-resources',
  },
  // {
  //   icon: <RiTodoLine className="text-white min-w-[18px] min-h-[20px]" />,
  //   id: "12",
  //   label: "Permohonan Penyaluran Langsung",
  //   path: "/permohonan-penyaluran-langsung",
  // },
  {
    icon: <BsSuitcaseLg className="text-white w-[18px] h-[20px]" />,
    id: '10',
    label: 'Rencana Kerja',
    path: [
      '/rencana-kerja-waktu',
      '/tor',
      '/tor/create',
      '/rencana-penarikan-dana',
    ],

    child: [
      {
        id: '31',
        label: 'ToR',
        path: '/tor',
      },
      {
        id: '10',
        label: 'Rencana Kerja Waktu Tertentu',
        path: '/rencana-kerja-waktu',
      },
      {
        id: '10',
        label: 'Rencana Penarikan Dana',
        path: '/rencana-penarikan-dana',
      },
    ],
  },
  {
    icon: <FaRegCheckSquare className="text-white w-[18px] h-[20px]" />,
    id: '11',
    label: 'Approval',
    path: [
      '/approval-rencana-penarikan-dana',
      '/approval-pembayaran-implementing-partner',
      '/approval-pembayaran-resources',
      '/approval-pembayaran-lemtara',
      '/approval-penyaluran-langsung',
    ],
    child: [
      {
        id: '11',
        label: 'Approval Rencana Penarikan Dana',
        path: '/approval-rencana-penarikan-dana',
      },
      {
        id: '11',
        label: 'Approval Pembayaran Implementing Partner',
        path: '/approval-pembayaran-implementing-partner',
      },
      {
        id: '11',
        label: 'Approval Pembayaran Resources',
        path: '/approval-pembayaran-resources',
      },
      {
        id: '11',
        label: 'Approval Pembayaran Lemtara',
        path: '/approval-pembayaran-lemtara',
      },
      {
        icon: <RiTodoLine className="text-white min-w-[18px] min-h-[20px]" />,
        id: '11',
        label: 'Approval Penyaluran Langsung',
        path: '/approval-penyaluran-langsung',
      },
    ],
  },
  {
    icon: <RiTodoLine className="text-white w-[18px] h-[20px]" />,
    id: '9',
    label: 'Proposal',

    child: [
      {
        icon: <RiTodoLine className="text-white w-[18px] h-[20px]" />,
        id: '9',
        label: 'Verifikasi Konsep Note',
        path: '/concept-notes',
      },
      {
        icon: <RiTodoLine className="text-white w-[18px] h-[20px]" />,
        id: '9',
        label: 'Verifikasi Full Proposal',
        path: '/call-for-proposal',
      },
      {
        icon: <RiTodoLine className="text-white w-[18px] h-[20px]" />,
        id: '9',
        label: 'Komitmen Safeguard',
        path: '/commitment-safeguard',
      },
    ],
  },
  {
    icon: <BsSuitcaseLg className="text-white w-[18px] h-[20px]" />,
    id: '12',
    label: 'Partner',
    path: [
      '/institutional-ministry',
      '/institutional-ministry/create',
      '/implementing-partner/',
      '/implementing-partner/create/',
      '/implementing-partner-project/',
      '/implementing-partner-project/create',
      '/implementing-partner-payment/',
      '/implementing-partner-payment/create',
      '/implementing-partner-payment/edit',
    ],

    child: [
      {
        id: '12',
        label: 'Daftar End Beneficiaries',
        path: '/institutional-ministry',
      },
      {
        id: '12',
        label: 'Daftar Implementing Partner',
        path: '/implementing-partner',
      },
      {
        id: '12',
        label: 'Proyek Implementing Partner',
        path: '/implementing-partner-project',
      },
      {
        id: '12',
        label: 'Pengajuan Pembayaran Implementing Partner',
        path: '/implementing-partner-payment',
      },
    ],
  },
  {
    icon: <IoDocumentsOutline className="text-white w-[18px] h-[20px]" />,
    id: '14',
    label: 'Review Laporan Kegiatan',
    path: ['/activity-report-review', '/progress-report-review'],

    child: [
      {
        id: '14',
        label: 'Laporan Kegiatan',
        path: '/activity-report-review',
      },
      {
        id: '14',
        label: 'Laporan Kemajuan',
        path: '/progress-report-review',
      },
    ],
  },
];

export default MENU_ITEMS;

import React, { useState } from "react";
import { CiCircleRemove } from "react-icons/ci";

import { Button, InputForm, InputFormUpload } from "components";
import { FormModal } from "components/molecules/Modal";

const ConfirmReject = ({
  confirmText = "Tolak Pengajuan",
  disabled = false,
  onConfirm,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        type="button"
        className="px-10 text-white border-[#D92D20] bg-[#D92D20] hover:bg-[#ae241a] hover:border-[#ae241a]"
        disabled={disabled}
        onClick={() => setShowModal(true)}
      >
        <CiCircleRemove className="text-lg" />
        Tolak
      </Button>

      <FormModal
        title={confirmText}
        open={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={onConfirm}
        titleClassName="text-lg text-gray-800 font-semibold"
      >
        <InputForm
          controllerName={"reason"}
          className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
          label={"Alasan Penolakan"}
          required
          textArea={true}
        />

        <div className="py-6">
          <InputFormUpload
            controllerName={"credentials.documents"}
            maxSize={10240}
            label="Dokumen Pendukung"
            labelClassname="font-semibold text-sm"
            isMulti
            listFiles={[]}
          />
        </div>
      </FormModal>
    </>
  );
};

export default ConfirmReject;

import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getList,
  getDetail,
  postProjectClosure,
  postSafeguardClosure,
} from "services/danaProgram/investmentPlan";
import { getErrorMessage } from "helpers";

const useInvestmentPlan = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  const {
    data: investmentPlanData,
    isLoading: isLoadingInvestmentPlanData,
    isFetching: isFetchingInvestmentPlanData,
    refetch: refetchInvestmentPlanData,
  } = useQuery(["list-investmentplan", params], () => getList(queryParams), {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const { data: DetailInvetmentPlan, refetch: refetchDetailInvetmentPlan } =
    useQuery(["data-DetailInvetmentPlan"], () => getDetail(params?.id), {
      refetchOnWindowFocus: false,
      enabled: false,
    });

  const submitProjectClosure = useMutation({
    mutationFn: (params) => {
      return postProjectClosure(params);
    },
  });

  const submitSafeguardClosure = useMutation({
    mutationFn: (params) => {
      return postSafeguardClosure(params);
    },
  });

  return {
    investmentPlanData: investmentPlanData?.data?.data.data || [],
    paginationinvestmentPlanData:
      {
        lastPage: investmentPlanData?.data?.data?.lastPage,
        page: investmentPlanData?.data?.data?.page,
        perPage: investmentPlanData?.data?.data?.perPage,
        total: investmentPlanData?.data?.data?.total,
      } || {},
    isLoadingInvestmentPlanData,
    isFetchingInvestmentPlanData,
    refetch: refetchInvestmentPlanData,
    DetailInvetmentPlan: DetailInvetmentPlan?.data?.data,
    refetchDetailInvetmentPlan,
    submitProjectClosure,
    submitSafeguardClosure,
  };
};

export default useInvestmentPlan;

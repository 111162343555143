import {
  AtomDatePicker,
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  InputFormUpload,
} from "components";
import { kebabCase } from "lodash";
import { UploadImage } from "pages/Tor/assets";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import {
  useCityList,
  useCreateImplementingPartnerProject,
  useDistrictList,
  useInvestmentPlanList,
  useProvinceList,
  useSubdistrictList,
} from "../hooks/useImplementingPartnerProjectServices";
import CustomSelectForm from "components/molecules/CustomSelectForm";
import {
  useImplementingPartnerDetail,
  useImplementingPartnerList,
  useInstitutionTypeList,
} from "pages/ImplementingPartner/hooks/useImplementingPartnerServices";
import { fileUpload } from "services/danaProgram/fileService";

const links = [
  {
    label: "Daftar Proyek Implementing Partner",
    path: "/implementing-partner-project",
  },
  {
    label: "Tambah Proyek",
  },
];

const ImplementingPartnerProjectCreate = () => {
  const methods = useForm({
    defaultValues: {
      implementing_partner_id: {},
      project_id: "",
      project_name: {},
      institutional_ministry: {},
      activity_title: "",
      location: [],
      activity_periode: "",
      proposal_doc: [],
      agreement_number: "",
      agreement_date: "",
      agreement_doc: [],
    },
  });
  const navigate = useNavigate();

  const inputRef = useRef(null);
  const anotherInputRef = useRef(null);

  const [provinceId, setProvinceId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [districtId, setDistrictid] = useState(null);
  const [imeplementingPartnerId, setImplementingPartnerId] = useState(null);

  const { provinceList } = useProvinceList();
  const { cityList } = useCityList(provinceId);
  const { districtList } = useDistrictList(cityId);
  const { subdistrictList } = useSubdistrictList(districtId);
  const { institutionList } = useInstitutionTypeList();

  const {
    list: implementingPartnerList,
    isLoading: implementingPartnerIsLoading,
  } = useImplementingPartnerList({
    limit: 999,
    page: 1,
  });
  const { list: investmentPlantList, isLoading: investmentPlantIsLoading } =
    useInvestmentPlanList({
      limit: 999,
      page: 1,
    });

  const {
    detail: implementingPartnerDetail,
    detailIsLoading: implementingPartnerDetailIsLoading,
  } = useImplementingPartnerDetail(imeplementingPartnerId);
  const { submitMutation } = useCreateImplementingPartnerProject();

  useEffect(() => {
    if ((!implementingPartnerDetailIsLoading, imeplementingPartnerId)) {
      methods.setValue("province", implementingPartnerDetail?.province);
      methods.setValue("city", implementingPartnerDetail?.city);
      methods.setValue("district", implementingPartnerDetail?.district);
      methods.setValue("subdistrict", implementingPartnerDetail?.subdistrict);
      methods.setValue("address", implementingPartnerDetail?.address);
      methods.setValue(
        "institution",
        implementingPartnerDetail?.institution.label
      );
    }
  }, [implementingPartnerDetailIsLoading, imeplementingPartnerId]);

  const handleSubmitForm = async () => {
    try {
      const params = methods.getValues();

      const formatActivityPeriod = params.activity_periode.map((periode) => {
        const dateObj = new Date(periode);
        return `${dateObj.getDate().toString().padStart(2, "0")}/${(
          dateObj.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${dateObj.getFullYear()}`;
      });

      params.activity_periode = formatActivityPeriod.join(" - ");
      let agreementDate = new Date(params.agreement_date);
      params.agreement_date = `${agreementDate
        .getDate()
        .toString()
        .padStart(2, "0")}/${(agreementDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${agreementDate.getFullYear()}`;

      params.implementing_partner_id = params.implementing_partner_id
        ? params.implementing_partner_id.value
        : "";

      params.project_id = params.implementing_partner_name ? params.implementing_partner_name.value : "";
      params.project_name = params.implementing_partner_name?.label ?? "";

      const institutionalMinistry = params.institutional_ministry;

      params.institutional_ministry = {
        id: institutionalMinistry.value,
        name: institutionalMinistry.label,
      };

      params.location = params.location.map((item) => item.name);

      let proposal_doc = [];

      for (let file of params.proposal_doc) {
        const fileFormData = new FormData();
        fileFormData.append("file", file);

        const resFile = await fileUpload(fileFormData);
        const resDataFile = await resFile.data.data;

        const payloadPerFile = {
          fileId: resDataFile.id,
          fileName: resDataFile.name,
          fileSize: resDataFile.size,
          mimeType: resDataFile.mimeType,
          path: resDataFile.url,
        };

        proposal_doc.push(payloadPerFile);
      }

      params.proposal_doc = proposal_doc;

      let agreement_doc = [];

      for (let file of params.agreement_doc) {
        const fileFormData = new FormData();
        fileFormData.append("file", file);

        const resFile = await fileUpload(fileFormData);
        const resDataFile = await resFile.data.data;

        const payloadPerFile = {
          fileId: resDataFile.id,
          fileName: resDataFile.name,
          fileSize: resDataFile.size,
          mimeType: resDataFile.mimeType,
          path: resDataFile.url,
        };

        agreement_doc.push(payloadPerFile);
      }

      params.agreement_doc = agreement_doc;

      submitMutation.mutate(params, {
        onSuccess: (res) => {
          navigate("/implementing-partner-project");
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    methods.setValue("institutional_ministry", "");
  }, [methods.watch("implementing_partner_name")]);

  return (
    <div>
      <Breadcrumbs items={links} />
      <FormProvider {...methods}>
        <div className="flex flex-col gap-6 pt-8">
          <Card>
            <CardHeader>Data implementing partner</CardHeader>
            <CardBody>
              <div className="flex flex-col gap-5">
                <div>
                  <label className="label font-semibold text-[14px] mb-2">
                    <div className={`flex gap-[4px]`}>
                      <span className={`label-text`}>
                        Implementing Partner
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </div>
                  </label>
                  <CustomSelectForm
                    name="implementing_partner_id"
                    isDisabled={implementingPartnerIsLoading}
                    placeholder="Nama Proyek"
                    options={implementingPartnerList.map((item) => ({
                      value: item._id,
                      label: item.name,
                    }))}
                    onChange={(e) => setImplementingPartnerId(e.value)}
                  />
                </div>
                <InputForm
                  controllerName={"institution"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Jenis Lembaga"}
                  placeholder={"Jenis Lembaga"}
                  required={true}
                  disabled={true}
                />
                <div className="grid grid-cols-2 gap-5">
                  <div>
                    <label className="label font-semibold text-[14px] mb-2">
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>
                          Provinsi
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </div>
                    </label>
                    <CustomSelectForm
                      name="province"
                      isDisabled={true}
                      placeholder="Provinsi"
                      options={provinceList.map((item) => ({
                        ...item,
                        name: item.namaProvinsi,
                        value: item.id,
                        label: item.namaProvinsi,
                      }))}
                      onChange={(e) => {
                        setProvinceId(e.id);
                      }}
                    />
                  </div>
                  <div>
                    <label className="label font-semibold text-[14px] mb-2">
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>
                          Kota/ Kab
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </div>
                    </label>
                    <CustomSelectForm
                      name="city"
                      isDisabled={true}
                      placeholder="Kota/ Kab"
                      options={cityList
                        .filter((item) => item.idProvinsi === provinceId)
                        .map((item) => ({
                          ...item,
                          name: item.namaKabupatenKota,
                          value: item.id,
                          label: item.namaKabupatenKota,
                        }))}
                      onChange={(e) => setCityId(e.id)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <div>
                    <label className="label font-semibold text-[14px] mb-2">
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>
                          Kecamatan
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </div>
                    </label>
                    <CustomSelectForm
                      name="district"
                      isDisabled={true}
                      placeholder="Kecamatan"
                      options={districtList
                        .filter((item) => item.idKabupatenKota === cityId)
                        .map((item) => ({
                          ...item,
                          name: item.namaKecamatan,
                          value: item.id,
                          label: item.namaKecamatan,
                        }))}
                      onChange={(e) => setDistrictid(e.value)}
                    />
                  </div>
                  <div>
                    <label className="label font-semibold text-[14px] mb-2">
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>
                          Kelurahan/Desa
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </div>
                    </label>
                    <CustomSelectForm
                      name="subdistrict"
                      isDisabled={true}
                      placeholder="Kelurahan/Desa"
                      options={subdistrictList
                        .filter((item) => item.idKecamatan === districtId)
                        .map((item) => ({
                          ...item,
                          name: item.namaKelurahan,
                          value: item.id,
                          label: item.namaKelurahan,
                        }))}
                    />
                  </div>
                </div>
                <InputForm
                  textArea={true}
                  disabled={true}
                  controllerName={"address"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Alamat"}
                  placeholder={"Alamat"}
                  required={true}
                />
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Data proyek</CardHeader>
            <CardBody>
              <div className="flex flex-col gap-5">
                <div>
                  <label className="label font-semibold text-[14px] mb-2">
                    <div className={`flex gap-[4px]`}>
                      <span className={`label-text`}>
                        Nama Proyek
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </div>
                  </label>
                  <CustomSelectForm
                    name="implementing_partner_name"
                    isDisabled={investmentPlantIsLoading}
                    placeholder="Nama Proyek"
                    options={investmentPlantList.map((item) => ({
                      value: item._id,
                      label: item.name,
                      option: item?.step_2,
                    }))}
                  />
                </div>
                <div>
                  <label className="label font-semibold text-[14px] mb-2">
                    <div className={`flex gap-[4px]`}>
                      <span className={`label-text`}>
                        KL Pengampu/ End Beneficieries
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </div>
                  </label>
                  <CustomSelectForm
                    name="institutional_ministry"
                    placeholder="Jenis Lembaga"
                    options={
                      methods.watch("implementing_partner_name") !== ""
                        ? methods
                            .watch("implementing_partner_name")
                            ?.option?.map((item) => ({
                              value: item.benefitCiaryData,
                              label: item.benefitCiaryData,
                            }))
                        : []
                    }
                  />
                </div>
                <InputForm
                  textArea={true}
                  controllerName={"activity_title"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Judul Kegiatan"}
                  placeholder={"Judul Kegiatan"}
                  required={true}
                />
                <div>
                  <label className="label font-semibold text-[14px] mb-2">
                    <div className={`flex gap-[4px]`}>
                      <span className={`label-text`}>
                        Lokasi Kegiatan
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </div>
                  </label>
                  <CustomSelectForm
                    name="location"
                    isMulti={true}
                    placeholder="Provinsi"
                    options={provinceList.map((item) => ({
                      ...item,
                      name: item.namaProvinsi,
                      value: item.id,
                      label: item.namaProvinsi,
                    }))}
                  />
                </div>
                <AtomDatePicker
                  isRangeDatePicker
                  label="Periode Kegiatan"
                  wrapperClass={""}
                  format={"dd/MM/yyyy"}
                  value={""}
                  className={
                    "max-h-[38px] mt-0 rounded-md border-[#667085] border-solid text-sm outline-none focus:outline-none"
                  }
                  controllerName={"activity_periode"}
                />
                <div>
                  <label className="label font-semibold text-[14px]">
                    <div className={`flex gap-[4px]`}>
                      <span className={`label-text`}>
                        Upload Dokumen Proposal yang telah disetujui
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </div>
                  </label>
                  <InputFormUpload
                    controllerName={"proposal_doc"}
                    maxSize={10240}
                    label=""
                    isMulti
                    hideSectionUpload={false}
                    listFiles={[]}
                  />
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <InputForm
                    controllerName={`agreement_number`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                    label={"No Perjanjian"}
                    placeholder={"No Perjanjian"}
                    required={true}
                  />
                  <AtomDatePicker
                    controllerName={"agreement_date"}
                    label="Tanggal Perjanjian"
                    wrapperClass={""}
                    format={"dd/MM/yyyy"}
                    value={""}
                    className={
                      "max-h-[38px] mt-0 rounded-md border-[#667085] border-solid text-sm outline-none focus:outline-none"
                    }
                  />
                </div>
                <div>
                  <label className="label font-semibold text-[14px]">
                    <div className={`flex gap-[4px]`}>
                      <span className={`label-text`}>
                        Upload Dokumen Perjanjian dan dokumen pendukung lainnya
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </div>
                  </label>
                  <InputFormUpload
                    controllerName={"agreement_doc"}
                    maxSize={10240}
                    label=""
                    isMulti
                    hideSectionUpload={false}
                    listFiles={[]}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="items-center justify-between block px-6 py-5 mt-4 mb-10 bg-white border border-gray-200 rounded-lg md:flex">
          <div>
            <Button
              type="button"
              className="px-8 text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
              onClick={() => {
                navigate("/implementing-partner-project");
              }}
            >
              Batal
            </Button>
          </div>

          <div className="items-center justify-between block gap-2 md:flex">
            <Button
              type="button"
              disabled={submitMutation.isLoading}
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={() => {
                handleSubmitForm();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default ImplementingPartnerProjectCreate;

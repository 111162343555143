import { useMutation, useQuery } from "@tanstack/react-query";
import { ToastContext } from "components/atoms/Toast";
import { getErrorMessage } from "helpers";
import { enqueueSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getSumberDaya, submitSumberDaya } from "services/danaProgram/sumberDaya";
import moment from "moment";

const useSumberDaya = ({
  id = "",
}) => {
  const [temp, setTemp] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmBackModal, setConfirmBackModal] = useState(false);
  const [confirmDraftModal, setConfirmDraftModal] = useState(false);
  const [sumberDayaDraftData, setSumberDayaDraftData] = useState()
  const { showToastMessage, showToastDescription } = useContext(ToastContext)
  const navigate = useNavigate();

  const {
    data: sumberDayaData,
    isLoading: isLoadingSumberDaya,
    isFetching: isFetchingSumberDaya,
    refetch: refetchSumberDaya,
  } = useQuerySumberDaya(id)

  const formMutation = useFormSumberDaya();

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  useEffect(() => {
    const flatData = sumberDayaData?.data?.data
    if(flatData){
      if(flatData?.step_1?.partner_list?.program_personnel?.length > 0){
        flatData?.step_1?.partner_list?.program_personnel?.map((e, i) => {
          const dobOrig = flatData?.step_1?.partner_list?.program_personnel[i].dob; 
          
          flatData['step_1']['partner_list']['program_personnel'][i]['dob'] = convert(dobOrig)
        })
      }
      if(flatData?.step_1?.partner_list?.finance_personnel?.length > 0){
        flatData?.step_1?.partner_list?.finance_personnel?.map((e, i) => {
          const dobOrig = flatData?.step_1?.partner_list?.finance_personnel[i].dob; 
          flatData['step_1']['partner_list']['finance_personnel'][i]['dob'] = convert(dobOrig)
        })
      }
      if(flatData?.step_1?.partner_list?.safeguard_personnel?.length > 0){
        flatData?.step_1?.partner_list?.safeguard_personnel?.map((e, i) => {
          const dobOrig = flatData?.step_1?.partner_list?.safeguard_personnel[i].dob; 
          flatData['step_1']['partner_list']['safeguard_personnel'][i]['dob'] =  convert(dobOrig)
        })
      }
      if(flatData?.step_1?.partner_list?.other_personnel?.length > 0){
        flatData?.step_1?.partner_list?.other_personnel?.map((e, i) => {
          const dobOrig = flatData?.step_1?.partner_list?.other_personnel[i].dob; 
          flatData['step_1']['partner_list']['other_personnel'][i]['dob'] =  convert(dobOrig)
        })
      }
    }
    setSumberDayaDraftData(flatData)
  }, [sumberDayaData])

  const onDraftSubmit = async (data) => {
    setTemp(data);
    setConfirmDraftModal(true);
  }

  const onBack = async (data) => {
    setTemp(data);
    setConfirmModal(true)
  }

  const onSubmit = async (data) => {
    setConfirmModal(true)
  }

  const consumeAPI = async (data) => {
    const {
      _id: _id_sd,
      step_1,
      step_2,
      ...restData
    } = data
    // mapping step_1
    const {
      created_at: created_at_s1,
      updated_at: updated_at_s1,
      _id: _id_s1,
      resource_id: resource_id_s1,
      ...restStep1
    } = step_1
    // mapping step_2
    const {
      created_at: created_at_s2,
      updated_at: updated_at_s2,
      _id: _id_s2,
      resource_id: resource_id_s2,
      ...restStep2
    } = step_2
    const newParams = {
      ...restData,
      step_1: restStep1,
      step_2: restStep2,
    }
    const res = await formMutation.mutateAsync(newParams)
    return res;
  }

  const onSubmitConfirmModal = async () => {
    const response = await consumeAPI(sumberDayaDraftData)
    if (response.status === 200) {
      showToastMessage(
        "Data Berhasil Disimpan!",
        "Data yang anda masukan telah berhasil disimpan."
      )
      setConfirmModal(false);
      navigate("/verification/detail")
    } else {
      showToastMessage(
        "Data gagal Disimpan!",
        "Data yang anda masukan telah gagal1 disimpan."
      )
    }
  }

  const onSubmitConfirmBackModal = async () => {
    setConfirmBackModal(false);
    navigate("/verification/detail")
    showToastMessage(
      "Data Berhasil Disimpan!",
      "Data yang anda masukan telah berhasil disimpan."
    )
  }

  const onSubmitConfirmDraftModal = async () => {
    setConfirmDraftModal(false);
    navigate("/verification/detail")
    showToastMessage(
      "Data Berhasil Disimpan!",
      "Data yang anda masukan telah berhasil disimpan."
    )
  }

  return {
    sumberDayaDraftData,
    setSumberDayaDraftData,
    onDraftSubmit,
    onBack,
    onSubmit,
    onSubmitConfirmModal,
    onSubmitConfirmBackModal,
    onSubmitConfirmDraftModal,
    temp,
    setTemp,
    confirmModal,
    setConfirmModal,
    confirmBackModal,
    setConfirmBackModal,
    confirmDraftModal,
    setConfirmDraftModal,
  }

}

export const useQuerySumberDaya = (id) => {
  return useQuery(['list-sumber-daya'], () =>
  getSumberDaya({ id }), {
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      })
    },
    refetchOnWindowFocus: false,
  })
}

export const useFormSumberDaya = () => {
  return useMutation(async (payload) => submitSumberDaya(payload),
  {
    onSuccess: () => {
      enqueueSnackbar({
        message: "Success",
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    }
  })
}

export default useSumberDaya;
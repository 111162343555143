import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  InputFormRadio,
  Select,
  TitleText,
} from "components";
import Badge from "components/atoms/Badge";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line, RiInformationLine } from "react-icons/ri";
import { useNavigate } from "react-router";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const SPT = ({ tools, isEdit = false }) => {
  return (
    <div className="flex flex-col mt-4">
      <QuestionBadge text="Apakah Lembaga/ Institusi/ Perusahaan Anda Adalah Pemungut Pajak?" />
      <InputFormRadio
        controllerName={`is_tax_collectors`}
        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
        values={[
          { value: true, label: "Ya" },
          { value: false, label: "Tidak" },
        ]}
        disabled={!isEdit}
      />
    </div>
  );
};

export default SPT;

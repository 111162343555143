import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import {
  getDashboardCfpConceptNotes,
  getDashboardDetailDistribution,
  getDashboardProjectTeams,
  getDashboardTotalCommitment,
  getDashboardTotalDistributionMechanism,
  getDashboardTotalDonor,
  getDashboardTotalReportedFund,
  getDashboardUserBeneficiaries,
} from "services/danaProgram/danaProgramService";

const useDashboard = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: totalCommitmentData,
    isLoading: isLoadingTotalCommitmentData,
    isFetching: isFetchingTotalCommitmentData,
    refetch: refetchTotalCommitmentData,
  } = useQuery(
    ["list-totalCommitment", params],
    () => getDashboardTotalCommitment(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: totalReportedFundData,
    isLoading: isLoadingTotalReportedFundData,
    isFetching: isFetchingTotalReportedFundData,
    refetch: refetchTotalReportedFundData,
  } = useQuery(
    ["dashboard-totalReportedFund", params],
    () => getDashboardTotalReportedFund(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: totalDistributionMechanismData,
    isLoading: isLoadingTotalDistributionMechanismData,
    isFetching: isFetchingTotalDistributionMechanismData,
    refetch: refetchTotalDistributionMechanismData,
  } = useQuery(
    ["dashboard-totalDistributionMechanism"],
    () => getDashboardTotalDistributionMechanism(),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: detailDistributionData,
    isLoading: isLoadingDetailDistributionData,
    isFetching: isFetchingDetailDistributionData,
    refetch: refetchDetailDistributionData,
  } = useQuery(
    ["dashboard-detailDistribution", params],
    () => getDashboardDetailDistribution(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: totalDonorData,
    isLoading: isLoadingTotalDonorData,
    isFetching: isFetchingTotalDonorData,
    refetch: refetchTotalDonorData,
  } = useQuery(
    ["dashboard-totalDonor", params],
    () => getDashboardTotalDonor(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: cfpConceptNotesData,
    isLoading: isLoadingCfpConceptNotesData,
    isFetching: isFetchingCfpConceptNotesData,
    refetch: refetchCfpConceptNotesData,
  } = useQuery(
    ["dashboard-cfpConceptNotes", params],
    () => getDashboardCfpConceptNotes(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: projectTeamsData,
    isLoading: isLoadingProjectTeamsData,
    isFetching: isFetchingProjectTeamsData,
    refetch: refetchProjectTeamsData,
  } = useQuery(
    ["dashboard-projectTeams", params],
    () => getDashboardProjectTeams(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: userBeneficiariesData,
    isLoading: isLoadingUserBeneficiariesData,
    isFetching: isFetchingUserBeneficiariesData,
    refetch: refetchUserBeneficiariesData,
  } = useQuery(
    ["dashboard-userBeneficiaries", params],
    () => getDashboardUserBeneficiaries(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  return {
    totalCommitmentData: totalCommitmentData?.data?.data?.dashboard_data || [],
    isLoadingTotalCommitmentData,
    isFetchingTotalCommitmentData,
    refetchTotalCommitmentData,

    totalReportedFundData:
      totalReportedFundData?.data?.data?.dashboard_data || [],
    isLoadingTotalReportedFundData,
    isFetchingTotalReportedFundData,
    refetchTotalReportedFundData,

    totalDistributionMechanismData:
      totalDistributionMechanismData?.data?.data || [],
    isLoadingTotalDistributionMechanismData,
    isFetchingTotalDistributionMechanismData,
    refetchTotalDistributionMechanismData,

    detailDistributionData: detailDistributionData?.data?.data || [],
    isLoadingDetailDistributionData,
    isFetchingDetailDistributionData,
    refetchDetailDistributionData,

    totalDonorData:
      totalDonorData?.data?.data.reduce(
        (partialSum, value) => partialSum + value.count,
        0
      ) || [],
    isLoadingTotalDonorData,
    isFetchingTotalDonorData,
    refetchTotalDonorData,

    cfpConceptNotesData: cfpConceptNotesData?.data?.data || [],
    isLoadingCfpConceptNotesData,
    isFetchingCfpConceptNotesData,
    refetchCfpConceptNotesData,

    userBeneficiariesData: userBeneficiariesData?.data?.data || [],
    isLoadingUserBeneficiariesData,
    isFetchingUserBeneficiariesData,
    refetchUserBeneficiariesData,

    projectTeamsData: projectTeamsData?.data?.data || [],
    isLoadingProjectTeamsData,
    isFetchingProjectTeamsData,
    refetchProjectTeamsData,
  };
};

export default useDashboard;

import classNames from "classnames";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const errorClassName = "!border-error-500 focus:!border-error-500";
const defaultClassName =
  "flex flex-1 items-center rounded-md border-[#D0D5DD] border-solid border-[1px]";

const CustomInput = (props) => {
  const { name, placeholder, asText = false } = props;
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <>
            {asText ? (
              <p className="p-2">{value} Orang</p>
            ) : (
              <div
                className={classNames(defaultClassName.split(" "), {
                  [errorClassName]: invalid,
                })}
              >
                <input
                  className="flex-1 text-sm py-2 px-4 rounded-md border-transparent focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-transparent"
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                <div className="bg-[#EFEFEF] rounded-md px-4 py-2 text-gray-400 text-sm leading-6">
                  Orang
                </div>
              </div>
            )}
            {invalid && (
              <div className="text-xs text-error-600 mt-2">
                {error?.message}
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default CustomInput;

import config from "app/config";
import axiosInstance from "app/interceptors";

export const getList = (params) => {
  return axiosInstance.get(
    `${config.DAPROG_API_URL}/implementing-partner/payment/list`,
    {
      params,
    }
  );
};

export const getDetail = (params) => {
  return axiosInstance.get(
    `${config.DAPROG_API_URL}/implementing-partner/payment/detail`,
    {
      params,
    }
  );
};

export const createImplementingPartnerPayment = (params) => {
  return axiosInstance.post(
    `${config.DAPROG_API_URL}/implementing-partner/payment/submit`,
    params
  );
};

export const updateStatusImplementingPartnerPayment = (params) => {
  return axiosInstance.put(
    `${config.DAPROG_API_URL}/implementing-partner/payment/update-status`,
    params
  );
};

export const deleteImplementingPartnerPayment = (params) => {
  return axiosInstance.delete(
    `${config.DAPROG_API_URL}/implementing-partner/payment/delete`,
    { data: params }
  );
};

export const approvalEligiblityImplementingPartnerPayment = ({ queryKey }) => {
  const [, implementingPartnerPaymentId] = queryKey;

  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/implementing-partner/payment/approval-eligiblity`,
    {
      id: implementingPartnerPaymentId,
    }
  );
};

export const getListInvestmentPlan = (params) => {
  return axiosInstance.get(`${config.DAPROG_API_URL}/investment-plan/list`, {
    params,
  });
};

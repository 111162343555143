import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import AtomSelect from "components/atoms/Select";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import RadioBadge from "components/molecules/Badge/RadioBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { useNavigate } from "react-router";

const Sdm = ({
  openModal = () => console.log(`open`),
  isEdit = false,
  toogleIsEdit = () => {},
  fieldsProgramPersonnel = [],
  appendsProgramPersonnel = () => {},
  removeProgramPersonnel = () => {},
  fieldsFinancePersonnel = [],
  appendsFinancePersonnel = () => {},
  removeFinancePersonnel = () => {},
  fieldsSafeguardPersonnel = [],
  appendsSafeguardPersonnel = () => {},
  removeSafeguardPersonnel = () => {},
  fieldsOtherPersonnel = [],
  appendsOtherPersonnel = () => {},
  removeOtherPersonnel = () => {},
  fieldsWorkPerformance = [],
  appendsWorkPerformance = () => {},
  removeWorkPerformance = () => {},
  fieldsPunishment = [],
  appendsPunishment = () => {},
  removePunishment = () => {},
  optionsSumEmployeeCurrent = [],
  optionsSumEmployee = [],
  optionsSumRunProgram = []
}) => {
  const navigate = useNavigate();

  const { 
    watch, 
    getValues, 
    control,
    formState: { errors, isValid }
  } = useFormContext();

  const [isEditVerification, setIsEditVerification] = useState(false);
  const [isEditVerification2, setIsEditVerification2] = useState(false);

  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);
  const toogleIsEditVerification2 = () =>
    setIsEditVerification2(!isEditVerification2);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">SDM</div>
          <div className="flex-1 text-right">
            {sessionStorage.getItem('isLemtaraFormDisabled') !== "true" && (isEdit ? (
              <Button
                type="button"
                className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Simpan Perubahan
              </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Ubah
              </Button>
            ))}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col">
          <QuestionBadge text="Berapa Jumlah Karyawan Tetap dari Lembaga/ Institusi/ Perusahaan Anda Saat Ini ?" />
          <RadioBadge
            arrData={optionsSumEmployeeCurrent}
            isEdit={isEdit}
            name={`step_1.partner_list.total_employee`}
            className={`mt-[20px] flex gap-[18px] w-full`}
            errors={errors?.step1?.partner_list?.total_employee ? errors?.step1?.partner_list?.total_employee : false}
          />
          <div className="mt-[49px]">
            <QuestionBadge text="Berapa Jumlah Karyawan Kontrak dari Lembaga/ Institusi/ Perusahaan Anda ?" />
          </div>
          <RadioBadge
            arrData={optionsSumEmployee}
            isEdit={isEdit}
            name={`step_1.partner_list.total_contract_employee`}
            className={`mt-[20px] flex gap-[18px] w-full`}
            errors={errors?.step1?.partner_list?.total_contract_employee ? errors?.step1?.partner_list?.total_contract_employee : false}
          />
          <div className="mt-[49px]">
            <QuestionBadge text="Berapa Banyak Staf yang Lembaga/ Institusi/ Perusahaan Anda Alokasikan Ketika Menjalankan Suatu Program ?" />
          </div>
          <RadioBadge
            arrData={optionsSumRunProgram}
            isEdit={isEdit}
            name={`step_1.partner_list.total_staff_program`}
            className={`mt-[20px] flex gap-[18px] w-full`}
            errors={errors?.step1?.partner_list?.total_staff_program ? errors?.step1?.partner_list?.total_staff_program : false}
          />
          <div className="mt-[49px]">
            <QuestionBadge text="Bagaimana Susunan Komposisinya ?" />
          </div>
          {/* PERSONIL PROGRAM */}
          {fieldsProgramPersonnel?.length > 0 && (
            <div className="mt-[20px] text-[18px] text-[#01A24A] font-[600]">
              Personil Program
            </div>
          )}
          {fieldsProgramPersonnel?.length > 0 &&
            fieldsProgramPersonnel.map((e, index) => {
              return (
                <div key={e.id} className="mt-[20px]">
                  <CardForm
                    label={`Personil ${index + 1}`}
                    buttonCollapse={true}
                  >
                    <div className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <InputForm
                          controllerName={`step_1.partner_list.program_personnel.${index}.identity_number`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"NIK"}
                          placeholder={"NIK"}
                          disabled={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.program_personnel.${index}.employee_status`}
                          options={[
                            { value: "1", label: "Karyawan tetap " },
                            { value: "2", label: "Karyawan Kontrak " },
                          ]}
                          className="basic-single mt-2"
                          label="Status Kepegawaian"
                          disable={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_1.partner_list.program_personnel.${index}.name`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"Nama"}
                          placeholder={"Nama"}
                          disabled={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.program_personnel.${index}.gender`}
                          options={[
                            { value: "P", label: "Perempuan" },
                            { value: "L", label: "Laki-laki" },
                          ]}
                          className="basic-single mt-2"
                          label="Jenis Kelamin"
                          disable={!isEdit}
                        />
                        {/* <AtomDatePicker 
                          controllerName={`step_1.partner_list.program_personnel.${index}.dob`}
                          label="Tanggal Lahir"
                          required={false}
                          placeholder="Tanggal Lahir"
                          asText={!isEdit ? true : false}
                          format={'yyyy-mm-dd'}
                        /> */}
                        <InputForm
                          controllerName={`step_1.partner_list.program_personnel.${index}.dob`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"Tanggal Lahir"}
                          placeholder={"Tanggal Lahir"}
                          isDate
                          disabled={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.program_personnel.${index}.education`}
                          options={[
                            { value: "1", label: "S1" },
                            { value: "2", label: "S2" },
                            { value: "3", label: "SD" },
                            { value: "4", label: "SMP" },
                            { value: "5", label: "SMA" },
                          ]}
                          className="basic-single mt-2"
                          label="Pendidikan"
                          disable={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.program_personnel.${index}.position`}
                          options={[
                            {
                              value: "c39f5613-5e62-4587-b347-c4b5dc2caf7f",
                              label: "Direktur",
                            },
                            { value: "2", label: "Manager" },
                            { value: "3", label: "Karyawan" },
                          ]}
                          className="basic-single mt-2"
                          label="Jabatan Dalam Proyek"
                          disable={!isEdit}
                        />
                        <div className="col-span-2">
                          <InputForm
                            controllerName={`step_1.partner_list.program_personnel.${index}.work_experience`}
                            className={`py-2 border w-full rounded-md flex-1`}
                            label={"Pengalaman Kerja"}
                            placeholder={"Pengalaman Kerja"}
                            textArea
                            disabled={!isEdit}
                          />
                        </div>
                        <div className="col-span-2">
                          <InputForm
                            controllerName={`step_1.partner_list.program_personnel.${index}.skills`}
                            className={`py-2 border w-full rounded-md flex-1`}
                            label={"Keahlian"}
                            placeholder={"Keahlian"}
                            textArea
                            disabled={!isEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </CardForm>
                </div>
              );
            })}

          {/* PERSONIL KEUANGAN */}
          {fieldsFinancePersonnel?.length > 0 && (
            <div className="mt-[20px] text-[18px] text-[#01A24A] font-[600]">
              Personil Keuangan
            </div>
          )}
          {fieldsFinancePersonnel?.length > 0 &&
            fieldsFinancePersonnel.map((e, index) => {
              return (
                <div key={e.id} className="mt-[20px]">
                  <CardForm
                    label={`Personil ${index + 1}`}
                    buttonCollapse={true}
                  >
                    <div className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <InputForm
                          controllerName={`step_1.partner_list.finance_personnel.${index}.identity_number`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"NIK"}
                          placeholder={"NIK"}
                          disabled={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.finance_personnel.${index}.employee_status`}
                          options={[
                            { value: "1", label: "Karyawan tetap " },
                            { value: "2", label: "Karyawan Kontrak " },
                          ]}
                          className="basic-single mt-2"
                          label="Status Kepegawaian"
                          disable={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_1.partner_list.finance_personnel.${index}.name`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"Nama"}
                          placeholder={"Nama"}
                          disabled={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.finance_personnel.${index}.gender`}
                          options={[
                            { value: "P", label: "Perempuan" },
                            { value: "L", label: "Laki-laki" },
                          ]}
                          className="basic-single mt-2"
                          label="Jenis Kelamin"
                          disable={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_1.partner_list.finance_personnel.${index}.dob`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"Tanggal Lahir"}
                          placeholder={"Tanggal Lahir"}
                          isDate
                          disabled={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.finance_personnel.${index}.education`}
                          options={[
                            { value: "1", label: "S1" },
                            { value: "2", label: "S2" },
                            { value: "3", label: "SD" },
                            { value: "4", label: "SMP" },
                            { value: "5", label: "SMA" },
                          ]}
                          className="basic-single mt-2"
                          label="Pendidikan"
                          disable={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.finance_personnel.${index}.position`}
                          options={[
                            {
                              value: "c39f5613-5e62-4587-b347-c4b5dc2caf7f",
                              label: "Direktur",
                            },
                            { value: "2", label: "Manager" },
                            { value: "3", label: "Karyawan" },
                          ]}
                          className="basic-single mt-2"
                          label="Jabatan Dalam Proyek"
                          disable={!isEdit}
                        />
                        <div className="col-span-2">
                          <InputForm
                            controllerName={`step_1.partner_list.finance_personnel.${index}.work_experience`}
                            className={`py-2 border w-full rounded-md flex-1`}
                            label={"Pengalaman Kerja"}
                            placeholder={"Pengalaman Kerja"}
                            textArea
                            disabled={!isEdit}
                          />
                        </div>
                        <div className="col-span-2">
                          <InputForm
                            controllerName={`step_1.partner_list.finance_personnel.${index}.skills`}
                            className={`py-2 border w-full rounded-md flex-1`}
                            label={"Keahlian"}
                            placeholder={"Keahlian"}
                            textArea
                            disabled={!isEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </CardForm>
                </div>
              );
            })}

          {/* PERSONIL SAFEGUARD */}
          {fieldsSafeguardPersonnel?.length > 0 && (
            <div className="mt-[20px] text-[18px] text-[#01A24A] font-[600]">
              Personil Safeguard
            </div>
          )}
          {fieldsSafeguardPersonnel?.length > 0 &&
            fieldsSafeguardPersonnel.map((e, index) => {
              return (
                <div key={e.id} className="mt-[20px]">
                  <CardForm
                    label={`Personil ${index + 1}`}
                    buttonCollapse={true}
                  >
                    <div className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <InputForm
                          controllerName={`step_1.partner_list.safeguard_personnel.${index}.identity_number`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"NIK"}
                          placeholder={"NIK"}
                          disabled={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.safeguard_personnel.${index}.employee_status`}
                          options={[
                            { value: "1", label: "Karyawan tetap " },
                            { value: "2", label: "Karyawan Kontrak " },
                          ]}
                          className="basic-single mt-2"
                          label="Status Kepegawaian"
                          disable={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.safeguard_personnel.${index}.gender`}
                          options={[
                            { value: "P", label: "Perempuan" },
                            { value: "L", label: "Laki-laki" },
                          ]}
                          className="basic-single mt-2"
                          label="Jenis Kelamin"
                          disable={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_1.partner_list.safeguard_personnel.${index}.name`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"Nama"}
                          placeholder={"Nama"}
                          disabled={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_1.partner_list.safeguard_personnel.${index}.dob`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"Tanggal Lahir"}
                          placeholder={"Tanggal Lahir"}
                          isDate
                          disabled={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.safeguard_personnel.${index}.education`}
                          options={[
                            { value: "1", label: "S1" },
                            { value: "2", label: "S2" },
                            { value: "3", label: "SD" },
                            { value: "4", label: "SMP" },
                            { value: "5", label: "SMA" },
                          ]}
                          className="basic-single mt-2"
                          label="Pendidikan"
                          disable={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.safeguard_personnel.${index}.position`}
                          options={[
                            {
                              value: "c39f5613-5e62-4587-b347-c4b5dc2caf7f",
                              label: "Direktur",
                            },
                            { value: "2", label: "Manager" },
                            { value: "3", label: "Karyawan" },
                          ]}
                          className="basic-single mt-2"
                          label="Jabatan Dalam Proyek"
                          disable={!isEdit}
                        />
                        <div className="col-span-2">
                          <InputForm
                            controllerName={`step_1.partner_list.safeguard_personnel.${index}.work_experience`}
                            className={`py-2 border w-full rounded-md flex-1`}
                            label={"Pengalaman Kerja"}
                            placeholder={"Pengalaman Kerja"}
                            textArea
                            disabled={!isEdit}
                          />
                        </div>
                        <div className="col-span-2">
                          <InputForm
                            controllerName={`step_1.partner_list.safeguard_personnel.${index}.skills`}
                            className={`py-2 border w-full rounded-md flex-1`}
                            label={"Keahlian"}
                            placeholder={"Keahlian"}
                            textArea
                            disabled={!isEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </CardForm>
                </div>
              );
            })}

          {/* PERSONIL Lain - Lain */}
          {fieldsOtherPersonnel?.length > 0 && (
            <div className="mt-[20px] text-[18px] text-[#01A24A] font-[600]">
              Personil Lain - Lain
            </div>
          )}
          {fieldsOtherPersonnel?.length > 0 &&
            fieldsOtherPersonnel.map((e, index) => {
              return (
                <div key={e.id} className="mt-[20px]">
                  <CardForm
                    label={`Personil ${index + 1}`}
                    buttonCollapse={true}
                  >
                    <div className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <InputForm
                          controllerName={`step_1.partner_list.other_personnel.${index}.identity_number`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"NIK"}
                          placeholder={"NIK"}
                          disabled={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.other_personnel.${index}.employee_status`}
                          options={[
                            { value: "1", label: "Karyawan tetap " },
                            { value: "2", label: "Karyawan Kontrak " },
                          ]}
                          className="basic-single mt-2"
                          label="Status Kepegawaian"
                          disable={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_1.partner_list.other_personnel.${index}.name`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"Nama"}
                          placeholder={"Nama"}
                          disabled={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.other_personnel.${index}.gender`}
                          options={[
                            { value: "P", label: "Perempuan" },
                            { value: "L", label: "Laki-laki" },
                          ]}
                          className="basic-single mt-2"
                          label="Jenis Kelamin"
                          disable={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_1.partner_list.other_personnel.${index}.dob`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"Tanggal Lahir"}
                          placeholder={"Tanggal Lahir"}
                          isDate
                          disabled={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.other_personnel.${index}.education`}
                          options={[
                            { value: "1", label: "S1" },
                            { value: "2", label: "S2" },
                            { value: "3", label: "SD" },
                            { value: "4", label: "SMP" },
                            { value: "5", label: "SMA" },
                          ]}
                          className="basic-single mt-2"
                          label="Pendidikan"
                          disable={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_1.partner_list.other_personnel.${index}.position`}
                          options={[
                            {
                              value: "c39f5613-5e62-4587-b347-c4b5dc2caf7f",
                              label: "Direktur",
                            },
                            { value: "2", label: "Manager" },
                            { value: "3", label: "Karyawan" },
                          ]}
                          className="basic-single mt-2"
                          label="Jabatan Dalam Proyek"
                          disable={!isEdit}
                        />
                        <div className="col-span-2">
                          <InputForm
                            controllerName={`step_1.partner_list.other_personnel.${index}.work_experience`}
                            className={`py-2 border w-full rounded-md flex-1`}
                            label={"Pengalaman Kerja"}
                            placeholder={"Pengalaman Kerja"}
                            textArea
                            disabled={!isEdit}
                          />
                        </div>
                        <div className="col-span-2">
                          <InputForm
                            controllerName={`step_1.partner_list.other_personnel.${index}.skills`}
                            className={`py-2 border w-full rounded-md flex-1`}
                            label={"Keahlian"}
                            placeholder={"Keahlian"}
                            textArea
                            disabled={!isEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </CardForm>
                </div>
              );
            })}

          {/* button Verification */}
          <RadioVerificationBadge 
            className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
            name={"step_1.status_approval_personnel"}
            nameNotes={"step_1.notes_approval_personnel"}
            isEditVerification={isEditVerification}
            toogleIsEditVerification={toogleIsEditVerification}
            errors={errors?.step_1?.status_approval_personnel ? errors?.step_1?.status_approval_personnel : false}
          />

          {/* WORK PERFORMANCE */}
          {fieldsWorkPerformance?.length > 0 && (
            <div className="mt-[73px]">
              <QuestionBadge text="Ketika Karyawan/ Staf And Meraih Prestasi Kerja. Bentuk Apresiasi (Reward) Seperti Apa yang Lembaga/ Instansi/ Perusahaan And Berikan?" />
            </div>
          )}
          {fieldsWorkPerformance?.length > 0 &&
            fieldsWorkPerformance?.map((e, index) => {
              return (
                <>
                  <div className="mt-[20px]">
                    <AtomSelect
                      controllerName={`step_1.partner_list.work_performance.${index}.type_work_performance`}
                      options={[
                        { value: "1", label: "Lembaga 1 " },
                        { value: "2", label: "Lembaga 2 " },
                      ]}
                      className="basic-single mt-2"
                      label={`Jenis Prestasi Kerja ${index + 1}`}
                      disable={!isEdit}
                    />
                  </div>
                  <div className="mt-[20px]">
                    <InputForm
                      controllerName={`step_1.partner_list.work_performance.${index}.reward`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={`Reward yang Diberikan ${index + 1}`}
                      placeholder={`Reward yang Diberikan ${index + 1}`}
                      disabled={!isEdit}
                    />
                  </div>
                </>
              );
            })}

          {/* PUNISHMENT */}
          {fieldsPunishment?.length > 0 && (
            <div className="mt-[73px]">
              <QuestionBadge text="Ketika Karyawan/ Staf Lembaga/ Institusi/ Perusahaan Anda Melakukan Kesalahan/ Pelanggaran Kerja, Apa yang Menjadi Konsekwensi (Punishment) yang Diberikan Kepada Karyawan/ Staf And ?" />
            </div>
          )}

          <div className="mt-[20px]">
            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {fieldsPunishment?.length > 0 &&
                  fieldsPunishment?.map((e, index) => {
                    return (
                      <>
                        <InputForm
                          controllerName={`step_1.partner_list.punishment.${index}.type_punishment`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={`Jenis Kesalahan/ Pelanggaran ${index + 1}`}
                          placeholder={`Jenis Kesalahan/ Pelanggaran ${
                            index + 1
                          }`}
                          disabled={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_1.partner_list.punishment.${index}.consequence`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={`konsekwensi/ Punishment yang Diberikan ${
                            index + 1
                          }`}
                          placeholder={`konsekwensi/ Punishment yang Diberikan ${
                            index + 1
                          }`}
                          disabled={!isEdit}
                        />
                      </>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* button Verification */}
          <RadioVerificationBadge 
            className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
            name={"step_1.status_approval_reward_punishment"}
            nameNotes={"step_1.notes_approval_reward_punishment"}
            isEditVerification={isEditVerification2}
            toogleIsEditVerification={toogleIsEditVerification2}
            errors={errors?.step_1?.status_approval_reward_punishment ? errors?.step_1?.status_approval_reward_punishment : false}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default Sdm;

import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { getList } from "services/danaProgram/callForProposalService";
import { getErrorMessage } from "helpers";

const useCallForProposal = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== undefined && value !== "")
  );

  const {
    data: callForProposalData,
    isLoading: isLoadingCallForProposalData,
    isFetching: isFetchingCallForProposalData,
    refetch: refetchCallForProposalData,
  } = useQuery(
    ['list-callForProposal', params],
    () => getList(queryParams),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  return {
    callForProposalData: callForProposalData?.data?.data.data || [],
    paginationCallForProposalData: { 
      lastPage: callForProposalData?.data?.data?.lastPage,
      page: callForProposalData?.data?.data?.page,
      perPage: callForProposalData?.data?.data?.perPage,
      total: callForProposalData?.data?.data?.total
    } || {},
    isLoadingCallForProposalData,
    isFetchingCallForProposalData,
    refetch: refetchCallForProposalData,
  };
};

export default useCallForProposal;

import classNames from "classnames";
import { CardForm, Spinner, TableHeader, TableRow } from "components";
import { useMemo } from "react";

const KinerjaFiskal = ({
  data = {}
}) => {
  // table params
  let isLoading = false;
  let stripped = false;
  let wrapperClassName = "";
  let headerClassName = "";
  let className = "";
  let layout = "auto";
  let defaultSortOrder = undefined;

  const result = useMemo(() => {
    return [
      {
        name: "Total Pendapatan/ Total Asset",
        minScore: 1.51,
        description: "Semakin tinggi semakin baik",
        result: data?.income_to_asset_ratio?.toFixed(2),
        finalResult: Number(data?.income_to_asset_ratio?.toFixed(2)) > 1.51 ? "Baik" : "Buruk"
      },
      {
        name: "Total Pendapatan/ Total Biaya",
        minScore: 1.09,
        description: "Semakin tinggi semakin baik",
        result: data?.income_to_cost_ratio?.toFixed(2),
        finalResult: Number(data?.income_to_cost_ratio?.toFixed(2)) > 1.09 ? "Baik" : "Buruk"
      },
      {
        name: "Aset Nota/ Total Aset",
        minScore: 0.75,
        description: "Semakin tinggi semakin baik",
        result: data?.net_income_to_asset_ratio?.toFixed(2),
        finalResult: Number(data?.net_income_to_asset_ratio?.toFixed(2)) > 0.75 ? "Baik" : "Buruk"
      },
    ]
  })

  const tableColumns = useMemo(() => {
    return [
      {
        id: "name",
        title: "Nama Rasio",
        dataIndex: "name",
        className: "",
        columnClassName:'w-[200px] text-ellipsis overflow-hidden whitespace-pre-wrap',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[200px]">
            {value}
          </div>
        ),
      },
      {
        id: "minScore",
        title: "Nilai Minimal",
        dataIndex: "minScore",
        className: "",
        columnClassName:'w-[150px] text-ellipsis overflow-hidden whitespace-pre-wrap',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: "description",
        title: "Keterangan",
        dataIndex: "description",
        className: "",
        columnClassName:'w-[260px] text-ellipsis overflow-hidden whitespace-pre-wrap',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[260px]">
            {value}
          </div>
        ),
      },
      {
        id: "result",
        title: "Hasil Penilaian",
        dataIndex: "result",
        className: "",
        columnClassName:"overflow-hidden w-[200px] text-ellipsis overflow-hidden whitespace-pre-wrap",
        sortable: true,
        render: (value) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[150px] flex flex-col gap-[12px]">
              {`${value}%`}
            </div>
          )
        },
      },
      {
        id: "finalResult",
        title: "Kesimpulan",
        dataIndex: "finalResult",
        className: "",
        columnClassName:"w-[150px] text-ellipsis overflow-hidden whitespace-pre-wrap",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            <div className={`${value === `Baik` ? `bg-[#ECFDF3]` : `bg-[#FEF3F2]`} 
            rounded-[25px] flex gap-[1px] items-center justify-center w-[60px]`}>
              <div className={`${value === `Baik` ? `text-[#1C7D44]`: `text-[#B42318]`} font-medium text-[12px]`}>{value}</div>
            </div>
          </div>
        ),
      },
    ];
  }, []);


  return (
    <div>
      <CardForm
        label={"Rasio Kinerja Fiskal"}
        buttonCollapse={true}
      >
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-3">
            <div className="space-y-4">
              {/* TABLE */}
              <div className="border-[1px] border-solid border-gray-200 rounded-md">
                <div
                  className={classNames(
                    ["overflow-x-auto", "overflow-y-hidden"],
                    [...wrapperClassName.split(" ")]
                  )}
                >
                  <table
                    className={classNames(
                      ["table min-w-full"],
                      { "table-auto": layout === "auto" },
                      { "table-fixed": layout === "fixed" },
                      {
                        "table-striped": stripped,
                      },
                      [...className.split(" ")]
                    )}
                  >
                    {/* {layout === "fixed" && <TableColumn items={columns} />} */}
                    <TableHeader
                      items={tableColumns}
                      headerClassName={headerClassName}
                      defaultSortOrder={defaultSortOrder}
                    />
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan={tableColumns.length}>
                            <div className="w-100 h-[300px]">
                              <Spinner className="text-xl" />
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <TableRow columns={tableColumns} datas={result} />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardForm>
    </div>
  )
}

export default KinerjaFiskal
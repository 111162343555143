import React, { useCallback } from "react";
import { CustomSelect, DateInput, SearchInput } from "components";

const FilterProgramList = ({
  onSearch,
  typeOptions = [],
  categoryOptions = [],
  onChangeType,
  onChangeCategory,
}) => {
  return (
    <div className="space-x-5 flex">
      <SearchInput placeholder="Cari" onChange={onSearch} />
      {/* <CustomSelect
        optionsData={typeOptions}
        placeholder="Status"
        className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none"
        onChange={onChangeType}
      /> */}
      <DateInput/>
      {/* <div className="flex flex-1">
        <CustomSelect
          optionsData={categoryOptions}
          placeholder="Category"
          className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none"
          onChange={onChangeCategory}
        />
      </div> */}
    </div>
  );
};

export default FilterProgramList;

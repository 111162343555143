import {
  approvalEligiblityDirectSubmission,
  reviewDirectSubmission,
} from 'services/danaProgram/directSubmissionService';

import {
  approvalEligiblityImplementingPartnerPayment,
  updateStatusImplementingPartnerPayment,
} from 'services/danaProgram/implementingPartnerPaymentServices';
import {
  approvalEligiblityPembayaranResource,
  approvePembayaranResource,
} from 'services/danaProgram/pembayaranResourceService';

import {
  approvalEligiblityWithdrawalFundPlan,
  approvalWithdrawalFundPlan,
} from 'services/danaProgram/specificTimeWorkPlanService';

import {
  approvePembayaranLemtara,
  approvalEligiblityPembayaranLemtara,
} from 'services/danaProgram/approvalPembayaranLemtaraService';

export const MENUS_NEED_APPROVAL = [
  {
    module: 'rencana-penarikan-dana',
    key: 'withdrawal-work-plan',
    urlIndex: '/approval-rencana-penarikan-dana',
    queryVerify: approvalEligiblityWithdrawalFundPlan,
    querySubmit: approvalWithdrawalFundPlan,
  },
  {
    module: 'implementing-partner-payment',
    key: 'implementing-partner-payment',
    urlIndex: '/approval-pembayaran-implementing-partner',
    queryVerify: approvalEligiblityImplementingPartnerPayment,
    querySubmit: updateStatusImplementingPartnerPayment,
  },
  {
    module: 'pembayaran-resources',
    key: 'pembayaran-resources',
    urlIndex: '/approval-pembayaran-resources',
    queryVerify: approvalEligiblityPembayaranResource,
    querySubmit: approvePembayaranResource,
  },
  {
    module: 'penyaluran-langsung',
    key: 'penyaluran-langsung',
    urlIndex: '/approval-penyaluran-langsung',
    queryVerify: approvalEligiblityDirectSubmission,
    querySubmit: reviewDirectSubmission,
  },
  {
    module: 'pembayaran-lemtara',
    key: 'pembayaran-lemtara',
    urlIndex: '/approval-pembayaran-lemtara',
    queryVerify: approvalEligiblityPembayaranLemtara,
    querySubmit: approvePembayaranLemtara,
  },
];

export const APPROVAL_PIC = {
  level_1: 'Direktur Proyek',
  level_2: 'Direktur Penyaluran Dana',
};

export const APPROVAL_STATUSSES = [
  { value: null, label: 'Semua' },
  {
    value: 'APPROVED',
    label: 'Disetujui',
    color: 'text-green-400 bg-[#ECFDF3]',
  },
  {
    value: 'NEED_APPROVAL',
    label: 'Menunggu Persetujuan Direktur Proyek',
    color: 'text-yellow-400 bg-[#fffcdc]',
  },
  {
    value: 'NEED_APPROVAL_DIRECTOR',
    label: 'Menunggu Persetujuan Direktur Penyaluran Dana',
    color: 'text-yellow-400 bg-[#fffcdc]',
  },
  {
    value: 'REJECTED',
    label: 'Ditolak',
    color: 'text-red-400 bg-[#FEF3F2]',
  },
];

import React, { useMemo } from "react";

const TabContent = ({ children, activeTab }) => {
  // Memoize the active tab content to prevent unnecessary re-renders
  const activeTabContent = useMemo(
    () => children[activeTab],
    [children, activeTab]
  );

  return <div className="tabs-content py-5">{activeTabContent}</div>;
};

export default TabContent;

import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import AktivaAset from "./AktivaAset";
import KekayaanBersih from "./KekayaanBersih";
import LampiranDokumenPendukung from "./LampiranDokumenPendukung";
import Pasiva from "./Pasiva";
import PendapatanDanBeban from "./PendapatanDanBeban";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const InstitutionalFinancialReport = ({ tools, methods, isProfit }) => {
  const navigate = useNavigate();

  const data = tools[0]?.financial_report;

  const {
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();

  const [isEdit, setIsEdit] = useState(false);
  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEdit = () => setIsEdit(!isEdit);
  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  return (
    <div>
      {!isProfit ? (
        <Card>
          <CardHeader>
            <div className="flex justify-center items-center">
              <div className="flex-1">Informasi Rekening</div>
              <div className="flex-1 text-right">
                {isEdit ? (
                  <Button
                    type="button"
                    className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                    onClick={toogleIsEdit}
                  >
                    <BiPencil />
                    Simpan Perubahan
                  </Button>
                ) : (
                  <Button
                    type="button"
                    className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                    onClick={toogleIsEdit}
                  >
                    <BiPencil />
                    Ubah
                  </Button>
                )}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div className="flex flex-col">
              <div className="flex-1 pt-2">
                <InputForm
                  showPrefix={true}
                  prefix={"Rp. "}
                  controllerName={`advance_payment_program`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  label={"Uang Muka Program"}
                  placeholder={"0"}
                  required={true}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      ) : (
        ""
      )}
      {data?.map((field, index) => {
        return (
          <Card>
            <CardHeader>
              <div className="flex justify-center items-center">
                <div className="flex-1">
                  Laporan Keuangan Lembaga - Tahun {field.year}{" "}
                </div>
                <div className="flex-1 text-right">
                  {isEdit ? (
                    <Button
                      type="button"
                      className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                      onClick={toogleIsEdit}
                    >
                      <BiPencil />
                      Simpan Perubahan
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                      onClick={toogleIsEdit}
                    >
                      <BiPencil />
                      Ubah
                    </Button>
                  )}
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="flex flex-col">
                <AktivaAset
                  data={data[index]}
                  index={index}
                  methods={methods}
                />
                <Pasiva data={data[index]} index={index} methods={methods} />
                <KekayaanBersih data={data[index]} index={index} />
                <PendapatanDanBeban
                  data={data[index]}
                  index={index}
                  isProfit={isProfit}
                />
                {/* <LampiranDokumenPendukung data={data[index]} index={index} /> */}
                {/* button Verification */}
                <RadioVerificationBadge
                  className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
                  name={`tools.0.financial_report.${index}.result_verification`}
                  nameNotes={`tools.0.financial_report.${index}.reason`}
                  isEditVerification={isEditVerification}
                  toogleIsEditVerification={toogleIsEditVerification}
                  errors={
                    errors?.tools
                      ? errors?.tools[0]?.financial_report[index]
                          ?.result_verification
                      : false
                  }
                />
              </div>
            </CardBody>
          </Card>
        );
      })}
    </div>
  );
};

export default InstitutionalFinancialReport;

import { Button, Card, CardBody, CardHeader, InputForm, InputFormUpload, TitleText } from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";

const SupportingDocument = ({
  arrStaticFile = [],
  arrStaticFile2 = [],
}) => {
  const navigate = useNavigate();

  const {
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();
  const [isEditVerification, setIsEditVerification] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const toogleIsEditVerification = () => setIsEditVerification(!isEditVerification)
  const toogleIsEdit = () => setIsEdit(!isEdit)

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Dokumen Pendukung Verifikasi</div>
          <div className="flex-1 text-right">
            {sessionStorage.getItem(`isLemtaraFormDisabled`) !== "true" && (isEdit ? (
              <Button
                type="button"
                className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Simpan Perubahan
              </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Ubah
              </Button>
            ))}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {arrStaticFile.map((e, i) => {
            return (
              <>
                {watch(`resource_document.${e.key}.showFile`)?.length > 0 && watch(`resource_document.${e.key}.showFile`).map((ee, ii) => {
                  return (

                    <div key={ii} className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl">

                      <div className="flex gap-4">
                        <div>
                          <div className="bg-[#EEFFF4] rounded-full p-4">
                            <RiFile2Line className="text-primary-600" />
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="font-medium">
                            {`${e.name} ${ii + 1}`}
                          </span>
                          <span>
                            {`${Number(ee?.file?.size).toFixed(2)} MB`}
                          </span>
                          <span
                            className="font-semibold text-primary-600 cursor-pointer"
                            onClick={() => window.open(ee?.file?.url)}
                          >
                            Lihat Dokumen
                          </span>
                        </div>
                      </div>

                      {isEdit &&
                        <InputFormUpload
                          controllerName={"files_" + e.key}
                          maxSize={102400}
                          label=" "
                          // isMulti
                          hideSectionUpload={!isEdit}
                          listFiles={[watch(`resource_document.${e.key}.showFile`)] ?? []}
                        />
                      }
                    </div>
                  )
                })}
              </>
            )
          })}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          {arrStaticFile2.map((e, i) => {
            if (watch(`resource_document.${e.key}.showFile`)?.url) {
              return (
                <>
                  <div key={i} className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl">
                    <div className="flex gap-4">
                      <div>
                        <div className="bg-[#EEFFF4] rounded-full p-4">
                          <RiFile2Line className="text-primary-600" />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="font-medium">
                          {e.name}
                        </span>
                        <span>
                          {`${Number(watch(`resource_document.${e.key}.showFile`)?.size).toFixed(2)} MB`}
                        </span>
                        <span
                          className="font-semibold text-primary-600 cursor-pointer"
                          onClick={() => window.open(watch(`resource_document.${e.key}.showFile`).url)}
                        >
                          Lihat Dokumen
                        </span>
                      </div>
                    </div>

                    {isEdit &&
                      <InputFormUpload
                        controllerName={"files_" + e.key}
                        maxSize={102400}
                        label=" "
                        // isMulti
                        hideSectionUpload={!isEdit}
                        listFiles={[watch(`resource_document.${e.key}.showFile`)] ?? []}
                      />
                    }
                  </div>
                  <div className="mt-[0px]">
                    <InputForm
                      controllerName={`resource_document.${e.keyDesc}`}
                      className={`py-2 border w-full rounded-md flex-1`}
                      placeholder={'Uraian Singkat Terkait Isi Dokumen'}
                      label={'Uraian Singkat Terkait Isi Dokumen'}
                      rows="4"
                      textArea
                      disabled={!isEdit}
                    />
                  </div>
                </>
              )
            }
          })}
        </div>
        {/* button Verification */}
        <RadioVerificationBadge
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"resource_document.status_approval"}
          nameNotes={"resource_document.notes_approval"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={errors?.resource_document?.status_approval ? errors?.resource_document?.status_approval : false}
        />
      </CardBody>
    </Card>
  )
}

export default SupportingDocument;
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';
import moment from 'moment';

import { AiOutlineEye, AiOutlinePlus } from 'react-icons/ai';
import { BiPencil } from 'react-icons/bi';

import {
  Breadcrumbs,
  Button,
  CustomDatepicker,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from 'components';
import { useActivityReport } from './hooks';
import { useQuery } from '@tanstack/react-query';
import { getActivityReportList } from 'services/danaProgram/activityReportService';
import { createSearchParams } from 'react-router-dom';

const links = [
  {
    label: 'Daftar Laporan Implementasi Safeguard',
  },
];

const StatusArray = [
  {
    value: 'VERIFIED',
    label: 'Terverifikasi',
  },
  {
    value: 'NEED_APPROVAL',
    label: 'Menunggu Verifikasi',
  },
  {
    value: 'Drafted',
    label: 'Draft',
  },
];

const SafeguardImplementation = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [dataSource, setDataSource] = useState({});

  const [ProjectOptions, setProjectOptions] = useState([]);

  const { data, isLoading } = useQuery({
    queryKey: ['list-activity-report', { page, limit, keyword, statusFilter }],
    queryFn: async () => {
      const res = await getActivityReportList({
        page,
        limit,
        keyword,
        statusFilter,
      });
      return res.data.data;
    },
  });

  const uniqueData = (datas) => {
    let temp = datas.filter(
      (item, index, self) =>
        index ===
        self.findIndex((t) => t.label === item.label && t.value === item.value)
    );
    return temp;
  };

  useEffect(() => {
    localStorage.removeItem('ActivityReportsId');
    if (data) {
      let arr = data?.data.map((key) => ({
        label: key?.step_1?.project_name,
        value: key?.step_1?.project_name,
        ...key,
      }));
      setProjectOptions(uniqueData(arr));
      let mapped = data?.data?.map((item) => {
        return {
          id: item?._id,
          updated_date: item?.updated_at,
          project: item?.step_1?.project_name,
          submitter: item?.submitter,
          realisation_cost: item?.step_2?.total_cost,
          status: item?.status,
        };
      });

      setDataSource({
        data: mapped,
        paginationData: {
          total: data?.total,
          page: data?.page,
          lastPage: data?.lastPage,
          perPage: data?.perPage,
        },
      });
    }
  }, [data]);

  const onChangeStatus = useCallback((obj) => {
    setStatusFilter(obj?.value ?? undefined);
  }, []);
  const onChangeProject = useCallback((obj) => {
    setKeyword(obj?.value ?? undefined);
  }, []);
  const setNewPage = useCallback((val) => setPage(val), []);
  const setNewLimit = useCallback((val) => setLimit(val), []);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const tableColumns = useMemo(() => {
    return [
      {
        id: 'no',
        title: 'No',
        dataIndex: 'no',
        className: 'overflow-hidden',
        columnClassName: 'w-[50px]',
        render: (value, data, index) => {
          const no =
            parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: 'updated_date',
        title: 'Tanggal Laporan',
        dataIndex: 'updated_date',
        className: 'overflow-hidden text-ellipsis',
        columnClassName: 'w-[180px]',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
            {value ? moment(value).format('DD/MM/YYYY') : '-'}
          </div>
        ),
      },
      {
        id: 'project',
        title: 'Proyek',
        dataIndex: 'project',
        sortable: true,
        className: 'overflow-hidden text-ellipsis',
        columnClassName: 'w-[180px]',
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
            {value ?? '-'}
          </div>
        ),
      },
      {
        id: 'reporter',
        title: 'Pelapor',
        dataIndex: 'submitter',
        sortable: true,
        className: 'overflow-hidden text-ellipsis',
        columnClassName: 'w-[180px]',
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
            {value ?? '-'}
          </div>
        ),
      },
      {
        id: 'actions',
        title: 'Aksi',
        dataIndex: 'actions',
        width: 160,
        columnClassName: 'text-center w-[150px]',
        fixed: 'right',
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full justify-center">
            <AiOutlineEye
              className="text-gray-600 cursor-pointer"
              size={20}
              onClick={() => {
                navigate({
                  pathname: `/safeguard-implementation/detail/${data.id}`,
                  search: createSearchParams({
                    mode: 'detail',
                  }).toString(),
                });

                localStorage.setItem('ActivityReportsId', data.id);
              }}
            />
          </div>
        ),
      },
    ];
  }, [page, limit]);

  return (
    <Fragment>
      <div className="sm:max-w-[480px] md:max-w-[608px] lg:max-w-[864px] xl:max-w-[75vw]">
        <Breadcrumbs items={links} />
        <div className="md:flex block items-center my-3">
          <TitleText className="flex-1 text-gray-900 text-lg font-semibold">
            Daftar Laporan Implementasi Safeguard
          </TitleText>
        </div>
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-3">
            <div className="space-y-4">
              <div className="space-x-5 flex">
                <div className="flex-1 w-1/4">
                  <SearchInput placeholder="Cari" onChange={onSearch} />
                </div>
              </div>
              <Table
                bordered
                stripped
                layout="fixed"
                className="mb-4"
                columns={tableColumns}
                dataSource={dataSource.data ?? []}
                isLoading={isLoading}
                onChangePage={setNewPage}
                onChangeRowsPerPage={setNewLimit}
                pagination={dataSource?.paginationData}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SafeguardImplementation;

import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { BsExclamationCircle } from "react-icons/bs";
import { debounce } from "lodash";

import { SearchInput } from "components";
import CustomSelectForm from "components/molecules/CustomSelectForm";
import { TableWrapper } from "components/molecules/ListPage";
import { BadgeStatus as ApprovalBadgeStatus } from "components/organisms/Approval2Level";

import {
  useDeleteImplementingPartnerPayment,
  useImplementingPartnerPaymentList,
} from "./hooks/useImplementingPartnerPaymentServices";
import { APPROVAL_STATUSSES } from "components/organisms/Approval2Level/constant";

const links = [
  {
    label: "Daftar Pembayaran Implementing Partner",
  },
];

const ImplementingPartnerPayment = () => {
  const methods = useForm();
  const navigate = useNavigate();

  const [qParams, setQParams] = useState({
    name: "",
    limit: 10,
    page: 1,
    orderMethod: "desc",
    orderBy: "created_at",
  });
  const [showModal, setShowModal] = useState(false);
  const [dataId, setDataId] = useState("");

  const { list, pagination, refetch } =
    useImplementingPartnerPaymentList(qParams);
  const { deleteData } = useDeleteImplementingPartnerPayment();

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(qParams.page) * parseInt(qParams.limit) -
            parseInt(qParams.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "implementing_partner_project_name",
        title: "Nama Project",
        dataIndex: "implementing_partner_project_name",
        className: "",
        fixed: "",
        headerClassName: "text-left",
        columnClassName: "w-[250px] text-ellipsis overflow-hidden",
        sortable: true,
        render: (value, row) => (
          <div className="overflow-hidden text-ellipsis">{value}</div>
        ),
      },
      {
        id: "implementing_partner_name",
        title: "Nama Partner",
        dataIndex: "implementing_partner_name",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        fixed: "",
        columnClassName: "text-ellipsis overflow-hidden w-60",
        render: (value, row) => (
          <div className="overflow-hidden text-ellipsis">{value}</div>
        ),
      },
      {
        id: "trx_date",
        title: "Tanggal Transaksi",
        dataIndex: "transaction_date",
        className: "",
        fixed: "",
        headerClassName: "text-left",
        columnClassName: "whitespace-break-spaces",
        sortable: true,
        render: (value, row) => (
          <div className="overflow-hidden text-ellipsis">{value}</div>
        ),
      },
      {
        id: "institution",
        title: "total nilai transaksi",
        dataIndex: "total_payment",
        className: "",
        fixed: "",
        headerClassName: "text-left",
        columnClassName: "whitespace-break-spaces",
        sortable: true,
        render: (value, row) => (
          <div className="overflow-hidden text-ellipsis">
            {Number(value).toLocaleString()}
          </div>
        ),
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        className: "",
        columnClassName: "text-center whitespace-break-spaces",
        sortable: true,
        fixed: "center",
        render: (value) => <ApprovalBadgeStatus value={value} />,
      },

      {
        id: "actions",
        title: "Aksi",
        dataIndex: "_id",
        className: "",
        sortable: true,
        columnClassName: "text-center text-ellipsis overflow-hidden",
        fixed: "center",
        render: (value, data, index) => {
          return value ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                {/* <Button
                  type="button"
                  // disabled={submitMutation.isLoading}
                  className="w-full p-2 text-xs text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                  onClick={() => {
                    navigate(`/implementing-partner-payment/${value}`);
                  }}
                >
                  Review
                </Button> */}
                <div className="flex items-center justify-center w-full space-x-3">
                  <MdOutlineRemoveRedEye
                    className="text-gray-600 cursor-pointer"
                    size={20}
                    onClick={() =>
                      navigate(`/implementing-partner-payment/${value}`)
                    }
                  />
                  <FaRegEdit
                    className="text-gray-600 cursor-pointer"
                    size={20}
                    onClick={() =>
                      navigate(`/implementing-partner-payment/edit/${value}`)
                    }
                  />
                  <FaRegTrashAlt
                    className="text-red-600 cursor-pointer"
                    size={20}
                    onClick={() => {
                      setDataId(data._id);
                      setShowModal(!showModal);
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          );
        },
      },
    ];
  }, []);

  const onChangePage = useCallback(
    (val) => setQParams({ ...qParams, page: val }),
    []
  );

  const onChangeRowsPerPage = useCallback(
    (val) => setQParams({ ...qParams, limit: val }),
    []
  );

  const onSearch = debounce(
    useCallback(
      (event) => {
        setQParams({ ...qParams, name: event?.target?.value || "" });
      },
      [setQParams]
    ),
    1000
  );

  useEffect(() => {
    refetch();
  }, [qParams]);

  const handleDeleteData = async () => {
    try {
      deleteData.mutate(
        { id: dataId },
        {
          onSuccess: (res) => {
            setShowModal(!showModal);
            refetch();
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <TableWrapper
        links={links}
        title={"Daftar Pembayaran Implementing Partner"}
        btnSize={"md"}
        columns={tableColumns}
        dataSource={list}
        onCreate={() => navigate("/implementing-partner-payment/create")}
        sortMethod={"desc"}
        setSortMethod={() => console.log("first")}
        setSortBy={() => console.log("first")}
        customTextAddButton="Tambah"
        pagination={pagination}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        customFilter={
          <FormProvider {...methods}>
            <div className="grid grid-cols-2 gap-3">
              <SearchInput
                controllerName={`search`}
                placeholder="Cari"
                className={"w-full"}
                onChange={onSearch}
              />
              <CustomSelectForm
                name="status"
                placeholder="Status"
                options={APPROVAL_STATUSSES}
                onChange={(e) =>
                  setQParams((prev) => ({ ...prev, status: e.value }))
                }
              />
            </div>
          </FormProvider>
        }
      />

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/70 backdrop-blur-sm">
          <div className="flex flex-col items-center justify-center gap-5 p-5 bg-white rounded-xl w-96">
            <BsExclamationCircle size={50} className="text-red-500" />
            <div className="w-full font-semibold text-center">
              Apakah anda yakin akan menghapus Data ini?
            </div>
            <div className="flex items-center justify-center w-full gap-5 text-sm">
              <button
                onClick={() => setShowModal(!showModal)}
                className="px-5 py-2 border border-gray-500 rounded-lg"
              >
                Batal
              </button>
              <button
                onClick={handleDeleteData}
                disabled={deleteData.isLoading}
                className="px-5 py-2 font-semibold text-white bg-red-500 border border-red-500 rounded-lg"
              >
                Ya
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImplementingPartnerPayment;

import React, {
  useCallback,
  useEffect,
  useMemo as UseMemo,
  useState,
  useContext,
} from "react";
import { RiEyeLine } from "react-icons/ri";
import { FaRegTrashAlt } from "react-icons/fa";
import { debounce } from "lodash";
import { useNavigate } from "react-router";

import { BasicList } from "components/molecules/ListPage";
import { Button, CustomSelect, AtomDatePicker, SearchInput } from "components";
import useInvestmentPlan from "./hooks/useInvestmentPlan";
import { BsDot } from "react-icons/bs";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { ConfirmationModal } from "components/molecules/Modal";
import axios from "axios";
import { SuccessToast, ErrorToast, ToastContext } from "components/atoms/Toast";
import config from "app/config";
import axiosInstance from "app/interceptors";
import _ from "lodash";

const ListInvestmentPlan = () => {
  const methods = useForm();

  const navigate = useNavigate();

  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(null);
  const [rowPage, setRowPage] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formModal, setFormModal] = useState(false);
  const {
    showToast,
    setShowToast,
    initialShowToast,
    showToastMessage,
    toastMessage,
    toastDescription,
  } = useContext(ToastContext);

  const [start, end] = methods.watch("exampleDate") || [null, null];

  const params = {
    name: nameFilter,
    status: statusFilter,
    start_period: startDate,
    end_period: endDate,
    page: page,
    limit: rowPage,
  };

  const { investmentPlanData, paginationinvestmentPlanData, refetch } =
    useInvestmentPlan({ params });

  useEffect(() => {
    refetch();
  }, [nameFilter, statusFilter, endDate, page, rowPage]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setStartDate(formatDateRange(start));
    setEndDate(formatDateRange(end));
  }, [end]);

  const links = [
    {
      label: "Daftar Investment Plan",
    },
  ];

  const paginator = UseMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) => {
        setNameFilter(event?.target?.value || "");
      },
      [setNameFilter]
    ),
    1000
  );

  const onChangeStatus = useCallback((obj) => {
    setStatusFilter(obj.value || undefined);
  }, []);

  const statusOptions = UseMemo(() => {
    return [
      { value: null, label: "Semua" },
      { value: "draft", label: "Draft" },
      { value: "baru", label: "Baru" },
      { value: "awp terbit", label: "AWP Terbit" },
      { value: "proyek berjalan", label: "Proyek Berjalan" },
      { value: "proyek selesai", label: "Proyek Selesai" },
    ];
  });

  function formatDateRange(dateString) {
    const date = moment(dateString);
    if (date.isValid()) {
      return date.format("YYYY-MM-DD");
    } else {
      return null;
    }
  }

  const tableColumns = UseMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "projectName",
        title: "Nama Project",
        dataIndex: "name",
        className: "",
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: "investor",
        title: "Donor",
        dataIndex: "fund_source",
        className: "",
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: "location",
        title: "Lokasi",
        dataIndex: "location",
        className: "",
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value && _.isString(value)
              ? value
              : value?.map((loc) => loc?.label).join(", ") ?? ""}
          </div>
        ),
      },
      {
        id: "projectValue",
        title: "Nilai Project",
        dataIndex: "fund",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            Rp. {new Intl.NumberFormat("en-DE").format(value)}
          </div>
        ),
      },
      {
        id: "period",
        title: "Periode",
        dataIndex: "start_period",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "w-[125px] text-ellipsis overflow-hidden",
        render: (value, row) => {
          const startDate = moment
            .utc(row.start_period)
            .local()
            .format("DD/MM/YYYY");
          const endDate = moment
            .utc(row.end_period)
            .local()
            .format("DD/MM/YYYY");
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[125px]">
              {`${startDate} - ${endDate}`}
            </div>
          );
        },
      },
      {
        id: "distributionMechanism",
        title: "Mekanisme Penyaluran",
        dataIndex: "distribution_mechanism",
        className: "",
        columnClassName: "w-[150px] whitespace-break-spaces",
        sortable: true,
        fixed: "right",
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        columnClassName: "w-[200px] text-ellipsis overflow-hidden",
        sortable: true,
        fixed: "right",
        render: (value) => (
          <div className="text-ellipsis w-full max-w-[200px]">
            {value === "draft" && (
              <div className="bg-[#F2F4F7] rounded-[25px] flex gap-[1px] items-center max-w-[100px]">
                <BsDot className="text-[40px] text-[#667085]" />
                <div className="text-[#344054] font-medium text-[12px]">
                  Draft
                </div>
              </div>
            )}
            {value === "baru" && (
              <div className="bg-[#EFF8FF] rounded-[25px] flex gap-[1px] items-center max-w-[100px]">
                <BsDot className="text-[40px] text-[#2E90FA]" />
                <div className="text-[#175CD3] font-medium text-[12px]">
                  Baru
                </div>
              </div>
            )}
            {value === "awp terbit" && (
              <div className="bg-[#EFF8FF] rounded-[25px] flex gap-[1px] items-center max-w-[130px]">
                <BsDot className="text-[40px] text-[#2E90FA]" />
                <div className="text-[#175CD3] font-medium text-[12px]">
                  AWP Terbit
                </div>
              </div>
            )}
            {value === "project berjalan" ||
              (value === "proyek berjalan" && (
                <div className="bg-[#F9E8FC] rounded-[25px] flex gap-[1px] items-center max-w-[160px]">
                  <BsDot className="text-[40px] text-[#667085]" />
                  <div className="text-[#344054] font-medium text-[12px]">
                    Proyek Berjalan
                  </div>
                </div>
              ))}
            {value === "proyek selesai" && (
              <div className="bg-[#ECFDF3] rounded-[25px] flex gap-[1px] items-center max-w-[150px]">
                <BsDot className="text-[40px] text-[#27AE5F]" />
                <div className="text-[#1C7D44] font-medium text-[12px]">
                  Proyek Selesai
                </div>
              </div>
            )}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "status",
        className: "",
        sortable: true,
        columnClassName: "w-[250px] text-center text-ellipsis overflow-hidden",
        fixed: "right",
        render: (value, data, index) => {
          return value === "draft" ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                <div className="space-x-3 flex w-full">
                  <FaRegTrashAlt
                    className="text-gray-600 cursor-pointer flex-1"
                    size={20}
                    onClick={() => {
                      setFormModal(true);
                      setSelectedItem(data);
                    }}
                  />
                </div>
                <div className="space-x-3 justify-center flex w-full">
                  <Button
                    type="button"
                    className={`
                        text-white w-40
                          bg-[#00BD52] hover:bg-[#009440]
                        `}
                    onClick={() => {
                      continueDraft(data);
                    }}
                  >
                    Lanjutkan Draft
                  </Button>
                </div>
              </div>
            </>
          ) : value === "project berjalan" || value === "proyek berjalan" ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                <div className="space-x-3 flex w-full">
                  <RiEyeLine
                    className="text-gray-600 cursor-pointer flex-1"
                    size={20}
                    onClick={() => navigate(`/investment-plan/${data?._id}`)}
                  />
                </div>
                <div className="space-x-3 justify-center flex w-full">
                  <div className="flex flex-col">
                    <Button
                      type="button"
                      className={`
                      text-white w-40
                      bg-[#00BD52]
                      mt-3
                      `}
                      onClick={() =>
                        navigate(
                          data?.closing_safeguard_data
                            ? `/investment-plan/safeguard-closure/detail/${data?._id}`
                            : `/investment-plan/safeguard-closure/${data?._id}`
                        )
                      }
                    >
                      {Object.hasOwn(data, "closing_safeguard_data")
                        ? "Detail Penutupan Safeguard"
                        : "Penutupan Safeguard"}
                    </Button>
                    {Object.hasOwn(data, "closing_project_data") ? (
                      <Button
                        type="button"
                        className="text-white w-40 bg-[#B713E3] hover:bg-[#9A10BF] active:bg-[#C83AEF] mt-3"
                        onClick={() =>
                          navigate(
                            `/investment-plan/project-closure/detail/${data?._id}`
                          )
                        }
                      >
                        Detail Penutupan Proyek
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        className="text-white w-40 bg-[#B713E3] hover:bg-[#9A10BF] active:bg-[#C83AEF] mt-3"
                        onClick={() =>
                          navigate(
                            `/investment-plan/project-closure/${data?._id}`
                          )
                        }
                      >
                        Penutupan Proyek
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : value === "baru" ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                <div className="space-x-3 flex w-full">
                  <RiEyeLine
                    className="text-gray-600 cursor-pointer flex-1"
                    size={20}
                    onClick={() => navigate(`/investment-plan/${data?._id}`)}
                  />
                </div>
                <div className="space-x-3 justify-center flex w-full">
                  <Button
                    type="button"
                    onClick={() =>
                      navigate(
                        `/annual-work-plan/add?investment_id=${data?._id}`
                      )
                    }
                    className={`
                        text-white w-40
                          bg-[#06AED4]
                        `}
                  >
                    Submit AWP Konsolidasi Final
                  </Button>
                </div>
              </div>
            </>
          ) : value === "awp terbit" ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                <div className="space-x-3 flex w-full">
                  <RiEyeLine
                    className="text-gray-600 cursor-pointer flex-1"
                    size={20}
                    onClick={() => navigate(`/investment-plan/${data?._id}`)}
                  />
                </div>
                <div className="space-x-3 justify-center flex w-full">
                  <div className="flex flex-col">
                    <Button
                      type="button"
                      className="text-white w-40 bg-[#00BD52] hover:bg-[#009440]"
                    >
                      Assign PMU
                    </Button>
                    <Button
                      type="button"
                      className={`
                          text-white w-40
                            bg-[#E34F13]
                            hover:bg-[#BF4310]
                            mt-3
                          `}
                    >
                      Revisi AWP
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                <div className="space-x-3 flex w-full">
                  <RiEyeLine
                    className="text-gray-600 cursor-pointer flex-1"
                    size={20}
                    onClick={() => navigate(`/investment-plan/${data?._id}`)}
                  />
                </div>
                <div className="space-x-3 justify-center flex w-full">
                  <div className="flex flex-col">
                    <Button
                      type="button"
                      className="text-white w-40 bg-[#B713E3] hover:bg-[#9A10BF] active:bg-[#C83AEF] mt-3"
                      onClick={() =>
                        navigate(
                          `/investment-plan/project-closure/detail/${data?._id}`
                        )
                      }
                    >
                      Detail Penutupan Proyek
                    </Button>
                    <Button
                      type="button"
                      className={`
                          text-white w-40
                            bg-[#E34F13]
                            hover:bg-[#BF4310]
                            mt-3
                          `}
                      onClick={() =>
                        navigate(
                          `/investment-plan/safeguard-closure/detail/${data?._id}`
                        )
                      }
                    >
                      Detail Penutupan Safeguard
                    </Button>
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
    ];
  }, []);

  const continueDraft = (data) => {
    if (
      Object.keys(data.step_1).length !== 0 &&
      Object.keys(data.step_2).length === 0
    ) {
      navigate("/investment-plan/add/", {
        state: {
          idInvestment: data._id,
          idProposal: data.step_1.proposal_id,
          typeForm: "edit",
          currentStep: "1",
        },
      });
    } else if (
      Object.keys(data.step_2).length !== 0 &&
      Object.keys(data.step_3).length === 0
    ) {
      navigate("/investment-plan/add/", {
        state: {
          idInvestment: data._id,
          idProposal: data.step_1.proposal_id,
          typeForm: "edit",
          currentStep: "2",
        },
      });
    } else if (
      Object.keys(data.step_3).length !== 0 &&
      Object.keys(data.step_4).length === 0
    ) {
      navigate("/investment-plan/add/", {
        state: {
          idInvestment: data._id,
          idProposal: data.step_1.proposal_id,
          typeForm: "edit",
          currentStep: "3",
        },
      });
    } else {
      navigate("/investment-plan/add/", {
        state: {
          idInvestment: data._id,
          idProposal: data.step_1.proposal_id,
          typeForm: "edit",
          currentStep: "4",
        },
      });
    }
  };
  const onCreate = () => {
    navigate("/investment-plan/add/");
    localStorage.setItem("currentStep", JSON.stringify(1));
  };

  const deleteItemHandler = async () => {
    let payload = {
      id: selectedItem?._id,
    };

    try {
      const response = await axiosInstance.delete(
        `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/delete`,
        {
          data: payload,
        }
      );
      const data = response.data;

      if (data?.code === 200) {
        setFormModal(false);
        setSelectedItem(null);
        showToastMessage(
          "Data berhasil dihapus!",
          "Data yang anda pilih berhasil dihapus."
        );
        setShowToast((prev) => ({ ...prev, success: true }));
        refetch();
      }
    } catch (error) {
      if (error?.response?.data) {
        setFormModal(false);
        setSelectedItem(null);
        showToastMessage(
          "Data gagal dihapus!",
          error?.response?.data?.message || error?.response?.data?.[0]?.message
        );
        setShowToast((prev) => ({ ...prev, error: true }));
        refetch();
      }
    }
  };

  return (
    <div>
      <ConfirmationModal
        open={formModal}
        onClose={() => setFormModal(false)}
        onSubmit={deleteItemHandler}
      >
        <div>
          <div className="text-gray-900 text-lg font-semibold">
            Apakah Anda yakin akan menghapus draft ini?
          </div>
        </div>
      </ConfirmationModal>
      {showToast.success && (
        <SuccessToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      {showToast.error && (
        <ErrorToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      <BasicList
        links={links}
        title={"Daftar Investment Plan"}
        columns={tableColumns}
        dataSource={investmentPlanData}
        onCreate={onCreate}
        customTextAddButton="Penyusunan Investment Plan"
        pagination={paginationinvestmentPlanData}
        onChangePage={(page) => setPage(page)}
        onChangeRowsPerPage={(row) => setRowPage(row)}
        customFilter={
          <FormProvider {...methods}>
            <div className="space-x-5 flex">
              <SearchInput placeholder="Cari" onChange={onSearch} />
              <CustomSelect
                optionsData={statusOptions}
                placeholder="Status"
                className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
                onChange={onChangeStatus}
              />
              <div className="flex-1">
                <AtomDatePicker
                  className="max-h-[38px] mt-0 w-full rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none focus:ring-0 focus:border-0"
                  controllerName="exampleDate"
                  label={null}
                  placeholder="Select a date"
                  asText={false}
                  format={"dd/MM/yyyy"}
                  isRangeDatePicker={true}
                />
              </div>
            </div>
          </FormProvider>
        }
      />
    </div>
  );
};

export default ListInvestmentPlan;

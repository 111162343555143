import React, { Fragment, useCallback, useMemo, useState } from "react";
import { isEmpty } from "lodash";

import { Breadcrumbs } from "components";
import Stepper from "./form/stepper";
import ActivityResult from "./form/ActivityResult";
import CostRealization from "./form/CostRealization";
import moment from "moment";

const links = [
  {
    path: "/activity-report",
    label: "Laporan Kegiatan",
  },
  {
    label: "Buat Baru",
  },
];

const stepperItems = [
  {
    label: "Hasil Pelaksanaan Kegiatan",
  },
  {
    label: "Realisasi Biaya",
  },
];

const NewActivityReport = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [payload, setPayload] = useState({});
  const [activityResultValues, setActivityResultValues] = useState({});
  const [costRealizationValues, setCostRealizationValues] = useState({});

  const onBack = useCallback(
    (data) => {
      if (currentStep > 0) {
        setCostRealizationValues((curr) => ({
          ...curr,
          ...data,
        }));
        setCurrentStep(0);
      }
    },
    [currentStep]
  );

  const onSubmit = useCallback(
    (data) => {
      if (currentStep < 1) {
        setPayload((curr) => ({
          ...curr,
          ...data,
        }));
        setActivityResultValues((curr) => ({
          ...curr,
          ...data,
        }));
        setCurrentStep(1);
      } else {
        if (!isEmpty(payload)) {
          const dataToSend = {
            ...payload,
            ...data,
          };

          console.log("data to send ", dataToSend);
        }
      }
    },
    [currentStep, payload]
  );

  const activityResultDefaultValues = useMemo(
    () => ({
      main_activity: null,
      status: 1,
      activity_goals: [],
      partners: [],
      involvement: {
        male: null,
        female: null,
      },
      safeguards: [],
      activity_result: {
        activity_result: "",
        attachment: [],
      },
      ...activityResultValues,
      start_date: activityResultValues?.start_date
        ? moment(activityResultValues?.start_date).toDate()
        : null,
      end_date: activityResultValues?.end_date
        ? moment(activityResultValues?.end_date).toDate()
        : null,
    }),
    [activityResultValues]
  );

  const costRealizationDefaultValues = useMemo(
    () => ({
      stages: [
        {
          activity_detail: "",
          date: "",
          total_cost: 0,
        },
      ],
      attachment: [],
      ...costRealizationValues,
    }),
    [costRealizationValues]
  );

  return (
    <Fragment>
      <Breadcrumbs items={links} />
      <div className="flex items-center justify-center w-full mt-6 mb-6">
        <Stepper currentStep={currentStep} items={stepperItems} />
      </div>
      {currentStep < 1 ? (
        <ActivityResult
          defaultValues={activityResultDefaultValues}
          onNext={onSubmit}
        />
      ) : (
        <CostRealization
          onBack={onBack}
          onNext={onSubmit}
          defaultValues={costRealizationDefaultValues}
          payload={payload}
        />
      )}
    </Fragment>
  );
};

export default NewActivityReport;

import React from "react";

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  Input,
  InputFormRadio,
  TitleText,
} from "components";
import { useNavigate } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine, RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";

const ProposalEvaluatedDetailSafeguard = () => {
  const navigate = useNavigate();
  const links = [
    {
      label: "Pengajuan Dana",
      path: "/pengajuan-dana-program",
    },
    {
      label: "Detail",
    },
  ];
  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <div>Detail</div>
        </CardHeader>
        <CardBody>
          <div className="space-y-5">
            <CardForm label="Preferensi Safeguard A" buttonCollapse={true}>
              <div className="space-y-5">
                <div className="space-y-2">
                  <span className="font-bold">Lorem ipsum dolor sit amet consectetur.</span>
                  <div className={`grid grid-cols-2 gap-4 mt-2`}>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>A.1</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>B.2</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>C.3</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>D.4</span>
                    </div>
                  </div>
                </div>
                <div className="space-y-2">
                  <span className="font-bold">Lorem ipsum dolor sit amet consectetur.</span>
                  <div className={`grid grid-cols-2 gap-4 mt-2`}>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>A.1</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>B.2</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>C.3</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>D.4</span>
                    </div>
                  </div>
                </div>
                <div className="space-y-2">
                  <span className="font-bold">Lorem ipsum dolor sit amet consectetur.</span>
                  <div className={`grid grid-cols-2 gap-4 mt-2`}>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>A.1</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>B.2</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>C.3</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>D.4</span>
                    </div>
                  </div>
                </div>
              </div>
            </CardForm>
            <CardForm label="Preferensi Safeguard A" buttonCollapse={true}>
              <div className="space-y-5">
                <div className="space-y-2">
                  <span className="font-bold">Lorem ipsum dolor sit amet consectetur.</span>
                  <div className={`grid grid-cols-2 gap-4 mt-2`}>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>A.1</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>B.2</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>C.3</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>D.4</span>
                    </div>
                  </div>
                </div>
                <div className="space-y-2">
                  <span className="font-bold">Lorem ipsum dolor sit amet consectetur.</span>
                  <div className={`grid grid-cols-2 gap-4 mt-2`}>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>A.1</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>B.2</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>C.3</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>D.4</span>
                    </div>
                  </div>
                </div>
                <div className="space-y-2">
                  <span className="font-bold">Lorem ipsum dolor sit amet consectetur.</span>
                  <div className={`grid grid-cols-2 gap-4 mt-2`}>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>A.1</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>B.2</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>C.3</span>
                    </div>
                    <div className="flex items-center bg-white border py-2 px-4 rounded-lg gap-4">
                      <Input className={''} type='radio'/>
                      <span>D.4</span>
                    </div>
                  </div>
                </div>
              </div>
            </CardForm>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <div>Rekomendasi/Hasil Penilaian</div>
        </CardHeader>
        <CardBody>
          <div className="space-y-5">
            <div className="space-y-5">
              <TitleText className="font-bold">
                Rekomendasi/Hasil Penilaian
              </TitleText>
              <Input className={'w-[80%] h-24'} type={'textarea'}/>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate(-1)}
          >
            <RiArrowLeftLine/>
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          >
            Setujui & Lanjutkan
            <RiArrowRightLine className="text-white"/>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProposalEvaluatedDetailSafeguard;

import config from 'app/config';
import axiosInstance from 'app/interceptors';

const headers = {
  'Content-Type': 'application/json',
  'ngrok-skip-browser-warning': true,
};

export const getActivityReportList = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/activity-report/list?limit=${
      params?.limit
    }&page=${params?.page}${
      params?.keyword !== undefined ? `&search=${params?.keyword}` : ''
    }${params?.orderBy !== undefined ? `&order_by=${params?.orderBy}` : ''}${
      params?.orderMethod !== undefined
        ? `&order_method=${params?.orderMethod}`
        : ''
    }${
      params?.statusFilter !== undefined
        ? `&status=${params?.statusFilter}`
        : ''
    }`,
    { headers }
  );
};

export const getActivityReportDetail = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/activity-report/detail`,
    { params, headers }
  );
};

export const postActivityReportStep1 = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/activity-report/step-one`,
    params,
    { headers }
  );
};

export const postActivityReportStep2 = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/activity-report/step-two`,
    params,
    { headers }
  );
};

import axios from "axios";
import config, { config_master } from "app/config";
import axiosInstance from "app/interceptors";

const headers = {
  accept: `text/plain`,
  // "Content-Type": "application/json",
};

export const getIssueFocuses = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/FokusIsu/list`,
    params,
    {
      headers,
    }
  );
};
export const getCollaborationFields = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/BidangKerjasama/list`,
    params,
    { headers }
  );
};
export const getInstitutionTypes = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/JenisLembaga/list`,
    params,
    {
      headers,
    }
  );
};
export const getStrategicPlans = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/RencanaStrategis/list`,
    params,
    { headers }
  );
};

export const getPositions = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/JabatanExternal/list`,
    params,
    { headers }
  );
};

export const getCountries = (params) => {
  return axiosInstance.get(`${config_master.BASE_URL}/v1/Negara/list`, params, {
    headers,
  });
};

export const getProvinces = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/Provinsi/list`,
    params,
    { headers }
  );
};

export const getCities = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/KabupatenKota/list`,
    params,
    { headers }
  );
};

export const getCitiesByProvinceId = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/KabupatenKota/${params}`,
    params,
    { headers }
  );
};

export const getSubdistricts = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/Kecamatan/list`,
    params,
    { headers }
  );
};

export const getVillages = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/Kelurahan/list`,
    params,
    { headers }
  );
};

export const getPostalCodes = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/KodePos/list`,
    params,
    { headers }
  );
};

export const addNewStrategicPlans = (params) => {
  return axiosInstance.post(
    `${config_master.BASE_URL}/v1/RencanaStrategis`,
    params,
    {
      headers,
    }
  );
};

export const taxType = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/JenisPajak/list`,
    params,
    {
      headers,
    }
  );
};

export const budgetAccount = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/BudgetAccount/list`,
    params,
    {
      headers,
    }
  );
};
// Jenis Pajak

export const getListPajak = (params) => {
  return axiosInstance.get(`${config_master.BASE_URL}/v1/JenisPajak/list`, {
    params,
    headers,
  });
};

// Isp

export const getIspList = (params) => {
  return axiosInstance.get(`${config_master.BASE_URL}/v1/Isp/list`, {
    params,
    headers,
  });
};
export const getBudgetAccount = (params) => {
  return axiosInstance.get(`${config_master.BASE_URL}/v1/BudgetAccount/list`, {
    params,
    headers,
  });
};
export const getAllEmployee = () => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/user-management/v1/Employee/list`,
    {
      headers,
    }
  );
};

// export const getJobPerformances = (params) => {
//   return axios.get(
//     `${config_master.BASE_URL}/v1/FokusIsu/list`,
//     params,
//   );
// };
// export const getToolCategory = (params) => {
//   return axios.get(
//     `${config_master.BASE_URL}/v1/FokusIsu/list`,
//     params,
//   );
// };
// export const getPublicAccountingFirm = (params) => {
//   return axios.get(
//     `${config_master.BASE_URL}/v1/FokusIsu/list`,
//     params,
//   );
// };
// export const getProjectPosition = (params) => {
//   return axios.get(
//     `${config_master.BASE_URL}/v1/FokusIsu/list`,
//     params,
//   );
// };

import { CustomSelect, SearchInput, AtomDatePicker, Button } from 'components';
import { BasicList } from 'components/molecules/ListPage';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo as UseMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import moment from 'moment';
import useCallForProposal from './hooks/useCallForProposal';

const ListCallForProposal = () => {
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      date_of_filling: [null, null],
    },
  });

  const [keyword, setKeyword] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { watch } = methods;

  const params = {
    proposal_number: keyword,
    status: statusFilter,
    page: page,
    limit: limit,
    start_date: watch('date_of_filling')?.[0]
      ? moment.utc(watch('date_of_filling')[0]).local().format('YYYY-MM-DD')
      : null,
    end_date: watch('date_of_filling')?.[1]
      ? moment.utc(watch('date_of_filling')[1]).local().format('YYYY-MM-DD')
      : null,
  };

  const { callForProposalData, paginationCallForProposalData, refetch } =
    useCallForProposal({ params });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [keyword, statusFilter, page, limit, watch('date_of_filling')]);

  const links = [
    {
      label: 'Daftar Permohonan Call for Proposal',
    },
  ];

  const paginator = UseMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) => {
        setKeyword(event?.target?.value || '');
      },
      [setKeyword]
    ),
    1000
  );

  const statusOptions = UseMemo(() => {
    return [
      { value: null, label: 'Semua' },
      { value: 'menunggu-verifikasi', label: 'Menunggu Verifikasi' },
      { value: 'sedang-diverifikasi', label: 'Sedang Diverifikasi' },
      { value: 'ditolak', label: 'Ditolak' },
      { value: 'ditolak', label: 'Ditolak' },
    ];
  });

  const onChangeStatus = useCallback((obj) => {
    setStatusFilter(obj.value || undefined);
  }, []);

  const tableColumns = UseMemo(() => {
    return [
      {
        id: 'no',
        title: 'No',
        dataIndex: 'no',
        className: 'overflow-hidden',
        columnClassName: 'w-[50px]',
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: 'ProposalTitle',
        title: 'Judul Proposal',
        dataIndex: 'proposal_title',
        className: '',
        columnClassName:
          'w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: 'created_by_name',
        title: 'Pengaju',
        dataIndex: 'created_by_name',
        className: '',
        columnClassName:
          'w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: 'project_name',
        title: 'Proyek',
        dataIndex: 'project_name',
        className: 'overflow-hidden text-ellipsis',
        sortable: true,
        columnClassName:
          'w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces',
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: 'date_submit',
        title: 'Tanggal Pengajuan',
        dataIndex: 'date_submit',
        className: '',
        columnClassName:
          'w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: 'status',
        title: 'Status',
        dataIndex: 'status',
        sortable: true,
        fixed: 'center',
        columnClassName: 'w-[150px] text-ellipsis overflow-hidden',
        render: (value) => (
          <div className="text-ellipsis w-full max-w-[200px]">
            {(value === 'Menunggu Verifikasi' ||
              value === 'Sedang Diverifikasi') && (
              <div className="bg-[#FFFAEB] px-1 rounded-[25px] flex gap-[1px] items-center w-fit mx-auto">
                <div className="text-[#B54708] font-medium text-[12px]">
                  {value}
                </div>
              </div>
            )}
            {value === 'Disetujui' && (
              <div className="bg-[#ECFDF3] px-3 rounded-[25px] flex gap-[1px] w-fit mx-auto items-center">
                <div className="text-[#027A48] font-medium text-[12px]">
                  Disetujui
                </div>
              </div>
            )}
            {value === 'Ditolak' && (
              <div className="bg-[#FEF3F2] px-1 rounded-[25px] flex gap-[1px] items-center w-fit mx-auto">
                <div className="text-[#B42318] font-medium text-[12px]">
                  Ditolak
                </div>
              </div>
            )}

            {value === 'Kontrak Deal' && (
              <div className="bg-[#ECFDF3] px-3 rounded-[25px] flex gap-[1px] w-fit mx-auto items-center">
                <div className="text-[#027A48] font-medium text-[12px]">
                  Kontrak Deal
                </div>
              </div>
            )}
          </div>
        ),
      },
      {
        id: 'actions',
        title: 'Aksi',
        dataIndex: 'status',
        className: '',
        sortable: true,
        columnClassName: 'w-[125px] text-center text-ellipsis overflow-hidden',
        fixed: 'right',
        render: (value, data, index) => {
          return (
            <>
            {
              value === 'Sedang Diverifikasi' && (
                <div className="space-x-3 justify-center flex w-full">
                      <Button
                        type="button"
                        size="sm"
                        className={`
                            text-white w-40 text-xs
                              bg-[#F58A42]
                            `}
                            onClick={() =>
                              navigate(`/call-for-proposal/${data?._id}`)
                            }
                      >
                        Lanjutkan
                      </Button>
                    </div>
              )
            }
              {[
                'Kontrak Deal',
                'Disetujui',
                'Ditolak',
                // 'Sedang Diverifikasi',
              ].includes(data.status) && (
                <>
                  <div className="flex flex-col justify-center items-center gap-[12px]">
                    <FaEye
                      className="text-gray-600 cursor-pointer flex-1"
                      size={20}
                      onClick={() =>
                        navigate(`/call-for-proposal/${data?._id}`)
                      }
                    />
                  </div>
                </>
              )}

              {value === 'Menunggu Verifikasi' && (
                <div className="flex flex-col justify-center items-center gap-[12px]">
                  <div className="space-x-3 justify-center flex w-full">
                    <Button
                      type="button"
                      className={`
                    text-white w-40
                      bg-[#00BD52]
                    `}
                      onClick={() =>
                        navigate(`/call-for-proposal/${data?._id}`)
                      }
                    >
                      Review
                    </Button>
                  </div>
                </div>
              )}
            </>
          );
        },
      },
    ];
  }, [paginationCallForProposalData]);

  return (
    <BasicList
      links={links}
      title={'Daftar Permohonan Call for Proposal'}
      columns={tableColumns}
      dataSource={callForProposalData}
      onChangePage={(page) => setPage(page)}
      onChangeRowsPerPage={(row) => setLimit(row)}
      pagination={paginationCallForProposalData}
      customFilter={
        <FormProvider {...methods}>
          <div className="space-x-5 flex">
            <SearchInput placeholder="Cari" onChange={onSearch} />
            <CustomSelect
              optionsData={statusOptions}
              placeholder="Status"
              className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none items-center self-center"
              onChange={onChangeStatus}
            />
            <div className="flex-1">
              <AtomDatePicker
                className="max-h-[38px] mt-0 w-full rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none focus:ring-0 focus:border-0"
                controllerName="exampleDate"
                label={null}
                placeholder="Select a date"
                asText={false}
                format={'dd/MM/yyyy'}
                isRangeDatePicker={true}
              />
            </div>
          </div>
        </FormProvider>
      }
    />
  );
};

export default ListCallForProposal;

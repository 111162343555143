import React, { useState, useMemo, useEffect } from "react";
import { BasicList } from "components/molecules/ListPage";
import { createSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { FaEye } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import moment from "moment";

import { formattedValue } from "helpers";
import { APPROVAL_STATUSSES } from "components/organisms/Approval2Level/constant";
import { BadgeStatus as ApprovalBadgeStatus } from "components/organisms/Approval2Level";
import { CustomSelect, AtomDatePicker, SearchInput, Button } from "components";

import { getDirectSubmissionList } from "services/danaProgram/directSubmissionService";

const ListPenyaluranLangsung = () => {
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      date_of_filling: [null, null],
    },
  });

  const { watch } = methods;

  const [keyword, setKeyword] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState(null);
  const [orderMethod, setOrderMethod] = useState(null);

  const params = {
    name: search,
    proposal_number: keyword,
    status: statusFilter,
    page: page,
    limit: limit,
    order_by: orderBy,
    order_method: orderMethod,
    start_date: watch("date_of_filling")?.[0]
      ? moment.utc(watch("date_of_filling")[0]).local().format("YYYY-MM-DD")
      : null,
    end_date: watch("date_of_filling")?.[1]
      ? moment.utc(watch("date_of_filling")[1]).local().format("YYYY-MM-DD")
      : null,
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["get-direct-submission-list", search, statusFilter],
    queryFn: async () => {
      const res = await getDirectSubmissionList(params);

      return res.data.data;
    },
  });

  useEffect(() => {
    refetch();
  }, [
    statusFilter,
    page,
    limit,
    search,
    orderBy,
    orderMethod,
    watch("date_of_filling"),
  ]);

  const links = [
    {
      label: "Daftar Penyaluran Langsung",
    },
  ];

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "ApplicantName",
        title: "Nama Pemohon",
        dataIndex: "creator",
        className: "",
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value?.general_information?.fullname}
          </div>
        ),
      },
      {
        id: "TotalIncentiveSuggestion",
        title: "Usulan Nilai Insentif",
        dataIndex: "activity",
        className: "",
        columnClassName: "w-[120px] text-ellipsis overflow-hidden",
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },

        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value
              ?.map((item) => item.total_budget)
              .reduce((prev, curr) => formattedValue(prev + curr), 0)}
          </div>
        ),
      },
      {
        id: "RequestDate",
        title: "Tanggal Pengajuan",
        dataIndex: "created_at",
        className: "",
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },

        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {moment(value).format("DD/MM/YYYY")}
          </div>
        ),
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        headerTextPosition: "center",
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },
        fixed: "center",
        render: (value) => <ApprovalBadgeStatus value={value} />,
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "status",
        className: "",
        sortable: true,
        columnClassName: "w-[125px] text-center text-ellipsis overflow-hidden",
        fixed: "right",
        render: (value, data, index) => {
          return (
            <>
              {["NEED_APPROVAL", "NEED_APPROVAL_DIRECTOR"] ? (
                <div className="flex flex-col justify-center items-center gap-[12px]">
                  <div className="space-x-3 justify-center flex w-full text-xl">
                    <Button
                      onClick={() => {
                        navigate({
                          pathname: `/approval-penyaluran-langsung/${data._id}`,
                          search: createSearchParams({
                            mode: "review",
                          }).toString(),
                        });
                      }}
                    >
                      Review
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex flex-col justify-center items-center gap-[12px]">
                    <FaEye
                      className="text-gray-600 cursor-pointer flex-1"
                      size={20}
                      onClick={() => {
                        navigate({
                          pathname: `/approval-penyaluran-langsung/${data._id}`,
                          search: createSearchParams({
                            mode: "detail",
                          }).toString(),
                        });
                      }}
                    />
                  </div>
                </>
              )}
            </>
          );
        },
      },
    ];
  }, []);

  const onSearch = debounce((value) => {
    setSearch(value);
  }, 500);

  return (
    <BasicList
      links={links}
      title="Daftar Penyaluran Langsung"
      columns={tableColumns}
      dataSource={data?.data}
      onChangePage={(page) => setPage(page)}
      onChangeRowsPerPage={(row) => setLimit(row)}
      isLoading={isLoading}
      pagination={{
        page: data?.page,
        perPage: data?.perPage,
        total: data?.total,
        lastPage: data?.lastPage,
      }}
      customFilter={
        <FormProvider {...methods}>
          <div className="space-x-5 flex items-center">
            <div className="w-full">
              <SearchInput
                placeholder="Cari"
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
            <AtomDatePicker
              className="max-h-[38px] w-full !mt-0 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none focus:ring-0 focus:border-0"
              controllerName="date_of_filling"
              label={null}
              placeholder="Select a date"
              asText={false}
              format={"dd/MM/yyyy"}
              isRangeDatePicker={true}
            />
            <CustomSelect
              optionsData={APPROVAL_STATUSSES}
              onChange={(status) => setStatusFilter(status.value)}
              placeholder="Status"
              className="rounded-md w-full border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
            />
          </div>
        </FormProvider>
      }
    />
  );
};

export default ListPenyaluranLangsung;

export function formatCurrency(amount) {
  if (isNaN(amount)) {
    return "Invalid input";
  }
  const [integerPart, decimalPart] = amount.toFixed(0).split(".");
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  const formattedCurrency = `Rp. ${formattedInteger}${
    decimalPart ? `,${decimalPart}` : ""
  }`;

  return formattedCurrency;
}

import { createContext, useContext, useEffect, useState } from "react";

const GeneralProfileContext = createContext();

export const GeneralProfileProvider = ({ children }) => {
  const [generalProfileState, setGeneralProfileState] = useState({
    general_information: null,
    general_profile_document: null,
    legal_foundation: null,
    working_area: null,
  });

  const value = { generalProfileState, setGeneralProfileState };

  useEffect(() => {
    let temp = JSON.stringify(generalProfileState);
    sessionStorage.setItem("generalProfileContext", temp);
  }, [generalProfileState]);

  return (
    <GeneralProfileContext.Provider value={value}>
      {children}
    </GeneralProfileContext.Provider>
  );
};

export const useGeneralProfileContext = () => useContext(GeneralProfileContext);

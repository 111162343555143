import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  InputFormUpload,
} from "components";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { BiPencil, BiSolidSave } from "react-icons/bi";

const SupportingDocument = ({
  arrStaticFile = [],
  dataAdditionalDocument = [],
  appendDataAdditionalDocument = () => {},
  removeDataAdditionalDocument = () => {},
  isEdit = false,
  toogleIsEdit = () => {},
}) => {
  const navigate = useNavigate();

  const {
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();

  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center ">
          <div>Verifikasi Dokumen Pendukung</div>
          {isEdit ? (
            <Button
              type="button"
              className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
              onClick={toogleIsEdit}
            >
              <BiSolidSave />
              Simpan Perubahan
            </Button>
          ) : (
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
              onClick={toogleIsEdit}
            >
              <BiPencil />
              Ubah
            </Button>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {arrStaticFile.map((e) => {
            if (watch(`general_profile_document.${e.key}.showFile`)?.url) {
              return (
                <div className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl">
                  <div className="flex gap-4">
                    <div>
                      <div className="bg-[#EEFFF4] rounded-full p-4">
                        <RiFile2Line className="text-primary-600" />
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="font-medium">{e.name}</span>
                      <span>
                        {`${Number(
                          watch(`general_profile_document.${e.key}.showFile`)
                            ?.size
                        ).toFixed(2)} MB`}
                      </span>
                      <span
                        className="font-semibold text-primary-600 cursor-pointer"
                        onClick={() =>
                          window.open(
                            watch(`general_profile_document.${e.key}.showFile`)
                              .url
                          )
                        }
                      >
                        Lihat Dokumen
                      </span>
                    </div>
                  </div>
                  {isEdit && (
                    <InputFormUpload
                      controllerName={"edited_files_" + e.key}
                      maxSize={102400}
                      // label="Upload Dokumen Pendukung"
                      // isMulti
                      hideSectionUpload={!isEdit}
                      listFiles={
                        watch(`general_profile_document.${e.key}.showFile`) ??
                        []
                      }
                    />
                  )}
                </div>
              );
            }
          })}
          {dataAdditionalDocument?.length > 0 &&
            dataAdditionalDocument?.map((e, i) => {
              return (
                <div
                  className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl"
                  key={e.id}
                >
                  <div className="flex gap-4">
                    <div>
                      <div className="bg-[#EEFFF4] rounded-full p-4">
                        <RiFile2Line className="text-primary-600" />
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="font-medium">{e.name}</span>
                      <span>
                        {`${Number(
                          watch(
                            `general_profile_document.additional_document.showFile.${i}`
                          )?.size
                        ).toFixed(2)} MB`}
                      </span>
                      <span
                        className="font-semibold text-primary-600 cursor-pointer"
                        onClick={() =>
                          window.open(
                            watch(
                              `general_profile_document.additional_document.showFile.${i}`
                            ).url
                          )
                        }
                      >
                        Lihat Dokumen
                      </span>
                    </div>
                  </div>
                  {isEdit && (
                    <InputFormUpload
                      controllerName={"edited_files_" + e.key}
                      maxSize={102400}
                      // label="Upload Dokumen Pendukung"
                      // isMulti
                      disableDelete
                      hideSectionUpload={!isEdit}
                      listFiles={
                        watch(
                          `general_profile_document.additional_document.showFile.${i}`
                        ) ?? []
                      }
                    />
                  )}
                </div>
              );
            })}
        </div>
        {/* button Verification */}
        <RadioVerificationBadge
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"general_profile_document.status_approval"}
          nameNotes={"general_profile_document.notes_approval"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={
            errors?.general_profile_document?.status_approval
              ? errors?.general_profile_document?.status_approval
              : false
          }
        />
      </CardBody>
    </Card>
  );
};

export default SupportingDocument;

import React, { useState, useMemo, useEffect } from "react";

import {
    Breadcrumbs,
    Button,
    Table,
    TitleText,
} from "components";
import { RiHistoryLine } from "react-icons/ri";
import AttachmentDocumentDetail from "./attachmentDocument";
import GeneralInformationDetail from "./generalInformation";
import WorkPlanDetail from "./workPlan";
  import { FormProvider, useForm } from "react-hook-form";
import { InformationModal } from "components/molecules/Modal";
import { useParams } from "react-router";
import ExecutorLocationDetail from "./executorLocation";
import BeneficiariesDetail from "./beneficiaries";
import SafeguardsDetail from "./safeguards";
import ReviewDetail from "./review";

const CallForProposalDetail = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [modal, setModal] = useState({ isOpen: false, type: "" });
    const [data, setData] = useState();
    const methods = useForm();

    let { id } = useParams();

    const links = [
        {
            label: "Daftar Permohonan Call for Proposal",
            path: "/call-for-proposal",
        },
        {
            label: "Detail",
        },
    ];

    const TabList = [
      {
        label: "Lampiran Dokumen",
        id: 1,
        step: 'supporting-file'
      },
      {
        label: "General Informasi",
        id: 2,
        step: 'general-information',
      },
      {
        label: "Penyaringan Daftar Negatif",
        id: 3,
        step: 'safeguard'
      },
      {
        label: "Beneficiaries",
        id: 4,
        step: 'beneficiaries'
      },
      {
        label: "Lokasi Pelaksanaan",
        id: 5,
        step: 'executor-locatoion'
      },
      {
        label: "Rencana Kerja",
        id: 6,
        step: 'work-plan'
      },
      {
        label: "Hasil Review",
        id: 7,
        step: 'review'
      },
    ];

    // const TabList = [
    //   {
    //     label: "Lampiran Dokumen",
    //     id: 6,
    //     step: 'supporting-file'
    //   },
    //   {
    //     label: "General Informasi",
    //     id: 1,
    //     step: 'general-information',
    //   },
    //   {
    //     label: "Penyaringan Daftar Negatif",
    //     id: 5,
    //     step: 'safeguard'
    //   },
    //   {
    //     label: "Beneficiaries",
    //     id: 2,
    //     step: 'beneficiaries'
    //   },
    //   {
    //     label: "Lokasi Pelaksanaan",
    //     id: 3,
    //     step: 'executor-locatoion'
    //   },
    //   {
    //     label: "Rencana Kerja",
    //     id: 4,
    //     step: 'work-plan'
    //   },
    //   {
    //     label: "Hasil Review",
    //     id: 7,
    //     step: 'review'
    //   },
    // ];

    const onNext = () => {
      if (activeTab < TabList.length) {
        setActiveTab(activeTab + 1)
        window.scrollTo({ behavior: "smooth", top: 0 });
      }
    }

    const onPrev = () => {
      if (activeTab > 1) {
        setActiveTab(activeTab - 1)
        window.scrollTo({ behavior: "smooth", top: 0 });
      }
    }

    return (
      <>
        <div className="space-y-6">
          <Breadcrumbs items={links} />
          <div className="md:flex block items-center my-3">
            <TitleText className="flex-1 font-semibold text-lg">Daftar Permohonan Call for Proposal</TitleText>
          </div>
          <div className="tabs flex w-full items-center">
            {
              TabList.map((data) => (
                <a className={`tab tab-bordered ${activeTab === data.id ? 'border-b-primary-600 text-primary-600 bg-primary-100 font-bold': ''} hover:text-primary-600 hover:border-primary-700
                cursor-default
                `} 
                // onClick={()=> setActiveTab(data.id)}
                >
                  {data.label}
                </a>
              ))
            }
           </div>
          <div>
            <FormProvider {...methods}>
            {activeTab === 1 && <AttachmentDocumentDetail onNext={onNext}/>} {/* Lampiran Document */}
              {activeTab === 2 && <GeneralInformationDetail onNext={onNext} onPrev={onPrev}/>}  {/* General Information */}
              {activeTab === 3 && <SafeguardsDetail onNext={onNext} onPrev={onPrev}/>} {/* E & S Safeguard */}
              {activeTab === 4 && <BeneficiariesDetail onNext={onNext} onPrev={onPrev}/>} {/* Beneficiaries */}
              {activeTab === 5 && <ExecutorLocationDetail onNext={onNext} onPrev={onPrev}/>} {/* Location */}
              {activeTab === 6 && <WorkPlanDetail onNext={onNext} onPrev={onPrev}/>} {/* Rencana Kerja */}
              {activeTab === 7 && <ReviewDetail onNext={onNext} onPrev={onPrev}/>} {/* Hasil Review */}
            </FormProvider>
          </div>
        </div>
      </>
    );
};

export default CallForProposalDetail;

import { useNavigate, useParams } from "react-router";
import { FormProvider, useForm } from "react-hook-form";

import Approval2LevelActions, {
  BadgeStatus as ApprovalBadgeStatus,
  Progress as Approval2LevelProgress,
} from "components/organisms/Approval2Level";

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputFormUpload,
} from "components";

import { formatTotalCost } from "pages/Tor/helpers";
import { useImplementingPartnerPaymentDetail } from "../hooks/useImplementingPartnerPaymentServices";

const links = [
  {
    label: "Daftar Pembayaran Implementing Partner",
    path: "/implementing-partner-payment",
  },
  {
    label: "Detail",
  },
];

const ApprovalImplementingPartnerPaymentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      reason: "",
    },
  });

  const { detail } = useImplementingPartnerPaymentDetail(id);

  return (
    <div>
      <Breadcrumbs items={links} />
      <FormProvider {...methods}>
        <div className="flex flex-col gap-6 pt-8">
          <Card>
            <CardHeader>Detail</CardHeader>
            <CardBody>
              <div className="flex flex-col gap-5">
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <label className="label font-semibold text-[14px] mb-2">
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>Uraian Pengajuan</span>
                      </div>
                    </label>
                    <div className="px-1">{detail?.desc}</div>
                  </div>
                  <div>
                    <label className="label font-semibold text-[14px] mb-2">
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>Status</span>
                      </div>
                    </label>
                    <div className="px-1">
                      <ApprovalBadgeStatus
                        align="left"
                        value={detail?.status}
                        fit
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <label className="label font-semibold text-[14px] mb-2">
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>
                          Tanggal SPJ/ Tagihan/ Transaksi
                        </span>
                      </div>
                    </label>
                    <div className="px-1">{detail?.transaction_date}</div>
                  </div>
                  <div>
                    <label className="label font-semibold text-[14px] mb-2">
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>Nomor Invoice</span>
                      </div>
                    </label>
                    <div className="px-1">{detail?.invoice_number}</div>
                  </div>
                </div>
                <div>
                  <label className="label font-semibold text-[14px] mb-2">
                    <div className={`flex gap-[4px]`}>
                      <span className={`label-text`}>Dibayarkan Kepada</span>
                    </div>
                  </label>
                  <div className="px-1">
                    {detail?.implementing_partner_name}
                  </div>
                </div>
                <div>
                  <label className="label font-semibold text-[14px] mb-2">
                    <div className={`flex gap-[4px]`}>
                      <span className={`label-text`}>Alamat</span>
                    </div>
                  </label>
                  <div className="px-1">-</div>
                </div>
                <CardForm
                  label={
                    <div className="flex items-center w-full gap-3">
                      Data Pengajuan Pembayaran
                      <span className="font-semibold text-sm text-[#E34F13] bg-[#FFF4ED] rounded-full px-2 py-1">
                        Data Pembayaran :{" "}
                        {formatTotalCost(detail?.total_payment ?? 0)}
                      </span>
                    </div>
                  }
                  buttonCollapse={false}
                  childerForm={true}
                >
                  <div className="flex flex-col gap-5">
                    <div>
                      <label className="label font-semibold text-[14px] mb-2">
                        <div className={`flex gap-[4px]`}>
                          <span className={`label-text`}>Kegiatan Utama</span>
                        </div>
                      </label>
                      <div className="px-1">
                        {detail?.primary_activity.name}
                      </div>
                    </div>
                    <div>
                      <label className="label font-semibold text-[14px] mb-2">
                        <div className={`flex gap-[4px]`}>
                          <span className={`label-text`}>Detail Kegiatan</span>
                        </div>
                      </label>
                      <div className="px-1">{detail?.detail_activity.name}</div>
                    </div>
                    <div>
                      <label className="label font-semibold text-[14px] mb-2">
                        <div className={`flex gap-[4px]`}>
                          <span className={`label-text`}>Mata Anggaran</span>
                        </div>
                      </label>
                      <div className="px-1">{detail?.currency.name}</div>
                    </div>
                    <div className="grid grid-cols-5 gap-3">
                      <div>
                        <label className="label font-semibold text-[14px] mb-2">
                          <div className={`flex gap-[4px]`}>
                            <span className={`label-text`}>
                              Nilai Pengajuan (RP)
                            </span>
                          </div>
                        </label>
                        <div className="px-1">
                          {formatTotalCost(detail?.required_payment ?? 0)}
                        </div>
                      </div>
                      <div>
                        <label className="label font-semibold text-[14px] mb-2">
                          <div className={`flex gap-[4px]`}>
                            <span className={`label-text`}>Pajak</span>
                          </div>
                        </label>
                        <div className="px-1">{detail?.tax.name}</div>
                      </div>
                      <div>
                        <label className="label font-semibold text-[14px] mb-2">
                          <div className={`flex gap-[4px]`}>
                            <span className={`label-text`}>
                              Nilai Pajak (RP)
                            </span>
                          </div>
                        </label>
                        <div className="px-1">
                          {formatTotalCost(detail?.tax.value ?? 0)}
                        </div>
                      </div>
                      <div>
                        <label className="label font-semibold text-[14px] mb-2">
                          <div className={`flex gap-[4px]`}>
                            <span className={`label-text`}>Kode Billing</span>
                          </div>
                        </label>
                        <div className="px-1">{detail?.billing_code}</div>
                      </div>
                      <div>
                        <label className="label font-semibold text-[14px] mb-2">
                          <div className={`flex gap-[4px]`}>
                            <span className={`label-text`}>
                              Total Dibayarkan (Rp)
                            </span>
                          </div>
                        </label>
                        <div className="px-1">
                          {formatTotalCost(detail?.total_payment ?? 0)}
                        </div>
                      </div>
                    </div>
                    <div className="border-b">
                      <InputFormUpload
                        controllerName={"supporting_doc"}
                        maxSize={10240}
                        label=""
                        isMulti
                        hideSectionUpload={true}
                        listFiles={
                          detail?.supporting_doc
                            ? detail.supporting_doc.map((doc) => ({
                                ...doc,
                                preview: doc.path,
                              }))
                            : []
                        }
                        disableDelete
                      />
                    </div>
                    <div className="grid grid-cols-4 gap-3">
                      <div>
                        <label className="label font-semibold text-[14px] mb-2">
                          <div className={`flex gap-[4px]`}>
                            <span className={`label-text`}>
                              Nomor Rekening Penerima
                            </span>
                          </div>
                        </label>
                        <div className="px-1">{detail?.account_number}</div>
                      </div>
                      <div>
                        <label className="label font-semibold text-[14px] mb-2">
                          <div className={`flex gap-[4px]`}>
                            <span className={`label-text`}>
                              Nama Pemilik Rekening
                            </span>
                          </div>
                        </label>
                        <div className="px-1">{detail?.account_owner_name}</div>
                      </div>
                      <div>
                        <label className="label font-semibold text-[14px] mb-2">
                          <div className={`flex gap-[4px]`}>
                            <span className={`label-text`}>Bank Penerima</span>
                          </div>
                        </label>
                        <div className="px-1">{detail?.bank.name}</div>
                      </div>
                      <div>
                        <label className="label font-semibold text-[14px] mb-2">
                          <div className={`flex gap-[4px]`}>
                            <span className={`label-text`}>Cabang</span>
                          </div>
                        </label>
                        <div className="px-1">{detail?.branch}</div>
                      </div>
                    </div>
                  </div>
                </CardForm>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="mt-6">
          <Approval2LevelProgress
            module="implementing-partner-payment"
            credentials={detail?.credentials}
            rejectReason={detail?.reason}
          />
        </div>

        <div className="items-center justify-between block px-6 py-5 mt-4 mb-10 bg-white border border-gray-200 rounded-lg md:flex">
          <div>
            <Button
              type="button"
              className="px-8 text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
              onClick={() => {
                navigate("/approval-pembayaran-implementing-partner");
              }}
            >
              Batal
            </Button>
          </div>

          <div className="items-center justify-between block gap-2 md:flex">
            <Approval2LevelActions
              module="implementing-partner-payment"
              id={id}
              status={detail?.status}
            />
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default ApprovalImplementingPartnerPaymentDetail;

import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
} from "components";
import FileDetail from "components/molecules/FileDetail";
import InputCurrency from "components/molecules/InputCurrency";
import InputFormDropzone from "components/molecules/InputFormDropzone";
import SelectCity from "components/organisms/Inputs/SelectCity";
import SelectDistrict from "components/organisms/Inputs/SelectDistrict";
import SelectProvince from "components/organisms/Inputs/SelectProvince";
import SelectVillage from "components/organisms/Inputs/SelectVillage";
import SelectZipCode from "components/organisms/Inputs/SelectZipCode";
import { enqueueSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiLoader } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";
import { RiArrowLeftLine, RiSave3Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  getDirectSubmissionDetail,
  postDirectSubmission,
} from "services/danaProgram/directSubmissionService";
import { fileUpload } from "services/danaProgram/fileService";

const FormPermohonanPenyaluranLangsung = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const [initDocs, setInitDocs] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedVillage, setSelectedVillage] = useState("");
  const [selectedZipcode, setSelectedZipcode] = useState("");

  const methods = useForm({
    defaultValues: {
      applicant_name: "",
      applicant_address: "",
      nik: "",
      activity_type: "",
      activity_desc: "",
      activity_result: "",
      location_address: {
        address: "",
        province: {
          label: "",
          value: "",
        },
        city: {
          label: "",
          value: "",
        },
        district: {
          label: "",
          value: "",
        },
        sub_district: {
          label: "",
          value: "",
        },
        post_code: {
          label: "",
          value: "",
        },
      },
      partner_suggestion: "",
      total_incentive_suggestion: 0,
      note: "",
      doc: [],
    },
  });

  const links = [
    {
      path: "/permohonan-penyaluran-langsung",
      label: "Daftar Permohonan Penyaluran Langsung",
    },
    {
      label: "Form",
    },
  ];

  const { data, isLoading } = useQuery({
    enabled: !!searchParams.get("id"),
    queryKey: ["get-detail-direct-submission"],
    queryFn: async () => {
      const res = await getDirectSubmissionDetail(searchParams.get("id"));

      return res.data.data;
    },
  });

  useEffect(() => {
    if (data && !!searchParams.get("id")) {
      methods.reset({
        applicant_name: data.applicant_name,
        applicant_address: data.applicant_address,
        nik: data.nik,
        activity_type: data.activity_type,
        activity_desc: data.activity_desc,
        activity_result: data.activity_result,
        location_address: {
          ...data.location_address,
        },
        partner_suggestion: data.partner_suggestion,
        total_incentive_suggestion: data.total_incentive_suggestion,
        note: data.note,
      });
      setSelectedProvince({
        ...data.location_address.province,
      });
      setSelectedCity({
        ...data.location_address.city,
      });
      setSelectedDistrict({
        ...data.location_address.district,
      });
      setSelectedVillage({
        ...data.location_address.sub_district,
      });
      setSelectedZipcode({
        ...data.location_address.post_code,
      });
      setInitDocs(data.doc);
    }
  }, [data]);

  const onSubmit = async () => {
    postFile.mutate(methods.getValues("doc"));
  };

  const postFile = useMutation({
    mutationKey: ["post-file-direct-submission"],
    mutationFn: async (data) => {
      if (methods.getValues("doc")?.length > 0) {
        const promise = await data?.map(async (item) => {
          const formData = new FormData();
          formData.append("file", item);
          const res = await fileUpload(formData);
          return {
            fileId: res.data.data.id,
            fileName: res.data.data.name,
            fileSize: res.data.data.size,
            mimeType: res.data.data.mimeType,
            path: res.data.data.key,
          };
        });
        return Promise.all(promise);
      } else {
        return [];
      }
    },
    onSuccess: async (res) => {
      let params;

      if (!!searchParams.get("id")) {
        params = {
          ...methods.getValues(),
          id: searchParams.get("id"),
          location_address: {
            address: methods.getValues("location_address.address"),
            province: {
              ...selectedProvince,
            },
            city: {
              ...selectedCity,
            },
            district: {
              ...selectedDistrict,
            },
            sub_district: {
              ...selectedVillage,
            },
            post_code: {
              ...selectedZipcode,
            },
          },
          doc: [...initDocs, ...res],
        };
      } else {
        params = {
          ...methods.getValues(),
          location_address: {
            address: methods.getValues("location_address.address"),
            province: {
              ...selectedProvince,
            },
            city: {
              ...selectedCity,
            },
            district: {
              ...selectedDistrict,
            },
            sub_district: {
              ...selectedVillage,
            },
            post_code: {
              ...selectedZipcode,
            },
          },
          doc: [...initDocs, ...res],
        };
      }
      submitData.mutate(params);
    },
  });

  const submitData = useMutation({
    mutationKey: ["submit-form-direct-submission"],
    mutationFn: async (payload) => {
      const res = await postDirectSubmission(payload);

      return res.data.data;
    },
    onSuccess: (res) => {
      enqueueSnackbar("Data berhasil disimpan", {
        variant: "success",
      });
      navigate("/permohonan-penyaluran-langsung");
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return (
    <Fragment>
      <div className="mb-5">
        <Breadcrumbs items={links} />
      </div>
      <Card>
        <CardHeader>Pengajuan Permohonan Pembayaran Langsung</CardHeader>
        <CardBody>
          {data?.status === "Ditolak" && (
            <div className="flex bg-[#fff5f5] self-stretch p-4 gap-3 border border-[#f57070] rounded-xl mb-5">
              <FiAlertTriangle color="#BE600E" size={20} />
              <div className="flex flex-col gap-1">
                <p className="font-medium text-[#8F480A] text-sm">
                  Alasan Ditolak
                </p>
                <p className="text-sm">{data?.reason}</p>
              </div>
            </div>
          )}
          {/* Information Card */}
          {!searchParams.get("id") && (
            <div className="flex bg-[#FFFCF5] self-stretch p-4 gap-3 border border-[#F5AE70] rounded-xl mb-5">
              <FiAlertTriangle color="#BE600E" size={20} />
              <div className="flex flex-col gap-1">
                <p className="font-medium text-[#8F480A] text-sm">
                  Lengkapi Data
                </p>
                <p className="text-sm">
                  Silakan isi semua data dibawah ini untuk bisa melanjutkan
                  pengisian ke tahap selanjutnya
                </p>
              </div>
            </div>
          )}
          {/* End Information Card */}

          {/* Form Section */}
          <FormProvider {...methods}>
            {isLoading && !!searchParams.get("id") ? (
              <div className="w-full flex items-center justify-center">
                <BiLoader className="animate-spin" />
              </div>
            ) : (
              <>
                <div className="w-full grid grid-cols-2 gap-5">
                  <InputForm
                    controllerName={"applicant_name"}
                    className={`py-2 border border-slate-300 w-full rounded-md flex-1`}
                    label={"Nama Pemohon"}
                    placeholder={""}
                    disabled={searchParams.get("mode") === "detail"}
                    rows="5"
                  />
                  <InputForm
                    controllerName={"applicant_address"}
                    className={`py-2 border border-slate-300 w-full rounded-md flex-1`}
                    label={"Alamat Pemohon"}
                    placeholder={""}
                    disabled={searchParams.get("mode") === "detail"}
                    rows="5"
                    textArea
                  />
                  <div className="col-span-2">
                    <InputForm
                      controllerName={"nik"}
                      className={`py-2 border border-slate-300 w-full rounded-md flex-1`}
                      label={"NIK/NIB"}
                      placeholder={""}
                      disabled={searchParams.get("mode") === "detail"}
                      rows="5"
                    />
                  </div>
                  <InputForm
                    controllerName={"activity_type"}
                    className={`py-2 border border-slate-300 w-full rounded-md flex-1`}
                    label={"Jenis Kegiatan"}
                    disabled={searchParams.get("mode") === "detail"}
                    placeholder={""}
                    rows="5"
                  />
                  <InputForm
                    controllerName={"activity_desc"}
                    className={`py-2 border border-slate-300 w-full rounded-md flex-1`}
                    label={"Deskripsi Kegiatan"}
                    disabled={searchParams.get("mode") === "detail"}
                    placeholder={""}
                    rows="5"
                    textArea
                  />
                  <div className="col-span-2">
                    <InputForm
                      controllerName={"activity_result"}
                      className={`py-2 border border-slate-300 w-full rounded-md flex-1`}
                      label={"Hasil Kegiatan"}
                      placeholder={""}
                      disabled={searchParams.get("mode") === "detail"}
                      rows="5"
                      textArea
                    />
                  </div>
                  <h1 className="my-5 text-xl font-semibold col-span-2">
                    Alamat Lokasi Kegiatan
                  </h1>
                  <div>
                    <p className="label-text font-semibold">Provinsi</p>
                    <SelectProvince
                      onChange={(e) => setSelectedProvince(e)}
                      defaultValue={
                        data &&
                        !!searchParams.get("id") && {
                          ...data?.location_address?.province,
                        }
                      }
                      disabled={searchParams.get("mode") === "detail"}
                    />
                  </div>
                  <div>
                    <p className="label-text font-semibold">Kota</p>
                    <SelectCity
                      defaultValue={
                        data &&
                        !!searchParams.get("id") && {
                          ...data?.location_address?.city,
                        }
                      }
                      onChange={(e) => setSelectedCity(e)}
                      provinceId={selectedProvince.value}
                      disabled={searchParams.get("mode") === "detail"}
                    />
                  </div>
                  <div>
                    <p className="label-text font-semibold">Kecamatan</p>
                    <SelectDistrict
                      defaultValue={
                        data &&
                        !!searchParams.get("id") && {
                          ...data?.location_address?.district,
                        }
                      }
                      onChange={(e) => setSelectedDistrict(e)}
                      cityId={selectedCity.value}
                      disabled={searchParams.get("mode") === "detail"}
                    />
                  </div>
                  <div>
                    <p className="label-text font-semibold">Kelurahan</p>
                    <SelectVillage
                      defaultValue={
                        data &&
                        !!searchParams.get("id") && {
                          ...data?.location_address?.sub_district,
                        }
                      }
                      onChange={(e) => setSelectedVillage(e)}
                      districtId={selectedDistrict.value}
                      disabled={searchParams.get("mode") === "detail"}
                    />
                  </div>
                  <div>
                    <p className="label-text font-semibold">Kode Pos</p>
                    <SelectZipCode
                      defaultValue={
                        data &&
                        !!searchParams.get("id") && {
                          ...data?.location_address?.post_code,
                        }
                      }
                      onChange={(e) => setSelectedZipcode(e)}
                      villageId={selectedVillage.value}
                      disabled={searchParams.get("mode") === "detail"}
                    />
                  </div>
                  <div className="col-span-2">
                    <InputForm
                      controllerName={"location_address.address"}
                      className={`py-2 border border-slate-300 w-full rounded-md flex-1`}
                      label={"Alamat Lengkap"}
                      placeholder={""}
                      disabled={searchParams.get("mode") === "detail"}
                      rows="5"
                      textArea
                    />
                  </div>
                  <InputForm
                    controllerName={"partner_suggestion"}
                    className={`py-2 border border-slate-300 w-full rounded-md flex-1`}
                    label={"Usulan EPC/Mitra"}
                    placeholder={""}
                    disabled={searchParams.get("mode") === "detail"}
                    rows="5"
                  />
                  <InputCurrency
                    controllerName={"total_incentive_suggestion"}
                    label="Usulan Nilai Insentif Total (Rp)"
                    className={`py-2 border border-slate-300 w-full rounded-md flex-1`}
                    disabled={searchParams.get("mode") === "detail"}
                  />
                  <div className="col-span-2">
                    <InputForm
                      controllerName={"note"}
                      className={`py-2 border border-slate-300 w-full rounded-md flex-1`}
                      label={"Catatan"}
                      placeholder={""}
                      disabled={searchParams.get("mode") === "detail"}
                      rows="5"
                      textArea
                    />
                  </div>
                  <h1 className="my-5 text-xl font-semibold col-span-2">
                    Lampiran Dokumen Pendukung
                  </h1>
                  <div className="col-span-2">
                    {searchParams.get("mode") !== "detail" && (
                      <>
                        <p className="label-text font-semibold mb-3">
                          Unggah Lampiran Dokumen Pendukung
                        </p>
                        <InputFormDropzone
                          name="doc"
                          accept={{
                            "image/png": [],
                            "image/jpeg": [],
                            "application/pdf": [],
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                              [],
                          }}
                          maxFiles={10}
                          maxSize={10}
                          multiple={true}
                          informationText="PNG, JPG, PDF, Docx"
                        />
                      </>
                    )}
                    {initDocs?.map((item) => (
                      <FileDetail
                        key={item.fileId}
                        file={item}
                        onRemove={() => {
                          const filtered = initDocs.filter(
                            (file) => file.fileId !== item.fileId
                          );
                          setInitDocs(filtered);
                        }}
                        hideDelete={searchParams.get("mode") === "detail"}
                      />
                    ))}
                  </div>
                </div>
                <div className="w-full flex justify-between mt-5">
                  <Button
                    type="button"
                    className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
                    onClick={() => navigate("/permohonan-penyaluran-langsung")}
                  >
                    <RiArrowLeftLine />
                    Kembali
                  </Button>
                  {searchParams.get("mode") !== "detail" && (
                    <Button
                      type="button"
                      className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 min-w-32"
                      onClick={onSubmit}
                    >
                      {submitData?.isLoading || postFile?.isLoading ? (
                        <BiLoader className="animate-spin" />
                      ) : (
                        <>
                          <RiSave3Line />
                          <p>Simpan</p>
                        </>
                      )}
                    </Button>
                  )}
                </div>
              </>
            )}
          </FormProvider>
          {/* End Form Section */}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default FormPermohonanPenyaluranLangsung;

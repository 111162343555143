import React, { createContext, useContext, useReducer } from 'react';

const InvestmentPlanContext = createContext();

const initialState = {
  idInvestment: null,
  idProposal: null,
  currentStep: 1,
  type: "create",
  dataInvestment: {},
  fetchedData: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        idInvestment: action.payload.idInvestment,
        idProposal: action.payload.idProposal,
        currentStep: parseInt(action.payload.currentStep),
        type: action.payload.type,
        dataInvestment: action.payload.dataInvestment,
        fetchedData: action.payload.fetchedData
      };
    case 'SET_CURRENT_STEP':
      return {
        ...state,
        currentStep: parseInt(action.payload.currentStep),
      };
    case 'SET_DATA_INVESTMENT':
      return {
        ...state,
        dataInvestment: action.payload.dataInvestment
      }
    default:
      return state;
  }
};

export const InvestmentPlanProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setData = (data) => {
    dispatch({ type: 'SET_DATA', payload: data });
  };

  const setCurrentStep = (data) => {
    dispatch({ type: 'SET_CURRENT_STEP', payload: data });
  };

  const setDataInvestment = (data) => {
    dispatch({ type: 'SET_DATA_INVESTMENT', payload: data });
  }

  return (
    <InvestmentPlanContext.Provider value={{ ...state, setData, setCurrentStep, setDataInvestment }}>
      {children}
    </InvestmentPlanContext.Provider>
  );
};

export const useInvestmentPlanContext = () => {
  return useContext(InvestmentPlanContext);
};

import config from 'app/config';
import axiosInstance from 'app/interceptors';
import axios from 'axios';

const headers = {
  'Content-Type': 'application/json',
  'ngrok-skip-browser-warning': true,
};

export const getList = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/notes/list`,
    { params, headers }
  );
};

export const approvalEligiblityConceptNote = ({ queryKey }) => {
  const [, conceptNoteId] = queryKey;

  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/notes/approval-eligiblity`,
    {
      id: conceptNoteId,
    }
  );
};

export const approvalConceptNote = (params, payload) => {
  console.log(params);
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/notes/${params.id}/${params.status}`,
    payload
  );
};

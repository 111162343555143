import React from 'react';
import ApprovalPembayaranLemtaraDetail from '../detail';

const ApprovalPembayaranLemtaraReview = () => {
  return (
    <ApprovalPembayaranLemtaraDetail 
      isReview={true}
    />
  );
};

export default ApprovalPembayaranLemtaraReview;
import config from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const getList = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/project-resources-payment/list`,
    { params, headers }
  );
};

export const postPembayaranResource = (payload) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/project-resources-payment/submit`,
    payload,
    { headers }
  );
};

export const getDetail = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/project-resources-payment/detail`,
    { params, headers }
  );
};

export const deletePembayaranResource = (params) => {
  return axiosInstance.delete(
    `${config.BASE_URL}/api/dana-program/v1/cms/project-resources-payment/delete`,
    { params, headers }
  );
};

export const approvePembayaranResource = (payload) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/project-resources-payment/approval`,
    payload,
    { headers }
  );
};

export const approvalEligiblityPembayaranResource = ({ queryKey }) => {
  const [, pembayaranResourceId] = queryKey;

  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/project-resources-payment/approval-eligiblity`,
    {
      id: pembayaranResourceId,
    }
  );
};

import React from "react";

const Input = React.forwardRef(
  (
    { className = "", hookForm = {}, prefix = "", suffix = "", ...props },
    ref
  ) => (
    // <input ref={ref} {...hookForm} {...props} className={`focus:outline-none focus:border-primary-700 ${className}`} />
    <div className={`flex items-center relative ${props?.unit && "w-[80%]"}`}>
      {prefix && (
        <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none -z-0">
          {prefix}
        </span>
      )}
      <input
        ref={ref}
        {...hookForm}
        {...props}
        className={` ${
          prefix ? "pl-10" : "pl-3"
        } focus:outline-none focus:border-primary-700 rounded ${className} 
        ${props.disabled && `cursor-not-allowed bg-gray-200 border-gray-300`}`}
        style={{ paddingRight: props?.rightIcon ? 45 : 0 }}
      />
      {suffix && (
        <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          {suffix}
        </span>
      )}
      {props?.rightIcon && (
        <div className="absolute right-4 inset-y-5">{props?.rightIcon}</div>
      )}

      {props?.unit && <p className="text-sm font-medium pl-4">{props?.unit}</p>}
    </div>
  )
);

export default Input;

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  InputFormUpload,
} from 'components';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import {
  useBankList,
  useCityList,
  useCreateImplementingPartner,
  useDistrictList,
  useImplementingPartnerDetail,
  useInstitutionTypeList,
  useProvinceList,
  useSubdistrictList,
} from '../hooks/useImplementingPartnerServices';
import CustomSelectForm from 'components/molecules/CustomSelectForm';
import { fileUpload } from 'services/danaProgram/fileService';

const links = [
  {
    label: 'Daftar Implementing Partner',
    path: '/implementing-partner',
  },
  {
    label: 'Detail data implementing partner',
  },
];

const ImplementingPartnerDetail = () => {
  const { id } = useParams();

  const methods = useForm({
    defaultValues: {
      id: '',
      name: '',
      institution: {
        id: '',
        name: '',
      },
      province: {
        id: '',
        name: '',
      },
      city: {
        id: '',
        name: '',
      },
      district: {
        id: '',
        name: '',
      },
      subdistrict: {
        id: '',
        name: '',
      },
      address: '',
      account_number: '',
      account_owner_name: '',
      bank: {
        id: '',
        name: '',
      },
      branch: '',
      pic: '',
      pic_phone_number: '',
      doc: [],
      status: '',
    },
  });
  const navigate = useNavigate();

  const [provinceId, setProvinceId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [districtId, setDistrictid] = useState(null);

  const { institutionList } = useInstitutionTypeList();
  const { provinceList } = useProvinceList();
  const { cityList } = useCityList(provinceId);
  const { districtList } = useDistrictList(cityId);
  const { subdistrictList } = useSubdistrictList(districtId);
  const { bankList } = useBankList();
  const { detail, detailIsLoading } = useImplementingPartnerDetail(id);

  useEffect(() => {
    methods.setValue('id', detail?._id);
    methods.setValue('name', detail?.name);
    methods.setValue('institution', detail?.institution);
    methods.setValue('province', detail?.province);
    methods.setValue('city', detail?.city);
    methods.setValue('district', detail?.district);
    methods.setValue('subdistrict', detail?.subdistrict);
    methods.setValue('address', detail?.address);
    methods.setValue('account_number', detail?.account_number);
    methods.setValue('account_owner_name', detail?.account_owner_name);
    methods.setValue('bank', detail?.bank);
    methods.setValue('branch', detail?.branch);
    methods.setValue('pic', detail?.pic);
    methods.setValue('pic_phone_number', detail?.pic_phone_number);
    methods.setValue('doc', detail?.doc);
    methods.setValue('status', detail?.status);
  }, [detailIsLoading]);

  const { submitMutation } = useCreateImplementingPartner();

  const handleSubmitForm = async () => {
    try {
      const params = methods.getValues();

      let additional_document = [];

      for (let file of params.doc) {
        if (file instanceof File) {
          const fileFormData = new FormData();
          fileFormData.append('file', file);

          const resFile = await fileUpload(fileFormData);
          const resDataFile = await resFile.data.data;

          const payloadPerFile = {
            fileId: resDataFile.id,
            fileName: resDataFile.name,
            fileSize: resDataFile.size,
            mimeType: resDataFile.mimeType,
            path: resDataFile.url,
          };

          additional_document.push(payloadPerFile);
        } else {
          additional_document.push(file);
        }
      }

      params.doc = additional_document;

      submitMutation.mutate(params, {
        onSuccess: (res) => {
          navigate('/implementing-partner');
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Breadcrumbs items={links} />
      <FormProvider {...methods}>
        <div className="flex flex-col gap-6 pt-8">
          <Card>
            <CardHeader>Detail Data</CardHeader>
            <CardBody>
              <div className="flex flex-col gap-5">
                <InputForm
                  controllerName={`name`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={'Nama Implementing Partner'}
                  placeholder={'Nama Implementing Partner'}
                  required={true}
                  disabled={true}
                />
                <div>
                  <label className="label font-semibold text-[14px] mb-2">
                    <div className={`flex gap-[4px]`}>
                      <span className={`label-text`}>
                        Jenis Lembaga
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </div>
                  </label>
                  <CustomSelectForm
                    name="institution"
                    placeholder="Jenis Lembaga"
                    options={institutionList.map((item) => ({
                      ...item,
                      name: item.nama,
                      value: item.id,
                      label: item.nama,
                    }))}
                    isDisabled={true}
                  />
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <div>
                    <label className="label font-semibold text-[14px] mb-2">
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>
                          Provinsi
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </div>
                    </label>
                    <CustomSelectForm
                      name="province"
                      placeholder="Provinsi"
                      options={provinceList.map((item) => ({
                        ...item,
                        name: item.namaProvinsi,
                        value: item.id,
                        label: item.namaProvinsi,
                      }))}
                      onChange={(e) => {
                        setProvinceId(e.id);
                      }}
                      isDisabled={true}
                    />
                  </div>

                  <div>
                    <label className="label font-semibold text-[14px] mb-2">
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>
                          Kota/ Kab
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </div>
                    </label>
                    <CustomSelectForm
                      name="city"
                      placeholder="Kota/ Kab"
                      options={cityList
                        .filter((item) => item.idProvinsi === provinceId)
                        .map((item) => ({
                          ...item,
                          name: item.namaKabupatenKota,
                          value: item.id,
                          label: item.namaKabupatenKota,
                        }))}
                      onChange={(e) => setCityId(e.id)}
                      isDisabled={true}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <div>
                    <label className="label font-semibold text-[14px] mb-2">
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>
                          District
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </div>
                    </label>
                    <CustomSelectForm
                      name="district"
                      placeholder="Kecamatan"
                      options={districtList
                        .filter((item) => item.idKabupatenKota === cityId)
                        .map((item) => ({
                          ...item,
                          name: item.namaKecamatan,
                          value: item.id,
                          label: item.namaKecamatan,
                        }))}
                      onChange={(e) => setDistrictid(e.value)}
                      isDisabled={true}
                    />
                  </div>
                  <div>
                    <label className="label font-semibold text-[14px] mb-2">
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>
                          Kelurahan/Desa
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </div>
                    </label>
                    <CustomSelectForm
                      name="subdistrict"
                      placeholder="Kelurahan/Desa"
                      options={subdistrictList
                        .filter((item) => item.idKecamatan === districtId)
                        .map((item) => ({
                          ...item,
                          name: item.namaKelurahan,
                          value: item.id,
                          label: item.namaKelurahan,
                        }))}
                      isDisabled={true}
                    />
                  </div>
                </div>
                <InputForm
                  textArea={true}
                  controllerName={'address'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={'Alamat'}
                  placeholder={'Alamat'}
                  required={true}
                  disabled={true}
                />
                <div className="grid grid-cols-2 gap-5">
                  <InputForm
                    controllerName={`account_number`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                    label={'nomor Rekening'}
                    placeholder={'nomor Rekening'}
                    required={true}
                    disabled={true}
                  />
                  <InputForm
                    controllerName={`account_owner_name`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                    label={'Nama Pemilik Rekening'}
                    placeholder={'Nama Pemilik Rekening'}
                    required={true}
                    disabled={true}
                  />
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <div>
                    <label className="label font-semibold text-[14px] mb-2">
                      <div className={`flex gap-[4px]`}>
                        <span className={`label-text`}>
                          Bank
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </div>
                    </label>
                    <CustomSelectForm
                      name="bank"
                      placeholder="Pilih Bank"
                      options={bankList.map((item) => ({
                        ...item,
                        value: item.id,
                        label: item.name,
                      }))}
                      isDisabled={true}
                    />
                  </div>
                  <InputForm
                    controllerName={`branch`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                    label={'Cabang'}
                    placeholder={'Cabang'}
                    required={true}
                    disabled={true}
                  />
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <InputForm
                    controllerName={`pic`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                    label={'Nama Narahubung'}
                    placeholder={'Nama Narahubung'}
                    required={true}
                    disabled={true}
                  />
                  <InputForm
                    controllerName={`pic_phone_number`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                    label={'nomor Telepon Narahubung'}
                    placeholder={'nomor Telepon Narahubung'}
                    required={true}
                    disabled={true}
                  />
                </div>

                <div>
                  <label className="label font-semibold text-[14px]">
                    <div className={`flex gap-[4px]`}>
                      <span className={`label-text`}>
                        Upload Dokumen Kelengkapan Profil Implementing Partner
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </div>
                  </label>
                  <InputFormUpload
                    controllerName={'doc'}
                    maxSize={10240}
                    label=""
                    isMulti
                    hideSectionUpload={true}
                    listFiles={detail?.doc}
                    disableDelete
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="items-center justify-between block px-6 py-5 mt-4 mb-10 bg-white border border-gray-200 rounded-lg md:flex">
          <div>
            <Button
              type="button"
              className="px-8 text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
              onClick={() => {
                navigate('/implementing-partner');
              }}
            >
              Kembali
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default ImplementingPartnerDetail;

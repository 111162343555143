import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Breadcrumbs } from "components";
import * as yup from "yup";
import HasilPelaksanaanKegiatan from "./Step1/form";
import RealisasiBiaya from "./Step2/form";
import Stepper from "pages/ActivityReport/form/stepper";
import useActivityReport from "../hooks/useHooks";

const FormLaporanKegiatan = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [edit, setEdit] = useState(true);
  const idReport = sessionStorage.getItem("ActivityReportsId");

  const [activeTab, setActiveTab] = useState(0);
  const params = { id: idReport };

  const [listAllEmployee, setListAllEmployee] = useState();
  const { ActivityReport, refetchActivityReport, isFetchingActivityReport } =
    useActivityReport({
      params,
    });

  useEffect(() => {
    refetchActivityReport();
  }, []);

  function mapOption(data) {
    let temp = data?.map((obj) => ({
      value: obj.name,
      label: obj.name,
      ...obj,
    }));
    setListAllEmployee(temp);
  }

  useEffect(() => {
    if (currentPath.includes("detail")) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  }, []);

  const validationSchema = yup.object().shape({
    // work_plan_name: yup.mixed().required("wajib"),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const links = [
    {
      label: "Funding Proposal",
      path: `/activity-report-review`,
    },
    {
      label: "Laporan Kegiatan",
    },
  ];

  const TabList = [
    {
      label: "Hasil Pelaksanaan Kegiatan",
      id: 0,
    },
    {
      label: "Realisasi Biaya",
      id: 1,
    },
  ];

  return (
    <div>
      <div className="bg-transparent flex items-center flex-wrap flex-1 mb-[24px]">
        <Breadcrumbs items={links} />
      </div>
      <div className="tabs">
        <Stepper currentStep={activeTab} items={TabList} />
      </div>
      <div>
        {activeTab === 0 && (
          <HasilPelaksanaanKegiatan
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            datas={!isFetchingActivityReport ? ActivityReport?.step_1 : {}}
          />
        )}
        {activeTab === 1 && (
          <RealisasiBiaya
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            datas={!isFetchingActivityReport ? ActivityReport?.step_2 : {}}
          />
        )}
      </div>
    </div>
  );
};

export default FormLaporanKegiatan;

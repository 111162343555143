import React, { useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { Button, Card, CardBody, CardForm, CardHeader, InputForm, TitleText, } from "components";
import { BiPencil } from "react-icons/bi";
import AtomSelect from "components/atoms/Select";
import { RiFile2Line } from "react-icons/ri";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import { useFormContext } from "react-hook-form";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";

const Directors = ({
  openModal = () => console.log(`open`),
  isEdit = false,
  toogleIsEdit = () => {},
  dataDirectors = [],
  appendDataDirectors = () => {},
  removeDataDirectors = () => {},
  masterData = {}
}) => {
  const navigate = useNavigate();

  const { 
    watch, 
    getValues,
    formState: { errors, isValid } 
  } = useFormContext();

  const [isEditVerification, setIsEditVerification] = useState(false);
  const toogleIsEditVerification = () => setIsEditVerification(!isEditVerification)
  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Direksi/ Ketua Yayasan/ Rektor/ Dekan</div>
        </div>
      </CardHeader>
      <CardBody>
        {dataDirectors?.length > 0 && (
          dataDirectors?.map((e, index) => {
            return (
              <CardForm key={e.id} label={`Direksi/ Ketua Yayasan/ Rektor/ Dekan ${index + 1}`} buttonCollapse={true}>
                <div className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <InputForm
                      controllerName={`structure_organization.directors.${index}.name`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={'Nama'}
                      placeholder={'Nama'}
                      required
                      disabled={!isEdit}
                    />
                    <InputForm
                      controllerName={`structure_organization.directors.${index}.nik`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={'Nomer KTP'}
                      placeholder={'Nomer KTP'}
                      required
                      disabled={!isEdit}
                    />
                    <AtomSelect
                      controllerName={`structure_organization.directors.${index}.gender`}
                      options={[
                        { value: "L", label: 'Laki-Laki ' },
                        { value: "P", label: 'Perempuan ' },
                      ]} className="basic-single mt-3"
                      label={"Jenis Kelamin"}
                      disable={!isEdit}
                      returnObject={false}
                      defaultValue={e.gender}
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <AtomSelect
                      controllerName={`structure_organization.directors.${index}.position`}
                      options={masterData?.jabatan} className="basic-single mt-3"
                      label={"Jabatan"}
                      disable={!isEdit}
                      returnObject={typeof getValues(`structure_organization.directors.${index}.position`) === "object"}
                    />
                    <InputForm
                      controllerName={`structure_organization.directors.${index}.email`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={'Email'}
                      placeholder={'Email'}
                      required
                      disabled={!isEdit}
                    />
                  </div>
                </div>
              </CardForm>
            )
          })
        )}
        
        {/* button Verification */}
        <RadioVerificationBadge 
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"structure_organization.status_approval_directors"}
          nameNotes={"structure_organization.notes_approval_directors"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={errors?.structure_organization?.status_approval_directors ? errors?.structure_organization?.status_approval_directors : false}
        />
      </CardBody>
    </Card>
  );
};

export default Directors;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import FormTimPengelolaProyek from "./form";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Breadcrumbs } from "components";
import * as yup from "yup";
import useProjectManagementTeam from "../hooks/useHooks";
import { useSnackbar } from "notistack";

const Form = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const currentPath = location.pathname;
  const [edit, setEdit] = useState(true);
  const [listAllEmployee, setListAllEmployee] = useState();
  const [hasNotified, setHasNotified] = useState(false);
  let params = localStorage.getItem("projectManagementTeamsId");

  const {
    projectManagementTeam,
    refetchProjectManagementTeam,
    employeeList,
    refetchEmployeeList,
    submitMutation,
  } = useProjectManagementTeam({ params });

  const validationSchema = yup.object().shape({
    proposal_name: yup.string().required(""),
    project_director_name: yup.mixed().required("Project Director wajib diisi"),
    project_manager_name: yup.mixed().required("Project Manager wajib diisi"),
    project_leader_name: yup.mixed(),
    safeguard_specialist_name: yup.mixed(),
    pum_name: yup.mixed(),
    procurement_specialist_name: yup.mixed(),
    finance_specialist_name: yup.mixed(),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      position_1: "Project Director",
      position_2: "Project Manager",
      position_3: "Project Leader",
      position_4: "Safeguard Specialist",
      position_5: "PUM",
      position_6: "Procurement Specialist",
      position_7: "Finance Specialist",
    },
  });

  const {
    formState: { isValid },
  } = methods;

  useEffect(() => {
    refetchProjectManagementTeam();
    refetchEmployeeList();
  }, []);

  useEffect(() => {
    mapOption(employeeList);
  }, [employeeList]);

  function mapOption(data) {
    let temp = data?.map((obj) => ({
      value: obj.name,
      label: obj.name,
      ...obj,
    }));
    setListAllEmployee(temp);
  }

  useEffect(() => {
    if (currentPath.includes("detail")) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  }, []);

  const links = [
    {
      label: "Tim Pengelola Proyek",
      path: `/project-management-team`,
    },
    {
      label: "Assign Tim Pengelola Proyek",
    },
  ];

  const onSubmit = (datas) => {
    let projectsData = projectManagementTeam?.data?.data;
    let params = {
      id: projectsData?._id,
      proposal_id: projectsData?.proposal_id,
      proposal_name: projectsData?.proposal_name,
      donor_id: projectsData?.donor_id,
      donor_name: projectsData?.donor_name,
      project_director_id: datas?.project_director_name?.id,
      project_director_name: datas?.project_director_name?.value,
      project_manager_id: datas?.project_manager_name?.id,
      project_manager_name: datas?.project_manager_name?.value,
      project_leader_id: datas?.project_leader_name?.id,
      project_leader_name: datas?.project_leader_name?.value,
      safeguard_specialist_id: datas?.safeguard_specialist_name?.id,
      safeguard_specialist_name: datas?.safeguard_specialist_name?.value,
      pum_id: datas?.pum_name?.id,
      pum_name: datas?.pum_name?.value,
      procurement_specialist_id: datas?.procurement_specialist_name?.id,
      procurement_specialist_name: datas?.procurement_specialist_name?.value,
      finance_specialist_id: datas?.finance_specialist_name?.id,
      finance_specialist_name: datas?.finance_specialist_name?.value,
    };

    submitMutation.mutate(params);
  };

  useEffect(() => {
    if (submitMutation?.isSuccess && !hasNotified) {
      enqueueSnackbar({
        message: "Successfully Assigned Project Management Team!",
        variant: "success",
      });
      setHasNotified(true);
    }
    if (submitMutation?.isError && !hasNotified) {
      enqueueSnackbar({
        message:
          "Failed Assigned Project Management Team! Error Code:" +
          submitMutation?.data?.data?.status,
        variant: "error",
      });
    }
  }, [submitMutation?.isSuccess, hasNotified]);

  if (submitMutation?.isSuccess) {
    return <Navigate to="/project-management-team" replace />;
  }

  return (
    <div>
      {/* start Breadcrumb */}
      <div className="bg-transparent flex items-center flex-wrap flex-1 mb-[24px]">
        <Breadcrumbs items={links} />
      </div>
      <FormProvider {...methods}>
        <FormTimPengelolaProyek
          permission={edit}
          title={"Assign Tim Pengelola Proyek"}
          listAllEmployee={listAllEmployee}
          data={projectManagementTeam?.data?.data}
          isValid={isValid}
          onSubmit={onSubmit}
        />
      </FormProvider>
    </div>
  );
};

export default Form;

import { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import classNames from "classnames";

const CardForm = ({
  className,
  children,
  label = "",
  buttonCollapse = false,
  actionButton = false,
  chip = null,
}) => {
  const [formCollapse, setFormCollapse] = useState(true);

  const collapse = (data) => {
    setFormCollapse(data);
  };

  return (
    <>
      <div className={`${className} flex rounded-lg border-[1px]`}>
        <div className={`flex flex-col w-full `}>
          <div className={`flex items-center bg-[#E1F1D6] rounded-lg`}>
            <div className="flex justify-between flex-1 p-4">
              <span className={"text-[#01A24A] font-[600]"}>{label}</span>
              <div
                className={`bg-[#EEFFF4] rounded-xl px-2  ${
                  chip === null && "hidden"
                }`}
              >
                <span className={`text-[#01A24A] text-md font-[600]`}>
                  {chip}
                </span>
              </div>
            </div>
            {buttonCollapse ? (
              <div
                className={`${
                  actionButton ? "flex gap-4" : "gap-0"
                } flex-1 text-right p-4`}
              >
                {actionButton ? (
                  <div className="flex-1">{actionButton}</div>
                ) : (
                  <></>
                )}
                <button onClick={() => collapse(!formCollapse)}>
                  <span className="text-[#01A24A] flex items-center">
                    {formCollapse ? "Tutup" : "Buka"}
                    {formCollapse ? (
                      <RiArrowDropUpLine />
                    ) : (
                      <RiArrowDropDownLine />
                    )}
                  </span>
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className={classNames("flex flex-col gap-4", formCollapse && "p-4")}
          >
            {buttonCollapse ? (formCollapse ? children : "") : children}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardForm;

import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  useForm,
  FormProvider,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { sortBy } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { CgSpinner } from 'react-icons/cg';
import { BiTrash } from 'react-icons/bi';
import { useSearchParams } from 'react-router-dom';

import {
  AtomDatePicker,
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
} from 'components';
import CustomSelectForm from './Select';
import CustomInput from './Input';
import AtomSelect from 'components/atoms/Select';
import { getAwpDetail, getList } from 'services/danaProgram/awsService';
import { getIspList } from 'services/danaProgram/masterDataService';
import FileDetail from 'components/molecules/FileDetail';
import { getActivityReportDetail } from 'services/danaProgram/activityReportService';

const ActivityResult = ({ defaultValues }) => {
  const ActivityReportsId = localStorage.getItem('ActivityReportsId') || null;
  let [searchParams] = useSearchParams();
  let mode = searchParams.get('mode');
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues,
  });
  const { setValue } = methods;

  const [isChangeIsp, setIsChangeIsp] = useState(true);
  const [selectedIsp, setSelectedIsp] = useState(0);
  const [awp, setAwp] = useState([]);
  const [pageAwp, setPageAwp] = useState(1);
  const [lastPageAwp, setLastPageAwp] = useState(1);
  const [activityOption, setActivityOption] = useState([]);

  // For Edit
  const { data: dataDetail, isFetching: isFetchingDetail } = useQuery({
    enabled: ActivityReportsId !== null,
    queryKey: ['get-activity-report-detail'],
    queryFn: async () => {
      const res = await getActivityReportDetail({
        id: ActivityReportsId,
      });
      return res.data.data;
    },
  });

  useEffect(() => {
    if (dataDetail && !!ActivityReportsId) {
      let startDate = dataDetail?.step_1?.start_date.split('/');
      let endDate = dataDetail?.step_1?.end_date.split('/');
      methods.reset({
        project: {
          value: dataDetail?.step_1?.project_id,
          label: dataDetail?.step_1?.project_name,
        },
        main_activity: {
          detail: dataDetail?.step_1?.main_activity.detail,
          value: dataDetail?.step_1?.main_activity.main_activity_id,
          label: dataDetail?.step_1?.main_activity.main_activity_name,
        },
        output_id: dataDetail?.step_1?.output_id,
        output_name: dataDetail?.step_1?.output_name,
        start_date: new Date(`${startDate[2]}/${startDate[1]}/${startDate[0]}`),
        end_date: new Date(`${endDate[2]}/${endDate[1]}/${endDate[0]}`),
        activity_goals: dataDetail?.step_1?.purposes?.map((item) => ({
          label: item,
          value: item,
        })),
        partners: dataDetail?.step_1?.partners?.map((item) => ({
          label: item,
          value: item,
        })),
        involvement: {
          male: dataDetail?.step_1?.total_male,
          female: dataDetail?.step_1?.total_female,
        },
        activity_result: {
          activity_result: dataDetail?.step_1?.activity_result,
          attachment: dataDetail?.step_1?.document_support,
        },
        safeguards: dataDetail?.step_1?.safeguards,
      });
    }
  }, [dataDetail]);
  // End Section For Edit

  const { data: ispList, isFetching: isFetchingIspList } = useQuery({
    queryKey: ['get-isp-list'],
    queryFn: async () => {
      const res = await getIspList();

      return res.data.data;
    },
  });

  useEffect(() => {
    if (ispList && !ActivityReportsId) {
      let sorted = sortBy(ispList, (el) => {
        return parseInt(el?.nama.split(' ')[1]);
      });
      setValue(
        'safeguards',
        sorted.map((item) => {
          return {
            principle_name: item?.deskripsi,
            activity_list: [
              {
                activity: '',
                description: '',
                document: [],
              },
            ],
          };
        })
      );
    }
    setTimeout(() => {
      setIsChangeIsp(false);
    }, 300);
  }, [ispList]);

  const { data: awpList } = useQuery({
    queryKey: ['get-awp-list', pageAwp],
    queryFn: async () => {
      const res = await getList({ page: pageAwp, status: 'dikirim' });

      return res.data.data;
    },
  });

  const reachBottomAwp = () => {
    if (pageAwp < lastPageAwp) {
      setPageAwp(pageAwp + 1);
    }
  };

  useEffect(() => {
    if (awpList) {
      let mapped = awpList?.data?.map((item) => ({
        label: item?.proposal_number,
        value: item?._id,
      }));
      setAwp((prevState) => [...prevState, ...mapped]);
      setPageAwp(awpList?.page);
      setLastPageAwp(awpList?.lastPage);
    }
  }, [awpList]);

  const { data: awpDetail, isFetching: isFetchingAWPDetail } = useQuery({
    enabled: !!methods.getValues('project')?.value,
    queryKey: ['get-awp-detail', methods.watch('project')],
    queryFn: async () => {
      const res = await getAwpDetail(methods.getValues('project')?.value, {});

      return res.data.data;
    },
  });

  useEffect(() => {
    if (awpDetail) {
      let activity = awpDetail?.output?.flatMap((parent, index) => {
        return parent?.activity.map((item) => ({
          id: item?._id,
          name: item?.name,
          detail: item?.detail?.map((item) => ({
            label: item?.name,
            value: item?._id,
          })),
          output: parent?.name,
          output_id: item?.awp_output_id,
        }));
      });
      setActivityOption(activity);
    }
  }, [awpDetail]);

  return (
    <FormProvider {...methods}>
      {isFetchingDetail || isFetchingIspList || isFetchingAWPDetail ? (
        <div className="w-full h-full flex justify-center">
          <CgSpinner className="animate-spin text-primary-400" />
        </div>
      ) : (
        <form noValidate autoComplete="off">
          <div className="space-y-4 w-full overflow-hidden">
            <Card>
              <CardHeader>
                <div>Pelaksanaan Kegiatan</div>
              </CardHeader>
              <CardBody>
                <div className="space-y-5">
                  <AtomSelect
                    label={'Proyek'}
                    controllerName={'project'}
                    options={awp}
                    onMenuScrollToBottom={reachBottomAwp}
                    required
                    disable={mode === 'detail'}
                  />
                  <div>
                    <label className="label font-semibold text-[14px]">
                      <span className={`label-text`}>
                        Kegiatan Utama
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </label>
                    <CustomSelectForm
                      name="main_activity"
                      placeholder="Kegiatan Utama"
                      options={activityOption?.map((item) => ({
                        label: item?.name,
                        value: item?.id,
                        output: item?.output,
                        output_id: item?.output_id,
                        detail: item?.detail,
                      }))}
                      onChange={(obj) => {
                        methods.setValue('output_id', obj.output_id);
                        methods.setValue('output_name', obj.output);
                      }}
                      disabled={mode === 'detail'}
                    />
                  </div>
                  <InputForm
                    controllerName={`output_name`}
                    className={`text-sm px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 w-full`}
                    label={'Output'}
                    placeholder={'Output'}
                    disabled
                  />
                  <div className="flex gap-5">
                    <div className="w-full grid grid-cols-2 gap-3">
                      <AtomDatePicker
                        className="max-h-[38px] mt-0 w-full rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none focus:ring-0 focus:border-0"
                        controllerName="start_date"
                        label={'Tanggal Kegiatan'}
                        placeholder="Select a date"
                        format={'dd/MM/yyyy'}
                        datepickerOptions={{
                          maxDate: methods.getValues('end_date'),
                        }}
                        asText={mode === 'detail'}
                      />
                      <div className="flex items-end">
                        <AtomDatePicker
                          className="max-h-[38px] flex w-full rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none focus:ring-0 focus:border-0"
                          controllerName="end_date"
                          label={''}
                          placeholder="Select a date"
                          datepickerOptions={{
                            minDate: methods.getValues('start_date'),
                          }}
                          format={'dd/MM/yyyy'}
                          asText={mode === 'detail'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <label className="label font-semibold text-[14px]">
                      <span className={`label-text`}>
                        Tujuan Kegiatan
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </label>
                    <CustomSelectForm
                      type="creatable"
                      name="activity_goals"
                      placeholder="Tujuan Kegiatan"
                      disabled={mode === 'detail'}
                    />
                  </div>
                  <div className="w-full">
                    <label className="label font-semibold text-[14px]">
                      <span className={`label-text`}>
                        Mitra / Pemangku Yang Dilibatkan
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </label>
                    <CustomSelectForm
                      type="creatable"
                      name="partners"
                      placeholder="Mitra / Pemangku Yang Dilibatkan"
                      disabled={mode === 'detail'}
                    />
                  </div>
                  <div className="text-lg font-semibold text-primary-700">
                    Keterlibatan
                  </div>
                  <div className="flex gap-5">
                    <div className="w-full">
                      <label className="label font-semibold text-[14px]">
                        <span className={`label-text`}>
                          Perempuan
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </label>
                      <CustomInput
                        name={`involvement.female`}
                        placeholder="Perempuan"
                        asText={mode === 'detail'}
                      />
                    </div>
                    <div className="w-full">
                      <label className="label font-semibold text-[14px]">
                        <span className={`label-text`}>
                          Laki-laki
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </label>
                      <CustomInput
                        name={`involvement.male`}
                        placeholder="Laki-laki"
                        asText={mode === 'detail'}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div>Hasil Kegiatan</div>
              </CardHeader>
              <CardBody>
                <div className="space-y-5">
                  <InputForm
                    controllerName={`activity_result.activity_result`}
                    className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
                    label={'Hasil kegiatan dan atau kemajuan'}
                    placeholder={'Hasil kegiatan dan atau kemajuan'}
                    textArea={true}
                    rows={5}
                    disabled={mode === 'detail'}
                  />
                  <div className="w-full">
                    <label className="label font-semibold text-[14px]">
                      <span className={`label-text`}>
                        Upload Dokumen Bukti Pelaksanaan Kegiatan
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </label>
                    {methods?.watch('activity_result.attachment').length >
                      0 && (
                      <>
                        {methods
                          ?.getValues('activity_result.attachment')
                          .map((item, idx) => (
                            <FileDetail
                              key={idx}
                              file={item}
                              onRemove={() => {
                                setValue('activity_result.attachment', []);
                              }}
                              hideDelete={mode === 'detail'}
                            />
                          ))}
                      </>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div>Safeguard yang Diimplementasi</div>
              </CardHeader>
              <div className="p-5 w-full">
                <div className="flex gap-3 max-w-full overflow-x-scroll">
                  {sortBy(ispList, (el) => {
                    return parseInt(el?.nama.split(' ')[1]);
                  }).map((item, index) => (
                    <div
                      key={index}
                      className={`px-5 py-3 whitespace-nowrap rounded-md cursor-pointer ${
                        index === selectedIsp
                          ? 'bg-primary-700 text-white font-semibold'
                          : 'bg-slate-200'
                      }`}
                      onClick={() => {
                        setIsChangeIsp(true);
                        setSelectedIsp(index);
                        setTimeout(() => {
                          setIsChangeIsp(false);
                        }, 300);
                      }}
                    >
                      ISP {index + 1}
                    </div>
                  ))}
                </div>
                {isChangeIsp ? (
                  <div className="w-full flex justify-center m-5">
                    <CgSpinner className="animate-spin text-primary-400" />
                  </div>
                ) : (
                  <div className="mt-5">
                    <InputForm
                      controllerName={`safeguards.${selectedIsp}.principle_name`}
                      className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 w-full`}
                      label={''}
                      disabled
                      rows={5}
                    />
                    <SafeguardActivitySection
                      alias={`safeguards.${selectedIsp}`}
                      mode={mode}
                    />
                  </div>
                )}
              </div>
            </Card>
          </div>
          <div className="block md:flex items-center justify-between px-10 py-5 border-[2px] border-gray-200 rounded-3xl mt-4 bg-white mb-10">
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => navigate('/safeguard-implementation')}
            >
              Kembali
            </Button>
          </div>
        </form>
      )}
    </FormProvider>
  );
};

const SafeguardActivitySection = ({ alias, mode }) => {
  const methods = useFormContext();

  const { fields, remove } = useFieldArray({
    name: `${alias}.activity_list`,
    keyName: 'id',
  });
  return (
    <Fragment>
      {fields?.map((item, index) => (
        <div key={item.id} className="mt-3 flex flex-col gap-3">
          <div className="w-full p-3 bg-primary-600 font-semibold text-white flex justify-between items-center">
            <div>Kegiatan {index + 1}</div>
            {fields.length > 1 && mode !== 'detail' && (
              <div
                className="p-2 bg-error-500 rounded-md cursor-pointer"
                onClick={() => {
                  remove(index);
                }}
              >
                <BiTrash className=" text-white" />
              </div>
            )}
          </div>
          <div className="w-full flex flex-col gap-2">
            <InputForm
              controllerName={`${alias}.activity_list.${index}.activity`}
              className={`w-full text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
              label={'Kegiatan'}
              placeholder={'-'}
              disabled={mode === 'detail'}
            />
            <InputForm
              controllerName={`${alias}.activity_list.${index}.description`}
              className={`w-full text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
              label={'Deskripsi'}
              placeholder={'-'}
              textArea={true}
              rows={4}
              disabled={mode === 'detail'}
            />
            <div className="w-full">
              <label className="label font-semibold text-[14px]">
                <span className={`label-text`}>Dokumen Pendukung</span>
              </label>
              {methods.watch(`${alias}.activity_list.${index}.document`)
                ?.length > 0 && (
                <>
                  {methods
                    .getValues(`${alias}.activity_list.${index}.document`)
                    .map((item) => (
                      <FileDetail
                        file={item}
                        onRemove={() => {
                          methods.setValue(
                            `${alias}.activity_list.${index}.document`,
                            []
                          );
                        }}
                        hideDelete={mode === 'detail'}
                      />
                    ))}
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default ActivityResult;

import React, { useState } from "react";

import {
    Breadcrumbs,
    Button,
    Card,
    CardBody,
    CardHeader,
    Label,
    TitleText,
} from "components";
import { useNavigate } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine, RiCheckboxCircleLine, RiCloseCircleLine, RiFile2Line, RiHistoryLine } from "react-icons/ri";
import ProposalSubmissionDetailFundAttachment from "./fundAttachment";
import ProposalSubmissionDetailGeneralInformation from "./generalInformation";
import ProposalSubmissionDetailIndicator from "./indicator";
import ProposalSubmissionDetailRab from "./rab";
import ProposalSubmissionDetailWorkplan from "./workplan";
import ProposalSubmissionDetailSafeguard from "./safeguard";

const ProposalSubmissionDetail = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);

    const links = [
        {
            label: "Pengajuan Dana",
            path: "/pengajuan-dana-program",
        },
        {
            label: "Detail",
        },
    ];

    const TabList = [
        {
            label: "Lampiran Dokumen",
            id: 1,
        },
        {
            label: "General Informasi",
            id: 2,
        },
        {
            label: "Indikator",
            id: 3,
        },
        {
            label: "Safeguard",
            id: 4,
        },
        {
            label: "Rencana Kerja",
            id: 5,
        },
        {
            label: "RAB",
            id: 6,
        },
    ]
    return (
        <div className="space-y-6">
            <Breadcrumbs items={links} />
            <div className="md:flex block items-center my-3">
                <TitleText className="flex-1">Detail Pengajuan Dana</TitleText>
                <Button
                    className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                    startIcon={<RiHistoryLine />}
                >
                    Histori Pengajuan
                </Button>
            </div>
            <div className="tabs flex">
                {
                    TabList.map((data) => (
                        <a className={`tab flex-1 tab-bordered ${activeTab === data.id ? 'border-b-primary-600 text-primary-600 bg-primary-100 font-bold': ''} hover:text-primary-600 hover:border-primary-700`} onClick={()=> setActiveTab(data.id)}>
                            {data.label}
                        </a>
                    ))
                }
            </div>
            <div>
                {activeTab === 1 && <ProposalSubmissionDetailFundAttachment/>}
                {activeTab === 2 && <ProposalSubmissionDetailGeneralInformation/>}
                {activeTab === 3 && <ProposalSubmissionDetailIndicator/>}
                {activeTab === 4 && <ProposalSubmissionDetailSafeguard/>}
                {activeTab === 5 && <ProposalSubmissionDetailWorkplan/>}
                {activeTab === 6 && <ProposalSubmissionDetailRab/>}
            </div>
        </div>
    );
};

export default ProposalSubmissionDetail;

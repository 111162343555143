import { getErrorMessage } from "helpers";
import { enqueueSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import {
  getAllForm,
  getDetailData,
  updateUnperform,
} from "services/danaProgram/verification";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ToastContext } from "components/atoms/Toast";

const useVerification = ({ search, status, page, limit, id }) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [idTemp, setIdTemp] = useState("");

  const { setShowToast, showToastMessage } = useContext(ToastContext);

  const objQuery = {
    page,
    limit,
  };

  if (search) {
    objQuery["search"] = search;
  }

  if (status) {
    objQuery["status"] = status;
  }

  const {
    data: verificationData,
    isLoading: isLoadingVerificationData,
    isFetching: isFetchingVerificationData,
    refetch: refetchVerificationData,
    isPreviousData,
  } = useQueryVerification(objQuery);

  const {
    data: DetailData,
    isLoading: isLoadingDetailData,
    isFetching: isFetchingDetailData,
    refetch: refetchDetailData,
  } = useQueryDetail(id);

  const useQueryUnperformMutation = useQueryUnperform();

  const onUnperform = async (id) => {
    setConfirmModal(true);
    setIdTemp(id);
  };

  const onProcessUnperform = async () => {
    try {
      const res = await useQueryUnperformMutation.mutateAsync({ id: idTemp });
      if (res?.status === 200) {
        showToastMessage(
          "LEMTARA berhasil dinonaktifkan!",
          "Data yang anda pilih telah berhasil dinonaktifkan."
        );
        setShowToast((prev) => ({ ...prev, success: true }));
      }
    } catch (error) {
      // showToastMessage("Data gagal dihapus!", error?.response?.data?.message || error?.response?.data?.[0]?.message);
      // setShowToast((prev) => ({ ...prev, error: true }));
      console.log(error);
    } finally {
      setConfirmModal(false);
    }
  };

  return {
    verificationData: verificationData?.data?.data,
    refetch: refetchVerificationData,
    DetailData: DetailData?.data?.data,
    refetchDetailData: refetchDetailData,
    isPreviousData,
    onUnperform,
    confirmModal,
    setConfirmModal,
    onProcessUnperform,
  };
};

export const useQueryVerification = (param) => {
  return useQuery({
    queryKey: ["list-verification", param],
    queryFn: () => getAllForm(param),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useQueryDetail = (param) => {
  return useQuery({
    queryKey: ["list-detail", param],
    queryFn: () => getDetailData(param),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useQueryUnperform = () => {
  const queryClient = useQueryClient();
  return useMutation(async (payload) => updateUnperform(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries("list-verification");
      // enqueueSnackbar({
      //   message: "Success",
      //   variant: "success",
      // });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });
};

export default useVerification;

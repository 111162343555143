import config from 'app/config';
import axiosInstance from 'app/interceptors';

const headers = {
  'Content-Type': 'application/json',
  'ngrok-skip-browser-warning': true,
};

export const getList = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/disbursement-application/list`,
    { params, headers }
  );
};

export const getDetail = (id) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/disbursement-application?id=${id}`
  );
};

export const approvePembayaranLemtara = (payload) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/disbursement-application/approve`,
    payload,
    { headers }
  );
};

export const approvalEligiblityPembayaranLemtara = ({ queryKey }) => {
  const [, pembayaranLemtaraId] = queryKey;

  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/disbursement-application/approval-eligiblity`,
    {
      id: pembayaranLemtaraId,
    }
  );
};

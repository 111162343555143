import React, { useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import { BiPencil } from "react-icons/bi";
import { useFormContext } from "react-hook-form";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";

const InstitutionalFoundingGround = ({
  additionalFoundationsFields = [],
  appendadditionalFoundations = () => {},
  removeadditionalFoundations = () => {},
}) => {
  const navigate = useNavigate();

  const {
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();
  const [isEditVerification, setIsEditVerification] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const toogleIsEdit = () => setIsEdit(!isEdit);
  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Landasan Hukum Pendirian Lembaga</div>
          <div className="flex-1 text-right">
            {sessionStorage.getItem("isLemtaraFormDisabled") !== "true" &&
              (isEdit ? (
                <Button
                  type="button"
                  className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                  onClick={toogleIsEdit}
                >
                  <BiPencil />
                  Simpan Perubahan
                </Button>
              ) : (
                <Button
                  type="button"
                  className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                  onClick={toogleIsEdit}
                >
                  <BiPencil />
                  Ubah
                </Button>
              ))}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="col-span-2">
            <InputForm
              controllerName={"legal_foundation.no_nib"}
              className={`py-2 px-4 mt-2 border md:w-1/2 w-full rounded-md flex-1 md:flex-none`}
              label={"Nomor NIB"}
              placeholder={"Nomor NIB"}
              required
              disabled={!isEdit}
            />
          </div>
        </div>
      </CardBody>
      <div className="border-b border-[1px]" />
      <CardBody>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <InputForm
            controllerName={"legal_foundation.no_deed"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Nomor Akta Pendirian/ SK Kemenhukam"}
            placeholder={"Nomor Akta Pendirian/ SK Kemenhukam"}
            required
            disabled={!isEdit}
          />
          <InputForm
            controllerName={`legal_foundation.date_deed`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Tanggal Akta"}
            placeholder={"Tanggal Akta"}
            required
            disabled={!isEdit}
          />
          <InputForm
            controllerName={"legal_foundation.notary_public"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Nama Notaris"}
            placeholder={"Nama Notaris"}
            required
            disabled={!isEdit}
          />
        </div>
      </CardBody>
      <div className="border-b border-[1px]" />
      {additionalFoundationsFields?.length > 0 &&
        additionalFoundationsFields?.map((data, index) => (
          <Card>
            <CardHeader>
              <div className="flex justify-center items-center">
                <div className="flex-1">Nama Data Lainnya {index + 1}</div>
              </div>
            </CardHeader>
            <CardBody>
              <div
                className="grid grid-cols-1 md:grid-cols-3 gap-4"
                key={data.id}
              >
                <div className="col-span-3">
                  <InputForm
                    controllerName={`legal_foundation.additional_foundations.${index}.name`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    label={`Nama Data Lainnya`}
                    placeholder={`Nama Data Lainnya`}
                    required
                    disabled={!isEdit}
                  />
                </div>
                <div className="col-span-3">
                  <InputForm
                    controllerName={`legal_foundation.additional_foundations.${index}.desc`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    label={`Deskripsi`}
                    placeholder={`Deskripsi`}
                    rows="5"
                    textArea
                    disabled={!isEdit}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        ))}
      <CardBody>
        {/* button Verification */}
        <RadioVerificationBadge
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"legal_foundation.status_approval"}
          nameNotes={"legal_foundation.notes_approval"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={
            errors?.legal_foundation?.status_approval
              ? errors?.legal_foundation?.status_approval
              : false
          }
        />
      </CardBody>
    </Card>
  );
};

export default InstitutionalFoundingGround;

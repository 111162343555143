import React, { Fragment, useEffect, useState } from "react";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import * as yup from "yup";

import {
  AtomDatePicker,
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
} from "components";
import CustomDropzoneForm from "./Dropzone";
import { AiOutlinePlus } from "react-icons/ai";
import { FaTrashAlt } from "react-icons/fa";
import CustomSelectForm from "./Select";
import NumberInput from "./NumberInput";
import InputCurrency from "components/molecules/InputCurrency";
import AtomSelect from "components/atoms/Select";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fileUpload } from "services/danaProgram/fileService";
import moment from "moment";
import {
  getActivityReportDetail,
  postActivityReportStep2,
} from "services/danaProgram/activityReportService";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router";
import { toString } from "lodash";
import FileDetail from "components/molecules/FileDetail";
import { useSearchParams } from "react-router-dom";

const validationSchema = yup.object({
  stages: yup.array().of(
    yup.object().shape({
      activity_detail: yup.string().required("Detail Kegiatan wajib diisi"),
      date: yup.string().required("Tanggal Pelaksanaan wajib diisi"),
      total_cost: yup
        .number()
        .required("Nilai Realisasi Biaya wajib diisi")
        .min(1, "Nilai Realisasi Biaya minimal 1")
        .typeError("Nilai Realisasi Biaya harus berupa angka")
        .transform((_, val) => {
          if (val === "") return null;
          else if (
            val &&
            isNaN(Number(val.replace(/[$.]+/g, "").replace(/[$,]+/g, ".")))
          )
            return false;
          return (
            val && Number(val.replace(/[$.]+/g, "").replace(/[$,]+/g, "."))
          );
        }),
    })
  ),
  attachment: yup
    .array()
    .of(yup.mixed())
    .min(1, "Dokumen Bukti Penggunaan Biaya wajib diisi")
    .test(
      "fileType",
      "Dokumen Bukti Penggunaan Biaya harus dalam format .pdf",
      (values) => {
        return (
          values.length &&
          values.every((x) => x !== null) &&
          values.every((x) => x.type === "application/pdf")
        );
      }
    )
    .nullable(),
});

const CostRealization = ({ onNext, onBack, defaultValues, payload }) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  let { id: formId } = useParams();

  let mode = searchParams.get("mode");

  const [detailActOption, setDetailActOption] = useState([]);

  const methods = useForm({
    // resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { control, getValues, setValue } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "stages",
  });

  useEffect(() => {
    setDetailActOption(payload?.main_activity?.detail);
  }, [payload]);

  // For Edit
  const { data: dataDetail, isFetching: isFetchingDetail } = useQuery({
    enabled: !!formId,
    queryKey: ["get-activity-report-detail"],
    queryFn: async () => {
      const res = await getActivityReportDetail({ id: formId });

      return res.data.data;
    },
  });

  useEffect(() => {
    if (dataDetail && !!formId) {
      methods.reset({
        stages: dataDetail?.step_2?.cost_realizations?.map((item) => {
          let startDate = item?.date.split("/");
          return {
            activity_detail: {
              label: item?.detail_activity_name,
              value: item?.detail_activity_id,
            },
            date: new Date(`${startDate[2]}/${startDate[1]}/${startDate[0]}`),
            total_cost: toString(item?.total_cost),
          };
        }),
        attachment: dataDetail?.step_2?.document,
      });
    }
  }, [dataDetail]);
  // End Section For Edit

  const onSubmit = (payload) => {
    postFile.mutate(payload?.attachment);
  };

  const postFile = useMutation({
    mutationKey: ["post-file-safeguard-activity-report-step-2"],
    mutationFn: async (data) => {
      let additionalDocs = data?.map(async (item, index) => {
        if (!item?.fileId) {
          const formData = new FormData();
          formData.append("file", item);
          const res = await fileUpload(formData);
          return {
            fileId: res.data.data.id,
            fileName: res.data.data.name,
            fileSize: res.data.data.size,
            mimeType: res.data.data.mimeType,
            path: res.data.data.key,
          };
        } else {
          return item;
        }
      });
      const additionalResult = await Promise.all(additionalDocs);
      return additionalResult;
    },
    onSuccess: async (res) => {
      let cost_realizations = getValues("stages")?.map((item) => {
        return {
          detail_activity_id: item?.activity_detail?.value,
          detail_activity_name: item?.activity_detail?.label,
          date: moment(item?.date).format("DD/MM/YYYY"),
          total_cost: parseInt(item?.total_cost),
        };
      });

      let total_cost = getValues("stages")
        ?.map((item) => item.total_cost)
        .reduce((prev, curr) => {
          return parseInt(prev) + parseInt(curr);
        }, 0);

      let params = {
        id: payload?._id || dataDetail?._id,
        cost_realizations,
        total_cost,
        document: res,
      };

      postStep2.mutate(params);
    },
  });

  const postStep2 = useMutation({
    mutationKey: ["post-step-2-activity-report"],
    mutationFn: async (payload) => {
      const res = await postActivityReportStep2(payload);
      return res.data.data;
    },
    onSuccess: (res) => {
      enqueueSnackbar("Data berhasil disimpan", { variant: "success" });
      navigate("/activity-report");
    },
  });

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off">
        <div className="space-y-4">
          <Card>
            <CardHeader>
              <div>Tahapan Pelaksanaan Kegiatan & Biaya Kegiatan</div>
            </CardHeader>
            <CardBody>
              <div className="space-y-5">
                {fields.map((field, key) => {
                  return (
                    <Fragment>
                      <div className="space-y-5" key={field.id}>
                        <div className="flex-1">
                          <AtomSelect
                            label={"Detail Kegiatan"}
                            controllerName={`stages.${key}.activity_detail`}
                            options={detailActOption}
                            required
                            disable={mode === "detail"}
                          />
                        </div>
                        <div className="flex-1">
                          <AtomDatePicker
                            className="max-h-[38px] mt-0 w-full rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none focus:ring-0 focus:border-0"
                            controllerName={`stages.${key}.date`}
                            label={"Tanggal Pelaksanaan"}
                            placeholder={"Tanggal Pelaksanaan"}
                            asText={mode === "detail"}
                            format={"dd/MM/yyyy"}
                          />
                        </div>
                        <div className="flex-1 w-full">
                          <InputCurrency
                            controllerName={`stages.${key}.total_cost`}
                            label="Nilai Realisasi Biaya (Rp)"
                            className={`py-2 border border-slate-300 w-full rounded-md flex-1`}
                            asText={mode === "detail"}
                          />
                        </div>
                      </div>
                      <hr className="my-5 mx-[-24px]" />
                      {mode !== "detail" && (
                        <div className="flex space-x-4 justify-end">
                          {((key > 0 && key === fields.length - 1) ||
                            (key === 0 && fields.length === 1)) && (
                            <Button
                              size="md"
                              type="button"
                              startIcon={
                                <AiOutlinePlus className="text-gray-700" />
                              }
                              className="text-gray-700 border-gray-300 bg-white hover:bg-white hover:border-gray-300"
                              onClick={() => {
                                if (fields.length < 5)
                                  append({
                                    activity_detail: "",
                                    date: "",
                                    total_cost: 0,
                                  });
                              }}
                            >
                              Tahapan Kegiatan
                            </Button>
                          )}
                          {fields.length > 1 && (
                            <Button
                              size="md"
                              type="button"
                              startIcon={
                                <FaTrashAlt className="text-error-700" />
                              }
                              className="text-error-700 border-error-300 bg-white hover:bg-white hover:border-error-300"
                              onClick={() => {
                                remove(key);
                              }}
                            >
                              Hapus Data
                            </Button>
                          )}
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <div>Dokumen Pendukung</div>
            </CardHeader>
            <CardBody>
              {methods?.watch("attachment")?.length > 0 ? (
                <>
                  {methods?.getValues("attachment").map((item, idx) => (
                    <FileDetail
                      key={idx}
                      file={item}
                      onRemove={() => {
                        setValue("attachment", []);
                      }}
                      hideDelete={mode === "detail"}
                    />
                  ))}
                </>
              ) : (
                <div className="w-full">
                  <label className="label font-semibold text-[14px]">
                    <span className={`label-text`}>
                      Upload Dokumen Bukti Penggunaan Biaya
                      <span className="text-[#F04438] text-[14px]">*</span>
                    </span>
                  </label>
                  <CustomDropzoneForm
                    name="attachment"
                    acceptedFile={{ "application/pdf": [] }}
                    informationText="PDF (max. 10MB)"
                    maxSize={10}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </div>
        <div className="block md:flex items-center justify-between px-10 py-5 border-[2px] border-gray-200 rounded-3xl mt-4 bg-white mb-10">
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={onBack}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
          {mode !== "detail" && (
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={methods.handleSubmit(onSubmit)}
            >
              Simpan
              <RiArrowRightLine className="text-white" />
            </Button>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default CostRealization;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Button,
  InputForm,
} from "components";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import useInvestmentPlan from "../hooks/useInvestmentPlan";
import { enqueueSnackbar } from "notistack";
import CustomDropzoneForm from "pages/ActivityReport/form/Dropzone";
import FileDetail from "components/molecules/FileDetail";
import { useMutation } from "@tanstack/react-query";
import { fileUpload } from "services/danaProgram/fileService";
const FormPenetupanProyek = () => {
  const location = useLocation();
  const isDetail = location.pathname.includes("detail");
  const InvestmentPlanId = location.pathname.includes("detail")
    ? location.pathname.replace("/investment-plan/project-closure/detail/", "")
    : location.pathname.replace("/investment-plan/project-closure/", "");
  const navigate = useNavigate();

  const params = { id: InvestmentPlanId };

  const {
    DetailInvetmentPlan,
    refetchDetailInvetmentPlan,
    submitProjectClosure,
  } = useInvestmentPlan({ params });

  useEffect(() => {
    refetchDetailInvetmentPlan();
  }, []);

  const validationSchema = yup.object().shape({
    feedback: yup
      .string()
      .required("Hal yang sudah Berjalan dengan baik wajib diisi"),
    evaluation: yup
      .string()
      .required(
        "Hal yang belum Berjalan dengan baik dan perlu diperbaiki wajib diisi"
      ),
    question: yup.string().required("Pertanyaan wajib diisi"),
    idea: yup.string().required("Ide wajib diisi"),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const {
    formState: { isValid },
  } = methods;

  const links = [
    {
      label: "Investment Plan",
      path: `/investment-plan/`,
    },
    {
      label: "Penutupan Proyek",
    },
  ];

  const postFile = useMutation({
    mutationKey: ["post-file-safeguard-activity-report"],
    mutationFn: async (data) => {
      if (Object.hasOwn(data?.additionalDocs, "additionalResult"))
        return data?.additionalDocs;
      let additionalDocs = data?.additionalDocs?.map(async (item, index) => {
        if (!item?.fileId) {
          const formData = new FormData();
          formData.append("file", item);
          const res = await fileUpload(formData);
          return {
            fileId: res.data.data.id,
            fileName: res.data.data.name,
            fileSize: res.data.data.size,
            mimeType: res.data.data.mimeType,
            path: res.data.data.key,
          };
        } else {
          return item;
        }
      });
      const additionalResult = await Promise.all(additionalDocs);
      return { additionalResult };
    },
    onSuccess: async (res) => {
      methods.setValue("attachment", res);
    },
  });

  const onSubmit = async () => {
    let values = methods.getValues();

    try {
      const result = await postFile.mutateAsync({
        additionalDocs: values?.attachment,
      });

      methods.setValue("attachment", result);

      if (!isValid) return null;

      let params = {
        id: InvestmentPlanId,
        rating: 5,
        closing_data: {
          feedback: values.feedback,
          evaluation: values.evaluation,
          question: values.question,
          idea: values.idea,
        },
        document: values?.attachment?.additionalResult,
      };
      submitProjectClosure.mutate(params, {
        onSuccess: () => {
          enqueueSnackbar("Data saved successfully!", {
            variant: "success",
            preventDuplicate: false,
          });
          navigate("/investment-plan/");
        },
      });
    } catch (error) {
      console.error("File upload failed", error);
    }
  };

  const handleClick = async () => {
    await methods.trigger();
    let values = methods.getValues();
    await postFile.mutateAsync({
      additionalDocs: values?.attachment,
    });
    onSubmit();
  };

  return (
    <div>
      <div className="bg-transparent flex items-center flex-wrap flex-1 mb-[24px]">
        <Breadcrumbs items={links} />
      </div>
      <FormProvider {...methods}>
        <div className="flex flex-col gap-4 mt-3">
          <Card>
            <CardHeader>
              <div>{isDetail && "Detail"} Penutupan Proyek</div>
            </CardHeader>
            <CardBody>
              <div className="flex flex-col gap-4">
                <InputForm
                  controllerName="project_name"
                  className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Nama Proyek"}
                  disabled={true}
                  customValue={
                    DetailInvetmentPlan
                      ? DetailInvetmentPlan?.step_1?.project_name
                      : null
                  }
                />
                <InputForm
                  controllerName="funding_sources"
                  className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Sumber Pendanaan"}
                  disabled={true}
                  customValue={
                    DetailInvetmentPlan
                      ? DetailInvetmentPlan?.fund_source
                      : null
                  }
                />
              </div>

              <p className="text-lg font-semibold my-5">Feedback & Evaluasi</p>
              <div className="flex flex-col gap-4">
                <InputForm
                  controllerName="feedback"
                  className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Hal yang sudah Berjalan dengan baik"}
                  textArea
                  required
                  disabled={isDetail}
                  customValue={
                    DetailInvetmentPlan &&
                    DetailInvetmentPlan?.closing_project_data &&
                    DetailInvetmentPlan?.closing_project_data?.feedback
                      ? DetailInvetmentPlan?.closing_project_data?.feedback
                      : null
                  }
                />
                <InputForm
                  controllerName="evaluation"
                  className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={
                    "Hal yang belum Berjalan dengan baik dan perlu diperbaiki"
                  }
                  textArea
                  required
                  disabled={isDetail}
                  customValue={
                    DetailInvetmentPlan &&
                    DetailInvetmentPlan?.closing_project_data &&
                    DetailInvetmentPlan?.closing_project_data?.evaluation
                      ? DetailInvetmentPlan?.closing_project_data?.evaluation
                      : null
                  }
                />
                <InputForm
                  controllerName="question"
                  className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Pertanyaan"}
                  textArea
                  required
                  disabled={isDetail}
                  customValue={
                    DetailInvetmentPlan &&
                    DetailInvetmentPlan?.closing_project_data &&
                    DetailInvetmentPlan?.closing_project_data?.question
                      ? DetailInvetmentPlan?.closing_project_data?.question
                      : null
                  }
                />
                <InputForm
                  controllerName="idea"
                  className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Ide"}
                  textArea
                  required
                  disabled={isDetail}
                  customValue={DetailInvetmentPlan?.closing_project_data?.idea}
                />
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Dokumen Pendukung</CardHeader>
            <CardBody>
              <div className="w-full">
                <label className="label font-semibold text-[14px]">
                  <span className={`label-text`}>
                    {!isDetail && "Upload"} Dokumen Pendukung{" "}
                    {!isDetail && "(multiple)"}
                    <span
                      className={`text-[#F04438] text-[14px] ${
                        isDetail && "hidden"
                      }`}
                    >
                      *
                    </span>
                  </span>
                </label>
                {DetailInvetmentPlan?.closing_project_document &&
                DetailInvetmentPlan?.closing_project_document?.length > 0 ? (
                  <>
                    {DetailInvetmentPlan?.closing_project_document.map(
                      (item, idx) => (
                        <FileDetail
                          key={idx}
                          file={item}
                          onRemove={() => {
                            methods.setValue("attachment", []);
                          }}
                          hideDelete={isDetail}
                        />
                      )
                    )}
                  </>
                ) : (
                  ""
                )}
                <div className={`${isDetail && "hidden"}`}>
                  <CustomDropzoneForm
                    name="attachment"
                    controllerName="attachment"
                    acceptedFile={{ "application/pdf": [] }}
                    informationText="PDF (max. 10MB)"
                    maxSize={10}
                    maxFiles={10}
                    multiple={true}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate("/investment-plan/")}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
          <Button
            type="button"
            className={`${
              isDetail && "hidden"
            } text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-40`}
            onClick={handleClick}
          >
            Submit
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default FormPenetupanProyek;

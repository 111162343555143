import { createContext, useContext, useEffect, useState } from "react";

const FinancialProfileContext = createContext();

export const FinancialProfileProvider = ({ children }) => {
  const [FinancialProfileState, setFinancialProfileState] = useState({
    step_1: null,
    step_2: null,
    step_3: null,
    step_4: null,
    document_support: null,
  });

  const value = { FinancialProfileState, setFinancialProfileState };

  useEffect(() => {
    let temp = JSON.stringify(FinancialProfileState);
    console.log("temp state", FinancialProfileState);
    sessionStorage.setItem("FinancialProfileContext", temp);
  }, [FinancialProfileState]);

  return (
    <FinancialProfileContext.Provider value={value}>
      {children}
    </FinancialProfileContext.Provider>
  );
};

export const useFinancialProfileContext = () =>
  useContext(FinancialProfileContext);

import { Button, Card, CardBody, CardForm, CardHeader, InputForm, TitleText } from "components"
import AtomSelect from "components/atoms/Select"
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge"
import QuestionBadge from "components/molecules/Badge/QuestionBadge"
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge"
import { useState } from "react"
import { useFormContext } from "react-hook-form"
import { BiPencil } from "react-icons/bi"
import { RiFile2Line } from "react-icons/ri"
import { useNavigate } from "react-router"

const PeralatanPerlengkapanChild = ({
  openModal = () => console.log(`open`),
  isEdit = false,
  toogleIsEdit = () => {},
  fieldsList = [],
  appendsList = () => {},
  removeList = () => {},
}) => {
  const navigate = useNavigate()

  const { 
    watch, 
    getValues, 
    control,
    formState: { errors, isValid } 
  } = useFormContext();

  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEditVerification = () => setIsEditVerification(!isEditVerification)

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Peralatan/ Perlengkapan</div>
          <div className="flex-1 text-right">
            {sessionStorage.getItem(`isLemtaraFormDisabled`) !== `true` && (isEdit ? (
              <Button
              type="button"
              className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
              onClick={toogleIsEdit}
            >
              <BiPencil/>
              Simpan Perubahan
            </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil/>
                Ubah
              </Button>
            ))}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col">
          <QuestionBadge 
            text="Apa Saja Fasilitas Fisik/ infrastruktur yang Dimiliki Oleh Lembaga/ Instansi/ Perusahaan Anda untuk Mengelola dan Menjalankan Program?"
          />
          {fieldsList?.length > 0 && (
            fieldsList?.map((e, index) => {
              return (
                <div className="mt-[20px]" key={e.id}>
                  <CardForm label={`Alat ${index + 1}`} buttonCollapse={true}>
                    <div className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <InputForm
                          controllerName={`step_2.equipment.list.${index}.name`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={'Nama Alat'}
                          placeholder={'Nama Alat'}
                          disabled={!isEdit}
                        />
                        <AtomSelect
                          controllerName={`step_2.equipment.list.${index}.category`}
                          options={[
                            { value: "1", label: 'Kategori 1' },
                            { value: "2", label: 'Kategori 2' },
                          ]} className="basic-single mt-2"
                          label="Status Kepegawaian"
                          disable={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_2.equipment.list.${index}.total`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={'Jumlah'}
                          placeholder={'Jumlah'}
                          disabled={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_2.equipment.list.${index}.capacity`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={'Kapasitas Saat Ini'}
                          placeholder={'Kapasitas Saat Ini'}
                          disabled={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_2.equipment.list.${index}.mark_and_type`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={'Merk & Tipe'}
                          placeholder={'Merk & Tipe'}
                          disabled={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_2.equipment.list.${index}.production_year`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={'Tahun Pembuatan'}
                          placeholder={'Tahun Pembuatan'}
                          disabled={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_2.equipment.list.${index}.condition`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={'Kondisi'}
                          placeholder={'Kondisi'}
                        disabled={!isEdit}
                        />
                        <InputForm
                          controllerName={`step_2.equipment.list.${index}.location`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={'Lokasi Alat'}
                          placeholder={'Lokasi Alat'}
                        disabled={!isEdit}
                        />
                      </div>
                    </div>
                  </CardForm>
                </div>
              )
            })
          )}
          {/* button Verification */}
          <RadioVerificationBadge 
            className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
            name={"step_2.status_approval_equipment"}
            nameNotes={"step2.notes_approval_equipment"}
            isEditVerification={isEditVerification}
            toogleIsEditVerification={toogleIsEditVerification}
            errors={errors?.step_2?.status_approval_equipment ? errors?.step_2?.status_approval_equipment : false}
          />
        </div>
      </CardBody>
    </Card>
  )
}

export default PeralatanPerlengkapanChild;
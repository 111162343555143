import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { RiArrowLeftLine, RiFile2Line } from 'react-icons/ri';
import moment from 'moment';
import 'moment/locale/id';

import {
  Card,
  CardBody,
  CardHeader,
  Button,
  CardForm,
  Table,
} from 'components';
import { getFile } from 'services/danaProgram/fileService';

const Child = ({ title = {}, data }) => {
  // hooks
  const navigate = useNavigate();
  const { setValue } = useFormContext();

  // constants
  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        title: 'Indikator',
        dataIndex: 'name',
        className: 'overflow-hidden ',
        columnClassName: '',
        render: (value) => {
          return (
            <div className="w-full overflow-hidden text-ellipsis ">{value}</div>
          );
        },
      },
      {
        id: 'contribution',
        title: 'Kontribusi Capaian',
        dataIndex: 'contribution',
        className: 'overflow-hidden text-ellipsis',
        sortable: true,
        fixed: 'center',
        columnClassName: 'text-center overflow-hidden',
        render: (value) => (
          <div
            className="overflow-hidden"
            style={{ textAlign: '-webkit-center' }}
          >
            {value}%
          </div>
        ),
      },
    ];
  }, []);

  const calculateProgress = (data) => {
    let totalContribution = 0;
    let contributionCount = 0;

    if (data.output) {
      data?.output.forEach((item) => {
        item?.indicator_list.forEach((indicator) => {
          totalContribution += parseFloat(indicator.contribution);
          contributionCount++;
        });
      });

      const averageContribution = totalContribution / contributionCount;

      return Math.round(averageContribution);
    }
  };

  // requests
  const onPreviewFile = useMutation({
    mutationKey: [`get-detail-file`],
    mutationFn: async (id) => {
      const res = await getFile(id);
      return res.data.data;
    },
    onSuccess: (res) => {
      window.open(res.url, `_blank`);
    },
  });

  // cycles
  useEffect(() => {
    if (data?.note) {
      setValue('note', data?.note);
    }
  }, [data?.note]);

  return (
    <div>
      <div className="flex flex-col gap-4 mt-3">
        <Card>
          <CardHeader>
            <div>{title}</div>
          </CardHeader>
          <CardBody>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-6">
                <div className="flex gap-4 flex-row justify-between text-normal">
                  <div className="flex flex-col gap-1">
                    <p className="font-semibold">Nama Project</p>
                    <p className="font-normal">{data?.project_name}</p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="font-semibold">Tanggal Laporan</p>
                    <p className="font-normal">
                      {moment(data?.created_at).format('dddd, DD MMMM YYYY')}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="font-semibold">Pelapor</p>
                    <p className="font-normal">{data?.submitter}</p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="font-semibold">Capaian Rata-rata</p>
                    <div className="bg-green-300 px-2 w-fit rounded">
                      <p className="font-normal">{calculateProgress(data)}%</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-4 flex-col">
                <p className="font-semibold">Dokumen Pendukung</p>
                <div className="grid grid-cols-3 gap-4">
                  {data?.document?.map((doc, i) => (
                    <div
                      key={'project-resource-data-doc-' + i}
                      className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl"
                    >
                      <div className="flex gap-4">
                        <div>
                          <div className="bg-[#EEFFF4] rounded-full p-4">
                            <RiFile2Line className="text-primary-600" />
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="font-medium">{doc?.fileName}</span>
                          <span>
                            {`${parseFloat(doc?.fileSize / 1000, 2)} KB`}
                          </span>
                          <span
                            onClick={() => onPreviewFile.mutate(doc?.fileId)}
                            className="font-semibold text-primary-600 cursor-pointer"
                          >
                            Lihat Dokumen
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex-1">
                <p className="text-lg font-semibold mb-2">Capaian Output</p>
                <div className="flex flex-col gap-6">
                  {}
                  {data?.output?.length > 0 &&
                    data?.output.map((val, index) => {
                      return (
                        <CardForm
                          label={`Output ${index + 1}`}
                          buttonCollapse={true}
                        >
                          <div className="flex flex-col gap-6">
                            <Table
                              bordered
                              stripped
                              layout="fixed"
                              className="mb-4"
                              columns={columns}
                              dataSource={
                                data?.output[index]?.indicator_list?.length > 0
                                  ? data?.output[index]?.indicator_list
                                  : []
                              }
                            />
                          </div>
                        </CardForm>
                      );
                    })}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>

      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate('/progress-report/')}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Child;

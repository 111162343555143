import React, { useState, Fragment, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { BiTrash } from 'react-icons/bi';
import { AiOutlinePlus } from 'react-icons/ai';
import { useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';

import {
  Breadcrumbs,
  TitleText,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  Button,
  AtomDatePicker,
} from 'components';
import { getIspList } from 'services/danaProgram/masterDataService';

const CommitmentSafeguardDetail = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  let mode = searchParams.get('mode');
  const links = [
    {
      label: 'Daftar Komitmen Safeguard',
      path: '/commitment-safeguard',
    },
    {
      label: mode === 'detail' ? 'Detail' : 'Komitmen Safeguard',
    },
  ];

  // datas
  const [isp, setIsp] = useState();

  // forms
  const methods = useForm();
  const { control, setValue, getValues } = methods;

  const { fields, append, remove } = useFieldArray({
    name: `commitment`,
    keyName: 'id',
    control,
  });

  const {
    fields: activityList,
    append: add,
    remove: slice,
  } = useFieldArray({
    name: `activity_list`,
    keyName: 'id',
    control,
  });

  // requests
  const { data: ispList, isFetching: isFetchingIspList } = useQuery({
    queryKey: ['get-isp-list'],
    queryFn: async () => {
      const res = await getIspList();

      return res.data.data;
    },
  });

  // cycles
  useEffect(() => {
    if (ispList) {
      let sorted = sortBy(ispList, (el) => {
        return parseInt(el?.nama.split(' ')[1]);
      });

      const filteredIsp = sorted.filter((item) =>
        ['ISP 1', 'ISP 3', 'ISP 10'].includes(item.nama)
      );

      setIsp(filteredIsp);
    }
  }, [ispList]);

  useEffect(() => {
    setValue('commitment', [
      {
        activity_list: [
          {
            name: '',
            date: '',
            description: '',
            party: '',
          },
        ],
      },
    ]);
  }, []);

  return (
    <FormProvider {...methods}>
      <div className="space-y-6">
        <Breadcrumbs items={links} />
        <div className="md:flex block items-center my-3">
          <TitleText className="flex-1 font-semibold text-lg">
            Komitmen Full Proposal
          </TitleText>
        </div>

        {isp &&
          isp.map((item, index) => (
            <Card>
              <CardHeader>
                <div>{`${item.nama} - ${item.deskripsi}`}</div>
              </CardHeader>
              <CardBody>
                {fields.map((item, commitmentIndex) => (
                  <div className="space-y-5 mb-4">
                    <CardForm
                      label={`Komitmen ${commitmentIndex + 1}`}
                      buttonCollapse={true}
                    >
                      {fields.length > 1 && mode !== 'detail' && (
                        <div
                          className="p-2 bg-error-500 rounded-md cursor-pointer w-fit ml-auto"
                          onClick={() => {
                            remove(commitmentIndex);
                          }}
                        >
                          <BiTrash className=" text-white" />
                        </div>
                      )}
                      <InputForm
                        controllerName={`commitment[${commitmentIndex}].text`}
                        className={`w-full text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
                        label={'Komitmen'}
                        placeholder={'-'}
                        textArea={true}
                        rows={4}
                        disabled={mode === 'detail'}
                      />

                      <Fragment>
                        {activityList.map((item, activityIndex) => (
                          <div
                            key={item.id}
                            className="mt-3 flex flex-col gap-3"
                          >
                            <div className="w-full p-3 bg-primary-600 font-semibold text-white flex justify-between items-center">
                              <div>Kegiatan {activityIndex + 1}</div>
                              {activityList.length > 1 && mode !== 'detail' && (
                                <div
                                  className="p-2 bg-error-500 rounded-md cursor-pointer"
                                  onClick={() => {
                                    slice(activityIndex);
                                  }}
                                >
                                  <BiTrash className=" text-white" />
                                </div>
                              )}
                            </div>
                            <div className="w-full flex flex-col gap-2">
                              <InputForm
                                controllerName={`commitment.activity_list[${activityIndex}].name`}
                                className={`w-full text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
                                label={'Kegiatan'}
                                placeholder={'-'}
                                disabled={mode === 'detail'}
                              />
                              <div className="flex-1 flex flex-col">
                                <p className="text-sm font-semibold mb-0">
                                  Tata Waktu Pelaksanaan
                                </p>
                                <AtomDatePicker
                                  className="max-h-[38px] mt-0 w-full rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none focus:ring-0 focus:border-0"
                                  controllerName={`commitment.activity_list[${activityIndex}].date`}
                                  label={null}
                                  placeholder="Select a date"
                                  asText={false}
                                  format={'dd/MM/yyyy'}
                                  isRangeDatePicker={true}
                                />
                              </div>

                              <InputForm
                                controllerName={`commitment.activity_list[${activityIndex}].description`}
                                className={`w-full text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
                                label={'Deskripsi'}
                                placeholder={'-'}
                                textArea={true}
                                rows={4}
                                disabled={mode === 'detail'}
                              />

                              <InputForm
                                controllerName={`commitment.activity_list[${activityIndex}].party`}
                                className={`w-full text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
                                label={'Pihak/Entitas yang Bertanggungjawab'}
                                placeholder={'-'}
                                disabled={mode === 'detail'}
                              />
                            </div>
                          </div>
                        ))}
                      </Fragment>
                      <div className="w-full flex justify-end mt-3">
                        {mode !== 'detail' && (
                          <Button
                            className="border-primary-600 text-primary-600 bg-white hover:bg-primary-600 hover:text-white"
                            startIcon={<AiOutlinePlus />}
                            onClick={() => {
                              add({
                                activity: '',
                                description: '',
                              });
                            }}
                          >
                            Tambah Kegiatan
                          </Button>
                        )}
                      </div>
                    </CardForm>
                  </div>
                ))}
                <div className="w-full flex justify-center mt-3">
                  {mode !== 'detail' && (
                    <Button
                      className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                      startIcon={<AiOutlinePlus className="text-white" />}
                      onClick={() => {
                        append({
                          activity_list: [
                            {
                              activity: '',
                              description: '',
                            },
                          ],
                        });
                      }}
                    >
                      Tambah Komitmen
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          ))}
      </div>
      <div className="block md:flex items-center justify-between px-10 py-5 border-[2px] border-gray-200 rounded-3xl mt-4 bg-white mb-10">
        <Button
          type="button"
          className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
          onClick={() => navigate('/commitment-safeguard')}
        >
          Batal
        </Button>
        <Button
          type="button"
          className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          onClick={() => {}}
        >
          Submit
        </Button>
      </div>
    </FormProvider>
  );
};

export default CommitmentSafeguardDetail;

import { useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import { useSnackbar } from "notistack";
import { getList } from "services/danaProgram/procurementsService";

const status = ["new", "done", "waiting"];
const randomDate = (start, end) => {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
};
const randomNumber = (max, min) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
const DUMMY_DATA = Array.from(Array(25)).map((x, idx) => ({
  deadline_date: randomDate(new Date(2022, 0, 1), new Date()),
  procurement_date: randomDate(new Date(2022, 0, 1), new Date()),
  qty: randomNumber(100, 1),
  project: `Proyek ${Math.floor(Math.random() * (25 - 1 + 1) + 1)}`,
  status: status[Math.floor(Math.random() * status.length)]
}));

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const useProcurements = (param) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: procurementData,
    isLoading: isLoadingProcurementData,
    isFetching: isFetchingProcurementData,
    refetch: refetchProcurementData
  } = useQuery({
    queryKey: ['list-procurement', param],
    queryFn: () => getList(param),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error"
      })
    },
    refetchOnWindowFocus: false,
    enabled: false
  });

  return {
    procurementData: procurementData?.data?.data?.data || [],
    paginationProcurementData: {
      lastPage: procurementData?.data?.data?.lastPage,
      page: procurementData?.data?.data?.page,
      perPage: procurementData?.data?.data?.perPage,
      total: procurementData?.data?.data?.total
    },
    isLoadingProcurementData,
    isFetchingProcurementData,
    refetch: refetchProcurementData
  }
};

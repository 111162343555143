import { CardForm, InputForm, InputFormRadio } from "components";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import moment from "moment";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { RiInformationLine } from "react-icons/ri";
export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const PPH = ({ tools, datas, isEdit = false }) => {
  const { watch, getValues } = useFormContext();

  return (
    <div className="flex flex-col mt-4">
      <QuestionBadge text="Apakah Lembaga/ Institusi/ Perusahaan Anda Melakukan Pemotongan PPH 21 pada Penghasilan Karyawan Anda?" />
      <InputFormRadio
        controllerName={`is_income_cut`}
        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
        values={[
          { value: true, label: "Ya" },
          { value: false, label: "Tidak" },
        ]}
        disabled={!isEdit}
      />

      {getValues("is_income_cut") === true && (
        <>
          <div className="w-full mt-4 flex gap-2 items-center border border-[color:var(--blue-light-300,#0086C9)] backdrop-blur-2xl rounded-xl border-solid bg-[#F5FBFF] p-4  rounded-lg text-[#026AA2] ">
            <RiInformationLine />
            Laporan PPh 21 selama 3 bulan terakhir
          </div>
          {tools[1]?.pph_report?.map((field, index) => {
            return (
              <>
                <div className="mt-[20px]" key={field.id}>
                  <CardForm
                    label={`Bulan ${field?.month} ${field?.year}`}
                    buttonCollapse={true}
                  >
                    <div className="space-y-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <InputForm
                          controllerName={`tools.${1}.pph_report.${index}.no_report`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"Nomor Laporan"}
                          placeholder={"Nomor Laporan"}
                          disabled={!isEdit}
                        />

                        <InputForm
                          controllerName={`tools.${1}.pph_report.${index}.no_description`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"Deskripsi"}
                          placeholder={"Deskripsi"}
                          disabled={!isEdit}
                          textArea={true}
                        />
                      </div>
                    </div>
                  </CardForm>
                </div>
              </>
            );
          })}
        </>
      )}

      <div className="my-4 w-full border-t border-black-700"></div>

      {tools[1]?.tax_payment?.map((field, index) => {
        return (
          <>
            <div className="mt-[20px]">
              <CardForm label={`Tahun ${field.year}`} buttonCollapse={true}>
                <QuestionBadge text="Apakah Nihil?" />
                <InputFormRadio
                  controllerName={`tools.${1}.tax_payment.${index}.tax_type`}
                  className={`py-2 px-4 mt-[-20px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  values={[
                    { value: true, label: "Ya" },
                    { value: false, label: "Tidak" },
                  ]}
                  disabled={!isEdit}
                />
                {getValues(`tools.${1}.tax_payment.${index}.tax_type`) ===
                  true && (
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <InputForm
                        controllerName={`tools.${1}.tax_payment.${index}.tax_type`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                        label={"Jenis Pajak"}
                        placeholder={"Jenis Pajak"}
                        disabled={!isEdit}
                      />

                      <InputForm
                        controllerName={`tools.${1}.tax_payment.${index}.no_payment_evidence`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                        label={"Nomor Bukti Pelunasan"}
                        placeholder={"Nomor Bukti Pelunasan"}
                        disabled={!isEdit}
                      />
                    </div>
                    <InputForm
                      controllerName={`tools.${1}.tax_payment.${index}.tax_payment_evidence_date`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Tanggal Bukti Pelunasan Pajak"}
                      placeholder={"Tanggal Bukti Pelunasan Pajak"}
                      disabled={!isEdit}
                    />
                  </div>
                )}
              </CardForm>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default PPH;

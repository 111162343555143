import { useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import { useSnackbar } from "notistack"
import { useEffect } from "react";
import { getList } from "services/danaProgram/pembayaranResourceService";
import { queryKeys } from ".";

export const useApprovalPembayaranResources = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    isError,
    error
  } = useQuery({
    queryKey: queryKeys.all,
    queryFn: async () => {
      const res = await getList(params)

      return res?.data?.data || []
    },
    retry: 1
  })

  useEffect(() => {
    if(isError) {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: 'error'
      })
    }
  }, [isError]);

  return {
    data: data?.data,
    pagination: {
      lastPage: data?.lastPage,
      page: data?.page,
      perPage: data?.perPage,
      total: data?.total
    },
    isLoading,
    isFetching,
    refetch
  }
}
import { Breadcrumbs } from "components";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import DokumenPendukung from "./DokumenPendukung";
import PengalamanPengelolaanProgram from "./PengalamanPengelolaanProgram";
import { useMutation } from "@tanstack/react-query";
import {
  getFinancialProfile,
  getProgramExperience,
  programExperienceSubmit,
} from "services/danaProgram/danaProgramService";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import {
  ProgramExperienceProvider,
  useProgramExperienceContext,
} from "./ProgramExperienceState";

const PengalamanProgram = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const links = [
    {
      label: "Daftar Lemtara",
      path: "/verification",
    },
    {
      label: "Detail",
      path: "/verification/detail",
    },
    {
      label: "Pengalaman Program",
    },
  ];

  const TabList = [
    {
      label: "Dokumen Pendukung",
      id: 1,
    },
    {
      label: "Pengalaman Pengelolaan Program",
      id: 2,
    },
  ];

  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState();
  const [formState, setFormState] = useState();

  let user_id = JSON.parse(
    sessionStorage.getItem("idData")
  )?.program_experience;

  const mutation = useMutation({
    mutationFn: async () => {
      try {
        const promise = await getProgramExperience(user_id);
        setData(promise.data.data);
        setIsFetching(false);
      } catch (error) {
        console.error("Error:", error);
        enqueueSnackbar(error.response.data.message, {
          autoHideDuration: 2500,
          variant: "error",
        });
      }
    },
  });

  useEffect(() => {
    mutation.mutate();
  }, []);

  const submitData = useMutation({
    mutationFn: (params) => {
      return programExperienceSubmit(params);
    },
    onSuccess: () => {
      sessionStorage.removeItem("ProgramExperienceContext");
      enqueueSnackbar({
        message: "Success",
        variant: "success",
      });
      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });

  const consumeAPI = (arr) => {
    let params;

    let ProgramExperienceState = JSON.parse(
      sessionStorage.getItem("ProgramExperienceContext")
    );

    params = {
      user_id: data?.user_id,
      status: "VERIFIED",
      experience_programme_document: data?.experience_programme_document,
      programme: arr.experience,
      status_approval_programme: arr?.status_approval_programme,
      notes_approval_programme: arr?.notes_approval_programme,
      status_approval_document: formState?.status_approval_document,
      notes_approval_document: formState?.notes_approval_document,
    };

    submitData.mutateAsync(params);
  };

  return (
    <ProgramExperienceProvider>
      <div className="space-y-6">
        <Breadcrumbs items={links} />
        <div className="tabs">
          {TabList.map((data) => (
            <a
              className={`tab text-[14px] px-8 tab-bordered ${
                activeTab === data.id
                  ? "border-b-primary-600 text-primary-600 bg-primary-100 font-bold"
                  : ""
              } hover:text-primary-600 hover:border-primary-700`}
              onClick={() => setActiveTab(data.id)}
            >
              {data.label}
            </a>
          ))}
        </div>
        <div>
          {activeTab === 1 && (
            <DokumenPendukung
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              data={data}
              setFormState={setFormState}
            />
          )}
          {activeTab === 2 && (
            <PengalamanPengelolaanProgram
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              data={data}
              submitData={consumeAPI}
            />
          )}
        </div>
      </div>
    </ProgramExperienceProvider>
  );
};

export default PengalamanProgram;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import * as yup from "yup";

import { Breadcrumbs } from "components";
import { getSpecificTimeWorkPlan } from "services/danaProgram/specificTimeWorkPlanService";

import TimeWorkPlanForm from "./form";

const Form = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [edit, setEdit] = useState(true);

  const { data: timeWorkPlanData, refetch: refetchTimeWorkPlanData } = useQuery(
    ["list-timeWorkPlan"],
    () => getSpecificTimeWorkPlan({ page: 1, limit: 10 }),
    {
      onError: (error) => {
        // enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  useEffect(() => {
    refetchTimeWorkPlanData();
    if (currentPath.includes("detail")) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  }, []);

  useEffect(() => {
    refetchTimeWorkPlanData();
  }, []);

  const validationSchema = yup.object().shape({
    // work_plan_name: yup.mixed().required("wajib"),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const links = [
    {
      label: `${
        currentPath.includes("approval")
          ? "Pengajuan Rencana Penarikan Dana"
          : "Rencana Penarikan Dana"
      }`,
      path: `${
        currentPath.includes("approval")
          ? "/approval-rencana-penarikan-dana/"
          : "/rencana-penarikan-dana"
      }`,
    },
    {
      label: `${
        currentPath.includes("add")
          ? currentPath.includes("verif")
            ? "Approval Rencana Penarikan Dana"
            : "Tambah Rencana Penarikan Dana"
          : "Detail Rencana Penarikan Dana"
      }`,
    },
  ];

  return (
    <div>
      {/* start Breadcrumb */}
      <div className="bg-transparent flex items-center flex-wrap flex-1 mb-[24px]">
        <Breadcrumbs items={links} />
      </div>
      <FormProvider {...methods}>
        <TimeWorkPlanForm
          permission={edit}
          title={
            currentPath.includes("add")
              ? "Tambah Rencana Penarikan Dana"
              : "Detail Rencana Penarikan Dana"
          }
          timeWorkPlanData={timeWorkPlanData?.data?.data?.data}
        />
      </FormProvider>
    </div>
  );
};

export default Form;

import config from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

export const getTorList = (params) => {
  return axiosInstance.get(
    `${config.DAPROG_API_URL}/tor/list?limit=${params?.limit}&page=${
      params?.page
    }${params?.searchKey !== undefined ? `&search=${params?.searchKey}` : ""}${
      params?.startDate !== undefined && params?.startDate !== null
        ? `&start_date=${params?.startDate} `
        : ""
    }${
      params?.endDate !== undefined && params?.endDate !== null
        ? `&end_date=${params?.endDate} `
        : ""
    }${params?.orderBy !== undefined ? `&order_by=${params?.orderBy}` : ""}${
      params?.orderMethod !== undefined
        ? `&order_method=${params?.orderMethod}`
        : ""
    }${params?.status !== undefined ? `&status=${params?.status}` : ""}`
  );
};

export const submitTorStepOne = (params) => {
  return axiosInstance.post(`${config.DAPROG_API_URL}/tor/step-one`, params);
};

export const submitTorStepTwoMutations = (params) => {
  return axiosInstance.post(
    `${config.DAPROG_API_URL}/tor/step-two/resource`,
    params
  );
};

export const submitTorStepTwoParticipans = (params) => {
  return axiosInstance.post(
    `${config.DAPROG_API_URL}/tor/step-two/participant`,
    params
  );
};

export const submitTorStepTwoItems = (params) => {
  return axiosInstance.post(
    `${config.DAPROG_API_URL}/tor/step-two/item`,
    params
  );
};

export const getTorRecap = (torId) => {
  return axiosInstance.get(`${config.DAPROG_API_URL}/tor/detail?id=${torId}`);
};

export const submitTorRecap = (params) => {
  return axiosInstance.post(`${config.DAPROG_API_URL}/tor/step-three`, params);
};

export const submitDocument = (params) => {
  return axiosInstance.post(`${config.DAPROG_API_URL}/tor/step-four`, params);
};

import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  Select,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";

const DaftarKepemilikanSaham = ({ tools, methods, data }) => {
  const navigate = useNavigate();

  const {
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();

  const [isEdit, setIsEdit] = useState(false);
  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEdit = () => setIsEdit(!isEdit);
  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">
            Daftar Kepemilikan Saham (untuk PT) / Anggota Pengurus (untuk
            Yayasan/Perkumpulan)
          </div>
          <div className="text-right">
            {isEdit ? (
              <Button
                type="button"
                className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Simpan Perubahan
              </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Ubah
              </Button>
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col">
          {tools.map((field, index) => {
            return (
              <div className="mt-[20px]" key={field.id}>
                <div className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <InputForm
                      controllerName={`tools.${index}.name`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Nama"}
                      placeholder={"Nama"}
                      disabled={!isEdit}
                    />
                    <InputForm
                      controllerName={`tools.${index}.identity_number`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Nomor KTP"}
                      placeholder={"Nomor KTP"}
                      disabled={!isEdit}
                    />
                    <Select
                      controllerName={`tools.${index}.nationality`}
                      className={`py-2 w-full flex-1`}
                      label={"WNI/WNA"}
                      placeholder={"WNI/WNA"}
                      aligned={true}
                      options={[
                        { value: "Dalam Negeri", label: "dalam negeri" },
                        { value: "Luar Negeri", label: "luar negeri" },
                      ]}
                      disable={!isEdit}
                    />
                    <InputForm
                      controllerName={`tools.${index}.percentage`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Persantase (Optional)"}
                      placeholder={"Persantase (Optional)"}
                      disabled={!isEdit}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          {/* button Verification */}
          <RadioVerificationBadge
            className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
            name={"stock_ownership.result_verification"}
            nameNotes={"stock_ownership.reason"}
            isEditVerification={isEditVerification}
            toogleIsEditVerification={toogleIsEditVerification}
            errors={
              errors?.stock_ownership?.result_verification
                ? errors?.stock_ownership?.result_verification
                : false
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default DaftarKepemilikanSaham;

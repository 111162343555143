import { yupResolver } from "@hookform/resolvers/yup"
import { Button } from "components";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { RiArrowLeftLine, RiCheckFill } from "react-icons/ri";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import PeralatanPerlengkapanChild from "./Form/PeralatanPerlengkapan";

const PeralatanPerlengkapan = ({
  setActiveTab,
  activeTab = 1,
  data = {},
  allData = {},
  setData = () => {},
  onSubmit = () => {},
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const toogleIsEdit = () => setIsEdit(!isEdit)

  const validationSchema = useMemo(() => Yup.object().shape({
    step_2: Yup.object().shape({
      equipment: Yup.object().shape({
        list: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required(),
            category: Yup.mixed(),
            total: Yup.number().typeError('Jumlah harus angka').required('Jumlah harus di isi'),
            capacity: Yup.string().required(),
            mark_and_type: Yup.string().required(),
            production_year: Yup.number().typeError('Tahun pembuatan harus angka').required('Tahun pembuatan harus di isi'),
            condition: Yup.string().required(),
            location: Yup.string().required(),
          })
        ),
      }),
      status_approval_equipment: Yup.string().required(`Hasil verifikasi wajib diisi`),
      notes_approval_equipment: Yup.string().nullable(true),
    })
  }))

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      step_2: {
        status_approval_equipment: "APPROVED",
      }
    }
  });

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    trigger,
  } = methods;

  const { 
    fields: fieldsList, 
    appends: appendsList, 
    remove: removeList 
  } = useFieldArray({
    control,
    name: "step_2.equipment.list",
  })

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((keys) => {
          if (keys === `equipment`){
            Object.keys(data[key][keys]).forEach((keyss) => {
              setValue(`${key}.${keys}.${keyss}`, data[key][keys][keyss]);
              // console.log(`${key}.${keys}.${keyss}`, data[key][keys][keyss])
            })
          } else {
            setValue(`${key}.${keys}`, data[key][keys]);
            // console.log(`${key}.${keys}`, data[key][keys])
          }
        })
      });
      trigger();
    }
  }, [data, setValue, methods]);

  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);
  
  const saveData = async () => {
    const isValid = await trigger()
    const allValues = getValues()
    allData['step_2'] = allValues.step_2
    setData(allData)
    return isValid;
  }

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <PeralatanPerlengkapanChild 
          openModal={() => setIsOpenModal(true)}
          isEdit={isEdit}
          toogleIsEdit={toogleIsEdit}
          fieldsList={fieldsList}
          appendsList={appendsList}
          removeList={removeList}
        />
        {/* <Card>
          <CardHeader>
            <div>Rekomendasi Verificator</div>
          </CardHeader>
          <CardBody>
            <div className="space-y-5">
              <div className="space-y-2">
                <TitleText className="font-bold">
                  Rekomendasi Verificator
                </TitleText>
                <InputForm
                  controllerName={"verificatorRecomendation"}
                  className={`py-2 border w-full rounded-md flex-1`}
                  label={''}
                  placeholder={'Verifikasi Remokendasi'}
                  textArea
                />
              </div>
            </div>
          </CardBody>
        </Card> */}
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => activeTab === 1 ? navigate(-1) : setActiveTab(activeTab - 1)}
            >
              <RiArrowLeftLine/>
              Kembali
            </Button>
          </div>
          {sessionStorage.getItem(`isLemtaraFormDisabled`) !== "true" && (
            <div className="block md:flex items-center justify-between gap-2">
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={async () => {
                  const validData = await saveData()
                  if (validData)
                    onSubmit(allData)
                }}
              >
                <RiCheckFill className={`text-[23px]`} />
                Verifikasi Selesai
              </Button>
            </div>
          )}
        </div>
        {/* form modal */}
        {/* <FormModal 
          title={``}
          open={isOpenModal}
          onClose={() => setIsOpenModal(!isOpenModal)}
          onSubmit={() => {
            setIsOpenModal(!isOpenModal)
          }}
          positiveConfirm={`Ya`}
          negativeConfirm={`Tidak`}
          size={`w-3/12`}
        /> */}
      </FormProvider>
    </div>
  )
}

export default PeralatanPerlengkapan
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import config from "app/config";
import { Link } from "react-router-dom";
import { RiHome6Line } from "react-icons/ri";
import { FiChevronRight } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import TimeWorkPlanForm from "./form";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Breadcrumbs } from "components";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getSpecificTimeWorkPlanDetail } from "services/danaProgram/specificTimeWorkPlanService";
import * as yup from "yup";

const Form = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const idInvestment = JSON.parse(localStorage.getItem("id_investment"));

  const id = localStorage.getItem("specificWorkPlanId");

  const [detailData, setDetailData] = useState();
  const queryClient = useQueryClient();

  const { data: timeWorkPlanDataDetail, refetch: refetchInvestmentPlanData } =
    useQuery(
      [`detail-timeWorkPlan-${id}`, id],
      () => getSpecificTimeWorkPlanDetail(id),
      {
        onError: (error) => {
          // enqueueSnackbar(getErrorMessage(error), { variant: "error" });
        },
        refetchOnWindowFocus: false,
        enabled: false,
      }
    );

  useEffect(() => {
    if (currentPath.includes("revision")) {
      refetchInvestmentPlanData();

      return () => {
        queryClient.setQueriesData([`detail-timeWorkPlan-${id}`, id], null);
      };
    }
  }, [detailData, id]);

  useEffect(() => {
    refetchInvestmentPlanData();
    setDetailData(timeWorkPlanDataDetail?.data?.data);
  }, []);

  useEffect(() => {
    if(currentPath.includes("add")) {
      localStorage.removeItem("specificWorkPlanId");
    }
  }, []);

  const fetchInvestment = async () => {};

  const stepForm = [
    "Informasi Umum",
    "Penerima Manfaat",
    "Result Chain",
    "Dokumen Pendukung",
  ];
  const step = Number(location.pathname.split("/")[3]);

  useEffect(() => {
    fetchInvestment();
  }, []);

  const validationSchema = yup.object().shape({
    work_plan_name: yup.string().required("wajib"),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const links = [
    {
      label: "Rencana Kerja Waktu Tertentu",
      path: "/rencana-kerja-waktu",
    },
    {
      label: currentPath.includes("revision") ? "Revisi Data" : "Tambah Baru",
    },
  ];

  return (
    <div>
      <div className="bg-transparent flex items-center flex-wrap flex-1 mb-[24px]">
        <Breadcrumbs items={links} />
      </div>
      <TimeWorkPlanForm
        title={
          currentPath.includes("revision")
            ? "Revisi Rencana Kerja Waktu"
            : "Tambah Rencana Kerja Waktu"
        }
        data={timeWorkPlanDataDetail?.data?.data}
        id={id}
        />
    </div>
  );
};

export default Form;

import React, { useMemo } from "react";

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  TitleText,
} from "components";
import { useNavigate } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine, RiCheckboxCircleLine, RiCloseCircleLine, RiEyeLine } from "react-icons/ri";
import { BasicList } from "components/molecules/ListPage";
import { Input } from "components";
import { InputValidation } from "components";

const ProposalSubmissionDetail = () => {
  const navigate = useNavigate();
  const links = [
    {
      label: "Pengajuan Dana",
      path: "/pengajuan-dana-program",
    },
    {
      label: "Detail",
    },
  ];

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full">
              {no}
            </div>
          );
        },
      },
      {
        id: "activity",
        title: "Kegiatan",
        dataIndex: "activity",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "activityName",
        title: "Nama Kegiatan/Item/Barang/Jasa",
        dataIndex: "activityName",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) =>(
          <div className="overflow-hidden text-ellipsis w-full">
            {value}
          </div>),
      },
      {
        id: "quantity",
        title: "QTY",
        dataIndex: "quantity",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>),
      },
      {
        id: "unit",
        title: "satuan",
        dataIndex: "unit",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "priceUnit",
        title: "Biaya Satuan",
        dataIndex: "priceUnit",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex w-full">
            <InputValidation/>
          </div>
        ),
      },
    ];
  }, []);



  const result = useMemo(() => {
    return [
      {
        code: 12,
        activity: "Proposal A",
        activityName: "Proposal A",
        quantity: "John Doe",
        unit: "10 Februari 2023",
        description: "Rp50,000,000",
      },
      {
        code: 142,
        activity: "Proposal B",
        activityName: "Proposal B",
        quantity: "John Doe",
        unit: "10 Februari 2023",
        description: "Rp50,000,000",
      },
      {
        code: 112,
        activity: "Proposal C",
        activityName: "Proposal C",
        quantity: "John Doe",
        unit: "10 Februari 2023",
        description: "Rp50,000,000",
      },
      {
        code: 512,
        activity: "Proposal D",
        activityName: "Proposal D",
        quantity: "John Doe",
        unit: "10 Februari 2023",
        description: "Rp50,000,000",
      },
      {
        code: 162,
        activity: "Proposal E",
        activityName: "Proposal E",
        quantity: "John Doe",
        unit: "10 Februari 2023",
        description: "Rp50,000,000",
      },
    ];
  });


  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  return (
    <div className="space-y-6">
      <Card noBorder={true}>
        <CardBody borderBottom={true}>
          <div className="space-y-5">
            <div className="flex flex-col space-y-2">
              <span>Total Biaya</span>
              <TitleText className={''}>Rp1,500,000</TitleText>
            </div>
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={result}
              isLoading={false}
              onChangePage={null}
              onChangeRowsPerPage={null}
              pagination={{}}
            />
          </div>
        </CardBody>
      </Card>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate(-1)}
          >
            <RiArrowLeftLine/>
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
          <Button
            type="button"
            className="text-white border-error-600 bg-error-600 hover:bg-error-700 hover:border-error-700 w-40"
          >
            <RiCloseCircleLine className="text-white"/>
            Tolak
          </Button>
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-40"
          >
            <RiCheckboxCircleLine className="text-white"/>
            Submit
          </Button>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
          >
            Selanjutnya
            <RiArrowRightLine/>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProposalSubmissionDetail;

import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputFormRadio,
  Table,
} from 'components';
import { useNavigate, useParams } from 'react-router';
import { RiArrowLeftLine, RiArrowRightLine, RiFile2Line } from 'react-icons/ri';
import { getDetailProposal } from 'services/danaProgram/callForProposalService';
import { getUrlFile } from 'services/danaProgram/fileService';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { postFilterNegativeList } from 'services/danaProgram/callForProposalService';

const SafeguardsDetail = ({ onNext, onPrev }) => {
  let { id } = useParams();
  let methods = useForm();
  const navigate = useNavigate();

  const [data, setData] = useState(null);

  const fetchDetailCfp = async (params) => {
    const res = await getDetailProposal(id, params);
    setData(res.data.data);
  };

  const { mutate } = useMutation({
    mutationKey: ['post-step-3-negative-list'],
    mutationFn: async (payload) => {
      const res = await postFilterNegativeList(payload);
      return res.data.data;
    },
    onSuccess: (res) => {
      navigate('/call-for-proposal');
    },
  });

  useEffect(() => {
    fetchDetailCfp({ category: 'safeguard' });
  }, []);

  useEffect(() => {
    if (data) {
      methods.setValue(
        'filter_negative_list',
        String(data.filter_negative_list.negative_list_status)
      );
    }
  }, [data]);

  const downloadFile = async (item) => {
    const res = await getUrlFile({
      identifier: 'id',
      value: item?.fileId,
    });
    window.open(res?.data?.data?.url, '_blank');
  };

  const onSubmit = () => {
    const payload = {
      id: id,
      negative_list_status: true,
      document: data.documents,
    };

    mutate(payload);
  };

  const tableColumns = useMemo(() => {
    return [
      {
        id: 'rights_holder',
        title: 'Pemangku Hak & Kepentingan',
        dataIndex: 'rights_holder',
        className: 'overflow-hidden text-ellipsis',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: 'role',
        title: 'Peran',
        dataIndex: 'role',
        className: 'overflow-hidden text-ellipsis',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
    ];
  }, []);

  return (
    <FormProvider {...methods}>
      <div className="space-y-6">
        <Card>
          <CardHeader>
            <div>Detail</div>
          </CardHeader>
          <CardBody>
            <div className="flex flex-col space-y-5">
              {/* jangan di hapus */}
              {/* <CardForm label="Informasi Proyek dan atau kegiatan yang berhubungan dengan perlindungan lingkungan dan sosial" buttonCollapse={true}>
                            <div className="flex flex-col space-y-5">
                                <div className="flex flex-col">
                                    <label className="text-[#1D2939] font-bold">
                                        Intervensi/kontribusi terhadap perbaikan dan/atau perlindungan lingkungan dan sosial yang akan dicapai
                                    </label>
                                    {
                                        data?.basic_information?.intervention.length > 0 ? data?.basic_information?.intervention.map((item, index) => {
                                            return (
                                                <label className="text-[#1D2939]">
                                                    {index + 1}. {item.name}
                                                </label>
                                            )
                                        }) : (<></>)
                                    }
                                </div>
                                <div className="flex flex-col space-y-2">
                                    <label className="text-[#1D2939] font-bold">
                                        Lingkup pelibatan para pemangku hak dan kepentingan yang relevan dan peranannya dalam kegiatan yang diusulkan
                                    </label>
                                    <Table
                                        bordered
                                        stripped
                                        layout="auto"
                                        className="mb-4"
                                        columns={tableColumns}
                                        dataSource={data?.basic_information?.involvement_scope}
                                        isLoading={false}
                                        onChangePage={null}
                                        onChangeRowsPerPage={null}
                                        pagination={{
                                            // lastPage: ProgressReportList?.data?.data?.lastPage,
                                            // page: ProgressReportList?.data?.data?.page,
                                            // perPage: ProgressReportList?.data?.data?.perPage,
                                            total: data?.basic_information?.involvement_scope?.length,
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label className="text-[#1D2939] font-bold">
                                        Kebijakan Perlindungan Lingkungan dan Sosial
                                    </label>
                                    <label className="text-[#1D2939]">
                                        {data?.basic_information?.policy}
                                    </label>
                                </div>
                                <div className="flex flex-col">
                                    <label className="text-[#1D2939] font-bold">
                                        Dokumen Bukti Pendukung
                                    </label>
                                    <div className="grid grid-cols-2 gap-4">
                                        {
                                            data?.basic_information?.supportive_doc.length > 0 ? data?.basic_information?.supportive_doc.map((item, index) => {
                                                return (
                                                    <div className="border p-4 rounded-2xl">
                                                        <div className="flex gap-4">
                                                            <div>
                                                                <div className="bg-[#EEFFF4] rounded-full p-4">
                                                                    <RiFile2Line className="text-primary-600" />
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col gap-2">
                                                                <span className="font-medium">
                                                                    {item.fileName}
                                                                </span>
                                                                <span>
                                                                    {item.fileSize}
                                                                </span>
                                                                <button
                                                                    className="text-left"
                                                                    onClick={() => downloadFile(item)}
                                                                >
                                                                    <span className="font-semibold text-primary-600">
                                                                        Download file
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : (<></>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </CardForm> */}
              <CardForm
                label="Penyaringan Daftar Negatif"
                buttonCollapse={true}
              >
                <div className="flex flex-col space-y-5">
                  <div className="flex flex-col">
                    {/* <label className="text-[#1D2939] font-bold">
                                        Berdasarkan hasil penyaringan terhadap daftar negatif di atas, diperoleh bahwa program dan/atau kegiatan yang Anda usulkan :
                                    </label> */}
                    <InputFormRadio
                      controllerName={'filter_negative_list'}
                      label="Berdasarkan hasil penyaringan terhadap daftar negatif di atas, diperoleh bahwa program dan/atau kegiatan yang Anda usulkan :"
                      values={[
                        {
                          label: 'Termasuk dalam daftar negatif',
                          value: 'true',
                        },
                        {
                          label: 'Tidak Termasuk dalam daftar negatif',
                          value: 'false',
                        },
                      ]}
                    />
                    {/* {
                                        data?.filter_negative_list?.negative_list_status === true ? (
                                            <label className="text-[#1D2939]">
                                                termasuk dalam daftar negatif
                                            </label>
                                        )
                                            : (
                                                <label className="text-[#1D2939]">
                                                    Tidak Termasuk dalam daftar negatif
                                                </label>
                                            )
                                    } */}
                  </div>
                  <div className="flex flex-col">
                    <label className="text-[#1D2939] font-bold">
                      Dokumen Bukti Pendukung
                    </label>
                    <div className="grid grid-cols-2 gap-4">
                      {data?.filter_negative_list?.supportive_doc.length > 0 ? (
                        data?.filter_negative_list?.supportive_doc.map(
                          (item, index) => {
                            return (
                              <div className="border p-4 rounded-2xl">
                                <div className="flex gap-4">
                                  <div>
                                    <div className="bg-[#EEFFF4] rounded-full p-4">
                                      <RiFile2Line className="text-primary-600" />
                                    </div>
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <span className="font-medium">
                                      {item.fileName}
                                    </span>
                                    <span>{item.fileSize}</span>
                                    <button
                                      className="text-left"
                                      onClick={() => downloadFile(item)}
                                    >
                                      <span className="font-semibold text-primary-600">
                                        Download file
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </CardForm>
              {/* jangan di hapus */}
              {/* <CardForm label="Kapasitas Pemrakarsa" buttonCollapse={true}>
                            <div className="flex flex-col space-y-5">
                                {
                                    data?.initiator.length > 0 ? data?.initiator.map((item, index) => {
                                        return (
                                            <>
                                                <div className="flex flex-col">
                                                    <label className="text-[#1D2939] font-bold">
                                                        {item.name}
                                                    </label>
                                                    {
                                                        item.is_true === true ? (
                                                            <label className="text-[#1D2939]">
                                                                Iya
                                                            </label>
                                                        )
                                                            : (
                                                                <label className="text-[#1D2939]">
                                                                    Tidak
                                                                </label>
                                                            )
                                                    }
                                                </div>
                                                <div className="grid grid-cols-2 gap-4">
                                                    {
                                                        item?.documents.length > 0 ? item?.documents.map((item, index) => {
                                                            return (
                                                                <div className="border p-4 rounded-2xl">
                                                                    <div className="flex gap-4">
                                                                        <div>
                                                                            <div className="bg-[#EEFFF4] rounded-full p-4">
                                                                                <RiFile2Line className="text-primary-600" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex flex-col gap-2">
                                                                            <span className="font-medium">
                                                                                {item.fileName}
                                                                            </span>
                                                                            <span>
                                                                                {item.fileSize}
                                                                            </span>
                                                                            <button
                                                                                className="text-left"
                                                                                onClick={() => downloadFile(item)}
                                                                            >
                                                                                <span className="font-semibold text-primary-600">
                                                                                    Download file
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) : (<></>)
                                                    }
                                                </div>
                                            </>
                                        )
                                    }) : (<></>)
                                }
                            </div>
                        </CardForm>
                        <CardForm label="klasifikasi tingkat resiko" buttonCollapse={true}>
                            <div className="flex flex-col space-y-5">
                                {
                                    data?.risk_classification.length > 0 ? data?.risk_classification.map((item, index) => {
                                        return (
                                            <div className="flex flex-col">
                                                <label className="text-[#1D2939] font-bold">
                                                    {index + 1} {item.question}
                                                </label>
                                                <label className="text-[#1D2939]">
                                                    {item.answer}
                                                </label>
                                            </div>
                                        )
                                    }) : (<></>)
                                }
                            </div>
                        </CardForm>
                        <CardForm label="penapisan" buttonCollapse={true}>
                            <div className="flex flex-col space-y-5">
                                {
                                    data?.screening.length > 0 ? data?.screening.map((item, index) => {
                                        return (
                                            <div className="flex flex-col">
                                                <label className="text-[#1D2939] font-bold">
                                                    {item.name}
                                                </label>
                                                {
                                                    item.answer === true ? (
                                                        <label className="text-[#1D2939]">
                                                            Iya
                                                        </label>
                                                    )
                                                        : (
                                                            <label className="text-[#1D2939]">
                                                                Tidak
                                                            </label>
                                                        )
                                                }
                                                {(item.principle && item.principle.length > 0) && <label className="text-[#1D2939]">
                                                    Perlindungan lingkungan dan sosial yang terpicu
                                                </label>}
                                                {
                                                    (item.principle && item.principle.length > 0) && item.principle.map((detailPrinciple) => {
                                                        return (
                                                            <label className="text-[#1D2939]">
                                                                - {detailPrinciple.nama}
                                                            </label>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }) : (<></>)
                                }
                            </div>
                        </CardForm>
                        <CardForm label="Mitigasi resiko perlindungan lingkungan dan sosial berdasarkan prinsip perlindungan lingkungan dan sosial yang terpicu dari penapisan" buttonCollapse={true}>
                            <div className="flex flex-col space-y-5">
                                {
                                    data?.risk_mitigation && data?.risk_mitigation.length > 0 ? data?.risk_mitigation?.map((item, index) => {
                                        return (
                                            <div className="flex flex-col">
                                                <label className="text-[#1D2939] font-bold">
                                                    {index + 1}. {!Array.isArray(item.principle) ? item?.principle : '-'}
                                                </label>
                                                {Array.isArray(item.principle) && (
                                                    item.principle.activity.map((itemActivity) => {
                                                        return (
                                                            <label className="text-[#1D2939]">
                                                                - {itemActivity.value}
                                                            </label>
                                                        )
                                                    })
                                                )}
                                            </div>
                                        )
                                    }) : (<></>)
                                }
                            </div>
                        </CardForm>
                        <CardForm label="Dokumen Pendukung" buttonCollapse={true}>
                            <div className="flex flex-col space-y-5">
                                <div className="grid grid-cols-2 gap-4">
                                    {
                                        data?.documents.length > 0 ? data?.documents.map((item, index) => {
                                            return (
                                                <div className="border p-4 rounded-2xl">
                                                    <div className="flex gap-4">
                                                        <div>
                                                            <div className="bg-[#EEFFF4] rounded-full p-4">
                                                                <RiFile2Line className="text-primary-600" />
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col gap-2">
                                                            <span className="font-medium">
                                                                {item.fileName}
                                                            </span>
                                                            <span>
                                                                {item.fileSize}
                                                            </span>
                                                            <button
                                                                className="text-left"
                                                                onClick={() => downloadFile(item)}
                                                            >
                                                                <span className="font-semibold text-primary-600">
                                                                    Download file
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : (<></>)
                                    }
                                </div>
                            </div>
                        </CardForm> */}
            </div>
          </CardBody>
        </Card>
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={onPrev}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={() => {
                let valueFilterNegativeList = methods.getValues(
                  'filter_negative_list'
                );

                valueFilterNegativeList === 'true' ? onSubmit() : onNext();
              }}
            >
              Submit
              <RiArrowRightLine className="text-white" />
            </Button>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default SafeguardsDetail;

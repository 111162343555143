import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { RiArrowLeftLine, RiFile2Line } from 'react-icons/ri';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';

import {
  Card,
  CardBody,
  CardHeader,
  Button,
  CardForm,
  Table,
} from 'components';
import { InputForm } from 'components';
import { getFile } from 'services/danaProgram/fileService';

import useProgressReport from '../hooks/useHooks';

const Child = ({ title = {}, data }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const formType = searchParams.get('type');

  const [modal, setModal] = useState({ isOpen: false, type: '' });

  const { submitProgressReport } = useProgressReport({ test: '' });

  // requests
  const onPreviewFile = useMutation({
    mutationKey: [`get-detail-file`],
    mutationFn: async (id) => {
      const res = await getFile(id);
      return res.data.data;
    },
    onSuccess: (res) => {
      window.open(res.url, `_blank`);
    },
  });

  const onSubmitVerif = async (type) => {
    try {
      const note = getValues('note');
      let params = {
        id: data?._id,
        project_id: data?.project_id,
        project_name: data?.project_name,
        output: data?.output,
        total_contribution: data?.total_contribution,
        document: data?.document,
        note: note,
      };
      submitProgressReport.mutate(params, {
        onSuccess: () => {
          sessionStorage.removeItem('ProgressReportsReviewId');
          enqueueSnackbar('Data saved successfully!', {
            variant: 'success',
            preventDuplicate: false,
          });
          setModal((prev) => ({ ...prev, isOpen: false, type: '' }));
          navigate('/progress-report-review/');
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const {
    watch,
    setValue,
    getValues,
    control,
    register,
    formState: { errors, isValid },
  } = useFormContext();

  function formatNumber(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  //   resettingAWPActivities();
  //   if (!isAdd) {
  //     refetchDetailWithdrawal();
  //   }
  //   methods.register("reason");
  // }, []);

  // useEffect(() => {
  //   if (!isAdd) {
  //     for (let key in DetailWithdrawal) {
  //       let data = DetailWithdrawal[key];
  //       if (key === "work_plan_name") {
  //         setValue("work_plan_name", {
  //           value: DetailWithdrawal.work_plan_id,
  //           label: data,
  //         });
  //       } else if (key === "awp_project_name") {
  //         setValue("awp_project_name", {
  //           value: DetailWithdrawal.awp_project_id,
  //           label: data,
  //         });
  //       } else if (key === "start_date_work_plan") {
  //         setValue(
  //           "work_plan_period",
  //           `${moment(data).format("DD/MM/YYYY")} - ${moment(
  //             DetailWithdrawal.end_date_work_plan
  //           ).format("DD/MM/YYYY")} `
  //         );
  //       } else if (key === "total_awp_fund") {
  //         setValue("total_awp_fund", formatNumber(data));
  //       } else if (key === "total_work_plan_fund") {
  //         setValue("total_work_plan_fund", formatNumber(data));
  //       } else if (key === "total_withdrawal_fund") {
  //         setTotalFund(formatNumber(data));
  //       } else if (key === "start_date_fund_usage") {
  //         setValue("period", [
  //           new Date(data),
  //           new Date(DetailWithdrawal.end_date_work_plan),
  //         ]);
  //       } else if (key === "detail_activities") {
  //         resettingAWPActivities();
  //         setValue("awp_project_activity_name", {
  //           label: data[0].awp_project_activity_name,
  //           value: data[0].awp_project_activity_id,
  //         });
  //         // appendingAWPFields(data);
  //       } else if (key === "reason") {
  //         setValue("reason", data);
  //       }
  //     }
  //   }
  // }, [DetailWithdrawal]);

  // const watchedValue = useWatch({
  //   control,
  //   name: "work_plan_name",
  // });

  // const withdrawalFund = useWatch({
  //   control,
  //   name: "awp_detail_activities",
  // });

  // useEffect(() => {
  //   _mapTimeWorkPlanData(timeWorkPlanData);
  // }, [timeWorkPlanData]);

  const firstRender = useRef(true);

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        title: 'Indikator',
        dataIndex: 'name',
        className: 'overflow-hidden ',
        columnClassName: '',
        render: (value) => {
          return (
            <div className="w-full overflow-hidden text-ellipsis ">{value}</div>
          );
        },
      },
      {
        id: 'contribution',
        title: 'Kontribusi Capaian',
        dataIndex: 'contribution',
        className: 'overflow-hidden text-ellipsis',
        sortable: true,
        fixed: 'center',
        columnClassName: 'text-center overflow-hidden',
        render: (value) => (
          <div
            className="overflow-hidden"
            style={{ textAlign: '-webkit-center' }}
          >
            {value}%
          </div>
        ),
      },
    ];
  }, []);

  useEffect(() => {
    if (data?.note) {
      setValue('note', data?.note);
    }
  }, [data?.note]);

  let dataSource = [
    {
      indicator:
        'KEPATUHAN TERHADAP PERATURAN PERUNDANG-UNDANGAN DAN AKUNTABILITAS',
      contribution: '30%',
    },
    {
      indicator:
        'KEPATUHAN TERHADAP PERATURAN PERUNDANG-UNDANGAN DAN AKUNTABILITAS',
      contribution: '30%',
    },
  ];

  function parseDateRange(dateString) {
    const [startDateStr, endDateStr] = dateString.split(' - ');

    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split('/');
      return new Date(year, month - 1, day);
    };

    const startDate = parseDate(startDateStr);
    const endDate = parseDate(endDateStr);

    return { startDate, endDate };
  }

  return (
    <div className="">
      {/* <Breadcrumbs items={links} /> */}
      {/* <ProgressStepForm items={stepForm} step={step} /> */}
      <div className="flex flex-col gap-4 mt-3">
        <Card>
          <CardHeader>
            <div>{title}</div>
          </CardHeader>
          <CardBody>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-6">
                <div className="flex gap-4 flex-row justify-between text-normal">
                  <div className="flex flex-col gap-1">
                    <p className="font-semibold">Nama Project</p>
                    <p className="font-normal">{data?.project_name}</p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="font-semibold">Tanggal Laporan</p>
                    <p className="font-normal">
                      {moment(data?.submission_date).format('DD/MM/YYYY')}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="font-semibold">Pelapor</p>
                    <p className="font-normal">{data?.submitter}</p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="font-semibold">Capaian Rata-rata</p>
                    <div className="bg-green-300 px-2 w-fit rounded">
                      <p className="font-normal">{data?.total_contribution}%</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-4 flex-col">
                <p className="font-semibold">Dokumen Pendukung</p>
                <div className="grid grid-cols-3 gap-4">
                  {data?.document?.map((doc, i) => (
                    <div
                      key={'project-resource-data-doc-' + i}
                      className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl"
                    >
                      <div className="flex gap-4">
                        <div>
                          <div className="bg-[#EEFFF4] rounded-full p-4">
                            <RiFile2Line className="text-primary-600" />
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="font-medium">{doc?.fileName}</span>
                          <span>
                            {`${parseFloat(doc?.fileSize / 1000, 2)} KB`}
                          </span>
                          <span
                            onClick={() => onPreviewFile.mutate(doc?.fileId)}
                            className="font-semibold text-primary-600 cursor-pointer"
                          >
                            Lihat Dokumen
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex-1">
                <p className="text-lg font-semibold mb-2">Capaian Output</p>
                <div className="flex flex-col gap-6">
                  {}
                  {data?.output?.length > 0 &&
                    data?.output.map((val, index) => {
                      return (
                        <CardForm
                          label={`Output ${index + 1}`}
                          buttonCollapse={true}
                        >
                          <div className="flex flex-col gap-6">
                            <Table
                              bordered
                              stripped
                              layout="fixed"
                              className="mb-4"
                              columns={columns}
                              dataSource={
                                data?.output[index]?.indicator_list.length > 0
                                  ? data?.output[index]?.indicator_list
                                  : []
                              }
                            />
                          </div>
                        </CardForm>
                      );
                    })}
                </div>
              </div>
            </div>
            <InputForm
              controllerName={'note'}
              className={`py-2 border border-slate-300 w-full rounded-md flex-1`}
              label={'Catatan'}
              placeholder={''}
              disabled={formType === 'detail'}
              rows="5"
              textArea
            />
          </CardBody>
        </Card>
      </div>

      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate('/progress-report-review/')}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
        <div className="flex gap-4 flex-row">
          <div className=" flex-1 block md:flex items-center justify-between gap-2">
            {formType !== 'detail' && (
              <Button
                type="button"
                className="px-10 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={() => onSubmitVerif('confirm')}
              >
                Simpan Hasil Verifikasi
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Child;

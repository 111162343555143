import { Breadcrumbs } from 'components';
import ProgressStepForm from 'components/molecules/ProgressStepForm';
import { useLocation } from 'react-router';
import { FiAlertTriangle } from 'react-icons/fi';
import Step1 from '../addInformation';
import Step2 from '../addMainActivity';
import Step3 from '../addDetailActivity';
import Step4 from '../addBudget';
import Step5 from '../addDocument';
import React, { useEffect } from 'react';
import {
  AnnualWorkPlanProvider,
  UseAnnualWorkPlanContext,
} from '../../../context/AnnualWorkPlanContext';
import axios from 'axios';
import config from 'app/config';
import axiosInstance from 'app/interceptors';

const AddAnnualWorkPlan = () => {
  const { idInvestment, idAwp, currentStep, type, fetchData, setData } =
    UseAnnualWorkPlanContext();
  const location = useLocation();
  const {
    typeForm,
    idAwp: awpFromState,
    idInvestment: investmentFromState,
    currentStep: stepFromState,
  } = location.state || {};

  const fetchAWPInvestmentPlan = async () => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/api/dana-program/v1/cms/awp?id=${
        idAwp ?? awpFromState
      }`
    );
    setData({
      dataAnnualWorkPlan: res.data?.data,
      idAwp: awpFromState ?? res?.data?.data?._id,
      idInvestment: investmentFromState ?? res?.data?.data?.investment_id,
      currentStep: currentStep ?? stepFromState,
      type: typeForm,
      fetchData: true,
    });
  };

  useEffect(() => {
    if ((idAwp ?? awpFromState) && fetchData === false) {
      fetchAWPInvestmentPlan();
    }
  }, [idAwp, fetchData, awpFromState]);

  const links = [
    {
      label: 'Pengajuan Dana',
      path: '/',
    },
    {
      label: 'Annual Work Plan',
      path: '/annual-work-plan',
    },
    {
      label: 'Tambah Permohonan',
    },
  ];

  const step = Number(location.pathname.split('/')[3]);

  const stepForm = [
    'General Informasi',
    'Kegiatan Utama',
    'Detail/Sub Kegiatan',
    'Anggaran Biaya',
    'Dokumen Pendukung',
  ];

  return (
    <div className="flex flex-col gap-8">
      <Breadcrumbs items={links} />
      {/* Header */}
      <div className="flex flex-col gap-1">
        <h1 className="font-medium text-3xl">Tambah Permohonan</h1>
        <p className="text-base">
          Silahkan daftar menggunakan data yang Anda miliki.
        </p>
      </div>

      <hr />

      <ProgressStepForm items={stepForm} step={currentStep} />

      {/* Information Bar */}
      <div className="flex bg-[#FFFCF5] self-stretch p-4 gap-3 border border-[#F5AE70] rounded-xl">
        <FiAlertTriangle color="#BE600E" size={20} />
        <div className="flex flex-col gap-1">
          <p className="font-medium text-[#8F480A] text-sm">Lengkapi Data</p>
          <p className="text-sm">
            Silakan isi semua data dibawah ini untuk bisa melanjutkan pengisian
            ke tahap selanjutnya
          </p>
        </div>
      </div>

      {currentStep === 1 ? (
        <Step1 />
      ) : currentStep === 2 ? (
        <Step2 />
      ) : currentStep === 3 ? (
        <Step3 />
      ) : currentStep === 4 ? (
        <Step4 />
      ) : (
        <Step5 />
      )}
    </div>
  );
};

const AddAnnualWorkPlanWithContext = () => {
  return (
    <AnnualWorkPlanProvider>
      <AddAnnualWorkPlan />
    </AnnualWorkPlanProvider>
  );
};
export default AddAnnualWorkPlanWithContext;

import React from "react";
import { AuthConsumer } from "../../providers/authProvider";
import { CgSpinner } from "react-icons/cg";

export const SignInCallback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback();
      return (
        <div className="relative min-h-screen w-screen flex justify-center items-center">
          <CgSpinner className="animate-spin" />
        </div>
      );
    }}
  </AuthConsumer>
);

import React, { Fragment } from "react";

const CardHeader = ({ children, info = "" }) => {
  return (
    <Fragment>
      <div className="px-6 py-5 text-[#101828] border-b-[1px] border-gray-200">
        <div className="flex flex-row gap-4">
          <div className="space-y-[9px] w-full font-semibold text-lg">
            {children}
          </div>
          {info && (
            <div className="space-y-[9px] bg-[#EEFFF4] text-[#01A24A] flex-shrink-0 text-sm font-medium text-center self-center py-[2px] px-[8px] align-center">
              <span>{info}</span>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CardHeader;

import config from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const getList = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/awp/list`,
    { params, headers }
  );
};

export const getAwpDetail = (id, params) => {
  return axiosInstance.get(`${config.BASE_URL}/api/dana-program/v1/cms/awp`, {
    params: {
      id,
      ...params,
    },
    headers,
  });
};

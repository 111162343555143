import Input from "components/atoms/Input";
import { kebabCase } from "lodash";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

const InputCurrency = ({
  label = "",
  labelClass,
  required = false,
  controllerName,
  className,
  invalid,
  disabled,
  asText = false,
  props,
}) => {
  const methods = useFormContext();
  const [value, setValue] = useState("");
  const formatIDR = (val) => {
    let rawValue = String(val).replace(/[^\d]/g, "");
    // Menghilangkan karakter selain angka
    const number = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return number;
  };
  const handleChange = (e) => {
    methods.setValue(controllerName, e.target.value.replace(/\./g, ""));
    setValue(formatIDR(e.target.value));
  };

  useEffect(() => {
    if (!!methods.getValues(controllerName)) {
      setValue(formatIDR(methods.getValues(controllerName)));
    }
  }, [methods.watch(controllerName)]);

  return (
    <div className="flex flex-col form-control w-full">
      {label && (
        <label
          className={["label font-semibold text-[14px]", labelClass].join(" ")}
          htmlFor={kebabCase(label)}
        >
          <div className={`flex gap-[4px]`}>
            <span className={`label-text`}>
              {label}
              {required && (
                <span className="text-[#F04438] text-[14px]">*</span>
              )}
            </span>
          </div>
        </label>
      )}
      {asText ? (
        <p className="p-2">Rp. {value}</p>
      ) : (
        <Input
          {...props}
          id={kebabCase(label)}
          prefix={"Rp"}
          onChange={handleChange}
          value={value}
          className={
            className
              ? `${className} border-[#D0D5DD] ${
                  invalid ? "border-[#F04438]" : ""
                }`
              : `input w-full max-w-xs border ${
                  invalid ? "input-error" : "input-bordered"
                } ${invalid ? "border-[#F04438]" : ""}`
          }
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default InputCurrency;

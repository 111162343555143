import React, {
  useCallback,
  useEffect,
  useMemo as UseMemo,
  useState,
  useContext,
} from 'react';
import { BasicList } from 'components/molecules/ListPage';
import { BsDot } from 'react-icons/bs';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, CustomSelect, AtomDatePicker, SearchInput } from 'components';
import { RiEyeLine } from 'react-icons/ri';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import moment from 'moment';
import useConsepNote from './hooks/useConsepNote';
import config from 'app/config';
import axios from 'axios';
import { debounce } from 'lodash';

const ListConsepNotes = () => {
  const methods = useForm({
    defaultValues: {
      date_of_filling: [null, null],
    },
  });

  const navigate = useNavigate();
  const [keyword, setKeyword] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderMethod, setOrderMethod] = useState('');

  const { watch } = methods;

  const params = {
    name: search,
    proposal_number: keyword,
    status: statusFilter,
    page: page,
    limit: limit,
    order_by: orderBy,
    order_method: orderMethod,
    start_date: watch('date_of_filling')?.[0]
      ? moment.utc(watch('date_of_filling')[0]).local().format('YYYY-MM-DD')
      : null,
    end_date: watch('date_of_filling')?.[1]
      ? moment.utc(watch('date_of_filling')[1]).local().format('YYYY-MM-DD')
      : null,
  };

  const { consepNoteData, paginationConsepNoteData, refetch } = useConsepNote({
    params,
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [
    keyword,
    statusFilter,
    page,
    limit,
    watch('date_of_filling'),
    search,
    orderBy,
    orderMethod,
  ]);

  const links = [
    {
      label: 'Daftar Permohonan Konsep Note',
    },
  ];

  const paginator = UseMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const tableColumns = UseMemo(() => {
    return [
      {
        id: 'no',
        title: 'No',
        dataIndex: 'no',
        className: 'overflow-hidden',
        columnClassName: 'w-[50px]',
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: 'ProposalTitle',
        title: 'Judul Proposal',
        dataIndex: 'proposal_title',
        className: '',
        columnClassName: 'w-[150px] text-ellipsis overflow-hidden',
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: 'projectName',
        title: 'Nama Proyek',
        dataIndex: 'project_name',
        className: '',
        columnClassName: 'w-[120px] text-ellipsis overflow-hidden',
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },

        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: 'period',
        title: 'Tanggal Pengajuan',
        dataIndex: 'date_of_filling',
        className: 'overflow-hidden text-ellipsis',
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },
        columnClassName: 'w-[150px] text-ellipsis overflow-hidden',
        render: (value) => {
          const Date = moment.utc(value).local().format('DD/MM/YYYY');
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[125px]">
              {`${Date}`}
            </div>
          );
        },
      },
      {
        id: 'location',
        title: 'Lokasi',
        dataIndex: 'location',
        className: '',
        columnClassName: 'w-[110px] text-ellipsis overflow-hidden',
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },

        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value?.[0].province?.label ?? value?.[0].province ?? '-'}
          </div>
        ),
      },
      {
        id: 'status',
        title: 'Status',
        dataIndex: 'status',
        columnClassName: 'w-[150px] text-ellipsis overflow-hidden',
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },

        fixed: 'center',
        render: (value) => (
          <div className="text-ellipsis w-full max-w-[200px]">
            {value === 'Menunggu Persetujuan' && (
              <div className="bg-[#FFFAEB] px-1 rounded-[25px] flex gap-[1px] items-center w-fit mx-auto">
                <div className="text-[#B54708] font-medium text-[12px]">
                  Menunggu Persetujuan
                </div>
              </div>
            )}
            {value === 'Disetujui' && (
              <div className="bg-[#ECFDF3] px-3 rounded-[25px] flex gap-[1px] w-fit mx-auto items-center">
                <div className="text-[#027A48] font-medium text-[12px]">
                  Disetujui
                </div>
              </div>
            )}
            {value === 'Ditolak' && (
              <div className="bg-[#FEF3F2] px-1 rounded-[25px] flex gap-[1px] items-center w-fit mx-auto">
                <div className="text-[#B42318] font-medium text-[12px]">
                  Ditolak
                </div>
              </div>
            )}
            {value === 'APPROVED' && (
              <div className="bg-[#ECFDF3] px-3 rounded-[25px] flex gap-[1px] w-fit mx-auto items-center">
                <div className="text-[#027A48] font-medium text-[12px]">
                  Disetujui
                </div>
              </div>
            )}
            {value === 'REJECTED' && (
              <div className="bg-[#FEF3F2] px-1 rounded-[25px] flex gap-[1px] items-center w-fit mx-auto">
                <div className="text-[#B42318] font-medium text-[12px]">
                  Ditolak
                </div>
              </div>
            )}
          </div>
        ),
      },
      {
        id: 'actions',
        title: 'Aksi',
        dataIndex: 'status',
        className: '',
        sortable: true,
        columnClassName: 'w-[125px] text-center text-ellipsis overflow-hidden',
        fixed: 'right',
        render: (value, data, index) => {
          return (
            <>
              {(value === 'Disetujui' ||
                value === 'Ditolak' ||
                value === 'APPROVED' ||
                value === 'REJECTED') && (
                <>
                  <div className="flex flex-col justify-center items-center gap-[12px]">
                    <FaEye
                      className="text-gray-600 cursor-pointer flex-1"
                      size={20}
                      onClick={() => navigate(`/concept-notes/${data?._id}`)}
                    />
                  </div>
                </>
              )}
              {value === 'Menunggu Persetujuan' && (
                <div className="flex flex-col justify-center items-center gap-[12px]">
                  <div className="space-x-3 justify-center flex w-full">
                    <Button
                      type="button"
                      className={`
                  text-white w-40
                    bg-[#00BD52]
                  `}
                      onClick={() =>
                        navigate(`/concept-notes/approval/${data?._id}`)
                      }
                    >
                      Review
                    </Button>
                  </div>
                </div>
              )}
            </>
          );
        },
      },
    ];
  }, [paginationConsepNoteData]);

  const statusOptions = UseMemo(() => {
    return [
      { value: null, label: 'Semua' },
      { value: 'Menunggu Persetujuan', label: 'Menunggu Persetujuan' },
      { value: 'Disetujui', label: 'Disetujui' },
      { value: 'Ditolak', label: 'Ditolak' },
    ];
  });

  const onSearch = debounce((value) => {
    setSearch(value);
  }, 500);

  return (
    <BasicList
      links={links}
      title="Daftar Permohonan Konsep Note"
      columns={tableColumns}
      dataSource={consepNoteData}
      onChangePage={(page) => setPage(page)}
      onChangeRowsPerPage={(row) => setLimit(row)}
      pagination={paginationConsepNoteData}
      customFilter={
        <FormProvider {...methods}>
          <div className="space-x-5 flex">
            <SearchInput
              placeholder="Cari"
              onChange={(e) => onSearch(e.target.value)}
            />
            <div className="flex-1">
              <AtomDatePicker
                className="max-h-[38px] mt-0 w-full rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none focus:ring-0 focus:border-0"
                controllerName="date_of_filling"
                label={null}
                placeholder="Tanggal Pengajuan"
                asText={false}
                format={'dd/MM/yyyy'}
                isRangeDatePicker={true}
              />
            </div>
            <CustomSelect
              optionsData={statusOptions}
              onChange={(status) => setStatusFilter(status.value)}
              placeholder="Status"
              className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
            />
          </div>
        </FormProvider>
      }
    />
  );
};

export default ListConsepNotes;

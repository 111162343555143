import React from 'react';
import ApprovalPembayaranResourcesDetail from '../detail';

const ApprovalPembayaranResourcesReview = () => {
  return (
    <ApprovalPembayaranResourcesDetail 
      isReview={true}
    />
  );
};

export default ApprovalPembayaranResourcesReview;
import config from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const getList = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/procurement/list`,
    { params, headers }
  );
};

export const getBankList = () => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/procurement/v1/common/banks?paginate=false`,
    { headers }
  );
};

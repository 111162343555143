import authSlice from "redux/authSlice";
import {
  masterDataReducer,
  financialProfileReducer,
  specificTimePlanReducer,
} from "./reducers";
import oidcSlice from "redux/oidcSlice";

const rootReducer = {
  auth: authSlice,
  oidc:oidcSlice,
  draftMasterData: masterDataReducer,
  draftFinancialProfile: financialProfileReducer,
  workPlan: specificTimePlanReducer,
};

export default rootReducer;

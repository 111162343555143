import React from "react";

const StepWizzard = ({ items, step }) => {
  if (items.length < 2) {
    return;
  }

  const checkActiveStep = (currentStep) => {
    return currentStep <= step ? true : false;
  };

  return (
    <div className="flex items-center justify-between my-6 max-w-[960px] mx-auto relative">
      {items.map((item, key) => (
        <div
          key={key}
          className="relative z-20 flex flex-col items-center justify-center"
        >
          <div
            className={`w-8 h-8 ${
              checkActiveStep(key + 1)
                ? "bg-[#EEFFF4]"
                : "bg-gray-100 border-transparent"
            } mb-5 border-4 rounded-full flex justify-center items-center`}
          >
            <div
              className={`h-2.5 w-2.5 ${
                checkActiveStep(key + 1) ? "bg-[#00BD52]" : "bg-gray-300"
              } rounded-full`}
            ></div>
          </div>
          <label
            className={`absolute text-sm font-semibold -bottom-5 ${
              checkActiveStep(key + 1) ? "text-primary-700" : "text-[#344054]"
            } whitespace-nowrap`}
          >
            {item}
          </label>
        </div>
      ))}
      <div className="w-full bg-[#EAECF0] h-0.5 absolute top-[15px] z-0"></div>
    </div>
  );
};

export default StepWizzard;

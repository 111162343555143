import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import {
  getList,
  getDetail,
} from 'services/danaProgram/approvalPembayaranLemtaraService';
import { getErrorMessage } from 'helpers';

const useApprovalPembayaranLemtara = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ''
    )
  );

  const {
    data: approvalPembayaranLemtara,
    isLoading: isLoadingApprovalPembayaranLemtara,
    isFetching: isFetchingApprovalPembayaranLemtara,
    refetch: refetchApprovalPembayaranLemtara,
  } = useQuery(
    ['list-approval-pembayaran-lemtara', params],
    () => getList(queryParams),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: detailApprovalPembayaranLemtara,
    isLoading: isLoadingDetailApprovalPembayaranLemtara,
    isFetching: isFetchingDetailApprovalPembayaranLemtara,
    refetch: refetchDetailApprovalPembayaranLemtara,
  } = useQuery(
    ['detail-approval-pembayaran-lemtara', params],
    () => getDetail(params.id),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  return {
    approvalPembayaranLemtara: approvalPembayaranLemtara?.data?.data.data || [],
    paginationApprovalPembayaranLemtara:
      {
        lastPage: approvalPembayaranLemtara?.data?.data?.lastPage,
        page: approvalPembayaranLemtara?.data?.data?.page,
        perPage: approvalPembayaranLemtara?.data?.data?.perPage,
        total: approvalPembayaranLemtara?.data?.data?.total,
      } || {},
    isLoadingApprovalPembayaranLemtara,
    isFetchingApprovalPembayaranLemtara,
    detailApprovalPembayaranLemtara:
      detailApprovalPembayaranLemtara?.data?.data || null,
    isLoadingDetailApprovalPembayaranLemtara,
    isFetchingDetailApprovalPembayaranLemtara,
    refetch: refetchApprovalPembayaranLemtara,
    refetchDetail: refetchDetailApprovalPembayaranLemtara,
  };
};

export default useApprovalPembayaranLemtara;

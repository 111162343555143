import {
  AtomDatePicker,
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  InputFormRadio,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const ProcurementChild = ({ tools }) => {
  const navigate = useNavigate();

  const {
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();

  const [isEdit, setIsEdit] = useState(false);
  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEdit = () => setIsEdit(!isEdit);
  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Data Pengalaman Lembaga</div>
          <div className="flex-1 text-right">
            {isEdit ? (
              <Button
                type="button"
                className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Simpan Perubahan
              </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Ubah
              </Button>
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col">
          {console.log(tools)}
          {tools.map((field, index) => {
            return (
              <>
                <div className="mt-[5px]" key={field.id}>
                  <div className="mb-7">
                    <QuestionBadge text="Apakah lembaga/ institusi/ perusahaan Anda menjalankan mekanisme procurement dalam memenuhi kebutuhan pengadaan lembaga/ institusi/ perusahaan Anda?" />
                    <InputFormRadio
                      controllerName={`tools.0.is_running_mechanism_procurement`}
                      className={`py-0 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      values={[
                        { value: true, label: "Ya" },
                        { value: false, label: "Tidak" },
                      ]}
                      disabled={!isEdit}
                    />
                  </div>
                  <div className="mb-7">
                    <QuestionBadge text="Apakah Anda memiliki aturan & ketentuan yg mengatur proses pengadaan di lembaga/ instansi/ perusahaan Anda?" />
                    <InputFormRadio
                      controllerName={`tools.0.is_any_sop`}
                      className={`py-0 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      values={[
                        { value: true, label: "Ya" },
                        { value: false, label: "Tidak" },
                      ]}
                      disabled={!isEdit}
                    />
                  </div>
                  <div className="mb-7">
                    <QuestionBadge text="Bagaimana cara Anda menyusun rencana strategis lembaga Anda?" />
                    <InputFormRadio
                      controllerName={`tools.0.formulate_strategic_plan`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      values={[
                        {
                          value: "Penunjukan Langsung",
                          label: "Penunjukan Langsung",
                        },
                        {
                          value: "Seleksi/ Lelang Tertutup",
                          label: "Seleksi/ Lelang Tertutup",
                        },
                        {
                          value: "Seleksi/ Lelang Terbuka",
                          label: "Seleksi/ Lelang Terbuka",
                        },
                      ]}
                      disabled={!isEdit}
                    />
                  </div>
                  <div className="mb-7">
                    <QuestionBadge text="Berapa nilai (nominal) minimum dari pengadaan yang biasa lembaga/ institusi/ perusahaan Anda lakukan melalui metode penujukan langsung?" />
                    <InputFormRadio
                      controllerName={`tools.0.minimum_value_procurement`}
                      className={`py-0 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      values={[
                        { value: "100 Juta", label: "100 Juta" },
                        { value: "250 Juta", label: "250 Juta" },
                        { value: "350 Juta", label: "350 Juta" },
                        { value: "500 Juta", label: "500 Juta" },
                        { value: "Nominal Lain", label: "Nominal Lain" },
                      ]}
                      disabled={!isEdit}
                    />
                  </div>
                  <div className="mb-7">
                    <QuestionBadge text="Sejauh mana Anda mengimplementasikan proses monitoring dari proses pengadaan yang dijalankan?" />
                    <InputFormRadio
                      controllerName={`tools.0.value_implementation_procurement`}
                      className={`py-0 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      values={[
                        { value: 1, label: "1" },
                        { value: 2, label: "2" },
                        { value: 3, label: "3" },
                        { value: 4, label: "4" },
                        { value: 5, label: "5" },
                      ]}
                      disabled={!isEdit}
                    />
                  </div>

                  <div className="space-y-6">
                    {" "}
                    <QuestionBadge text="Bagaimana komposisi panitia yang  lembaga/ institusi/ perusahaan Anda bentuk dalam proses pelaksanaan pengadaan?" />
                    {tools[0]?.structure_member?.map((field, i) => {
                      return (
                        <div
                          className="grid grid-cols-1 md:grid-cols-2 gap-4"
                          key={field.id}
                        >
                          <InputForm
                            controllerName={`tools.${0}.structure_member.${i}.position`}
                            className={`py-2 px-4 mt-1 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                            label={"Jabatan"}
                            placeholder={"Jabatan"}
                            disabled={!isEdit}
                          />
                          <InputForm
                            controllerName={`tools.${0}.structure_member.${i}.total_member`}
                            className={`py-2 px-4 mt-1 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                            label={"Jumlah Orang"}
                            placeholder={"Jumlah Orang"}
                            disabled={!isEdit}
                          />
                        </div>
                      );
                    })}
                    <QuestionBadge text="Apa saja pengalaman pengadaan yang pernah lembaga/institusi/ perusahaan Anda jalankan?" />
                    {tools[index]?.procurement_experience?.map((field, i) => {
                      return (
                        <>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <InputForm
                              controllerName={`tools.${0}.procurement_experience.${i}.job_name`}
                              className={`py-2 px-4 mt-1 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                              label={"Nama Paket Pekerjaan"}
                              placeholder={"Nama Paket Pekerjaan"}
                              disabled={!isEdit}
                            />
                            <InputForm
                              controllerName={`tools.${0}.procurement_experience.${i}.location`}
                              className={`py-2 px-4 mt-1 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                              label={"Lokasi"}
                              placeholder={"Lokasi"}
                              disabled={!isEdit}
                              textArea={true}
                            />
                            <InputForm
                              controllerName={`tools.${0}.procurement_experience.${i}.employer_name`}
                              className={`py-2 px-4 mt-1 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                              label={"Nama Pemberi Tugas/Pengguna Jasa"}
                              placeholder={"Nama Pemberi Tugas/Pengguna Jasa"}
                              disabled={!isEdit}
                            />
                            <InputForm
                              controllerName={`tools.${0}.procurement_experience.${i}.employer_address`}
                              className={`py-2 px-4 mt-1 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                              label={"Alamat Pemberi Tugas/Pengguna Jasa"}
                              placeholder={"Alamat Pemberi Tugas/Pengguna Jasa"}
                              disabled={!isEdit}
                              textArea={true}
                            />
                          </div>
                          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <InputForm
                              controllerName={`tools.${0}.procurement_experience.${i}.no_contract`}
                              className={`py-2 px-4 mt-1 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                              label={"Nomor Kontrak"}
                              placeholder={"Nomor Kontrak"}
                              disabled={!isEdit}
                            />
                            <AtomDatePicker
                              controllerName={`tools.${0}.procurement_experience.${i}.date_contract`}
                              className={`py-2 px-4 mt-1 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                              label={"Tanggal Kontrak"}
                              placeholder={"Tanggal Kontrak"}
                              disabled={!isEdit}
                              newDate
                            />
                            <InputForm
                              controllerName={`tools.${0}.procurement_experience.${i}.value_contract`}
                              className={`py-2 px-4 mt-1 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                              label={"Nilai Kontrak"}
                              placeholder={"Nilai Kontrak"}
                              disabled={!isEdit}
                            />
                          </div>{" "}
                          <div className=" grid grid-cols-1 md:grid-cols-2 gap-4">
                            <AtomDatePicker
                              controllerName={`tools.${0}.procurement_experience.${i}.end_date_contract`}
                              className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                              label={"Tanggal Selesai Kontrak"}
                              placeholder={"Tanggal Selesai Kontrak"}
                              disabled={!isEdit}
                              newDate
                            />
                            <AtomDatePicker
                              controllerName={`tools.${0}.procurement_experience.${i}.end_date_bast`}
                              className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                              label={"Tanggal Selesai BAST"}
                              placeholder={"Tanggal Selesai BAST"}
                              disabled={!isEdit}
                              newDate
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                {/* button Verification */}
                <RadioVerificationBadge
                  className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
                  name={`tools.${index}.result_verification`}
                  nameNotes={`tools.${index}.reason`}
                  isEditVerification={isEditVerification}
                  toogleIsEditVerification={toogleIsEditVerification}
                  errors={
                    errors?.tools
                      ? errors?.tools[index]?.result_verification
                      : false
                  }
                />
              </>
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};

export default ProcurementChild;

export const formatTotalCost = (number = 0) => {
  if (isNaN(number)) {
    number = 0;
  }

  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  })
    .format(number)
    .replace(/,00$/, "");
};

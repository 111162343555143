import React, { useState } from "react";
import classNames from "classnames";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";

const TableHeader = ({
  items,
  headerClassName,
  defaultSortOrder,
  bgColumns,
  sortMethod = "",
  setSortMethod = {},
  setSortBy = {},
}) => {
  const [sortOrder, setSortOrder] = useState(
    defaultSortOrder?.toLowerCase() ?? "asc"
  );

  const getValue = (value) => {
    if (typeof value === "string") return value;
    if (typeof value === "function") return value();
    return "";
  };

  if (!items.length) return null;
  return (
    <thead className={headerClassName}>
      <tr>
        {items.map((item, key) => {
          return (
            <th
              key={key}
              className={classNames(
                [
                  `${bgColumns ? bgColumns : `bg-[#077439]`}`,
                  "text-[#FFFFFF]",
                  "text-sm",
                  "font-semibold",
                  "capitalize",
                ],
                { "!relative": key === 0 },
                { sticky: Boolean(item?.fixed) },
                { "right-0": item?.fixed && item?.fixed === "right" },
                { "left-0": item?.fixed && item?.fixed === "left" },
                item.columnClassName && [...item.columnClassName.split(" ")]
              )}
            >
              {item?.sortable && item?.sorter ? (
                <div
                  className={`gap-2 flex cursor-pointer ${
                    item?.headerTextPosition === "end"
                      ? "justify-end"
                      : item?.headerTextPosition === "center"
                      ? "justify-center"
                      : "justify-start"
                  }`}
                  onClick={() => {
                    setSortOrder((curr) => {
                      if (curr === "asc") return "desc";
                      return "asc";
                    });
                    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";

                    // give two parameter to function sorter, first order method and secondly column table to be sorted
                    item.sorter(newSortOrder, item.dataIndex);
                  }}
                >
                  {getValue(item.title)}
                  {sortOrder === "asc" ? (
                    <BsArrowUpShort
                      size={20}
                      className="text-white cursor-pointer min-w-5"
                    />
                  ) : (
                    <BsArrowDownShort
                      size={20}
                      className="text-white cursor-pointer min-w-5"
                    />
                  )}
                </div>
              ) : (
                getValue(item.title)
              )}
              {/* {item?.sortable && sortMethod && setSortMethod ? (
                <div
                  className="gap-2 flex cursor-pointer place-content-center"
                  onClick={() => {
                    setSortMethod((curr) => {
                      if (curr === "asc") return "desc";
                      return "asc";
                    });
                    setSortBy(item?.dataIndex);
                  }}
                >
                  {getValue(item.title)}
                  {sortMethod === "desc" ? (
                    <BsArrowUpShort
                      size={20}
                      className="text-white cursor-pointer"
                    />
                  ) : (
                    <BsArrowDownShort
                      size={20}
                      className="text-white cursor-pointer"
                    />
                  )}
                </div>
              ) : (
                getValue(item.title)
              )} */}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default React.memo(TableHeader);

import { createContext, useContext, useEffect, useState } from "react";

const ProgramExperienceContext = createContext();

export const ProgramExperienceProvider = ({ children }) => {
  const [ProgramExperienceState, setProgramExperienceState] = useState({
    supporting_documents: null,
    programme: null,
  });

  const value = { ProgramExperienceState, setProgramExperienceState };

  useEffect(() => {
    let temp = JSON.stringify(ProgramExperienceState);
    sessionStorage.setItem("ProgramExperienceContext", temp);
  }, [ProgramExperienceState]);

  return (
    <ProgramExperienceContext.Provider value={value}>
      {children}
    </ProgramExperienceContext.Provider>
  );
};

export const useProgramExperienceContext = () =>
  useContext(ProgramExperienceContext);

import { yupResolver } from "@hookform/resolvers/yup";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { Button } from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
import SupportingDocument from "./SupportingDocument";
import { fileUpload, getFile } from "services/danaProgram/fileService";
import { useMutation } from "@tanstack/react-query";

const SupportingDocuments = ({
  setActiveTab,
  activeTab = 1,
  data = {},
  allData = {},
  setData = () => { },
}) => {
  const validationSchema = useMemo(() => Yup.object().shape({
    profile_organization_document: Yup.object().shape({
      status_approval: Yup.string().required('Hasil verifikasi wajib diisi'),
      notes_approval: Yup.string().nullable(true),
    })
  }));

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      profile_organization_document: {
        status_approval: "APPROVED"
      },
    }
  })

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    trigger,
  } = methods;

  useEffect(() => {
    if (data.profile_organization_document) {
      Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((keys) => {
          if (["structure_organization_document", "job_desc_document", "sop_document", "transparency_document", "anti_corruption_document", "institutional_plan_structure_document", "institutional_plan_job_desc_document", "partnership_strategy_document"].includes(keys)) {
            onLoadFile.mutate({ data: data[key][keys], key: `${key}.${keys}`, array: false })
          } else if (["partner_cooperation_documents", "subgrant_partnership_document"].includes(keys)) {
            onLoadFile.mutate({ data: data[key][keys], key: `${key}.${keys}`, array: true })
          } else {
            setValue(`${key}.${keys}`, data[key][keys]);
          }
        })
      });
      trigger();
    }
  }, [data, setValue, methods]);

  const onLoadFile = useMutation(async ({ data, key, array = false }) => {
    try {
      let newResult = [];
      if (array) {
        for (const p in data) {
          if (data[p]) {
            let reportResult = await getFile(data[p])
            reportResult = {
              url: reportResult?.data?.data?.url,
              id: reportResult?.data?.data?._id,
              size: Number(reportResult?.data?.data?.size) / 100000,
            };
            newResult.push({
              file: reportResult,
            })
          }
        }
      } else {
        if (data) {
          let reportResult = await getFile(data);
          reportResult = {
            url: reportResult?.data?.data?.url,
            id: reportResult?.data?.data?._id,
            size: Number(reportResult?.data?.data?.size) / 100000,
          };
          newResult = reportResult
        }
      }
      setValue(`${key}.showFile`, newResult)
      // clearErrors()
    } catch (error) {
      throw error;
    }
  })

  const navigate = useNavigate()

  const [isOpenModal, setIsOpenModal] = useState(false)

  const arrStaticFile = [
    {
      key: "structure_organization_document",
      keyDesc: "desc_structure_organization_document",
      name: "Dokumen struktur organisasi Lembaga/ Instansi/ perusahaan struktur Organisasi",
    },
    {
      key: "job_desc_document",
      keyDesc: "desc_job_desc_document",
      name: "Dokumen fungsi organ (job descriptions) Struktur Organisasi",
    },
    {
      key: "sop_document",
      keyDesc: "desc_sop_document",
      name: "Dokumen SOP",
    },
    {
      key: "transparency_document",
      keyDesc: "desc_transparency_document",
      name: "Tingkat Penerapan dan Akuntabilitas",
    },
    {
      key: "anti_corruption_document",
      keyDesc: "desc_anti_corruption_document",
      name: "Tingkat Pengelolaan dan Penerapan Anti Korupsi",
    },
    {
      key: "institutional_plan_structure_document",
      keyDesc: "desc_institutional_plan_structure_document",
      name: "Dokumen struktur organisasi Lembaga/ instansi/ perusahaan Rencana Kelembagaan",
    },
    {
      key: "institutional_plan_job_desc_document",
      keyDesc: "desc_institutional_plan_job_desc_document",
      name: "Dokumen fungsi organ (job descriptions) Rencana Kelembagaan",
    },
    {
      key: "partnership_strategy_document",
      keyDesc: "desc_partnership_strategy_document",
      name: "Dokumen strategi lembaga kemitraan",
    },
  ];

  const {
    fields: dataPartnerCooperation,
    append: appendDataPartnerCooperation,
    remove: removeDataPartnerCooperation,
  } = useFieldArray({
    control,
    name: 'profile_organization_document.partner_cooperation_documents.showFile'
  })

  const {
    fields: dataSubgrantPartnership,
    append: appendDataSubgrantPartnership,
    remove: removeDataSubgrantPartnership,
  } = useFieldArray({
    control,
    name: 'profile_organization_document.subgrant_partnership_document.showFile'
  })

  const saveData = async () => {
    const isValid = await trigger()
    const allValues = getValues()
    allData['profile_organization_document']['notes_approval'] = allValues?.profile_organization_document?.notes_approval
    allData['profile_organization_document']['status_approval'] = allValues?.profile_organization_document?.status_approval

    arrStaticFile.forEach(async (e) => {
      allData.profile_organization_document["desc_"+e.key] = allValues?.profile_organization_document["desc_"+e.key];
      
      let files = allValues["files_" + e.key];
      if (files) {
        const fileFormData = new FormData();
        fileFormData.append("file", files);
        const resFile = await fileUpload(fileFormData);
        const resDataFile = await resFile.data.data;

        allData.profile_organization_document[e.key] = resDataFile.id;
      }
    })
    
    console.log(allValues);
    console.log(allData);
    setData(allData)
    return isValid;
  }

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <SupportingDocument
          arrStaticFile={arrStaticFile}
          dataPartnerCooperation={dataPartnerCooperation}
          appendDataPartnerCooperation={appendDataPartnerCooperation}
          removeDataPartnerCooperation={removeDataPartnerCooperation}
          dataSubgrantPartnership={dataSubgrantPartnership}
          appendDataSubgrantPartnership={appendDataSubgrantPartnership}
          removeDataSubgrantPartnership={removeDataSubgrantPartnership}
        />
      </FormProvider>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => activeTab === 1 ? navigate(-1) : setActiveTab(activeTab - 1)}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
        {sessionStorage.getItem('isLemtaraFormDisabled') !== "true" && (
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={async () => {
                const validData = await saveData()
                if (validData)
                  setActiveTab(activeTab + 1)
              }}
            >
              Selanjutnya
              <RiArrowRightLine />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default SupportingDocuments;
import { useState, useEffect, useMemo, useCallback } from "react";
import {
  AtomDatePicker,
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  Input,
} from "components";
import ProgressStepForm from "components/molecules/ProgressStepForm";
import { FormProvider, useForm } from "react-hook-form";
import { BiPlus } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";
import { RiArrowLeftLine, RiArrowRightLine, RiSave3Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import _ from "lodash";
import axios from "axios";
import config from "app/config";
import { UseAnnualWorkPlanContext } from "../../../context/AnnualWorkPlanContext";
import Select from "react-select";
import { formattedValue } from "helpers";
import axiosInstance from "app/interceptors";
import { getListBudgetAccount } from "services/masterData/budgetAccount";
import { ConfirmationModal } from "components/molecules/Modal";

const AddAWPBudget = () => {
  const initialBudgetList = [
    // example structure of state
    // {
    //   detail: "sdsds",
    //   budget_account: [],
    //   output: "Meningkatnya Kemampuan Masyarakat Hutan dalam memaksimalkan potensi hutan",
    //   activity: "Workshop pemanfaatan potensi hutan ke masyarakat hutan",
    //   plan_time: [
    //     {
    //       time: "2024-04",
    //       person: "console log"
    //     }
    //   ],
    //   items: [
    //     {
    //       item_name: "bibit jagung",
    //       quantity: 20,
    //       unit: "bibit",
    //       volume: 100,
    //       unit_price: 50000,
    //       sub_total: 500000
    //     }
    //   ],
    //   isError: false,
    //   message: ""
    // }
  ];
  const {
    idAwp,
    setData,
    setCurrentStep,
    currentStep,
    dataAnnualWorkPlan,
    type,
  } = UseAnnualWorkPlanContext();
  // const idAwp = idInvestment;

  const [budgetList, setBudgetList] = useState(initialBudgetList);
  const [detailAWP, setDetailAWP] = useState(null);
  const [budgetAccount, setBudgetAccount] = useState([]);
  const [confirmationModal, setConfirmModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const methods = useForm();

  const links = [
    {
      label: "Pengajuan Dana",
      path: "/",
    },
    {
      label: "Annual Work Plan",
      path: "/annual-work-plan",
    },
    {
      label: "Tambah Permohonan",
    },
  ];

  const step = Number(location.pathname.split("/")[3]);

  const stepForm = [
    "General Informasi",
    "Kegiatan Utama",
    "Detail/Sub Kegiatan",
    "Anggaran Biaya",
    "Dokumen Pendukung",
  ];

  const convertMoney = (value) => {
    let oriValue = String(value);
    oriValue = oriValue.includes(".") ? oriValue.split(".").join("") : oriValue;
    let modifValue = "";
    let count = 1;
    if (oriValue !== "" && oriValue.length > 3) {
      const splitValue = oriValue.split("");
      for (let i = splitValue.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0 && i !== 0) {
          modifValue = `.${splitValue[i]}${modifValue}`;
        } else {
          modifValue = `${splitValue[i]}${modifValue}`;
        }
        count += 1;
      }
    } else {
      modifValue = oriValue;
    }

    return modifValue;
  };

  useEffect(() => {
    idAwp && fetchDetailAWP();
  }, [idAwp]);

  useEffect(() => {
    fetchBudgetAccount();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(detailAWP) && type !== "show") {
      const { output } = detailAWP;
      let arr = [];

      _.map(output, (output) => {
        _.map(output.activity, (activity) => {
          _.map(activity.detail, (detail) => {
            arr.push({
              awp_activity_detail_id: detail._id,
              detail: detail.name.label,
              budget_account: detail.budget_account,
              output: output.name,
              activity: activity.name,
              plan_time: detail.execution,
              items: detail?.budget?.map((item) => ({
                item_name: item.item_name,
                quantity: item.quantity,
                unit: item.unit,
                volume: item.volume,
                unit_price: item?.unit_price
                  ? convertMoney(item?.unit_price)
                  : null,
                sub_total: item?.sub_total
                  ? formattedValue(item?.sub_total)
                  : null,
              })),
              isError: false,
              message: "",
            });
          });
        });
      });

      setBudgetList(arr);
    }
  }, [detailAWP, type]);

  useEffect(() => {
    if (!_.isEmpty(dataAnnualWorkPlan)) {
      const { output } = dataAnnualWorkPlan;
      let arr = [];

      _.map(output, (output) => {
        _.map(output.activity, (activity) => {
          _.map(activity.detail, (detail) => {
            arr.push({
              awp_activity_detail_id: detail._id,
              detail: detail.name,
              budget_account: detail.budget_account,
              output: output.name,
              activity: activity.name,
              plan_time: detail.execution,
              items: detail.budget.map((item) => ({
                item_name: item.item_name,
                quantity: item.quantity,
                unit: item.unit,
                volume: item.volume,
                unit_price: item?.unit_price
                  ? convertMoney(item?.unit_price)
                  : null,
                sub_total: item?.sub_total
                  ? formattedValue(item?.sub_total)
                  : null,
              })),
              isError: false,
              message: "",
            });
          });
        });
      });

      setBudgetList(arr);
    }
  }, [dataAnnualWorkPlan]);

  const addItemHandler = (key) => {
    const temp = [...budgetList];
    temp[key].items.push({
      item_name: "",
      quantity: null,
      unit: "",
      volume: null,
      unit_price: null,
      sub_total: null,
    });

    setBudgetList(temp);
  };

  const onChangeItem = (e, detailKey, itemkey) => {
    const { name, value } = e.target;
    const temp = [...budgetList];

    if (name === "unit_price") {
      temp[detailKey].items[itemkey].unit_price = convertMoney(value);

      temp[detailKey].items[itemkey].sub_total =
        value.replace(/\D/g, "") *
        temp[detailKey].items[itemkey].quantity *
        parseInt(temp[detailKey].items[itemkey].volume);
    }

    if (name === "quantity") {
      temp[detailKey].items[itemkey].sub_total =
        value *
        (temp[detailKey].items[itemkey].unit_price?.replace(/\D/g, "") *
          parseInt(temp[detailKey].items[itemkey].volume));
    }

    if (name === "volume") {
      temp[detailKey].items[itemkey].sub_total =
        value *
        temp[detailKey].items[itemkey].unit_price?.replace(/\D/g, "") *
        parseInt(temp[detailKey].items[itemkey].quantity);
    }

    if (name !== "unit_price") {
      temp[detailKey].items[itemkey][name] = value;
    }

    setBudgetList(temp);
  };

  const onChangeBudgetHandler = (e, key) => {
    let temp = [...budgetList];
    temp[key].budget_account = e;

    setBudgetList(temp);
  };

  const fetchDetailAWP = async () => {
    const response = await axiosInstance.get(
      `${config.BASE_URL}/api/dana-program/v1/cms/awp?id=${idAwp}`
    );
    const data = await response.data.data;
    setDetailAWP(data);
  };

  const fetchBudgetAccount = async () => {
    const response = await getListBudgetAccount();

    const val = response.data.data.map((item) => ({
      value: item?.nama,
      label: item?.nama,
    }));

    setBudgetAccount(val);
  };

  const totalFunding = useMemo(() => {
    let total = null;

    budgetList?.forEach((obj) => {
      obj?.items?.forEach((item) => {
        total += _.isNumber(item?.sub_total)
          ? item?.sub_total
          : parseInt(item?.sub_total?.replace(/[a-zA-Z.\s]/g, ""));
      });
    });

    return !_.isNaN(total) ? formattedValue(total) : formattedValue(0);
  }, [budgetList]);

  const onSubmit = async (is_save_draft = false) => {
    if (type === "show") {
      setCurrentStep({ currentStep: "5" });
    } else {
      try {
        const getPayloadBudgeting = _.map(budgetList, (budget) => ({
          awp_activity_detail_id: budget.awp_activity_detail_id,
          budget_account: budget.budget_account,
          items: budget.items.map((item) => ({
            item_name: item.item_name,
            quantity: Number(item.quantity),
            unit: item.unit,
            volume: item.volume,
            unit_price: Number(item.unit_price.replace(/\D/g, "")),
            sub_total: Number(item.sub_total),
          })),
        }));

        const payload = {
          id: idAwp,
          total_funding: totalFunding,
          budgeting: getPayloadBudgeting,
        };

        const response = await axiosInstance.post(
          `${config.BASE_URL}/api/dana-program/v1/cms/awp/store/fourth-step`,
          payload
        );

        if (response.data.code === 200) {
          if(is_save_draft) {
            setConfirmModal(false);
            navigate('/annual-work-plan')
          } else {
            setCurrentStep({ currentStep: "5" });

          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const renderModal = () => {
    return (
      <ConfirmationModal
        open={confirmationModal}
        onClose={() => setConfirmModal(!confirmationModal)}
        onSubmit={() => {
          onSubmit(true);
        }}
      >
        Apakah anda akan menyimpan sebagai draft?
      </ConfirmationModal>
    );
  };

  return (
    <div className="flex flex-col gap-8">
      {/* <Breadcrumbs items={links} /> */}
      {/* Header */}
      {/* <div className="flex flex-col gap-1">
        <h1 className="font-medium text-3xl">Tambah Permohonan</h1>
        <p className="text-base">Silahkan daftar menggunakan data yang Anda miliki.</p>
      </div>

      <hr />

      <ProgressStepForm items={stepForm} step={step} /> */}

      {/* Information Bar */}
      {/* <div className="flex bg-[#FFFCF5] self-stretch p-4 gap-3 border border-[#F5AE70] rounded-xl">
        <FiAlertTriangle color="#BE600E" size={20} />
        <div className="flex flex-col gap-1">
          <p className="font-medium text-[#8F480A] text-sm">Lengkapi Data</p>
          <p className="text-sm">Silakan isi semua data dibawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya</p>
        </div>
      </div> */}

      {/* Card Content */}
      <FormProvider {...methods}>
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader>
              <div className="flex gap-2 items-center">
                <p>Anggaran Biaya</p>
                <span className="bg-[#FFFAEB] p-1 font-medium text-xs text-[#B54708]">
                  {totalFunding}
                </span>
              </div>
            </CardHeader>
            <CardBody>
              <div className="space-y-8">
                {/** Start of budget list */}
                {_.map(budgetList, (detail, detailKey) => (
                  <CardForm
                    key={"detail-key-" + detailKey}
                    label={
                      <div className="flex gap-2 item-center">
                        <p>
                          Detail/Sub Kegiatan {detailKey + 1} -{" "}
                          {detail?.detail?.label}
                        </p>
                        <span className="bg-[#ECFDF3] p-1 font-medium text-xs text-[#027A48] rounded">
                          Rp.
                          {new Intl.NumberFormat("en-DE").format(
                            detail?.items.length > 0
                              ? detail?.items
                                  .map((item) =>
                                    typeof item?.sub_total === "number"
                                      ? item?.sub_total
                                      : 0
                                  )
                                  .reduce((a, b) => a + b)
                              : 0
                          )}
                        </span>
                      </div>
                    }
                    buttonCollapse
                  >
                    <div className="flex flex-col w-full gap-5">
                      <div className="space-y-[9px]">
                        <label className="text-[#1D2939] font-semibold text-sm">
                          Budget Account
                          <span className="ml-1 text-error-500 text-sm">*</span>
                        </label>
                        <Select
                          className="text-sm"
                          isMulti
                          options={budgetAccount}
                          value={detail?.budget_account}
                          onChange={(e) => onChangeBudgetHandler(e, detailKey)}
                          isDisabled={type === "show"}
                        />
                      </div>

                      <div className="space-y-[9px]">
                        <label className="text-[#1D2939] font-semibold text-sm">
                          Output
                          <span className="ml-1 text-error-500 text-sm">*</span>
                        </label>
                        <Input
                          className="w-full text-sm"
                          value={detail?.output}
                          placeholder=""
                          disabled
                        />
                      </div>

                      <div className="space-y-[9px]">
                        <label className="text-[#1D2939] font-semibold text-sm">
                          Kegiatan Utama
                          <span className="ml-1 text-error-500 text-sm">*</span>
                        </label>
                        <Input
                          className="w-full text-sm"
                          value={detail?.activity}
                          placeholder=""
                          disabled
                        />
                      </div>

                      {_.map(detail?.plan_time, (plan, planKey) => (
                        <div className="flex w-full gap-6">
                          {/* <AtomDatePicker 
                              isRangeDatePicker 
                              label="Rencana Waktu Pelaksanaan"
                              wrapperClass={""}
                              format={"MM/yyyy"}
                              value={""}
                              className={"max-h-[38px] mt-0 rounded-md border-[#667085] border-solid text-sm outline-none focus:outline-none"}
                              controllerName={"implementation_time_plan"} 
                              disabled  
                            /> */}
                          <div className="flex flex-col gap-6 w-[50%]">
                            <label className="text-[#1D2939] font-semibold text-sm">
                              Rencana Waktu Pelaksanaan
                              <span className="ml-1 text-error-500 text-sm">
                                *
                              </span>
                            </label>
                            <Input
                              className={
                                "max-h-[38px] mt-0 rounded-md border-[#667085] border-solid text-sm outline-none focus:outline-none w-full"
                              }
                              type={"month"}
                              value={plan.execution_date}
                              disabled
                            />
                          </div>

                          <div className="flex-1 space-y-5">
                            <label className="text-[#1D2939] font-semibold text-sm">
                              Penanggung Jawab
                              <span className="ml-1 text-error-500 text-sm">
                                *
                              </span>
                            </label>

                            <Input
                              className="w-full text-sm"
                              value={plan.contact_person.label}
                              placeholder=""
                              disabled
                            />
                          </div>
                        </div>
                      ))}

                      {/* Information Bar */}
                      <div className="flex bg-[#FFFCF5] self-stretch p-4 gap-3 border border-[#F5AE70] rounded-xl">
                        <FiAlertTriangle color="#BE600E" size={20} />
                        <span className="text-[#8F480A] font-semibold text-sm">
                          Pada tabel dibawah, Anda dapat menambahkan
                          item/konsultan/person yang dibutuhkan untuk detail
                          kegiatan yang diatas
                        </span>
                      </div>

                      <Card>
                        <CardForm
                          label={
                            <div className="flex gap-2 item-center">
                              <p>Item/Konsultan/Person</p>
                              <span className="bg-[#ECFDF3] p-1 font-medium text-xs text-[#027A48] rounded">
                                Rp.
                                {new Intl.NumberFormat("en-DE").format(
                                  detail?.items.length > 1
                                    ? detail?.items.reduce(
                                        (acc, curr) =>
                                          acc === undefined
                                            ? 0 + parseInt(curr.sub_total)
                                            : parseInt(acc) +
                                              parseInt(curr.sub_total),
                                        0
                                      )
                                    : detail?.items.length === 1
                                    ? detail?.items[0].sub_total
                                    : 0
                                )}
                              </span>
                            </div>
                          }
                        >
                          <div className="border border-solid border-gray-200 rounded-tl-2xl rounded-tr-2xl">
                            <div className="overflow-x-auto">
                              <table className="table table-striped min-w-full">
                                <thead>
                                  <th className="bg-primary-800 text-white text-sm font-semibold capitalize rounded-tl-2xl">
                                    No
                                  </th>
                                  <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                                    Nama Item
                                  </th>
                                  <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                                    Qty
                                  </th>
                                  <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                                    Satuan/Unit
                                  </th>
                                  <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                                    Volume
                                  </th>
                                  <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                                    Harga Satuan
                                  </th>
                                  <th className="bg-primary-800 text-white text-sm font-semibold capitalize rounded-tr-2xl">
                                    Sub Total
                                  </th>
                                  {/* <th className="bg-primary-800 text-white text-sm font-semibold capitalize rounded-tr-2xl">
                                    Aksi
                                  </th> */}
                                </thead>
                                <tbody>
                                  {_.map(detail?.items, (item, itemKey) => (
                                    <tr>
                                      <td>{itemKey + 1}</td>
                                      <td>
                                        <Input
                                          className="w-full text-sm"
                                          name="item_name"
                                          value={item.item_name}
                                          onChange={(e) =>
                                            onChangeItem(e, detailKey, itemKey)
                                          }
                                          disabled={type === "show"}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="w-full text-sm"
                                          name="quantity"
                                          value={item.quantity}
                                          onChange={(e) =>
                                            onChangeItem(e, detailKey, itemKey)
                                          }
                                          disabled={type === "show"}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="w-full text-sm"
                                          name="unit"
                                          value={item.unit}
                                          onChange={(e) =>
                                            onChangeItem(e, detailKey, itemKey)
                                          }
                                          disabled={type === "show"}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="w-full text-sm"
                                          name="volume"
                                          value={item.volume}
                                          onChange={(e) =>
                                            onChangeItem(e, detailKey, itemKey)
                                          }
                                          disabled={type === "show"}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="w-full text-sm"
                                          name="unit_price"
                                          value={item.unit_price}
                                          onChange={(e) =>
                                            onChangeItem(e, detailKey, itemKey)
                                          }
                                          disabled={type === "show"}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="w-full text-sm"
                                          name="sub_total"
                                          value={
                                            _.isNumber(item.sub_total)
                                              ? formattedValue(item.sub_total)
                                              : item.sub_total
                                          }
                                          disabled
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                  <tr>
                                    <td colSpan={7} align="right">
                                      {type !== "show" && (
                                        <Button
                                          startIcon={<BiPlus size={15} />}
                                          className="bg-white border-[#D0D5DD]"
                                          onClick={() =>
                                            addItemHandler(detailKey)
                                          }
                                        >
                                          Item/Konsultan/Person
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </CardForm>
                      </Card>
                    </div>
                  </CardForm>
                ))}
                {/** End of budget card */}

                <Card>
                  <CardBody>
                    <div className="flex gap-6">
                      <div className="flex-1 space-y-[9px]">
                        <label className="text-[#1D2939] font-semibold text-sm">
                          Total Biaya Kegiatan Rupiah
                          <span className="ml-1 text-error-500 text-sm">*</span>
                        </label>

                        <Input
                          className="w-full text-sm"
                          value={totalFunding}
                          disabled
                        />
                      </div>

                      {/* <div className="flex-1 space-y-[9px]">
                        <label className="text-[#1D2939] font-semibold text-sm">
                          Total Biaya Kegiatan USD
                          <span className="ml-1 text-error-500 text-sm">*</span>
                        </label>

                        <Input
                          className="w-full text-sm"
                          value={(parseInt(totalFunding?.replace(/[^0-9]/g, "")) / 15000).toFixed(2)}
                          disabled
                        />
                        <span className="text-xs text-[#98A2B3]">
                          Kurs 1 USD = RP15.000
                        </span>
                      </div> */}
                    </div>
                  </CardBody>
                </Card>
              </div>
            </CardBody>
          </Card>
        </div>
      </FormProvider>

      {/* Button */}
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => setCurrentStep({ currentStep: "3" })}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
          {type !== "show" && (
            <Button type="button" onClick={() => setConfirmModal(true)}>
              <RiSave3Line />
              Simpan & Draft
            </Button>
          )}
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={() => onSubmit()}
          >
            Selanjutnya
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default AddAWPBudget;

import { yupResolver } from "@hookform/resolvers/yup";
import config from "app/config";
import axios from "axios";
import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Select,
} from "components";
import ProgressStepForm from "components/molecules/ProgressStepForm";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiAlertTriangle } from "react-icons/fi";
import { RiArrowLeftLine, RiArrowRightLine, RiSave3Line } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router";
import CreatableSelect from "react-select/creatable";
import * as yup from "yup";
import moment from "moment";
import {
  AnnualWorkPlanProvider,
  UseAnnualWorkPlanContext,
} from "../../../context/AnnualWorkPlanContext";
import _, { isObject } from "lodash";
import { ConfirmationModal } from "components/molecules/Modal";
import axiosInstance from "app/interceptors";

const validationSchema = yup.object().shape({
  // name: yup.string().required('Wajib diisi')
  proposal_number: yup.string().required("No Pengajuan wajib diisi"),
  // planned_year: yup.string().required("Tahun Rencana Kerja wajib diisi"),
  // awp_project: yup.string().required("Projek AWP wajib diisi"),
});

const AddAWPGeneralInformation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const investmentId = searchParams.get("investment_id");

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      proposal_number: "",
      awp_project: "",
      planned_year: "",
      thematic_programmes: null,
    },
  });

  const [optionAWPProject, setOptionAWPProject] = useState([]);
  const [optionThematic, setOptionThematic] = useState([]);
  const [outcome, setOutcome] = useState([]);
  const [output, setOutput] = useState([]);
  const [idInvestment, setIdInvestment] = useState("");
  const [optionYear, setOptionYear] = useState([]);
  const {
    setData,
    setCurrentStep,
    currentStep,
    setIdAwp,
    dataAnnualWorkPlan,
    type,
  } = UseAnnualWorkPlanContext();
  const projectName = methods.watch("awp_project");
  const [confirmModal, setConfirmModal] = useState(false);

  const years = Array.from({ length: 101 }, (_, index) => 2000 + index);
  const listYear = () => {
    try {
      const option = years.map((el) => ({
        value: el,
        label: el,
      }));

      setOptionYear(option);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchProject = async () => {
    try {
      const res = await axiosInstance.get(
        `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/list?limit=1000&page=1`
      );
      const option = res.data.data.data.map((el) => ({
        ...el,
        value: el._id,
        label: el.name,
      }));
      const filterNotShowDraft = option.filter((el) => el.status !== "draft");
      setOptionAWPProject(filterNotShowDraft);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchDetailProject = async (params) => {
    try {
      const res = await axiosInstance.get(
        `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan?id=${params}`
      );

      const option = res.data.data.step_1.thematic_programmes.map((el) => {
        if (isObject(el)) {
          return {
            value: el.value,
            label: el.label,
          };
        } else {
          return {
            value: el,
            label: el,
          };
        }
      });
      if (investmentId) {
        methods.setValue("awp_project", investmentId);
      }

      methods.setValue("thematic_programmes", option);
      setIdInvestment(res.data.data._id);

      let arrOutcome = [];
      let arrOutput = [];

      res.data.data.step_3.map((el) => {
        if (el.outcome) arrOutcome.push(el.outcome);
        if (el.output) arrOutput.push(el.output);
      });

      setOutcome(arrOutcome);
      setOutput(arrOutput);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchThematic = async () => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/api/master/v1/ThematicProgrammes/list`
    );

    const option = res.data.data.map((el) => ({
      value: el.nama,
      label: el.nama,
    }));

    setOptionThematic(option);
  };

  useEffect(() => {
    fetchProject();
    fetchThematic();
    listYear();
  }, []);

  useEffect(() => {
    if (!investmentId) return;
    fetchDetailProject(investmentId);
  }, [investmentId]);

  useEffect(() => {
    if (projectName) {
      fetchDetailProject(projectName);
    }
  }, [projectName]);

  useEffect(() => {
    if (!_.isEmpty(dataAnnualWorkPlan)) {
      methods.reset({
        proposal_number: dataAnnualWorkPlan?.proposal_number,
        awp_project: dataAnnualWorkPlan?.investment_id,
        planned_year: isNaN(dataAnnualWorkPlan?.planned_year) ? new Date(dataAnnualWorkPlan?.planned_year).getFullYear() : dataAnnualWorkPlan?.planned_year,
      });
    }
  }, [dataAnnualWorkPlan]);

  const links = [
    {
      label: "Pengajuan Dana",
      path: "/",
    },
    {
      label: "Annual Work Plan",
      path: "/annual-work-plan",
    },
    {
      label: "Tambah Permohonan",
    },
  ];

  const step = Number(location.pathname.split("/")[3]);

  const stepForm = [
    "General Informasi",
    "Kegiatan Utama",
    "Detail/Sub Kegiatan",
    "Anggaran Biaya",
    "Dokumen Pendukung",
  ];

  const onSubmit = (data, draft = false) => {
    if (type === "show") {
      setCurrentStep({ currentStep: "2" });
    } else {
      let map = data.thematic_programmes?.map((el) => el.value);
      if(dataAnnualWorkPlan?._id) {
        data.id = dataAnnualWorkPlan._id;
      }
      data.investment_id = idInvestment;
      data.outputs = output;

      data.thematic_programmes = map;

      try {
        axiosInstance
          .post(
            `${config.BASE_URL}/api/dana-program/v1/cms/awp/store/first-step`,
            data
          )
          .then((response) => {
            if (draft) {
              navigate("/annual-work-plan");
            } else {
              setIdAwp({
                idAwp: response.data.data.id,
              });
              setCurrentStep({ currentStep: "2" });
            }
          });
        // return res.data;
      } catch (err) {
        console.error(err);
      }
    }
  };

  const renderModal = () => {
    return (
      <ConfirmationModal
        open={confirmModal}
        onClose={() => setConfirmModal(!confirmModal)}
        onSubmit={() => {
          methods.handleSubmit(onSubmit)(true);
        }}
      >
        Apakah anda akan menyimpan sebagai draft?
      </ConfirmationModal>
    );
  };

  return (
    <div className="flex flex-col gap-8">
      {renderModal()}
      {/* <Breadcrumbs items={links} /> */}
      {/* Header */}
      {/* <div className="flex flex-col gap-1">
        <h1 className="font-medium text-3xl">Tambah Permohonan</h1>
        <p className="text-base">Silahkan daftar menggunakan data yang Anda miliki.</p>
      </div>

      <hr />

      <ProgressStepForm items={stepForm} step={step} /> */}

      {/* Information Bar */}
      {/* <div className="flex bg-[#FFFCF5] self-stretch p-4 gap-3 border border-[#F5AE70] rounded-xl">
        <FiAlertTriangle color="#BE600E" size={20} />
        <div className="flex flex-col gap-1">
          <p className="font-medium text-[#8F480A] text-sm">Lengkapi Data</p>
          <p className="text-sm">Silakan isi semua data dibawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya</p>
        </div>
      </div> */}

      {/* Card Content */}
      <FormProvider {...methods}>
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader>Informasi Umum</CardHeader>
            <CardBody>
              <div className="flex flex-col gap-5">
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-sm">
                    No Pengajuan
                    <span className="ml-1 text-error-500 text-sm">*</span>
                  </label>
                  <Input
                    className="w-[49%] text-sm"
                    placeholder="No Pengajuan"
                    {...methods.register("proposal_number")}
                    disabled={type === "show"}
                  />
                </div>

                <div className="flex flex-row justify-between gap-6">
                  <div className="flex-1 space-y-[9px]">
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Proyek
                      <span className="ml-1 text-error-500 text-sm">*</span>
                    </label>
                    <Select
                      controllerName={"awp_project"}
                      className="text-sm remove-input-txt-border-react-select"
                      options={optionAWPProject}
                      returnObject={false}
                      disable={type === "show"}
                    />
                  </div>
                  <div className="flex-1 space-y-[9px]">
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Tahun Rencana Kerja
                      <span className="ml-1 text-error-500 text-sm">*</span>
                    </label>
                    <Select
                      controllerName={"planned_year"}
                      className="text-sm remove-input-txt-border-react-select"
                      options={optionYear}
                      returnObject={false}
                      disable={type === "show"}
                    />
                  </div>
                </div>

                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-sm">
                    Thematic Programmes
                    <span className="ml-1 text-error-500 text-sm">*</span>
                  </label>
                  <CreatableSelect
                    name="thematic_programmes"
                    isMulti
                    {...methods.register("thematic_programmes")}
                    onChange={(e) => {
                      methods.setValue("thematic_programmes", e);
                    }}
                    className="text-sm remove-input-txt-border-react-select"
                    value={methods.watch("thematic_programmes")}
                    options={optionThematic}
                    isDisabled={type === "show"}
                  />
                </div>

                <div className="space-y-[9px]">
                  <p className="font-bold text-sm">Outcome</p>
                  <ul className="list-disc ml-4">
                    {outcome.map((el) => (
                      <li>{el}</li>
                    ))}
                  </ul>
                </div>

                <div className="space-y-[9px]">
                  <p className="font-bold text-sm">Output</p>
                  <ul className="list-disc ml-4">
                    {output.map((el) => (
                      <li>{el}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        {/* Button */}
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => navigate("/annual-work-plan")}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            {type !== "show" && (
              <Button
                type="button"
                onClick={() => setConfirmModal(true)}
                // disabled={methods.formState.isValidating || !methods.formState.isValid}
                disabled={
                  /^\s*$/.test(methods.watch("proposal_number"))
                }
              >
                <RiSave3Line />
                Simpan & Draft
              </Button>
            )}
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={() => methods.handleSubmit(onSubmit)(false)}
              disabled={
                type !== "show" &&
                (/^\s*$/.test(methods.watch("proposal_number")) ||
                  !methods.watch("awp_project") ||
                  !methods.watch("planned_year") ||
                  _.isEmpty(methods.watch("thematic_programmes")))
                // output.length === 0
              }
            >
              Selanjutnya
              <RiArrowRightLine className="text-white" />
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default AddAWPGeneralInformation;

import { Breadcrumbs, Button, SearchInput, TitleText, Table } from "components";
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";

const BasicList = ({
  links = [],
  title = "",
  columns = [],
  dataSource = [],
  className = "",
  isLoading = false,
  onCreate,
  onSearch,
  onChangePage,
  onChangeRowsPerPage,
  pagination = {},
  customFilter = null,
  customInformation = null,
  customTextAddButton = "Add Data",
  customClassnameAddButton = "text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700",
  customIconAddButton = <AiOutlinePlus className="text-white" />,
  sortMethod = "",
  setSortMethod = {},
  setSortBy = {},
}) => {
  return (
    <div
      className={
        className
          ? className
          : `sm:max-w-[480px] md:max-w-[608px] lg:max-w-[864px] xl:max-w-[75vw] min-[1517px]:max-w-[78vw] min-[1700px]:max-w-[80vw] min-[1800px]:max-w-[100vw]`
      }
    >
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3 `}>
        <TitleText className="flex-1 text-gray-900 text-lg font-semibold">
          {title}
        </TitleText>
        {Boolean(onCreate) && (
          <Button
            className={customClassnameAddButton}
            startIcon={customIconAddButton}
            onClick={onCreate}
          >
            {customTextAddButton}
          </Button>
        )}
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            {customFilter ? (
              React.cloneElement(customFilter)
            ) : (
              <div className="space-x-5 flex">
                <SearchInput placeholder="Cari" onChange={onSearch} />
              </div>
            )}
            {customInformation && customInformation}
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={columns}
              dataSource={dataSource}
              isLoading={isLoading}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={pagination}
              sortMethod={sortMethod}
              setSortMethod={setSortMethod}
              setSortBy={setSortBy}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicList;

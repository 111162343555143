/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import ReactSelect from "react-select";
import { Controller, useFormContext } from "react-hook-form";

const createOption = (label) => ({
  label,
  value: label,
});

const SelectComponent = {
  DropdownIndicator: null,
};

const CreatableInputOnly = (props) => {
  const [state, setState] = useState({
    inputValue: "",
    value: [],
  });

  useEffect(() => {
    if (props.value) {
      setState({ inputValue: "", value: [...props.value] });
    }
  }, []);

  const handleChange = (value, actionMeta) => {
    if (!value) setState((curr) => ({ ...curr, value: [] }));
    setState((curr) => ({ ...curr, value }));
    props.onChange(value);
  };
  const handleInputChange = (inputValue) => {
    setState((curr) => ({ ...curr, inputValue }));
    props.onChange(value);
  };
  const handleKeyDown = (event) => {
    const { inputValue, value } = state;
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
      case ",":
        setState((curr) => ({
          inputValue: "",
          value: [...curr.value, createOption(curr.inputValue)],
        }));
        props.onChange([...value, createOption(inputValue)]);
        event.preventDefault();
    }
  };

  const { inputValue, value } = state;

  return (
    <CreatableSelect
      classNamePrefix="my-select"
      components={SelectComponent}
      inputValue={inputValue}
      isClearable={false}
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={props?.placeholder ?? ""}
      value={value}
      isDisabled={props?.disabled}
      styles={{
        valueContainer: (styles) => ({
          ...styles,
          padding: "8px 16px",
          gap: "4px",
        }),
        multiValue: (styles) => ({
          ...styles,
          backgroundColor: "#01A24A",
          color: "#FFF",
          borderRadius: "6px",
          gap: "4px",
          padding: "2px 4px 2px 9px",
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          color: "#FFF",
        }),
        multiValueRemove: (styles) => ({
          ...styles,
          ":hover": {
            ...styles[":hover"],
            backgroundColor: "#077439",
            color: "#FFF",
          },
        }),
        placeholder: (styles) => ({
          ...styles,
          color: "rgb(71 84 103)",
          fontSize: 14,
          fontWeight: 400,
        }),
        control: (styles) => ({
          ...styles,
          borderColor: props?.invalid
            ? "#dc3545"
            : state.isFocused
            ? "#01A24A"
            : "#D0D5DD",
          ":hover": {
            ...styles[":hover"],
            borderColor: props?.invalid ? "#dc3545" : "#D0D5DD",
          },
          boxShadow: "none",
          borderRadius: "6px",
        }),
      }}
    />
  );
};

const CustomSelectForm = (props) => {
  const { name, placeholder, type, disabled = false, controllerName } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <div className="w-full">
            {type === "creatable" ? (
              <CreatableInputOnly
                onChange={(value) => {
                  onChange(value);
                }}
                onBlur={onBlur}
                value={value}
                name={name}
                placeholder={placeholder}
                invalid={invalid}
                disabled={disabled}
              />
            ) : (
              <ReactSelect
                {...props}
                classNamePrefix="my-select"
                placeholder={placeholder}
                value={value}
                onChange={(val) => {
                  onChange(val);
                  if (props?.onChange) props.onChange(val);
                }}
                onBlur={onBlur}
                isDisabled={disabled}
                styles={{
                  placeholder: (styles) => ({
                    ...styles,
                    color: "rgb(71 84 103)",
                    fontSize: 14,
                    fontWeight: 400,
                    padding: `${controllerName === "tax_types" && "7px"} `,
                  }),
                  control: (styles, state) => ({
                    ...styles,
                    borderColor: invalid
                      ? "#F04438"
                      : state.isFocused
                      ? "#01A24A"
                      : "#D0D5DD",
                    ":hover": {
                      ...styles[":hover"],
                      borderColor: invalid ? "#F04438" : "#D0D5DD",
                    },
                    boxShadow: "none",
                    borderRadius: "6px",
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    color: "rgb(71 84 103)",
                    fontSize: 14,
                    padding: `${controllerName === "tax_types" && "7px"} `,
                  }),
                  indicatorContainer: (styles, { isFocused }) => ({
                    ...styles,
                    color: isFocused ? "rgb(71 84 103)" : "rgb(71 84 103)",
                    ":hover": {
                      ...styles[":hover"],
                      color: "rgb(71 84 103)",
                    },
                  }),
                  option: (
                    styles,
                    { data, isSelected, isFocused, isDisabled }
                  ) => ({
                    ...styles,
                    cursor: isDisabled ? "not-allowed" : "auto",
                    backgroundColor: isDisabled
                      ? undefined
                      : isSelected
                      ? "#01A24A"
                      : isFocused
                      ? "#EEFFF4"
                      : undefined,
                    color: isDisabled
                      ? "hsl(0, 0%, 80%)"
                      : isSelected
                      ? "#fff"
                      : isFocused
                      ? "rgb(71 84 103)"
                      : "rgb(71 84 103)",
                  }),
                }}
              />
            )}
            {invalid && (
              <div className="text-xs text-error-600 mt-2">
                {error?.message}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default CustomSelectForm;

import React, { useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import { BiPencil } from "react-icons/bi";
import { useFormContext } from "react-hook-form";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import AtomSelect from "components/atoms/Select";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";

const ResponsibleInformation = ({
  openModal = () => console.log(`open`),
  isEdit = false,
  toogleIsEdit = () => {},
}) => {
  const navigate = useNavigate();

  const { 
    watch, 
    getValues, 
    formState: { errors, isValid } 
  } = useFormContext();

  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Informasi Penanggung Jawab</div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputForm
            controllerName={"general_information.contact_person"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Nama Narahubung"}
            placeholder={"Nama Narahubung"}
            required
            disabled={!isEdit}
          />
          {/* <AtomSelect
            controllerName={"general_information.gender"}
            options={[{
              label: "Laki-laki",
              value: 'L'
            },{
              label: "Perempuan",
              value: 'P'
            }]}
            className="basic-single mt-2"
            label="Jenis Kelamin"
            required
            returnObject={false}
            disabled={!isEdit}
          /> */}
          <InputForm
            controllerName={"general_information.directors"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Nama Direktur/Pimpinan"}
            placeholder={"Nama Direktur/Pimpinan"}
            required
            disabled={!isEdit}
          />

          <InputForm
            controllerName={"general_information.email_pic"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Email"}
            placeholder={"Email"}
            required
            disabled={!isEdit}
          />
          <InputForm
            controllerName={"general_information.phone_pic"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"No Telepon"}
            placeholder={"No Telepon"}
            required
            disabled={!isEdit}
          />
        </div>
        {/* button Verification */}
        <RadioVerificationBadge 
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"general_information.status_approval_general_pic"}
          nameNotes={"general_information.notes_approval_general_pic"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={errors?.general_information?.status_approval_general_pic ? errors?.general_information?.status_approval_general_pic : false}
        />
      </CardBody>
    </Card>
  );
};

export default ResponsibleInformation;

import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { Button } from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormInstitutionalPlan from "./Form/FormInstitutionalPlan";
import { FormModal } from "components/molecules/Modal";
import { useSelector } from "react-redux";

export const optTimeCreatePlanStrategic = [
  {
    value: "1-2 minggu",
    label: "1-2 minggu",
  },
  {
    value: `1-2 Bulan`,
    label: `1-2 Bulan`,
  },
  {
    value: `3-4 Bulan`,
    label: `3-4 Bulan`,
  },
  {
    value: `Diatas 4 Bulan`,
    label: `Diatas 4 Bulan`,
  },
];

export const optYesOrNo = [
  {
    label: "Ya",
    value: "Ya"
  },
  {
    label: "Tidak",
    value: "Tidak"
  },
]

const InstitutionalPlan = ({
  setActiveTab,
  activeTab = 1,
  data = {},
  allData = {},
  setData = () => {}
}) => {

  const masterData = useSelector((state) => state?.draftMasterData?.data || {})

  const [isEdit, setIsEdit] = useState(false);
  const toogleIsEdit = () => setIsEdit(!isEdit);

  const validationSchema = useMemo(() => Yup.object().shape({
    institutional_plan: Yup.object().shape({
      status_approval: Yup.string().required(`Hasil verifikasi wajib diisi`),
      notes_approval: Yup.string().nullable(true),
      plan_desc: Yup.string().trim(),
      plan_strategy_organization_time: Yup.string().required(`plan strategy organization time`),
      plan_strategy_organization: Yup.mixed(),
      strategy_master: Yup.array().of(
        Yup.object().shape({
          position: Yup.mixed().required(`Position shouldn't be empty`),
        })
      ),
      hirarki_position: Yup.mixed(),
    })
  }));

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      institutional_plan: {
        status_approval: "APPROVED",
        decisionMaker: "Ya",
      }
    }
  });

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    trigger,
  } = methods;

  const {
    fields: strategyMaster,
    append: appendstrategyMaster,
    remove: removestrategyMaster,
  } = useFieldArray({
    control,
    name: 'institutional_plan.strategy_master'
  })

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((keys) => {
          if (keys === "hirarki_position"){
            const newMap = data[key][keys].map((e) => {
              return {
                ...masterData?.jabatan.find((ee) => ee?.value === e)
              }
            })
            setValue(`${key}.${keys}`, newMap);
          } else if (keys === "decisionMaker"){
            if (optYesOrNo.map((e) => e?.value).includes(data[key][keys])){
              setValue(`${key}.${keys}`, data[key][keys]);
            }
          } else if (keys === "plan_strategy_organization_time"){
            if (optTimeCreatePlanStrategic.map((e) => e?.value).includes(data[key][keys])){
              setValue(`${key}.${keys}`, data[key][keys]);
            }
          } else {
            setValue(`${key}.${keys}`, data[key][keys]);
          }
          // console.log(`${key}.${keys}`, data[key][keys])
        })
      });
      trigger();
    }
  }, [data, setValue, methods]);

  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const saveData = async () => {
    const isValid = await trigger()
    const allValues = getValues()
    allValues['institutional_plan']['hirarki_position'] = allValues?.institutional_plan?.hirarki_position.map((e) => e?.value)
    allData['institutional_plan'] = allValues.institutional_plan
    setData(allData)
    return isValid;
  }
  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <FormInstitutionalPlan 
          openModal={() => setIsOpenModal(true)}
          isEdit={isEdit}
          toogleIsEdit={toogleIsEdit}
          strategyMaster={strategyMaster}
          appendstrategyMaster={appendstrategyMaster}
          removestrategyMaster={removestrategyMaster}
          masterData={masterData}
          optTimeCreatePlanStrategic={optTimeCreatePlanStrategic}
          optYesOrNo={optYesOrNo}
        />
        {/* <Card>
          <CardHeader>
            <div>Rekomendasi Verificator</div>
          </CardHeader>
          <CardBody>
            <div className="space-y-5">
              <div className="space-y-2">
                <TitleText className="font-bold">
                  Rekomendasi Verificator
                </TitleText>
                <InputForm
                  controllerName={"verificatorRecomendation"}
                  className={`py-2 border w-full rounded-md flex-1`}
                  label={''}
                  placeholder={'Verifikasi Remokendasi'}
                  textArea
                />
              </div>
            </div>
          </CardBody>
        </Card> */}
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => activeTab === 1 ? navigate(-1) : setActiveTab(activeTab - 1)}
            >
              <RiArrowLeftLine/>
              Kembali
            </Button>
          </div>
          {sessionStorage.getItem('isLemtaraFormDisabled') !== "true" && (
            <div className="block md:flex items-center justify-between gap-2">
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={async () => {
                  const validData = await saveData()
                  if (validData)
                    setActiveTab(activeTab + 1)
                }}
              >
                Selanjutnya
                <RiArrowRightLine/>
              </Button>
            </div>
          )}
        </div>
        {/* form modal */}
        <FormModal 
          title={``}
          open={isOpenModal}
          onClose={() => setIsOpenModal(!isOpenModal)}
          onSubmit={() => {
            setIsOpenModal(!isOpenModal)
          }}
          positiveConfirm={`Ya`}
          negativeConfirm={`Tidak`}
          size={`w-3/12`}
        />
      </FormProvider>
    </div>
  );
};

export default InstitutionalPlan;

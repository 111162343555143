import { getErrorMessage } from "helpers"
import { enqueueSnackbar } from "notistack"
import { getRasio } from "services/danaProgram/rasioValue"
import { useQuery } from "@tanstack/react-query";
import { ToastContext } from "components/atoms/Toast";
import { useContext } from "react";

const useRasioValue = ({
  id,
}) => {
  const { setShowToast, showToastMessage } = useContext(ToastContext);

  const objQuery = {
    id
  }

  const {
    data: rasioData,
    isLoading: isLoadingRasioData,
    isFetching: isFetchingRasioData,
    refetch: refetchRasioData,
    isPreviousData
  } = useQueryRasio(objQuery)

  return {
    rasioData: rasioData?.data?.data,
    refetch: refetchRasioData,
    isPreviousData,
  }
}

export const useQueryRasio = (param) => {
  return useQuery({
    queryKey: ['list-ratio', param],
    queryFn: () => getRasio(param),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      })
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })
}

export default useRasioValue
import { useMutation } from "@tanstack/react-query";
import { submitDocument } from "services/danaProgram/torService";

export const useAdditionalDocsMutations = () => {
  const submitDocs = useMutation({
    mutationFn: (params) => {
      return submitDocument(params);
    },
  });

  return {
    submitDocs,
  };
};

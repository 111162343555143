import { Breadcrumbs, Button } from "components";
import React, { useEffect, useState } from "react";
import TorForm from "./Partial/Forms/Tor";
import { useLocation, useNavigate } from "react-router";
import StepWizzard from "components/molecules/StepWizzard";
import RabForm from "./Partial/Forms/Rab";
import { useCreateStore } from "./store";
import RecapForm from "./Partial/Forms/Recap";
import AdditionalDocsForm from "./Partial/Forms/AdditionalDocs";

const links = [
  {
    label: "Daftar Pengajuan ToR",
    path: "/tor",
  },
  {
    label: "Buat Baru",
  },
];

const stepForm = ["TOR", "RAB", "RECAP", "DOKUMEN PENDUKUNG"];

const TorCreate = () => {
  const isEdit = !useLocation()?.pathname.includes("detail");
  const { step, setStep } = useCreateStore();
  const navigate = useNavigate();

  const [savedStep, setSavedStep] = useState(sessionStorage.getItem("step"));
  const [activityDetails, setActivityDetail] = useState([]);

  useEffect(() => {
    setSavedStep(sessionStorage.getItem("step"));
    if (savedStep !== null && !isNaN(savedStep) && savedStep !== undefined) {
      const parsedStep = parseInt(savedStep, 10);
      if (parsedStep >= 1 && parsedStep <= 4) {
        setStep(parsedStep);
      } else {
        console.log("Invalid step value in sessionStorage, resetting to 1");
        sessionStorage.setItem("step", "1");
        setStep(1);
      }
    } else {
      console.log("Invalid step value in sessionStorage, resetting to 1");
      sessionStorage.setItem("step", "1");
      setStep(1);
    }
  }, [savedStep, step, setStep]);

  return (
    <div className="relative overflow-hidden">
      <div className="bg-transparent flex items-center flex-wrap flex-1 mb-[24px]">
        <Breadcrumbs items={links} />
      </div>
      <div className="mb-10">
        <StepWizzard items={stepForm} step={step} />
      </div>
      {step === 1 && (
        <TorForm
          setSavedStep={setSavedStep}
          isEdit={isEdit}
          activityDetails={activityDetails}
          setActivityDetail={setActivityDetail}
        />
      )}
      {step === 2 && (
        <RabForm
          setSavedStep={setSavedStep}
          setStep={setStep}
          isEdit={isEdit}
          activityDetails={activityDetails}
          setActivityDetail={setActivityDetail}
        />
      )}
      {step === 3 && <RecapForm setSavedStep={setSavedStep} isEdit={isEdit} />}
      {step === 4 && (
        <AdditionalDocsForm setSavedStep={setSavedStep} isEdit={isEdit} />
      )}
    </div>
  );
};

export default TorCreate;

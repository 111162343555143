import { Button, Card, CardBody, CardHeader, InputForm, TitleText } from "components";
import OptionRadioBadge from "components/molecules/Badge/OptionRadioBadge";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import RadioBadge from "components/molecules/Badge/RadioBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { useNavigate } from "react-router";

const FormInstitutionalGovernance = ({
  openModal = () => console.log(`open`),
  isEdit = false,
  toogleIsEdit = () => {}
}) => {
  const navigate = useNavigate();

  const { 
    watch, 
    getValues,
    formState: { errors, isValid } 
  } = useFormContext();
  const [isEditVerification, setIsEditVerification] = useState(false)

  const toogleIsEditVerification = () => setIsEditVerification(!isEditVerification)

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Tata Kelola Kelembagaan</div>
          <div className="flex-1 text-right">
            {sessionStorage.getItem(`isLemtaraFormDisabled`) !== "true" && (isEdit ? (
              <Button
              type="button"
              className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
              onClick={toogleIsEdit}
            >
              <BiPencil/>
              Simpan Perubahan
            </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil/>
                Ubah
              </Button>
            ))}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col">
          <QuestionBadge 
            text="Bagaimana Tingkat Penerapan Akuntabilitas dan Transparansi di Lembaga/Institusi/Perusahaan anda?"
          />
          <RadioBadge
            arrData={new Array(5).fill(`a`).map((e, i) => {
              return {
                label: String(i + 1),
                value: i + 1
              }
            })}
            isEdit={isEdit}
            name={`institutional_governance.institutional_transparency`}
            className={`mt-[20px] flex gap-[18px]`}
            errors={errors?.institutional_governance?.institutional_transparency ? errors?.institutional_governance?.institutional_transparency : false}
          />
        </div>
        <div className="flex flex-col mt-[24px]">
          <QuestionBadge 
            text="Sejauh mana tingkat Pengelolaan dan penerapan anti korupsi di lembaga Anda? (dari skala 1-5)"
          />
          <RadioBadge
            arrData={new Array(5).fill(`a`).map((e, i) => {
              return {
                label: String(i + 1),
                value: i + 1
              }
            })}
            isEdit={isEdit}
            name={`institutional_governance.anti_corruption`}
            className={`mt-[20px] flex gap-[18px]`}
            errors={errors?.institutional_governance?.anti_corruption ? errors?.institutional_governance?.anti_corruption : false}
          />
        </div>
        {/* button Verification */}
        <RadioVerificationBadge 
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"institutional_governance.status_approval"}
          nameNotes={"institutional_governance.notes_approval"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={errors?.institutional_governance?.status_approval ? errors?.institutional_governance?.status_approval : false}
        />
      </CardBody>
    </Card>
  )
};

export default FormInstitutionalGovernance;
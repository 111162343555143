import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { getList } from "services/danaProgram/awsService";
import { getErrorMessage } from "helpers";

const useAnnualWorkplan = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== undefined && value !== "")
  );

  const {
    data: annualWorkPlanData,
    isLoading: isLoadingAnnualWorkPlanData,
    isFetching: isFetchingAnnualWorkPlanData,
    refetch: refetchAnnualWorkPlanData,
  } = useQuery(
    ['list-annualworkplan', params],
    () => getList(queryParams),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  );
  
  return {
    annualWorkPlanData: annualWorkPlanData?.data?.data.data || [],
    paginationAwsData: { 
      lastPage: annualWorkPlanData?.data?.data?.lastPage,
      page: annualWorkPlanData?.data?.data?.page,
      perPage: annualWorkPlanData?.data?.data?.perPage,
      total: annualWorkPlanData?.data?.data?.total
    } || {},
    isLoadingAnnualWorkPlanData,
    isFetchingAnnualWorkPlanData,
    refetch: refetchAnnualWorkPlanData,
  };
};

export default useAnnualWorkplan;

import { useMutation } from "@tanstack/react-query";
import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  InputFormUpload,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import { getFile } from "services/danaProgram/fileService";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const SupportingDocumentChild = ({ safeguards, data, methods }) => {
  const navigate = useNavigate();

  const {
    watch,
    getValues,
    formState: { errors, isValid }
  } = useFormContext();

  const [documentData, setDocumentData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [isEdit, setIsEdit] = useState(false);
  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEdit = () => setIsEdit(!isEdit);
  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  const handleClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Dokumen Pendukung Verifikasi</div>
          <div className="flex-1 text-right">
            {sessionStorage.getItem(`isLemtaraFormDisabled`) !== "true" && (isEdit ? (
              <Button
                type="button"
                className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Simpan Perubahan
              </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Ubah
              </Button>
            ))}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col justify-center items-center gap-[24px]">
          {safeguards &&
            safeguards?.map((field) => {
              return (
                <>
                  <div
                    key={field.id}
                    className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full"
                  >
                    <div className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl w-full">
                      <div className="flex gap-4">
                        <div>
                          <div className="bg-[#EEFFF4] rounded-full p-4">
                            <RiFile2Line className="text-primary-600 text-[28px]" />
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="font-medium">{field.key}</span>
                          <span>{field.size} MB</span>
                          <span
                            onClick={() => handleClick(field.url)}
                            style={{
                              cursor: "pointer",
                              color: "#00BD52",
                            }}
                            className="font-semibold text-primary-600"
                          >
                            Lihat Dokumen
                          </span>
                        </div>

                      </div>

                      {isEdit &&
                        <InputFormUpload
                          controllerName={"document_support"}
                          maxSize={102400}
                          label=" "
                          // isMulti
                          hideSectionUpload={!isEdit}
                          listFiles={[watch(`document_support`)] ?? []}
                        />
                      }
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        {/* button Verification */}
        <RadioVerificationBadge
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"status_approval"}
          nameNotes={"notes_approval"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={errors?.status_approval ? errors?.status_approval : false}
        />
      </CardBody>
    </Card>
  );
};

export default SupportingDocumentChild;

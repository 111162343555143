const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL || "https://bpldh-api-v2.groot.id",
  DAPROG_API_URL: process.env.REACT_APP_BASE_URL + "/api/dana-program/v1/cms",
};

export const config_master = {
  BASE_URL: "https://bpldh-api-v2.groot.id/api/master",
};

export const AUTOSAVE_DURATION = parseFloat(
  process.env.REACT_APP_AUTOSAVE_DURATION || 5 * 60 * 1000
); // in ms. DEFAULT: 5 min
export const MAX_DOC_UPLOAD = parseFloat(
  process.env.REACT_APP_MAX_DOC_UPLOAD || 10 * 1024 * 1024
); // in bytes. DEFAULT: 10MB
export const MAX_IMG_UPLOAD = parseFloat(
  process.env.REACT_APP_MAX_IMG_UPLOAD || 10 * 1024 * 1024
); // in bytes. DEFAULT: 10MB
export const DOC_UPLOAD_EXT =
  process.env.REACT_APP_DOC_UPLOAD_EXT?.trim()?.split(",") || [
    "jpg",
    "png",
    "jpeg",
    "pdf",
    "docx",
  ];
export const IMG_UPLOAD_EXT =
  process.env.REACT_APP_IMG_UPLOAD_EXT?.trim()?.split(",") || [
    "jpg",
    "png",
    "jpeg",
  ];

export const general_config_v2 = {
  BASE_URL:
    "https://bpldh-api-v2.groot.id/api/fdb-v2",
};

export default config;

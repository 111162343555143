import { RiInformationLine, RiQuestionLine } from "react-icons/ri";

const InfoBadge = ({
  text = "The Info"
}) => {
  return (
    <div
      className="flex items-center gap-[12px] border-[#0086C9] border-[2px] bg-[#F5FBFF] p-4 rounded-[12px]"
    >
      <RiInformationLine className="text-[35px] text-[#0086C9]" />
      <div className="text-[14px] text-[#026AA2] font-[600]">{text}</div>
    </div>
  )
}

export default InfoBadge;
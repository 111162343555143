import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import TabContainer from "components/atoms/TabContainer";
import TabHeader from "components/atoms/TabHeader";
import TabContent from "components/atoms/TabContent";

const Tab = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);
  const methods = useForm();

  const onTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <FormProvider {...methods}>
      <form>
        <TabContainer>
          <TabHeader
            tabs={tabs}
            activeTab={activeTab}
            onTabClick={onTabClick}
          />
          <TabContent activeTab={activeTab}>
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab-panel ${activeTab === index ? "active" : ""}`}
              >
                {tab.content}
              </div>
            ))}
          </TabContent>
        </TabContainer>
      </form>
    </FormProvider>
  );
};

export default Tab;

import { Breadcrumbs } from "components";
import ProgressStepForm from "components/molecules/ProgressStepForm";
import { FiAlertTriangle } from "react-icons/fi";
import Step1 from "../addInformation";
import Step2 from "../addMainActivity";
import Step3 from "../addDetailActivity";
import Step4 from "../addBudget";
import Step5 from "../addDocument";
import { useEffect } from "react";
import {
  AnnualWorkPlanProvider,
  UseAnnualWorkPlanContext,
} from "../../../context/AnnualWorkPlanContext";
import config from "app/config";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import axiosInstance from "app/interceptors";

const DetailAnnualWorkPlan = () => {
  const location = useLocation();
  const { idInvestment, idProposal, currentStep, type, fetchedData, setData } =
    UseAnnualWorkPlanContext();

  let { id } = useParams();

  const fetchAnnualWorkPlan = async () => {
    try {
      const res = await axiosInstance.get(
        `${config.BASE_URL}/api/dana-program/v1/cms/awp?id=${id}`
      );
      setData({
        dataAnnualWorkPlan: res?.data?.data,
        idAwp: res?.data?.data?._id,
        idInvestment: res?.data?.data?.step_1?.investment_id,
        currentStep: 1,
        type: "show",
        fetchedData: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    id && fetchAnnualWorkPlan();
  }, [id]);

  const links = [
    {
      label: "Pengajuan Dana",
      path: "/",
    },
    {
      label: "Annual Work Plan",
      path: "/annual-work-plan",
    },
    {
      label: "Detail Permohonan",
    },
  ];

  const step = Number(location.pathname.split("/")[3]);

  const stepForm = [
    "General Informasi",
    "Kegiatan Utama",
    "Detail/Sub Kegiatan",
    "Anggaran Biaya",
    "Dokumen Pendukung",
  ];

  return (
    <div className="flex flex-col gap-8">
      <Breadcrumbs items={links} />
      {/* Header */}
      <div className="flex flex-col gap-1">
        <h1 className="font-medium text-3xl">Detail Permohonan</h1>
        <p className="text-base">
          Silahkan daftar menggunakan data yang Anda miliki.
        </p>
      </div>

      <hr />

      <ProgressStepForm items={stepForm} step={currentStep} />

      {/* Information Bar */}
      <div className="flex bg-[#FFFCF5] self-stretch p-4 gap-3 border border-[#F5AE70] rounded-xl">
        <FiAlertTriangle color="#BE600E" size={20} />
        <div className="flex flex-col gap-1">
          <p className="font-medium text-[#8F480A] text-sm">Lengkapi Data</p>
          <p className="text-sm">
            Silakan isi semua data dibawah ini untuk bisa melanjutkan pengisian
            ke tahap selanjutnya
          </p>
        </div>
      </div>

      {currentStep === 1 ? (
        <Step1 />
      ) : currentStep === 2 ? (
        <Step2 />
      ) : currentStep === 3 ? (
        <Step3 />
      ) : currentStep === 4 ? (
        <Step4 />
      ) : (
        <Step5 />
      )}
    </div>
  );
};

const DetailAnnualWorkPlanWuthContext = () => {
  return (
    <AnnualWorkPlanProvider>
      <DetailAnnualWorkPlan />
    </AnnualWorkPlanProvider>
  );
};
export default DetailAnnualWorkPlanWuthContext;

import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
} from "components";
import AtomSelect from "components/atoms/Select";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import RadioBadge from "components/molecules/Badge/RadioBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { changeAreaCoverage } from "helpers";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { useNavigate } from "react-router";

const FormPartnership = ({
  openModal = () => console.log(`open`),
  isEdit = false,
  toogleIsEdit = () => { },
  partner = [],
  appendPartner = () => { },
  removePartner = () => { },
  masterData = {},
  partnershipAreaMasterData = [],
  setPartnershipAreaMasterData = () => { },
  optCountExpMitra = [],
  listBidangKerjasama
}) => {
  const navigate = useNavigate();

  const {
    watch,
    getValues,
    formState: { errors, isValid }
  } = useFormContext();
  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Daftar Rekanan/ Mitra</div>
          <div className={"flex-1 text-right"}>
            {sessionStorage.getItem(`isLemtaraFormDisabled`) !== "true" && (isEdit ? (
              <Button
                type="button"
                className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Simpan Perubahan
              </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Ubah
              </Button>
            ))}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col">
          <QuestionBadge text="Berapa Banyak Mitra yang Pernah Bekerja Sama dengan Lembaga/ Institusi/ Perusahaan Anda?" />
          <RadioBadge
            arrData={optCountExpMitra}
            isEdit={isEdit}
            name={`partnership.partners_total`}
            className={`mt-[11px] flex gap-[18px]`}
            errors={errors?.partnership?.partners_total ? errors?.partnership?.partners_total : false}
          />
          <div className={`mt-[49px]`}>
            <QuestionBadge text="Sejauh mana tingkat Pengelolaan dan penerapan anti korupsi di lembaga Anda? (dari skala 1-5)" />
          </div>
          <RadioBadge
            arrData={new Array(11).fill(`a`).map((e, i) => {
              return {
                label: String(i),
                value: i
              }
            })}
            isEdit={isEdit}
            name={`partnership.level_anti_korupsi`}
            className={`mt-[11px] flex gap-[18px]`}
            errors={errors?.partnership?.level_anti_korupsi ? errors?.partnership?.level_anti_korupsi : false}
          />
        </div>
        <div className={"mt-[31px]"}>
          {partner?.length > 0 &&
            partner?.map((e, i) => {
              return (
                <CardForm
                  key={e.id}
                  label={`Rekanan/ Mitra ${i + 1}`}
                  buttonCollapse={true}
                >
                  <div className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <InputForm
                        controllerName={`partnership.partner.${i}.name`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                        label={`Nama Rekanan Mitra`}
                        placeholder={`Nama Rekanan Mitra`}
                        required
                        disabled={!isEdit}
                      />
                      <AtomSelect
                        controllerName={`partnership.partner.${i}.cooperation`}
                        options={listBidangKerjasama}
                        className="basic-single mt-2"
                        label="Bidang Kerjasama"
                        multiple={true}
                        required
                        disable={!isEdit}
                      />
                      <InputForm
                        controllerName={`partnership.partner.${i}.start_date`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                        label={"Tanggal Mulai Kerjasama"}
                        placeholder={"Tanggal Mulai Kerjasama"}
                        isDate
                        required
                        disabled={!isEdit}
                      />
                      <InputForm
                        controllerName={`partnership.partner.${i}.end_date`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                        label={"Tanggal Selesai Kerjasama"}
                        placeholder={"Tanggal Selesai Kerjasama"}
                        isDate
                        required
                        disabled={!isEdit}
                      />
                      <div className={"mt-[11px] col-span-2"}>
                        <span className={"text-[#01A24A] font-[600]"}>
                          Alamat Rekaman/ Mitra
                        </span>
                      </div>
                      <AtomSelect
                        controllerName={`partnership.partners.${i}.nation`}
                        options={partnershipAreaMasterData[i].negara}
                        className="basic-single mt-2"
                        label="Negara"
                        disable={!isEdit}
                        changeDependsDropdown={{
                          isArray: true,
                          index: i,
                          function: changeAreaCoverage,
                          options: partnershipAreaMasterData,
                          masterData,
                          fieldChange: 'provinsi',
                          setState: setPartnershipAreaMasterData,
                        }}
                        returnObject={
                          typeof getValues(`partnership.partner.${i}.nation`) ===
                          "object"
                        }
                      />
                      <AtomSelect
                        controllerName={`partnership.partner.${i}.province_id`}
                        options={partnershipAreaMasterData[i].provinsi}
                        className="basic-single mt-2"
                        label="Provinsi"
                        disable={!isEdit}
                        changeDependsDropdown={{
                          isArray: true,
                          index: i,
                          function: changeAreaCoverage,
                          options: partnershipAreaMasterData,
                          masterData,
                          fieldChange: 'kotaKabupaten',
                          setState: setPartnershipAreaMasterData,
                        }}
                        returnObject={
                          typeof getValues(`partnership.partner.${i}.province_id`) ===
                          "object"
                        }
                      />
                      <AtomSelect
                        controllerName={`partnership.partner.${i}.city_id`}
                        options={partnershipAreaMasterData[i].kotaKabupaten}
                        className="basic-single mt-2"
                        label="Kota/Kabupaten"
                        disable={!isEdit}
                        changeDependsDropdown={{
                          isArray: true,
                          index: i,
                          function: changeAreaCoverage,
                          options: partnershipAreaMasterData,
                          masterData,
                          fieldChange: 'kecamatan',
                          setState: setPartnershipAreaMasterData,
                        }}
                        returnObject={
                          typeof getValues(`partnership.partner.${i}.city_id`) ===
                          "object"
                        }
                      />
                      <AtomSelect
                        controllerName={`partnership.partner.${i}.district_id`}
                        options={partnershipAreaMasterData[i].kecamatan}
                        className="basic-single mt-2"
                        label="Kecamatan"
                        disable={!isEdit}
                        changeDependsDropdown={{
                          isArray: true,
                          index: i,
                          function: changeAreaCoverage,
                          options: partnershipAreaMasterData,
                          masterData,
                          fieldChange: 'kelurahan',
                          setState: setPartnershipAreaMasterData,
                        }}
                        returnObject={
                          typeof getValues(`partnership.partner.${i}.district_id`) ===
                          "object"
                        }
                      />
                      <AtomSelect
                        controllerName={`partnership.partner.${i}.village_id`}
                        options={partnershipAreaMasterData[i].kelurahan}
                        className="basic-single mt-2"
                        label="Kelurahan"
                        returnObject={false}
                        disable={!isEdit}
                        changeDependsDropdown={{
                          isArray: true,
                          index: i,
                          function: changeAreaCoverage,
                          options: partnershipAreaMasterData,
                          masterData,
                          fieldChange: 'kodePos',
                          setState: setPartnershipAreaMasterData,
                        }}
                      />
                      <AtomSelect
                        controllerName={`partnership.partner.${i}.zip_code`}
                        options={partnershipAreaMasterData[i].kodePos}
                        className="basic-single mt-2"
                        label="Kode Pos"
                        returnObject={typeof getValues(`partnership.partner.${i}.zip_code`) === "object"}
                        disable={!isEdit}
                      />
                      <div className="col-span-2">
                        <InputForm
                          controllerName={`partnership.partner.${i}.address`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                          label={"Alamat Lengkap"}
                          placeholder={"Alamat Lengkap"}
                          rows="5"
                          textArea
                          disabled={!isEdit}
                        />
                      </div>
                    </div>
                  </div>
                </CardForm>
              );
            })}
        </div>
        {/* button Verification */}
        <RadioVerificationBadge
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"partnership.status_approval"}
          nameNotes={"partnership.notes_approval"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={errors?.partnership?.status_approval ? errors?.partnership?.status_approval : false}
        />
      </CardBody>
    </Card>
  );
};

export default FormPartnership;

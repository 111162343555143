import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import {
  getActivityReport,
  getActivityReportList,
} from "services/danaProgram/danaProgramService";
import { getAllEmployee } from "services/danaProgram/masterDataService";

const useActivityReport = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: ActivityReportList,
    isLoading: isLoadingActivityReportList,
    isFetching: isFetchingActivityReportList,
    refetch: refetchActivityReportList,
  } = useQuery(
    ["list-ActivityReportList"],
    () => getActivityReportList(params),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: ActivityReport,
    isLoading: isLoadingActivityReport,
    isFetching: isFetchingActivityReport,
    refetch: refetchActivityReport,
  } = useQuery(["data-ActivityReport"], () => getActivityReport(params?.id), {
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: employeeList,
    isLoading: isLoadingEmployeeList,
    isFetching: isFetchingEmployeeList,
    refetch: refetchEmployeeList,
  } = useQuery(["data-AllEmployee"], () => getAllEmployee(), {
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  function filterApproved(data) {
    return data?.filter((obj) => obj.status === "approved");
  }

  // const {
  //   data: AWPActivityData,
  //   isLoading: isLoadingAWPActivityData,
  //   isFetching: isFetchingAWPActivityData,
  //   refetch: refetchAWPActivityData,
  // } = useQuery(
  //   ["list-awp-activity"],
  //   () => getAWPActivity(`659cf12e7bae5c276083c3b7`),
  //   {
  //     onError: (error) => {
  //       enqueueSnackbar(getErrorMessage(error), { variant: "error" });
  //     },
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //   }
  // );

  // const {
  //   data: DetailWithdrawal,
  //   isLoading: isLoadingDetailWithdrawal,
  //   isFetching: isFetchingDetailWithdrawal,
  //   refetch: refetchDetailWithdrawal,
  // } = useQuery(
  //   ["withdrawal-detail", params],
  //   () => getWithdrawalFundPlanDetail(params),
  //   {
  //     onError: (error) => {
  //       enqueueSnackbar(getErrorMessage(error), { variant: "error" });
  //     },
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //   }
  // );

  // const {
  //   data: ActivityReportData,
  //   isLoading: isLoadingActivityReportData,
  //   isFetching: isFetchingActivityReportData,
  //   refetch: refetchActivityReportData,
  // } = useQuery(
  //   ["list-ActivityReport", params],
  //   () => getSpecificActivityReport(queryParams),
  //   {
  //     onError: (error) => {
  //       enqueueSnackbar(getErrorMessage(error), { variant: "error" });
  //     },
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //   }
  // );

  return {
    ActivityReportList: ActivityReportList?.data?.data?.data,
    ActivityReport: ActivityReport?.data?.data,
    employeeList: employeeList,
    paginationActivityReport: {
      lastPage: ActivityReportList?.data?.data?.lastPage,
      page: ActivityReportList?.data?.data?.page,
      perPage: ActivityReportList?.data?.data?.perPage,
      total: ActivityReportList?.data?.data?.total,
    },
    isLoadingActivityReportList,
    isFetchingActivityReportList,
    isFetchingActivityReport,
    refetch: refetchActivityReportList,
    refetchEmployeeList: refetchEmployeeList,
    refetchActivityReport: refetchActivityReport,
  };
};

export default useActivityReport;

import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import {
  getProjectManagementTeam,
  getProjectManagementTeamList,
  submitProjectManagementTeam,
} from "services/danaProgram/danaProgramService";
import { getAllEmployee } from "services/danaProgram/masterDataService";

const useProjectManagementTeam = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: projectManagementTeamList,
    isLoading: isLoadingProjectManagementTeamList,
    isFetching: isFetchingProjectManagementTeamList,
    refetch: refetchProjectManagementTeamList,
  } = useQuery(
    ["list-projectManagementTeamList", params],
    () => getProjectManagementTeamList(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const submitMutation = useMutation({
    mutationFn: (params) => {
      return submitProjectManagementTeam(params);
    },
  });

  const { data: projectManagementTeam, refetch: refetchProjectManagementTeam } =
    useQuery(
      ["data-projectManagementTeam", params],
      () => getProjectManagementTeam(params),
      {
        onError: (error) => {
          enqueueSnackbar(getErrorMessage(error), { variant: "error" });
        },
        refetchOnWindowFocus: false,
        enabled: false,
      }
    );

  const { data: employeeList, refetch: refetchEmployeeList } = useQuery(
    ["data-AllEmployee"],
    () => getAllEmployee(),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
  //   data: AWPActivityData,
  //   isLoading: isLoadingAWPActivityData,
  //   isFetching: isFetchingAWPActivityData,
  //   refetch: refetchAWPActivityData,
  // } = useQuery(
  //   ["list-awp-activity"],
  //   () => getAWPActivity(`659cf12e7bae5c276083c3b7`),
  //   {
  //     onError: (error) => {
  //       enqueueSnackbar(getErrorMessage(error), { variant: "error" });
  //     },
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //   }
  // );

  // const {
  //   data: DetailWithdrawal,
  //   isLoading: isLoadingDetailWithdrawal,
  //   isFetching: isFetchingDetailWithdrawal,
  //   refetch: refetchDetailWithdrawal,
  // } = useQuery(
  //   ["withdrawal-detail", params],
  //   () => getWithdrawalFundPlanDetail(params),
  //   {
  //     onError: (error) => {
  //       enqueueSnackbar(getErrorMessage(error), { variant: "error" });
  //     },
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //   }
  // );

  // const {
  //   data: timeWorkPlanData,
  //   isLoading: isLoadingTimeWorkPlanData,
  //   isFetching: isFetchingTimeWorkPlanData,
  //   refetch: refetchTimeWorkPlanData,
  // } = useQuery(
  //   ["list-timeWorkPlan", params],
  //   () => getSpecificTimeWorkPlan(queryParams),
  //   {
  //     onError: (error) => {
  //       enqueueSnackbar(getErrorMessage(error), { variant: "error" });
  //     },
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //   }
  // );

  return {
    employeeList: employeeList?.data?.data,
    projectManagementTeamList: projectManagementTeamList?.data?.data?.data,
    projectManagementTeam: projectManagementTeam,
    paginationProjectManagementTeam:
      {
        lastPage: projectManagementTeamList?.data?.data?.lastPage,
        page: projectManagementTeamList?.data?.data?.page,
        perPage: projectManagementTeamList?.data?.data?.perPage,
        total: projectManagementTeamList?.data?.data?.total,
      } || {},
    isLoadingProjectManagementTeamList,
    isFetchingProjectManagementTeamList,
    refetch: refetchProjectManagementTeamList,
    refetchEmployeeList: refetchEmployeeList,
    refetchProjectManagementTeam: refetchProjectManagementTeam,
    submitMutation: submitMutation,
  };
};

export default useProjectManagementTeam;

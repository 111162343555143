import React from "react";
import {
  AiOutlineCheckCircle,
  AiOutlineInfoCircle,
  AiOutlineCloseCircle,
  AiOutlineFileText,
} from "react-icons/ai";

import { Card, CardBody, CardHeader } from "components";
import { APPROVAL_PIC } from "./constant";

const Progress = ({
  module = "rencana-penarikan-dana",
  credentials,
  rejectReason,
}) => {
  let credentialsFormatted = [];
  //   handle old data only, credentials should format as array
  if (credentials) {
    credentialsFormatted = !credentials.length ? [credentials] : credentials;
  }

  return (
    <Card noBorder>
      <CardHeader>Progress Persetujuan</CardHeader>

      <CardBody>
        {Object.keys(APPROVAL_PIC).map((level) => {
          const levelIndex = level.replace("level_", "");
          const levelRole = APPROVAL_PIC[level];

          const isLastApprover =
            credentialsFormatted.length === Number(levelIndex);

          const approvedBy = credentialsFormatted.find(
            (cred) => cred.level === Number(levelIndex)
          );

          const approvedByDocs = approvedBy?.documents ?? [];

          let status = "Menunggu persetujuan";
          let statusColor = "bg-blue-100";

          let StatusIcon = AiOutlineInfoCircle;

          if (!!approvedBy) {
            status = "Disetujui oleh";
            statusColor = "bg-green-100";

            StatusIcon = AiOutlineCheckCircle;
          }

          if (!!rejectReason) {
            if (isLastApprover) {
              status = "Ditolak oleh";
              statusColor = "bg-red-100";

              StatusIcon = AiOutlineCloseCircle;
            } else if (!approvedBy) {
              return <></>;
            }
          }

          return (
            <div className={`${statusColor} rounded-lg mb-3 p-3 flex`}>
              <div className="mt-1 mr-2">
                <StatusIcon className="text-lg" />
              </div>

              <div>
                <p>
                  {status} <strong>{levelRole}</strong>
                </p>

                {approvedBy?.approver_name && (
                  <p>
                    {approvedBy.approver_name}.{" "}
                    <small>{approvedBy.timestamps}</small>
                  </p>
                )}

                {!!rejectReason && isLastApprover && (
                  <div className="mt-3 text-base">
                    <p className="font-semibold">Alasan:</p>
                    <p>{rejectReason}</p>
                  </div>
                )}

                {!!approvedByDocs.length && (
                  <div className="mt-3">
                    <p className="text-sm mb-1">Dokumen Pendukung</p>
                    {approvedByDocs.map((document) => (
                      <a
                        className={[
                          "btn btn-sm btn-outline mr-2",
                          statusColor === "bg-red-100"
                            ? "btn-error"
                            : "btn-accent",
                        ].join(" ")}
                        href={document.path}
                        title={document.fileName}
                      >
                        <AiOutlineFileText />

                        <div className="max-w-36 truncate">
                          {document.fileName}
                        </div>
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </CardBody>
    </Card>
  );
};

export default Progress;

import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { getList } from "services/danaProgram/consepNoteService";
import { getErrorMessage } from "helpers";

const useConsepNote = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== undefined && value !== "")
  );

  const {
    data: consepNoteData,
    isLoading: isLoadingConsepNoteData,
    isFetching: isFetchingConsepNoteData,
    refetch: refetchConsepNoteData,
  } = useQuery(
    ['list-consepNote', params],
    () => getList(queryParams),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  );
//   console.log(consepNoteData)
  
  return {
    consepNoteData: consepNoteData?.data?.data.data || [],
    paginationConsepNoteData: { 
      lastPage: consepNoteData?.data?.data?.lastPage,
      page: consepNoteData?.data?.data?.page,
      perPage: consepNoteData?.data?.data?.perPage,
      total: consepNoteData?.data?.data?.total
    } || {},
    isLoadingConsepNoteData,
    isFetchingConsepNoteData,
    refetch: refetchConsepNoteData,
  };
};

export default useConsepNote;

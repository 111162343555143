import React, { useCallback, useMemo, useState } from "react";
import { BiPencil } from "react-icons/bi";
import { RiEyeLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { debounce } from "lodash";

import { BasicList } from "components/molecules/ListPage";

import {
  CategoryBadge,
  TypeBadge,
} from "components";
import FilterProposalSubmission from "./FilterProposalSubmission";

const ProposalSubbmission = () => {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState(undefined);
  const [typeFilter, setTypeFilter] = useState(undefined);
  const [categoryFilter, setCategoryFilter] = useState(undefined);

  const [formModal, setFormModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);

  const links = [
    {
      label: "Pengajuan Dana",
    },
  ];

  const methods = useForm({
    defaultValues: {
      format_number: "#0",
      impact: "",
      description: "",
      indicators: [],
    },
  });

  const result = useMemo(() => {
    return [
      {
        code: 12,
        proposalTitle: "Proposal A",
        submitter: "John Doe",
        submitDate: "10 Februari 2023",
        description: "Rp50,000,000",
      },
      {
        code: 142,
        proposalTitle: "Proposal B",
        submitter: "John Doe",
        submitDate: "10 Februari 2023",
        description: "Rp50,000,000",
      },
      {
        code: 112,
        proposalTitle: "Proposal C",
        submitter: "John Doe",
        submitDate: "10 Februari 2023",
        description: "Rp50,000,000",
      },
      {
        code: 512,
        proposalTitle: "Proposal D",
        submitter: "John Doe",
        submitDate: "10 Februari 2023",
        description: "Rp50,000,000",
      },
      {
        code: 162,
        proposalTitle: "Proposal E",
        submitter: "John Doe",
        submitDate: "10 Februari 2023",
        description: "Rp50,000,000",
      },
    ];
  });

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const typeOptions = useMemo(() => {
    return [
      { value: 1, label: "Status 1" },
      { value: 2, label: "Status 2" },
    ];
  });

  const categoryOptions = useMemo(() => {
    return [
      { value: 1, label: "Domestic" },
      { value: 2, label: "International" },
    ];
  });

  const onChangeType = useCallback((obj) => setTypeFilter(obj.value), []);
  const onChangeCategory = useCallback(
    (obj) => setCategoryFilter(obj.value),
    []
  );

  const onCreate = () => {};
  const onEdit = (data) => {
    setFormModal(true);
    methods.reset(data);
  };

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "proposalTitle",
        title: "Judul Proposal",
        dataIndex: "proposalTitle",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "submitter",
        title: "Pengaju",
        dataIndex: "submitter",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) =>(
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>),
      },
      {
        id: "submitDate",
        title: "Tanggal Pengajuan",
        dataIndex: "submitDate",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>),
      },
      {
        id: "description",
        title: "Nilai Pengajuan",
        dataIndex: "description",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex w-full">
            <RiEyeLine
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate(`/pengajuan-dana-program/detail`)}
            />
          </div>
        ),
      },
    ];
  }, []);

  return (
    <div>
      <BasicList
        links={links}
        title={"Daftar Pengajuan Dana"}
        columns={tableColumns}
        dataSource={result}
        customFilter={
          <FilterProposalSubmission
            typeOptions={typeOptions}
            categoryOptions={categoryOptions}
            onSearch={onSearch}
            onChangeType={onChangeType}
            onChangeCategory={onChangeCategory}
          />
        }
        customInformation={
          <div className="space-x-7 flex">
            <span>
              CfP : Call for Proposal
            </span>
            <span>
              AWP : Annual Work Plan
            </span>
            <span>
              RfI : Request for Interest
            </span>
            <span>
             RfQ : Request for Quotation
            </span>
          </div>
        }
      />
    </div>
  );
};

export default ProposalSubbmission;

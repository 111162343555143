import React, { useMemo } from 'react';
import { map } from 'lodash';

const ProgressStepForm = ({ items, step }) => {
  
  const pixelBorder = useMemo(() => {
    return (960/items.length) - 24;
  }, [items]);

  //if items less than 2
  if(items.length < 2) {
    return;
  }

  return (
    <div className="flex items-center justify-center my-6 max-w-[960px] mx-auto overflow-auto">
      {map(items, ((item, key) => {
        return (
          <div 
            key={`progress-step-form-${key + 1}`}
            className="flex justify-center items-center flex-col space-y-[14px] w-60"
          >
            <div className="flex relative">
              <div className={`flex justify-center items-center w-6 h-6 rounded-full ${key + 1 <= step ? (key + 1 < step ? `bg-white border border-gray-200` : `bg-gray-200`) : `bg-white border border-gray-200`}`}>
                <div className={`flex justify-center items-center w-4 h-4 rounded-full ${key + 1 <= step ? (key + 1 < step ? `` : `border border-primary-700`) : ``}`}>
                  <div className={`${key + 1 <= step ? `bg-primary-700` : `bg-gray-200`} w-2 h-2 rounded-full`} />
                </div>
              </div>
              {item !== items[items.length - 1] && <div style={{ width: `${pixelBorder}px` }} className={`border-t-2 ${key + 2 <= step ? `border-primary-700` : `border-gray-200`} absolute left-6 top-3`} />}
            </div>
            <label className="text-primary-700 font-semibold text-sm whitespace-nowrap">{item}</label>
          </div>
        )  
      }))}
    </div>
  );
}

export default ProgressStepForm;
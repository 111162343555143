import { useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import { useSnackbar } from "notistack"
import { useEffect } from "react";
import { getDetail } from "services/danaProgram/pembayaranResourceService";
import { queryKeys } from ".";

export const useDetailApprovePembayaranResource = (id) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data
  } = useQuery({
    queryKey: queryKeys.detail(id),
    queryFn: async () => {
      const res = await getDetail({ id: id });

      return res?.data?.data || {}
    },
    retry: 1
  });

  return {
    detail: data
  }
}
import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  DateInput,
  AtomDatePicker,
  AtomButton,
  CardForm,
  InputFormUpload,
} from 'components';
import { Select as AtomSelect } from 'components';
import { InputForm } from 'components';
import Select from 'react-select';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import CreatableSelect from 'react-select/creatable';
import { useNavigate, useParams, useRoutes } from 'react-router';
import {
  RiAddLine,
  RiArrowLeftLine,
  RiArrowRightLine,
  RiDeleteBin6Line,
  RiSave3Line,
} from 'react-icons/ri';
import axios from 'axios';
import config from 'app/config';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import * as yup from 'yup';
import _ from 'lodash';
import { useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import InputCurrency from 'components/molecules/InputCurrency';
import ImageDisplay from '../Step1/imageDisplay';
import { convertMoney } from 'helpers';
import { useMutation } from '@tanstack/react-query';
import { submitActivityReportStep2 } from 'services/danaProgram/danaProgramService';
import { enqueueSnackbar } from 'notistack';
import FileDetail from 'components/molecules/FileDetail';

const RealisasiBiaya = ({ setActiveTab, activeTab, datas }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [searchParams] = useSearchParams();
  const formType = searchParams.get('type');
  const [totalCost, setTotalCost] = useState();
  let isDetail = currentPath.includes('detail');

  const validationSchema = yup.object().shape({
    tab2Verification: yup.boolean(),
    safeguards: yup.array().of(
      yup.object().shape({
        // Define your safeguards schema here
      })
    ),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      tab2Verification: false,
      safeguards: [{}],
    },
  });

  useEffect(() => {
    for (let key in datas) {
      if (key === 'cost_realizations') {
        datas[key].map((dat, index) => {
          for (let val in datas[key][index]) {
            if (val === 'total_cost') {
              methods.setValue(
                `${key}.${index}.${val}`,
                convertMoney(datas[key][index][val])
              );
            } else {
              methods.setValue(
                `${key}.${index}.${val}`,
                datas[key][index][val]
              );
            }
          }
        });
      } else {
        methods.setValue(key, datas[key]);
      }
    }
  }, [datas]);

  useEffect(() => {
    setTotalCost(convertMoney(methods.watch('total_cost')));
  }, [methods.watch('total_cost')]);

  const submit = useMutation({
    mutationKey: ['post-step-2-activity-report-review'],
    mutationFn: async (payload) => {
      const res = await submitActivityReportStep2(payload);
      return res.data.data;
    },
    onSuccess: (res) => {
      enqueueSnackbar('Data berhasil direview', { variant: 'success' });
      navigate(`/activity-report-review`);
    },
  });

  const onSubmit = () => {
    let params = {
      id: sessionStorage.getItem('ActivityReportsId'),
      note: methods.watch('note'),
      ...Object.fromEntries(
        Object.entries(datas).filter(([key]) => key !== 'note')
      ),
    };
    submit.mutate(params);
  };

  return (
    <div className="">
      {/* <Breadcrumbs items={links} /> */}
      {/* <ProgressStepForm items={stepForm} step={step} /> */}
      <div className="flex flex-col gap-4 mt-3">
        <FormProvider {...methods}>
          <Card>
            <CardHeader info={'Rp.' + totalCost}>Realisasi Biaya</CardHeader>
            <CardBody>
              {datas?.cost_realizations?.length > 0 &&
                datas?.cost_realizations.map((data, index) => {
                  return (
                    <div className="space-y-[9px]">
                      <div className="flex gap-4 flex-col border-b border-grey-700 pb-7">
                        <InputForm
                          controllerName={`cost_realizations.${index}.detail_activity_name`}
                          label="Detail Kegiatan"
                          required={true}
                          className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          disabled={isDetail}
                        />
                        <InputForm
                          controllerName={`cost_realizations.${index}.date`}
                          label="Tanggal Pelaksanaan"
                          required={true}
                          className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          disabled={isDetail}
                        />
                        <InputForm
                          controllerName={`cost_realizations.${index}.total_cost`}
                          label="Nilai Realisasi Biaya (Rp)"
                          required={true}
                          className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          disabled={isDetail}
                        />
                      </div>
                      <div className="space-y-[9px]">
                        <div className="flex flex-row">
                          <div className="flex-1 pt-2"></div>
                          <div className="flex-1 pt-2"></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Dokumen Pendukung</CardHeader>
            <CardBody>
              {' '}
              <div className="flex gap-4">
                {datas?.document?.length > 0 &&
                  datas?.document.map((file, index) => {
                    return <FileDetail key={index} file={file} hideDelete />;
                  })}
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Catatan Hasil Verifikasi</CardHeader>
            <CardBody>
              <InputForm
                controllerName={'note'}
                className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={'Catatan'}
                textArea
                disabled={formType === 'detail'}
              />
            </CardBody>
          </Card>
        </FormProvider>
      </div>

      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => setActiveTab(activeTab - 1)}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>

        <div className="block md:flex items-center justify-between gap-2">
          {formType !== 'detail' ? (
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={onSubmit}
            >
              Verifikasi
              <RiArrowRightLine className="text-white" />
            </Button>
          ) : (
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={() => navigate('/activity-report-review')}
            >
              Close
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RealisasiBiaya;

import React from "react";

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
} from "components";
import { useNavigate } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine, RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";

const ProposalSubmissionDetailGeneralInformation = () => {
  const navigate = useNavigate();
  const links = [
    {
      label: "Pengajuan Dana",
      path: "/pengajuan-dana-program",
    },
    {
      label: "Detail",
    },
  ];
  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <div>Detail</div>
        </CardHeader>
        <CardBody>
            <div className="space-y-5">
                <CardForm label="General Information" buttonCollapse={true}>
                    <div className="flex flex-col space-y-5">
                        <div className="flex w-full">
                            <span className="flex-1 font-bold">Program</span>
                            <span className="flex-1 font-bold">Tematik</span>
                            <span className="flex-1 font-bold">Judul Proposal</span>
                            <span className="flex-1 font-bold">Durasi Pelaksanaan</span>
                        </div>
                        <div className="flex">
                            <span className="flex-1">Lorem ipsum</span>
                            <span className="flex-1">Lorem ipsum</span>
                            <span className="flex-1">Lorem ipsum</span>
                            <span className="flex-1">12/01/2023 - 12/12/2023</span>
                        </div>
                        <span className="flex-1 font-bold">Deskripsi</span>
                        <span className="flex-1">Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec. Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam volutpat volutpat tellus aliquet a egestas ac ut.</span>
                    </div>
                </CardForm>
                <CardForm label="Informasi Pengusul" buttonCollapse={true}>
                    <div className="flex flex-col space-y-5">
                        <div className="flex w-full">
                            <span className="flex-1 font-bold">Nama</span>
                            <span className="flex-1 font-bold">NIDN/NIP</span>
                            <span className="flex-1 font-bold">Deskripsi</span>
                        </div>
                        <div className="flex">
                            <span className="flex-1">Lorem ipsum</span>
                            <span className="flex-1">Lorem ipsum</span>
                            <span className="flex-1">Lorem ipsum</span>
                        </div>
                        <div className="flex w-full">
                            <span className="flex-1 font-bold">Nama</span>
                            <span className="flex-1 font-bold">NIDN/NIP</span>
                            <span className="flex-1 font-bold">Deskripsi</span>
                        </div>
                        <div className="flex">
                            <span className="flex-1">Lorem ipsum</span>
                            <span className="flex-1">Lorem ipsum</span>
                            <span className="flex-1">Lorem ipsum</span>
                        </div>
                    </div>
                </CardForm>
                <CardForm label="Timeline/Jadwal Pelaksanaan" buttonCollapse={true}>
                    <div className="flex flex-col space-y-5">
                        <div className="flex w-full">
                            <span className="flex-1 font-bold">Nama kegiatan</span>
                            <span className="flex-1 font-bold">Jangkat Waktu Pelaksanaan</span>
                        </div>
                        <div className="flex">
                            <span className="flex-1">Lorem ipsum</span>
                            <span className="flex-1">12/01/2023 - 12/12/2023</span>
                        </div>
                        <div className="flex w-full">
                            <span className="flex-1 font-bold">Nama kegiatan</span>
                            <span className="flex-1 font-bold">Jangkat Waktu Pelaksanaan</span>
                        </div>
                        <div className="flex">
                            <span className="flex-1">Lorem ipsum</span>
                            <span className="flex-1">12/01/2023 - 12/12/2023</span>
                        </div>
                    </div>
                </CardForm>
                <CardForm label="Lokasi Pelaksanaan" buttonCollapse={true}>
                    <div className="flex flex-col space-y-5">
                        <span className="flex-1 font-bold">Alamat</span>
                        <span className="flex-1">Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec. Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam volutpat volutpat tellus aliquet a egestas ac ut.</span>
                        <div className="flex w-full">
                            <span className="flex-1 font-bold">Latitude</span>
                            <span className="flex-1 font-bold">Longitude</span>
                        </div>
                        <div className="flex">
                            <span className="flex-1">Lorem ipsum</span>
                            <span className="flex-1">12/01/2023 - 12/12/2023</span>
                        </div>
                    </div>
                </CardForm>
            </div>
        </CardBody>
      </Card>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate(-1)}
          >
            <RiArrowLeftLine/>
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
          <Button
            type="button"
            className="text-white border-error-600 bg-error-600 hover:bg-error-700 hover:border-error-700 w-40"
          >
            <RiCloseCircleLine className="text-white"/>
            Tolak
          </Button>
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-40"
          >
            <RiCheckboxCircleLine className="text-white"/>
            Submit
          </Button>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
          >
            Selanjutnya
            <RiArrowRightLine/>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProposalSubmissionDetailGeneralInformation;

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import { useMemo } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import InformasiRekening from "./Form/InformasiRekening";
import { BiCheck } from "react-icons/bi";
import DaftarKepemilikanSaham from "./Form/DaftarKepemilikanSaham";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFinancialProfile } from "app/actions";
import { useFinancialProfileContext } from "../FinancialProfileState";

const InformasiRekeningKepemilikan = ({
  setActiveTab,
  activeTab = 1,
  data = null,
  setFormData,
}) => {
  const { FinancialProfileState, setFinancialProfileState } =
    useFinancialProfileContext();
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      stock_ownership: Yup.object().shape({
        result_verification: Yup.string().required(
          `stock ownership Hasil Verifikasi wajib diisi`
        ),
        reason: Yup.string().nullable(true),
      }),
      result_verification: Yup.string().required(
        `Hasil Verifikasi wajib diisi`
      ),
      reason: Yup.string().nullable(true),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const dispatch = useDispatch();

  const { control, trigger } = { ...methods };
  const draftDataOnRedux = useSelector(
    (state) => state?.draftFinancialProfile?.data?.step_2 || {}
  );

  const {
    fields: fieldsTools,
    append: appendTools,
    remove: removeTools,
  } = useFieldArray({
    control,
    name: "tools",
  });

  useEffect(() => {
    removeTools(1);
    for (let key in data?.account) {
      if (key?.includes("reason") || key?.includes("result_verification")) {
        if (draftDataOnRedux) {
          if (!data?.account[key] && draftDataOnRedux[key]) {
            methods.setValue(key, draftDataOnRedux[key]);
          } else {
            methods.setValue(key, data?.account[key]);
          }
        }
      } else {
        methods.setValue(key, data?.account[key]);
      }
    }
    for (let field in data?.stock_ownership) {
      if (draftDataOnRedux[field]) {
        if (
          field?.includes("reason") ||
          field?.includes("result_verification")
        ) {
          if (
            !data?.stock_ownership[field] &&
            draftDataOnRedux?.stock_ownership[field]
          ) {
            methods.setValue(
              `stock_ownership.${field}`,
              draftDataOnRedux?.stock_ownership[field]
            );
          } else {
            methods.setValue(
              `stock_ownership.${field}`,
              data?.stock_ownership[field]
            );
          }
        }
      }
    }

    appendTools(data?.stock_ownership?.list);
  }, []);

  useEffect(() => {
    for (let i = 0; i < fieldsTools.length; i++) {
      removeTools(i + 1);
    }
  }, [fieldsTools?.length > data?.account?.length]);

  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <InformasiRekening
          tools={fieldsTools}
          methods={methods}
          data={data.account}
        />
        <DaftarKepemilikanSaham
          tools={fieldsTools}
          methods={methods}
          data={data.stock_ownership}
        />

        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => setActiveTab(activeTab - 1)}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={async () => {
                const isValid = await trigger();
                let formData = methods.getValues();

                dispatch(
                  updateFinancialProfile({
                    step_2: formData,
                  })
                );

                setFormData((prevData) => ({
                  ...prevData,
                  step_2: formData,
                }));
                setFinancialProfileState((prevState) => ({
                  ...prevState,
                  step_1: formData,
                }));
                if (isValid) setActiveTab(activeTab + 1);
              }}
            >
              Selanjutnya
              <RiArrowRightLine />
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default InformasiRekeningKepemilikan;

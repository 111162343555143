/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router";
import LandasanHukum from "./Form/DokumenPendukungChild";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import { BiPencil } from "react-icons/bi";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/danaProgram/fileService";
import DokumenPendukungChild from "./Form/DokumenPendukungChild";
import { useDispatch } from "react-redux";
import { updateFinancialProfile } from "app/actions";
import { useFinancialProfileContext } from "../FinancialProfileState";
import { removeWords } from "helpers";

const DokumenPendukung = ({
  setActiveTab,
  activeTab = 1,
  data,
  setFormData,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const toogleIsEdit = () => setIsEdit(!isEdit);
  const { FinancialProfileState, setFinancialProfileState } =
    useFinancialProfileContext();
  const dispatch = useDispatch();
  const [documentData, setDocumentData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      result_verification: Yup.string().required(
        `Hasil Verifikasi wajib diisi`
      ),
      reason: Yup.string().nullable(true),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { control, trigger } = { ...methods };

  const getFileMutation = useMutation(async ({ id, key }) => {
    try {
      console.log(data, key);
      if (key.includes("description")) {
        console.log("cek", key, data[key]);
        methods.setValue(key, data[key]);
      } else {
        const transformedString = key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        if (!Array.isArray(id) && id) {
          const response = await getFile(id);
          let temp = {
            propsName: key,
            key: transformedString,
            url: response.data.data.url,
            id: response.data.data.id,
            type: response.data.data.mimeType,
            name: response.data.data.name,
            size: Math.round(parseInt(response.data.data.size) / 1000),
          };
          setDocumentData((prevState) => [...prevState, temp]);
        } else {
          const requests = id.map(async (data, index) => {
            if (data !== null) {
              const response = await getFile(data);
              return {
                propsName: key + `_${index + 1}`,
                key: transformedString + ` #${index + 1}`,
                url: response.data.data.url,
                id: response.data.data.id,
                type: response.data.data.mimeType,
                name: response.data.data.name,
                size: Math.round(parseInt(response.data.data.size) / 1000),
              };
            } else {
              return null;
            }
          });
          const results = await Promise.all(requests);
          results.forEach((temp) => {
            if (temp !== null) {
              if (!documentData.some((item) => item?.key === temp?.key)) {
                setDocumentData((prevState) => [...prevState, temp]);
              }
            }
          });
        }
      }
    } catch (error) {
      throw error;
    }
  });

  useEffect(() => {
    if (data) {
      for (const key in data) {
        if (key.includes("document") || key.includes("description"))
          getFileMutation.mutate({ id: data[key], key: key });
      }
    }
    setIsFetching(false);
  }, []);

  useEffect(() => {
    const uniqueKeys = {};
    if (!isFetching && documentData.length > 0) {
      const filteredData = documentData.filter((item) => {
        if (!uniqueKeys[item.key]) {
          uniqueKeys[item.key] = true;
          return true;
        }
        return false;
      });
      setDocumentData(filteredData);
    }
  }, [documentData.length]);

  const uploadDocuments = async (file, key) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const res = await fileUpload(formData);

      const resFileData = res.data.data;

      let documentsUploaded = {
        fileId: resFileData.id,
        fileName: resFileData.name,
        fileSize: resFileData.size,
        mimeType: resFileData.mimeType,
        path: resFileData.url,
      };
      methods.setValue(removeWords(key, "files_"), documentsUploaded);
      return methods.setValue(key, documentsUploaded);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadData = async (data) => {
    for (let key in data) {
      if (key.includes("files") && data[key]) {
        uploadDocuments(data[key], key);
      }
    }
  };

  useEffect(() => {
    if (!isEdit) {
      const formData = methods.getValues();
      uploadData(formData);
    }
  }, [isEdit]);

  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        {!isFetching && (
          <>
            <DokumenPendukungChild
              data={documentData}
              methods={methods}
              obj={data}
              isEdit={isEdit}
              toogleIsEdit={toogleIsEdit}
            />
          </>
        )}
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => navigate(-1)}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={async () => {
                const isValid = await trigger();
                const data = methods.getValues();
                let temp = {
                  reason: data?.reason,
                  result_verification: data?.result_verification,
                };
                dispatch(
                  updateFinancialProfile({
                    step_1: temp,
                  })
                );
                setFormData((prevData) => ({
                  ...prevData,
                  step_1: data,
                }));
                setFinancialProfileState((prevState) => ({
                  ...prevState,
                  document_support: data,
                }));
                if (isValid) setActiveTab(activeTab + 1);
              }}
            >
              Selanjutnya
              <RiArrowRightLine />
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default DokumenPendukung;

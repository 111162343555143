import React, { useEffect, useState } from "react";

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  TitleText,
} from "components";
import { useNavigate, useParams } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine, RiCheckboxCircleLine, RiCloseCircleLine, RiFile2Line } from "react-icons/ri";
import { getDetailProposal } from "services/danaProgram/callForProposalService";
import { getUrlFile } from "services/danaProgram/fileService";

const AttachmentDocumentDetail = ({onNext}) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  let { id } = useParams();

  const fetchDetailCfp = async (params) => {
    const res = await getDetailProposal(id, params);
    setData(res.data.data);
  }

  useEffect(() => {
    fetchDetailCfp({category : 'supporting-file'})
  }, [])

  const downloadFile = async (item) => {
    const res = await getUrlFile({
      identifier: "id",
      value: item?.fileId
    });
    window.open(res?.data?.data?.url, "_blank");
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <div>Lampiran dokumen pendukung</div>
        </CardHeader>
        <CardBody>
          <div className="space-y-5">
            <div className="space-y-5">
              <TitleText className="font-bold">
                Lampiran File
              </TitleText>
              <div className="grid grid-cols-2 gap-4">
                {
                  data?.documents.length > 0 ? data?.documents.map((item, index) => {
                    return(
                      <div className="border p-4 rounded-2xl">
                        <div className="flex gap-4">
                          <div>
                            <div className="bg-[#EEFFF4] rounded-full p-4">
                              <RiFile2Line className="text-primary-600"/>
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <span className="font-medium">
                              {item.fileName}
                            </span>
                            <span>
                              {item.fileSize}
                            </span>
                            <button 
                              className="text-left"
                              onClick={() => downloadFile(item)}
                            >
                              <span className="font-semibold text-primary-600">
                                Download file
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  }): (<></>)
                }
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate(-1)}
          >
            <RiArrowLeftLine/>
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
        <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={() => onNext()}
          >
            Selanjutnya
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AttachmentDocumentDetail;

import { Breadcrumbs, Button, SearchInput, TitleText, Table } from "components";
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";

const TableWrapper = ({
  links = [],
  title = "",
  columns = [],
  dataSource = [],
  className = "",
  isLoading = false,
  onCreate,
  onSearch,
  onChangePage,
  onChangeRowsPerPage,
  pagination = {},
  customFilter = null,
  customInformation = null,
  customTextAddButton = "Add Data",
  customClassnameAddButton = "text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700",
  customIconAddButton = <AiOutlinePlus className="text-white" />,
  sortMethod = "",
  setSortMethod = {},
  setSortBy = {},
  btnSize = "md",
}) => {
  return (
    <div className={className ? className : `w-full`}>
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-6`}>
        <TitleText className="flex-1 text-lg font-semibold text-gray-900">
          {title}
        </TitleText>
        {Boolean(onCreate) && (
          <Button
            className={customClassnameAddButton}
            startIcon={customIconAddButton}
            onClick={onCreate}
            size={btnSize}
          >
            {customTextAddButton}
          </Button>
        )}
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="p-3 card-body">
          <div className="space-y-4">
            {customFilter ? (
              React.cloneElement(customFilter)
            ) : (
              <div className="flex space-x-5">
                <SearchInput placeholder="Cari" onChange={onSearch} />
              </div>
            )}
            {customInformation && customInformation}
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={columns}
              dataSource={dataSource}
              isLoading={isLoading}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={pagination}
              sortMethod={sortMethod}
              setSortMethod={setSortMethod}
              setSortBy={setSortBy}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableWrapper;

import React, {
  useState,
  useCallback,
  useMemo,
} from "react";

import {
  Breadcrumbs,
  Card,
  CardBody,
  CustomSelect,
} from "components";
import { CgNotes } from "react-icons/cg";
import { BsCashStack } from "react-icons/bs";
import { PiMoneyLight } from "react-icons/pi";
import { GrMoney } from "react-icons/gr";
import { formattedValue } from "helpers";

const Verification = () => {
  const links = [
    {
      label: "Dashboard For Donor",
    },
  ];

  const [typeFilter, setTypeFilter] = useState("donor");
  const [yearFilter, setYearFilter] = useState("2024");
  const [projectTotal, setProjecttotal] = useState(20)
  const [contractTotal, setContractTotal] = useState(100000000000)
  const [realizationTotal, setRealizationTotal] = useState(500000000)
  const [distributionTotal, setDistributionTotal] = useState(300000000)
  const [reportTotal, setReportTotal] = useState(400000000)
  const [lemtara, setLempara] = useState(50)
  const [kl, setKl] = useState(20)
  const [man, setMan] = useState(150)
  const [woman, setWoman] = useState(50)

  const notyetDistributionTotal = useMemo(() => realizationTotal - distributionTotal)
  const notyetReportTotal = useMemo(() => contractTotal - reportTotal)

  const typeOptions = useMemo(() => {
    return [
      { value: "donor", label: "Donor" },
    ]
  });

  const yearOptions = useMemo(() => {
    return [
      { value: "2024", label: "2024" },
      { value: "2023", label: "2023" },
    ]
  })

  const onChangeType = useCallback((obj) => {
    setTypeFilter(obj.value || undefined);
  }, []);

  const onChangeYear = useCallback((obj) => {
    setYearFilter(obj.value || undefined);
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />
      <div className="py-1 bg-[#F9FAFB] flex justify-between px-2 items-center my-4">
        <span className="text-2xl font-semibold">Dashboard For Donor</span>
        <div className="flex gap-3 space-w-4 w-[calc(100vw/3)]">
          <div className="flex-1">
            <CustomSelect
              optionsData={typeOptions}
              className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none"
              placeholder="Donor"
              onChange={onChangeType} 
            />
          </div>
          <div className="flex-1 w-1/2">
            <CustomSelect
              optionsData={yearOptions}
              className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none"
              placeholder="2024"
              onChange={onChangeYear} 
            />
          </div>
        </div>
      </div>

      <div className="bg-white p-4 rounded-lg border-l-4 border-[#1570EF] flex flex-col gap-6 mb-4">
        <div className="flex gap-5 items-center">
          <div className="bg-[#CFF9FE] rounded-full p-2">
            <CgNotes className="text-xl text-[#1570EF]" />
          </div>
          <span className="font-semibold">Jumlah Project</span>
        </div>
        <div className="py-2 px-4 bg-[#E0F2FE] w-full rounded-lg">
          <span className="text-2xl font-semibold">{formattedValue(projectTotal, false)}</span>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4">
        <div className="w-full bg-[#0086C9] mb-4 p-4 rounded-xl text-white">
          <div className="bg-[#EAFEFF] rounded-lg p-3 w-fit mb-3">
            <BsCashStack className="text-lg text-[#0086C9]" />
          </div>
          <div className="w-full bg-[#FFFFFF4D] p-4 rounded-xl text-white border border-white">
            <p className="text-sm">Total Kontrak Komitmen</p>
            <p className="text-lg font-bold">{formattedValue(contractTotal)}</p>
          </div>
        </div>
        <div className="w-full bg-[#F79009] mb-4 p-4 rounded-xl text-white">
          <div className="bg-[#FFFCE7] rounded-lg p-3 w-fit mb-3">
            <PiMoneyLight className="text-lg text-[#F79009]" />
          </div>
          <div className="w-full bg-[#FFFFFF4D] p-4 rounded-xl text-white border border-white">
            <p className="text-sm">Total Realisasi Komitmen</p>
            <p className="text-lg font-bold">{formattedValue(realizationTotal)}</p>
          </div>
        </div>
        <div className="w-full bg-[#6938EF] mb-4 p-4 rounded-xl text-white">
          <div className="bg-[#F4F3FF] rounded-lg p-3 w-fit mb-3">
            <PiMoneyLight className="text-lg text-[#6938EF]" />
          </div>
          <div className="w-full bg-[#FFFFFF4D] p-4 rounded-xl text-white border border-white">
            <p className="text-sm">Total Penyaluran</p>
            <p className="text-lg font-bold">{formattedValue(distributionTotal)}</p>
          </div>
        </div>
        <div className="w-full bg-[#01A24A] mb-4 p-4 rounded-xl text-white">
          <div className="bg-[#D6FFE8] rounded-lg p-3 w-fit mb-3">
            <GrMoney className="text-lg text-[#00BD52]" />
          </div>
          <div className="w-full bg-[#FFFFFF4D] p-4 rounded-xl text-white border border-white">
            <p className="text-sm">Total Pelaporan</p>
            <p className="text-lg font-bold">{formattedValue(reportTotal)}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="w-full bg-[#A20101] mb-4 p-4 rounded-xl text-white">
          <div className="w-full bg-[#FFFFFF4D] p-4 rounded-xl text-white border border-white">
            <p className="text-sm">Total yang Belum Tersalurkan</p>
            <p className="text-lg font-bold">{formattedValue(notyetDistributionTotal)}</p>
          </div>
        </div>
        <div className="w-full bg-[#C82386] mb-4 p-4 rounded-xl text-white">
          <div className="w-full bg-[#FFFFFF4D] p-4 rounded-xl text-white border border-white">
            <p className="text-sm">Total yang Belum Dilaporkan</p>
            <p className="text-lg font-bold">{formattedValue(notyetReportTotal)}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <Card noBorder>
          <CardBody>
              <div className="flex items-center flex-wrap flex-1">
                <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6 w-1/2 mb-3">
                  <div className="flex gap-5 items-center">
                    <div className="bg-[#D6FFE8] rounded-full p-2">
                      <CgNotes className="text-lg text-[#00BD52]" />
                    </div>
                    <span className="text-sm font-semibold">Lemtara</span>
                  </div>
                  <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
                    <span className="text-2xl font-semibold">{formattedValue(lemtara, false)}</span>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6 w-1/2 mb-3">
                  <div className="flex gap-5 items-center">
                    <div className="bg-[#D6FFE8] rounded-full p-2">
                      <CgNotes className="text-lg text-[#00BD52]" />
                    </div>
                    <span className="text-sm font-semibold">KL</span>
                  </div>
                  <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
                    <span className="text-2xl font-semibold">{formattedValue(kl, false)}</span>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6 w-1/2">
                  <div className="flex gap-5 items-center">
                    <div className="bg-[#D6FFE8] rounded-full p-2">
                      <CgNotes className="text-lg text-[#00BD52]" />
                    </div>
                    <span className="text-sm font-semibold">Laki-Laki</span>
                  </div>
                  <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
                    <span className="text-2xl font-semibold">{formattedValue(man, false)}</span>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-lg border-l-4 border-[#00BD52] flex flex-col gap-6 w-1/2">
                  <div className="flex gap-5 items-center">
                    <div className="bg-[#D6FFE8] rounded-full p-2">
                      <CgNotes className="text-lg text-[#00BD52]" />
                    </div>
                    <span className="text-sm font-semibold">Perempuan</span>
                  </div>
                  <div className="py-2 px-4 bg-[#F9FAFB] w-full rounded-lg">
                    <span className="text-2xl font-semibold">{formattedValue(woman, false)}</span>
                  </div>
                </div>
              </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Verification;

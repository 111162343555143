import React, {
  useCallback,
  useEffect,
  useMemo as UseMemo,
  useState,
  useContext,
} from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router";

import { BasicList } from "components/molecules/ListPage";
import { Button, SearchInput } from "components";
import useProjectManagementTeam from "./hooks/useHooks";
import { FormProvider, useForm } from "react-hook-form";
import { ConfirmationModal } from "components/molecules/Modal";
import axios from "axios";
import { SuccessToast, ErrorToast, ToastContext } from "components/atoms/Toast";
import config from "app/config";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { submitProjectManagementTeam } from "services/danaProgram/danaProgramService";

const TimPengelolaProyek = () => {
  const validationSchema = yup.object().shape({
    //   name: yup.string().required("wajib"),
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const navigate = useNavigate();

  const [nameFilter, setNameFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [rowPage, setRowPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortMethod, setSortMethod] = useState("asc");
  const [formModal, setFormModal] = useState(false);
  const {
    showToast,
    setShowToast,
    initialShowToast,
    showToastMessage,
    toastMessage,
    toastDescription,
  } = useContext(ToastContext);
  const [dataTable, setDataTable] = useState([]);

  const params = {
    searchKey: nameFilter,
    page: page,
    limit: rowPage,
    orderMethod: sortMethod,
    orderBy: sortBy,
  };

  const arrayOfProjectManagementTeams = [
    {
      _id: 1,
      no: 1,
      title: "Program Kehutanan",
      donor: "World Bank",
      team: 10,
    },
    {
      _id: 2,
      no: 2,
      title: "Program Kehutanan",
      donor: "UNDP",
      team: 27,
    },
    {
      _id: 1,
      no: 3,
      title: "Program Kehutanan",
      donor: "World Bank",
      team: 20,
    },
    {
      _id: 1,
      no: 4,
      title: "Program Kehutanan",
      donor: "Ford",
      team: 32,
    },
    {
      _id: 1,
      no: 5,
      title: "Program Kehutanan",
      donor: "Pemerintah",
      team: 18,
    },
  ];

  const {
    projectManagementTeamList,
    paginationProjectManagementTeam,
    refetch,
  } = useProjectManagementTeam({ params });

  useEffect(() => {
    refetch();
  }, [nameFilter, statusFilter, endDate, page, rowPage, sortMethod]);

  const submitData = useMutation({
    mutationFn: (params) => {
      return submitProjectManagementTeam(params);
    },
    onSuccess: () => {
      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const storeId = (id) => {
    localStorage.setItem("projectManagementTeamsId", id.toString());
  };

  const handleClick = (id) => {
    storeId(id);
    navigate("/project-management-team/assign");
  };

  const links = [
    {
      label: "Daftar Pengelola Proyek",
    },
  ];

  // useEffect(() => {
  //   // setStartDate(formatDateRange(start));
  //   // setEndDate(formatDateRange(end));
  // }, [end]);

  const paginator = UseMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) => {
        setNameFilter(event?.target?.value || "");
      },
      [setNameFilter]
    ),
    1000
  );

  const tableColumns = UseMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full w-[50px]">
              {no}
            </div>
          );
        },
      },

      {
        id: "proposal_name",
        title: "Judul Proposal",
        dataIndex: "proposal_name",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        fixed: "center",
        columnClassName: "w-[calc(100vw/8)] text-center overflow-hidden",
        render: (value) => (
          <div
            className="overflow-hidden  w-full w-[calc(100vw/8)]"
            style={{ textAlign: "-webkit-center" }}
          >
            {value}
          </div>
        ),
      },
      {
        id: "donor_name",
        title: "Donor",
        dataIndex: "donor_name",
        className: "",
        fixed: "center",
        columnClassName:
          "w-[calc(100vw/10)] text-ellipsis overflow-hidden text-center",
        sortable: true,
        render: (value) => (
          <div
            className="overflow-hidden w-full"
            style={{ textAlign: "-webkit-center" }}
          >
            {value}
          </div>
        ),
      },
      {
        id: "total_team",
        title: "Jumlah Tim",
        dataIndex: "total_team",
        className: "",
        fixed: "center",
        columnClassName:
          "w-[calc(100vw/6)] text-ellipsis overflow-hidden text-center",
        sortable: true,
        render: (value) => (
          <div
            className="overflow-hidden w-full"
            style={{ textAlign: "-webkit-center" }}
          >
            {value === 0 ? "-" : value}
          </div>
        ),
      },
      {
        id: "_id",
        title: "Aksi",
        dataIndex: "_id",
        className: "",
        columnClassName: "w-[250px] text-center text-ellipsis overflow-hidden",
        fixed: "center",
        render: (value) => {
          return value ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                <div className="space-x-3 justify-center flex w-full">
                  <Button
                    type="button"
                    className={`
                    hover:bg-green-600
                        text-white w-40
                          bg-[#00BD52]
                        `}
                    onClick={() => {
                      handleClick(value);
                    }}
                  >
                    Update Assign
                  </Button>
                </div>
              </div>
            </>
          ) : (
            ""
          );
        },
      },
    ];
  }, []);

  const onCreate = () => {
    navigate("/rencana-penarikan-dana/add");
    // localStorage.setItem("currentStep", JSON.stringify(1));
  };

  const deleteItemHandler = async () => {
    let payload = {
      id: selectedItem?._id,
    };

    try {
      const response = await axios.delete(
        `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/delete`,
        {
          data: payload,
        }
      );
      const data = response.data;

      if (data?.code === 200) {
        setFormModal(false);
        setSelectedItem(null);
        showToastMessage(
          "Data berhasil dihapus!",
          "Data yang anda pilih berhasil dihapus."
        );
        setShowToast((prev) => ({ ...prev, success: true }));
        refetch();
      }
    } catch (error) {
      if (error?.response?.data) {
        setFormModal(false);
        setSelectedItem(null);
        showToastMessage(
          "Data gagal dihapus!",
          error?.response?.data?.message || error?.response?.data?.[0]?.message
        );
        setShowToast((prev) => ({ ...prev, error: true }));
        refetch();
      }
    }
  };

  return (
    <div>
      <ConfirmationModal
        open={formModal}
        onClose={() => setFormModal(false)}
        onSubmit={deleteItemHandler}
      >
        <div>
          <div className="text-gray-900 text-lg font-semibold">
            Apakah Anda yakin akan menghapus draft ini?
          </div>
        </div>
      </ConfirmationModal>
      {showToast.success && (
        <SuccessToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      {showToast.error && (
        <ErrorToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      <BasicList
        links={links}
        title={"Tim Pengelola Proyek"}
        columns={tableColumns}
        dataSource={projectManagementTeamList}
        // onCreate={onCreate}
        sortMethod={sortMethod}
        setSortMethod={setSortMethod}
        setSortBy={setSortBy}
        // customTextAddButton="Tambah"
        className={`!w-full`}
        pagination={paginationProjectManagementTeam}
        onChangePage={(page) => setPage(page)}
        onChangeRowsPerPage={(row) => setRowPage(row)}
        customFilter={
          <FormProvider {...methods}>
            <div className="space-x-2 flex">
              <SearchInput
                controllerName={`search`}
                placeholder="Cari"
                onChange={onSearch}
              />
            </div>
          </FormProvider>
        }
      />
    </div>
  );
};

export default TimPengelolaProyek;

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
} from "components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import {
  useCreateInstitutionalMinistry,
  useInstitutionalMinistryDetail,
} from "../hooks/useInstitutionalMinistryService";
import { useEffect } from "react";

const links = [
  {
    label: "Daftar End Beneficiaries",
    path: "/institutional-ministry",
  },
  {
    label: "Tambah data end beneficiaries",
  },
];

const InstitutionalMinistryEdit = () => {
  const methods = useForm({
    defaultValues: {
      id: "",
      name: "",
      desc: "",
    },
  });
  const { id } = useParams();
  const { data, isLoading } = useInstitutionalMinistryDetail(id);

  const { submitMutation } = useCreateInstitutionalMinistry();

  const navigate = useNavigate();

  useEffect(() => {
    methods.setValue("id", data?._id);
    methods.setValue("name", data?.name);
    methods.setValue("desc", data?.desc);
  }, [isLoading]);

  const handleSubmitForm = async () => {
    try {
      const params = methods.getValues();
      submitMutation.mutate(params, {
        onSuccess: (res) => {
          navigate("/institutional-ministry");
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Breadcrumbs items={links} />
      <FormProvider {...methods}>
        <div className="flex flex-col gap-6 pt-8">
          <Card>
            <CardHeader>Rubah Data</CardHeader>
            <CardBody>
              <div className="flex flex-col gap-5">
                <InputForm
                  controllerName={`name`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Nama end beneficiaries"}
                  placeholder={"Nama end beneficiaries"}
                  required={true}
                />
                <InputForm
                  textArea={true}
                  controllerName={"desc"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Deskripsi"}
                  placeholder={"Deskripsi"}
                  required={true}
                />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="items-center justify-between block px-6 py-5 mt-4 mb-10 bg-white border border-gray-200 rounded-lg md:flex">
          <div>
            <Button
              type="button"
              className="px-8 text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
              onClick={() => {
                navigate("/institutional-ministry");
              }}
            >
              Batal
            </Button>
          </div>

          <div className="items-center justify-between block gap-2 md:flex">
            <Button
              type="button"
              disabled={submitMutation.isLoading}
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={() => {
                handleSubmitForm();
              }}
            >
              {submitMutation.isLoading
                ? "Tunggu Sebentar"
                : "Simpan Perubahan"}
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default InstitutionalMinistryEdit;

import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  InputFormRadio,
  Select,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const SafeguardsFormChild = ({ data, safeguards, methods }) => {
  const navigate = useNavigate();

  const { 
    watch, 
    getValues,
    formState: { errors, isValid } 
  } = useFormContext();

  console.log(`errors`, errors)

  const [isEdit, setIsEdit] = useState(false);
  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEdit = () => setIsEdit(!isEdit);
  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  useEffect(() => {
    methods.setValue("is_safeguard", data["is_safeguard"]);
  }, []);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Safeguards</div>
          <div className="flex-1 text-right">
            {isEdit ? (
              <Button
                type="button"
                className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Simpan Perubahan
              </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Ubah
              </Button>
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col">
          <QuestionBadge text="Apakah Lembaga Anda pernah mengimplementasikan safeguard?" />
          <InputFormRadio
            controllerName={`is_safeguard`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
            disabled={!isEdit}
            values={[
              {
                label: "Ya",
                value: true,
              },
              {
                label: "Tidak",
                value: false,
              },
            ]}
          />
          {methods.getValues("is_safeguard") &&
            safeguards?.map((field, index) => {
              return (
                <>
                  <div className="mt-[20px]" key={field.id}>
                    <div className="py-[24px] mb-4 w-ful h-[70px] justify-center align-start flex-col flex gap-[20px] self-stretch rounded-md bg-[#E1F1D6]">
                      <span className="text-green-500 pl-5 font-semibold">
                        Pengalaman Safeguard {index + 1}
                      </span>
                    </div>
                    <div className="space-y-3">
                      <InputForm
                        controllerName={`safeguards.${index}.name`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                        label={"Deskripsi Singkat gambaran safeguardnya"}
                        placeholder={"Deskripsi Singkat gambaran safeguardnya"}
                        disabled={!isEdit}
                        textArea={true}
                      />

                      <div className="grid md:grid-cols-2 gap-4">
                        <InputForm
                          controllerName={`safeguards.${index}.desc`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"Nama Donor"}
                          placeholder={"Nama Donor"}
                          disabled={!isEdit}
                          textArea={true}
                        />
                        <Select
                          controllerName={`safeguards.${index}.period`}
                          className={`mt-2 w-full flex-1`}
                          label={"Periode Pelaporan"}
                          placeholder={"Periode Pelaporan"}
                          disable={!isEdit}
                          returnObject={false}
                          options={[
                            { value: "1 Kali", label: "1 kali" },
                            { value: "2 Kali", label: "2 Kali" },
                            { value: "3 Kali", label: "3 Kali" },
                            { value: "4 Kali", label: "4 Kali" },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  {/* button Verification */}
                  <RadioVerificationBadge 
                    className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
                    name={`safeguards.${index}.status_approval_safeguard_data`}
                    nameNotes={`safeguards.${index}.notes_approval_safeguard_data`}
                    isEditVerification={isEditVerification}
                    toogleIsEditVerification={toogleIsEditVerification}
                    // errors={errors?.safeguards[index] ? errors?.safeguards[index]?.status_approval_safeguard_data : false}
                  />
                </>
              );
            })}
        </div>
      </CardBody>
    </Card>
  );
};

export default SafeguardsFormChild;

import React, { useMemo as UseMemo, useState, useMemo } from "react";
import { BasicList } from "components/molecules/ListPage";
import { FormProvider, useForm } from "react-hook-form";
import { CustomSelect, AtomDatePicker, SearchInput } from "components";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router";
import moment from "moment";
import { debounce } from "lodash";
import { BiEdit, BiTrash } from "react-icons/bi";
import { createSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  deleteDirectSubmission,
  getDirectSubmissionList,
} from "services/danaProgram/directSubmissionService";
import { formattedValue } from "helpers";
import { ConfirmationModal } from "components/molecules/Modal";
import { enqueueSnackbar } from "notistack";

const ListPermohonanPenyaluranLangsung = () => {
  const methods = useForm({
    defaultValues: {
      date_of_filling: [null, null],
    },
  });

  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState({
    open: false,
    id: "",
  });
  const [keyword, setKeyword] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState(null);
  const [orderMethod, setOrderMethod] = useState(null);

  const params = {
    name: search,
    status: statusFilter,
    page: page,
    limit: limit,
    order_by: orderBy,
    order_method: orderMethod,
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["get-direct-submission-list", search, statusFilter],
    queryFn: async () => {
      const res = await getDirectSubmissionList(params);

      return res.data.data;
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [statusFilter, page, limit, search, orderBy, orderMethod]);

  const links = [
    {
      label: "Daftar Permohonan Penyaluran Langsung",
    },
  ];

  const paginator = UseMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const tableColumns = UseMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "RequesterName",
        title: "Nama Pemohon",
        dataIndex: "applicant_name",
        className: "",
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: "TotalIncentiveSuggestion",
        title: "Usulan Nilai Insentif",
        dataIndex: "total_incentive_suggestion",
        className: "",
        columnClassName: "w-[120px] text-ellipsis overflow-hidden",
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },

        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {formattedValue(value)}
          </div>
        ),
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        headerTextPosition: "center",
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },
        fixed: "center",
        render: (value) => (
          <div className="text-ellipsis w-full flex justify-center">
            {value === "Menunggu Persetujuan" && (
              <div className="bg-[#FFFAEB] px-1 rounded-[25px] flex gap-[1px] items-center w-fit">
                <div className="text-[#B54708] font-medium text-[12px]">
                  Menunggu Persetujuan
                </div>
              </div>
            )}
            {value === "Disetujui" && (
              <div className="bg-[#ECFDF3] px-3 rounded-[25px] flex gap-[1px] w-fit items-center">
                <div className="text-[#027A48] font-medium text-[12px]">
                  Disetujui
                </div>
              </div>
            )}
            {value === "Ditolak" && (
              <div className="bg-[#FEF3F2] px-1 rounded-[25px] flex gap-[1px] items-center w-fit">
                <div className="text-[#B42318] font-medium text-[12px]">
                  Ditolak
                </div>
              </div>
            )}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "status",
        className: "",
        sortable: true,
        columnClassName: "w-[125px] text-center text-ellipsis overflow-hidden",
        fixed: "right",
        render: (value, data, index) => {
          return (
            <>
              {(value === "Disetujui" || value === "Ditolak") && (
                <>
                  <div className="flex flex-col justify-center items-center gap-[12px]">
                    <FaEye
                      className="text-gray-600 cursor-pointer flex-1"
                      size={20}
                      onClick={() => {
                        navigate({
                          pathname: "/permohonan-penyaluran-langsung/form",
                          search: createSearchParams({
                            id: data._id,
                            mode: "detail",
                          }).toString(),
                        });
                      }}
                    />
                  </div>
                </>
              )}
              {value === "Menunggu Persetujuan" && (
                <div className="flex flex-col justify-center items-center gap-[12px]">
                  <div className="space-x-3 justify-center flex w-full text-xl">
                    <BiEdit
                      onClick={() => {
                        navigate({
                          pathname: "/permohonan-penyaluran-langsung/form",
                          search: createSearchParams({
                            id: data._id,
                          }).toString(),
                        });
                      }}
                    />
                    <BiTrash
                      onClick={() =>
                        setConfirmModal({
                          open: true,
                          id: data._id,
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </>
          );
        },
      },
    ];
  }, []);

  const statusOptions = UseMemo(() => {
    return [
      { value: null, label: "Semua" },
      { value: "Menunggu Persetujuan", label: "Menunggu Persetujuan" },
      { value: "Disetujui", label: "Disetujui" },
      { value: "Ditolak", label: "Ditolak" },
    ];
  });

  const onSearch = debounce((value) => {
    setSearch(value);
  }, 500);

  const onDelete = useMutation({
    mutationKey: ["delete-direct-submission"],
    mutationFn: async (id) => {
      const res = await deleteDirectSubmission(id);

      return res.data.data;
    },
    onSuccess: (res) => {
      enqueueSnackbar("Data berhasil dihapus", { variant: "success" });
      setConfirmModal({
        open: false,
        id: "",
      });
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return (
    <>
      <ConfirmationModal
        open={confirmModal.open}
        onClose={() =>
          setConfirmModal({
            open: false,
            id: "",
          })
        }
        onSubmit={() => {
          onDelete.mutate(confirmModal.id);
        }}
      >
        Apakah anda yakin akan menghapus data pengajuan ini?
      </ConfirmationModal>
      <BasicList
        links={links}
        title="Daftar Permohonan Penyaluran Langsung"
        columns={tableColumns}
        dataSource={data?.data}
        onCreate={() => {
          navigate("/permohonan-penyaluran-langsung/form");
        }}
        onChangePage={(page) => setPage(page)}
        onChangeRowsPerPage={(row) => setLimit(row)}
        pagination={{
          page: data?.page,
          perPage: data?.perPage,
          total: data?.total,
          lastPage: data?.lastPage,
        }}
        isLoading={isLoading}
        customFilter={
          <FormProvider {...methods}>
            <div className="space-x-5 flex">
              <SearchInput
                placeholder="Cari"
                onChange={(e) => onSearch(e.target.value)}
              />
              <CustomSelect
                optionsData={statusOptions}
                onChange={(status) => setStatusFilter(status.value)}
                placeholder="Status"
                className="flex-1 rounded-md border-gray-200 border-solid text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
              />
            </div>
          </FormProvider>
        }
      />
    </>
  );
};

export default ListPermohonanPenyaluranLangsung;

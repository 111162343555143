import React, { useMemo, useCallback, useState, useEffect } from "react";
import ReactSelect from "react-select";

let NEXT_PAGE = null;

const CustomSelect = ({
  optionsData,
  onChange,
  value,
  placeholder = "Cari ...",
  inputClassName = "",
  ...props
}) => {
  const [options, setOptions] = useState([]);

  const SelectStyles = useMemo(
    () => ({
      control: (styles) => ({
        ...styles,
        borderColor: "#EAECF0",
        ":hover": {
          ...styles[":hover"],
          borderColor: "#EAECF0",
        },
        boxShadow: "none",
        padding: inputClassName ? inputClassName : "",
        borderRadius: "6px",
      }),
      singleValue: (styles) => ({
        ...styles,
        color: "#98A2B3",
      }),
      indicatorContainer: (styles, { isFocused }) => ({
        ...styles,
        color: isFocused ? "#98A2B3" : "#98A2B3",
        ":hover": {
          ...styles[":hover"],
          color: "#98A2B3",
        },
      }),
      option: (styles, { data, isSelected, isFocused, isDisabled }) => ({
        ...styles,
        cursor: isDisabled ? "not-allowed" : "auto",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#01A24A"
          : isFocused
          ? "#EEFFF4"
          : undefined,
        color: isDisabled
          ? "hsl(0, 0%, 80%)"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#98A2B3"
          : data.color,
      }),
    }),
    []
  );

  useEffect(() => {
    if (optionsData.length) {
      setOptions(
        optionsData.map((data) => ({
          value: data.value,
          label: data.label,
        }))
      );
    }
  }, [optionsData]);

  return (
    <ReactSelect
      {...props}
      placeholder={placeholder}
      value={value}
      options={options}
      styles={SelectStyles}
      closeMenuOnScroll={false}
      onChange={onChange}
      classNamePrefix={`my-select`}
    />
  );
};

export default React.memo(CustomSelect);

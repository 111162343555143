import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Routes as WrapperRoutes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import route from "./route";

import My404Component from "pages/My404Component";

import { BasicLayout } from "components";
import { ToastProvider } from "components/atoms/Toast";
import { useMutation } from "@tanstack/react-query";
import {
  getCities,
  getCollaborationFields,
  getCountries,
  getInstitutionTypes,
  getIssueFocuses,
  getPositions,
  getPostalCodes,
  getProvinces,
  getStrategicPlans,
  getSubdistricts,
  getVillages,
} from "services/danaProgram/masterDataService";
import { updateMasterData } from "app/actions";
import { Callback, SignInCallback } from "../components/auth/signInCallback";
import { SilentRenew } from "../components/auth/silentRenew";
import { AuthConsumer } from "providers/authProvider";
import Logout from "components/auth/logout";
import { SignOutCallback } from "components/auth/signOutCallback";

const Routes = () => {
  // const dispatch = useDispatch();
  // const [masterData, setMasterData] = useState({
  //   fokusIsu: null,
  //   bidangKerjasama: null,
  //   jenisLembaga: null,
  //   rencanaStrategis: null,
  //   jabatan: null,
  //   negara: null,
  //   provinsi: null,
  //   kotaKabupaten: null,
  //   kecamatan: null,
  //   kelurahan: null,
  // });
  // const oidcItems = [];
  // for (let i = 0; i < localStorage.length; i++) {
  //   const key = localStorage.key(i);

  //   if (key.includes("oidc")) {
  //     const item = localStorage.getItem(key);
  //     oidcItems.push({ key, item });
  //   }
  // }
  // const getIssue = useMutation(async () => {
  //   try {
  //     const issueResponse = await getIssueFocuses();
  //     setMasterData((prevMasterData) => ({
  //       ...prevMasterData,
  //       fokusIsu: _mapMasterData(issueResponse?.data?.data),
  //     }));
  //   } catch (error) {
  //     throw error;
  //   }
  // });
  // const getCollaboration = useMutation(async () => {
  //   try {
  //     const collabResponse = await getCollaborationFields();
  //     setMasterData((prevMasterData) => ({
  //       ...prevMasterData,
  //       bidangKerjasama: _mapMasterData(collabResponse?.data?.data),
  //     }));
  //   } catch (error) {
  //     throw error;
  //   }
  // });
  // const getInstitution = useMutation(async () => {
  //   try {
  //     const institutionResponse = await getInstitutionTypes();
  //     setMasterData((prevMasterData) => ({
  //       ...prevMasterData,
  //       jenisLembaga: _mapMasterData(institutionResponse?.data?.data),
  //     }));
  //   } catch (error) {
  //     throw error;
  //   }
  // });
  // const getStrategic = useMutation(async () => {
  //   try {
  //     const strategicResponse = await getStrategicPlans();
  //     setMasterData((prevMasterData) => ({
  //       ...prevMasterData,
  //       rencanaStrategis: _mapMasterData(strategicResponse?.data?.data),
  //     }));
  //   } catch (error) {
  //     throw error;
  //   }
  // });
  // const getPosition = useMutation(() => getPositions(),
  // {
  //   onSuccess: (data) => {
  //     setMasterData((prevMasterData) => ({
  //       ...prevMasterData,
  //       jabatan: _mapMasterData(data?.data?.data)
  //     }))
  //   },
  //   onError: (error) => {
  //     console.log('failed get master data from api/master/v1/Jabatan/list', error)
  //   }
  // })
  // const getCountry = useMutation(() => getCountries(),
  // {
  //   onSuccess: (data) => {
  //     setMasterData((prevMasterData) => ({
  //       ...prevMasterData,
  //       negara: _mapMasterData(data?.data?.data.map((e) => {
  //         return {
  //           id: e?.id,
  //           name: e?.namaNegara,
  //         }
  //       }))
  //     }))
  //   },
  //   onError: (error) => {
  //     console.log('failed get master data from api/master/v1/Negara/list', error)
  //   }
  // })
  // const getProvince = useMutation(() => getProvinces(),
  // {
  //   onSuccess: (data) => {
  //     setMasterData((prevMasterData) => ({
  //       ...prevMasterData,
  //       provinsi: _mapMasterData(data?.data?.data.map((e) => {
  //         return {
  //           id: e?.id,
  //           name: e?.namaProvinsi,
  //           extraId: e?.idNegara,
  //         }
  //       }))
  //     }))
  //   },
  //   onError: (error) => {
  //     console.log('failed get master data from api/master/v1/Provinsi/list', error)
  //   }
  // })
  // const getCity = useMutation(() => getCities(),
  // {
  //   onSuccess: (data) => {
  //     setMasterData((prevMasterData) => ({
  //       ...prevMasterData,
  //       kotaKabupaten: _mapMasterData(data?.data?.data.map((e) => {
  //         return {
  //           id: e?.id,
  //           name: e?.namaKabupatenKota,
  //           extraId: e?.idProvinsi,
  //         }
  //       }))
  //     }))
  //   },
  //   onError: (error) => {
  //     console.log('failed get master data from api/master/v1/KabupatenKota/list', error)
  //   }
  // })
  // const getSubdistrict = useMutation(() => getSubdistricts(),
  // {
  //   onSuccess: (data) => {
  //     setMasterData((prevMasterData) => ({
  //       ...prevMasterData,
  //       kecamatan: _mapMasterData(data?.data?.data.map((e) => {
  //         return {
  //           id: e?.id,
  //           name: e?.namaKecamatan,
  //           extraId: e?.idKabupatenKota,
  //         }
  //       }))
  //     }))
  //   },
  //   onError: (error) => {
  //     console.log('failed get master data from api/master/v1/Kecamatan/list', error)
  //   }
  // })
  // const getVillage = useMutation(() => getVillages(),
  // {
  //   onSuccess: (data) => {
  //     setMasterData((prevMasterData) => ({
  //       ...prevMasterData,
  //       kelurahan: _mapMasterData(data?.data?.data.map((e) => {
  //         return {
  //           id: e?.id,
  //           name: e?.namaKelurahan,
  //           extraId: e?.idKecamatan,
  //         }
  //       }))
  //     }))
  //   },
  //   onError: (error) => {
  //     console.log('failed get master data from api/master/v1/Kelurahan/list', error)
  //   }
  // })
  // const getPostalCode = useMutation(() => getPostalCodes(),
  // {
  //   onSuccess: (data) => {
  //     setMasterData((prevMasterData) => ({
  //       ...prevMasterData,
  //       kodePos: _mapMasterData(data?.data?.data.map((e) => {
  //         return {
  //           id: e?.id,
  //           name: e?.kodePos,
  //           extraId: e?.idKelurahan
  //         }
  //       }))
  //     }))
  //   },
  //   onError: (error) => {
  //     console.log('failed get master data from api/master/v1/KodePos/list', error)
  //   }
  // })

  // const _mapMasterData = useCallback((data) => {
  //   const transformedData = data?.map((item) => ({
  //     label: item?.nama ?? item?.name,
  //     value: item?.id,
  //     description: item?.deskripsi,
  //     extraId: item?.extraId,
  //   }));
  //   return transformedData;
  // }, []);

  // const hasRun = useRef(false);

  // useEffect(() => {
  //   if (!hasRun.current) {
  //     getIssue.mutate();
  //     getCollaboration.mutate();
  //     getInstitution.mutate();
  //     getStrategic.mutate();
  //     getPosition.mutate();
  //     getCountry.mutate();
  //     getProvince.mutate();
  //     getCity.mutate();
  //     getSubdistrict.mutate();
  //     getVillage.mutate();
  //     getPostalCode.mutate();
  //     hasRun.current = true;
  //   }
  // }, []);

  // useEffect(() => {
  //   dispatch(updateMasterData({ data: { ...masterData } }));
  // }, [masterData]);

  // const { token } = useSelector((state) => state.auth);
  // console.log(route)
  return (
    <Router>
      <ToastProvider>
        <WrapperRoutes>
          <Route path="/signin-oidc" element={<SignInCallback />} />
          <Route path="/signout-oidc" element={<SignOutCallback />} />
          <Route path="/silentrenew" component={<SilentRenew />} />
          <Route path="/logout" element={<Logout />} />
          {_.map(_.filter(route, { routeType: "public" }), (val, key) => {
            const Component = val.element;
            return (
              <Route
                exact={true}
                key={key}
                path={val.path}
                element={
                  <BasicLayout>
                    <Component />
                  </BasicLayout>
                }
              />
            );
          })}
          {_.map(_.filter(route, { routeType: "private" }), (val, key) => {
            const Component = val.element;

            return (
              <Route
                exact={true}
                key={key}
                path={val.path}
                element={
                  <AuthConsumer>
                    {({ isAuthenticated, signinRedirect }) => {
                      if (isAuthenticated()) {
                        return (
                          <BasicLayout
                            props={val.sidebarProps}
                            sidebar={val.sidebar}
                          >
                            <Component />
                          </BasicLayout>
                        );
                      } else {
                        return signinRedirect();
                      }
                    }}
                  </AuthConsumer>
                }
              />
            );
          })}
          <Route path="*" element={<My404Component />} />
        </WrapperRoutes>
      </ToastProvider>
    </Router>
  );
};

export default Routes;

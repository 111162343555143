import React, {
  useCallback,
  useEffect,
  useMemo as UseMemo,
  useState,
  useContext,
} from 'react';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router';

import { BasicList } from 'components/molecules/ListPage';
import { CustomSelect, AtomDatePicker, SearchInput, Button } from 'components';
import useProgressReport from './hooks/useHooks';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import { ConfirmationModal } from 'components/molecules/Modal';
import axios from 'axios';
import { SuccessToast, ErrorToast, ToastContext } from 'components/atoms/Toast';
import config from 'app/config';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
const LaporanKemajuan = () => {
  const validationSchema = yup.object().shape({
    //   name: yup.string().required("wajib"),
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const navigate = useNavigate();

  const [firstRender, setFirstRender] = useState(true);

  const [nameFilter, setNameFilter] = useState('');
  const [page, setPage] = useState(1);
  const [rowPage, setRowPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortBy, setSortBy] = useState('status');
  const [sortMethod, setSortMethod] = useState('asc');
  const [formModal, setFormModal] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const {
    showToast,
    setShowToast,
    initialShowToast,
    showToastMessage,
    toastMessage,
    toastDescription,
  } = useContext(ToastContext);
  const [dataTable, setDataTable] = useState([]);

  // const [start, end] = methods.watch("exampleDate") || [null, null];

  // const { watch, getValue, getValues, control } = useFormContext();

  // const [start, end] = methods.watch("exampleDate") || [null, null];

  const params = {
    searchKey: nameFilter,
    start_date: moment(methods.watch('exampleDate')).format('DD/MM/YYYY'),
    page: page,
    limit: rowPage,
    orderMethod: sortMethod,
    orderBy: sortBy,
  };

  const { ProgressReportList, paginationProgressReport, refetch } =
    useProgressReport({ params });

  const mapData = async (dataPromise) => {
    try {
      const data = await dataPromise;
      setDataTable(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    sessionStorage.removeItem('ProgressReportsReviewId');
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [page, rowPage]);

  useEffect(() => {
    let temp =
      ProgressReportList?.map((data) => ({
        label: data?.project_name,
        value: data?.project_id,
      })) || [];
    temp?.push({ value: '', label: 'All' });
    const sortedArray = [...temp].sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
    setProjectList(sortedArray);
  }, []);

  useEffect(() => {
    refetch();
  }, [nameFilter]);

  const handleOpenDetail = (id) => {
    storeId(id);
  };

  const storeId = (id) => {
    sessionStorage.setItem('ProgressReportsReviewId', id.toString());
  };

  const handleOpenRevision = (id) => {
    storeId(id);
    navigate('/project-management-team/assign');
  };

  const links = [
    {
      label: 'Daftar Laporan Kemajuan',
    },
  ];

  const paginator = UseMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) => {
        setNameFilter(event?.target?.value || '');
      },
      [setNameFilter]
    ),
    1000
  );

  const onChangeProject = (e) => {
    setNameFilter(e?.label === 'All' ? e?.value : e?.label);
  };

  const tableColumns = UseMemo(() => {
    return [
      {
        id: 'no',
        title: 'No',
        dataIndex: 'no',
        className: 'overflow-hidden',
        columnClassName: 'w-[50px]',
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: 'submission_date',
        title: 'Tanggal Laporan',
        dataIndex: 'submission_date',
        className: 'overflow-hidden text-ellipsis',
        sortable: true,
        fixed: 'center',
        columnClassName: 'w-[calc(100vw/8)] text-center overflow-hidden',
        render: (value) => (
          <div
            className="overflow-hidden  w-full w-[calc(100vw/8)]"
            style={{ textAlign: '-webkit-center' }}
          >
            {moment(value).format('DD MMM, YYYY')}
          </div>
        ),
      },
      {
        id: 'project_name',
        title: 'Proyek',
        dataIndex: 'project_name',
        className: 'overflow-hidden text-ellipsis',
        sortable: true,
        fixed: 'center',
        columnClassName: 'w-[calc(100vw/8)] text-center overflow-hidden',
        render: (value) => (
          <div
            className="overflow-hidden  w-full w-[calc(100vw/8)]"
            style={{ textAlign: '-webkit-center' }}
          >
            {value}
          </div>
        ),
      },
      {
        id: 'submitter',
        title: 'Pelapor',
        dataIndex: 'submitter',
        className: '',
        fixed: 'center',
        columnClassName:
          'w-[calc(100vw/10)] text-ellipsis overflow-hidden text-center',
        sortable: true,
        render: (value) => (
          <div
            className="overflow-hidden w-full"
            style={{ textAlign: '-webkit-center' }}
          >
            {value}
          </div>
        ),
      },
      {
        id: 'total_contribution',
        title: 'Capaian Kemajuan',
        dataIndex: 'total_contribution',
        className: '',
        fixed: 'center',
        columnClassName:
          'w-[calc(100vw/10)] text-ellipsis overflow-hidden text-center',
        sortable: true,
        render: (value) => (
          <div
            className="overflow-hidden w-full"
            style={{ textAlign: '-webkit-center' }}
          >
            {value}%
          </div>
        ),
      },
      {
        id: 'status',
        title: 'Status',
        dataIndex: 'status',
        className: '',
        fixed: 'center',
        columnClassName:
          'w-[calc(100vw/10)] text-ellipsis overflow-hidden text-center',
        sortable: true,
        render: (value) => (
          <div
            className="overflow-hidden text-white font-bold text-[12px]"
            style={{
              textAlign: '-webkit-center',
              color:
                value === 'VERIFIED'
                  ? 'green'
                  : value === 'DRAFTED'
                  ? 'lightgrey'
                  : 'orange',
              borderRadius: 100,
            }}
          >
            <p className="p-1 text-sm">
              {value === 'NEED_APPROVAL'
                ? 'Menunggu Verifikasi'
                : value === 'DRAFTED'
                ? 'Draft'
                : 'Terverifikasi'}
            </p>
          </div>
        ),
      },
      {
        id: '_id',
        title: 'Aksi',
        dataIndex: '_id',
        className: '',
        columnClassName:
          'w-[calc(100vw/12)] text-center text-ellipsis overflow-hidden',
        fixed: 'center',
        render: (value, data, index) => {
          return value ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                <div className="space-x-3 justify-center flex w-full">
                  <Button
                    type="button"
                    className={
                      data?.status === 'VERIFIED'
                        ? 'text-[#00BD52] bg-white border-gray-400 w-40'
                        : `text-white w-40
                          bg-[#00BD52]`
                    }
                    onClick={() => {
                      const url =
                        data?.status === 'VERIFIED'
                          ? '/progress-report-review/review?type=detail'
                          : '/progress-report-review/review?type=review';
                      handleOpenDetail(value);
                      navigate(url);
                    }}
                  >
                    {data?.status === 'VERIFIED' ? 'Detail' : 'Review'}
                  </Button>
                </div>
              </div>
            </>
          ) : (
            ''
          );
        },
      },
    ];
  }, []);

  const onCreate = () => {
    navigate('/rencana-penarikan-dana/add');
    // localStorage.setItem("currentStep", JSON.stringify(1));
  };

  const deleteItemHandler = async () => {
    let payload = {
      id: selectedItem?._id,
    };

    try {
      const response = await axios.delete(
        `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/delete`,
        {
          data: payload,
        }
      );
      const data = response.data;

      if (data?.code === 200) {
        setFormModal(false);
        setSelectedItem(null);
        showToastMessage(
          'Data berhasil dihapus!',
          'Data yang anda pilih berhasil dihapus.'
        );
        setShowToast((prev) => ({ ...prev, success: true }));
        refetch();
      }
    } catch (error) {
      if (error?.response?.data) {
        setFormModal(false);
        setSelectedItem(null);
        showToastMessage(
          'Data gagal dihapus!',
          error?.response?.data?.message || error?.response?.data?.[0]?.message
        );
        setShowToast((prev) => ({ ...prev, error: true }));
        refetch();
      }
    }
  };

  return (
    <div>
      <ConfirmationModal
        open={formModal}
        onClose={() => setFormModal(false)}
        onSubmit={deleteItemHandler}
      >
        <div>
          <div className="text-gray-900 text-lg font-semibold">
            Apakah Anda yakin akan menghapus draft ini?
          </div>
        </div>
      </ConfirmationModal>
      {showToast.success && (
        <SuccessToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      {showToast.error && (
        <ErrorToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      <BasicList
        links={links}
        title={'Daftar Laporan Kemajuan'}
        columns={tableColumns}
        dataSource={ProgressReportList}
        // onCreate={onCreate}
        sortMethod={sortMethod}
        setSortMethod={setSortMethod}
        setSortBy={setSortBy}
        // customTextAddButton="Tambah"
        className={`!w-full`}
        pagination={paginationProgressReport}
        onChangePage={(page) => setPage(page)}
        onChangeRowsPerPage={(row) => setRowPage(row)}
        customFilter={
          <FormProvider {...methods}>
            <div className="space-x-2 flex">
              <SearchInput
                controllerName={`search`}
                placeholder="Cari"
                onChange={onSearch}
              />
              <CustomSelect
                optionsData={projectList}
                placeholder="Proyek"
                inputClassName="5px"
                controllerName="ProjectName"
                className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
                onChange={onChangeProject}
              />
              <div className="flex-1">
                <AtomDatePicker
                  className="!mt-0 flex-1 rounded-md border-gray-200 border-solid text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
                  controllerName="DateProject"
                  label={null}
                  placeholder="Tanggal Laporan"
                  asText={false}
                  isRangeDatePicker={true}
                />
              </div>
            </div>
          </FormProvider>
        }
      />
    </div>
  );
};

export default LaporanKemajuan;

import React from "react";

const CardBody = ({ className, children, borderBottom = false }) => {
  return (
    <div
      className={`${className} px-6 py-5 ${
        borderBottom ? "border-b-[1px] border-gray-200" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default CardBody;

import { AtomButton, CardForm, InputForm } from "components";
import { convertMoney } from "helpers";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";

const ActivityTarget = ({ methods, disabled }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "activity_targets",
  });

  return (
    <CardForm
      label={`Target Kegiatan`}
      buttonCollapse={true}
      childerForm={true}
    >
      <div className="flex flex-col gap-6">
        {fields?.length > 0 &&
          fields?.map((field, key) => (
            <div key={field.id} className="grid grid-cols-11 gap-6">
              <div className="col-span-5">
                <InputForm
                  controllerName={`activity_targets.${key}.target`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Target Hasil"}
                  placeholder={"Target Hasil"}
                  required={false}
                  disabled={disabled}
                />
              </div>
              <div className="col-span-5">
                <InputForm
                  controllerName={`activity_targets.${key}.total`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Jumlah"}
                  placeholder={"Jumlah"}
                  required={false}
                  onChangeInput={(e) => {
                    methods.setValue(
                      `activity_targets.${key}.total`,
                      convertMoney(e.target.value)
                    );
                  }}
                  disabled={disabled}
                />
              </div>
              <div className="flex items-end h-full w-max">
                <button
                  className={`w-11 h-11 p-3 ${
                    key !== 0 ? "bg-[#D92D20]" : "hidden"
                  } text-white rounded-md flex justify-center items-center`}
                  onClick={() => remove(field.id)}
                >
                  <RiDeleteBin6Line
                    className={`${key === 0 ? "hidden" : ""} `}
                  />
                </button>
              </div>
            </div>
          ))}
        <div className="text-end">
          <AtomButton
            theme="ghost"
            className="p-4 normal-case border border-gray-300 rounded-lg"
            label={
              <div className="flex items-center gap-2 font-[14px]">
                <RiAddLine />
                Tambah Target Kegiatan
              </div>
            }
            disabled={disabled}
            onClick={() => append({ outcome_target: "", total: "" })}
          />
        </div>
      </div>
    </CardForm>
  );
};

export default ActivityTarget;

import React, { createContext, useContext, useReducer } from "react";

const AnnualWorkPlanContext = createContext();

const initialState = {
  idAwp: null,
  idInvestment: null,
  currentStep: 1,
  type: "create",
  dataAnnualWorkPlan: {},
  fetchData: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        idAwp: action.payload.idAwp,
        idInvestment: action.payload.idInvestment,
        currentStep: parseInt(action.payload.currentStep),
        type: action.payload.type,
        dataAnnualWorkPlan: action.payload.dataAnnualWorkPlan,
        fetchData: action.payload.fetchedData,
      };
    case "SET_CURRENT_STEP":
      return {
        ...state,
        currentStep: parseInt(action.payload.currentStep),
      };
    case "SET_ID_AWP":
      return {
        ...state,
        idAwp: action.payload.idAwp,
      };
    default:
      return state;
  }
};

export const AnnualWorkPlanProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };

  const setCurrentStep = (data) => {
    dispatch({ type: "SET_CURRENT_STEP", payload: data });
  };

  const setIdAwp = (data) => {
    dispatch({ type: "SET_ID_AWP", payload: data });
  };

  return (
    <AnnualWorkPlanContext.Provider
      value={{ ...state, setData, setCurrentStep, setIdAwp }}
    >
      {children}
    </AnnualWorkPlanContext.Provider>
  );
};

export const UseAnnualWorkPlanContext = () => {
  return useContext(AnnualWorkPlanContext);
};

import React from "react";
import { RiCalendarLine } from "react-icons/ri";

const DateInput = ({ placeholder = "dd/mm/yyyy", onChange, ...props }) => {
  return (
    <div
      className={`flex flex-1 items-center rounded-md border-gray-200 px-4 space-x-3 border-solid border-[1px] ${
        props?.invalid && "!border-[#F04438]"
      }`}
    >
      {/* <RiCalendarLine className="text-gray-400" size={20} /> */}
      <input
        type="date"
        className={`bg-white text-gray-400 w-full text-sm p-2 outline-none border-none ${
          props.disabled && `cursor-not-allowed`
        } focus:border-0 focus:ring-0`}
        placeholder={placeholder}
        disable
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export default DateInput;

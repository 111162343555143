import { Button, InputForm } from "components";
import React from "react";
import { IoMdClose } from "react-icons/io";
import { RiAddFill } from "react-icons/ri";

const AddResourcePersonModal = ({ close }) => {
  return (
    <div className="fixed inset-0 z-50 flex justify-center px-[271px] overflow-y-scroll bg-black/80">
      <div className="flex flex-col w-full gap-6 p-6 overflow-hidden bg-white h-max my-11 rounded-xl">
        {/* HEADER */}
        <div className="flex items-center justify-between">
          <p className="text-lg font-semibold">Pilih Narasumber</p>
          <IoMdClose onClick={close} className="cursor-pointer" />
        </div>
        {/* END HEADER */}
        {/* BODY */}
        <div className="flex flex-col gap-5">
          <InputForm
            controllerName={"project_name"}
            className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
            label={"Cari Narasumber"}
            placeholder={"Cari Narasumber"}
            required={false}
          />
          <div className="pb-6 border-b border-gray-300">
            <Button
              type="button"
              className="text-white w-max border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={() => {
                console.log("first");
              }}
            >
              <RiAddFill className="text-white" />
              Buat Data Baru
            </Button>
          </div>
          <InputForm
            controllerName={"project_name"}
            className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
            label={"NIK/ NIP"}
            placeholder={"NIK/ NIP"}
            required={false}
          />
          <InputForm
            controllerName={"project_name"}
            className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
            label={"Nama"}
            placeholder={"Nama"}
            required={false}
          />
          <InputForm
            controllerName={"project_name"}
            className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
            label={"Jenis kelamin"}
            placeholder={"Jenis kelamin"}
            required={false}
          />
          <InputForm
            controllerName={"project_name"}
            className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
            label={"No Handphone"}
            placeholder={"No Handphone"}
            required={false}
          />
          <InputForm
            controllerName={"project_name"}
            className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
            label={"NPWP"}
            placeholder={"NPWP"}
            required={false}
          />
          <InputForm
            controllerName={"project_name"}
            className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
            label={"Instansi"}
            placeholder={"Instansi"}
            required={false}
          />
          <InputForm
            controllerName={"project_name"}
            className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
            label={"Golongan"}
            placeholder={"Golongan"}
            required={false}
          />
          <InputForm
            controllerName={"project_name"}
            className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
            label={"Jabatan"}
            placeholder={"Jabatan"}
            required={false}
          />
          <InputForm
            controllerName={"project_name"}
            className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
            label={"Nomor Rekening"}
            placeholder={"Nomor Rekening"}
            required={false}
          />
          <InputForm
            controllerName={"project_name"}
            className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
            label={"Nama Pemilik Rekening"}
            placeholder={"Nama Pemilik Rekening"}
            required={false}
          />
          <InputForm
            controllerName={"project_name"}
            className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
            label={"Bank"}
            placeholder={"Bank"}
            required={false}
          />
        </div>
        <div className="grid grid-cols-3 gap-3">
          <Button
            type="button"
            className="w-full text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            type="button"
            className="w-full text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={() => {
              console.log("first");
            }}
          >
            Tambahkan Sebagai Data Baru
          </Button>
          <Button
            type="button"
            className="w-full text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={() => {
              console.log("first");
            }}
          >
            Pilih
          </Button>
        </div>
        {/* END BODY */}
      </div>
    </div>
  );
};

export default AddResourcePersonModal;

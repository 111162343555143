import React, { Fragment } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiAlertTriangle } from "react-icons/fi";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

import { formattedValue } from "helpers";
import { Breadcrumbs, Button, Card, CardBody, CardHeader } from "components";
import { BadgeStatus } from "components/organisms/Approval2Level";
import Approval2LevelActions, {
  Progress as Approval2LevelProgress,
} from "components/organisms/Approval2Level";
import FileDetail from "components/molecules/FileDetail";

import { getDirectSubmissionDetail } from "services/danaProgram/directSubmissionService";

const DetailPenyaluranLangsung = () => {
  let { id } = useParams();
  let [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const links = [
    {
      path: "/approval-penyaluran-langsung",
      label: "Daftar Penyaluran Langsung",
    },
    {
      label: "Detail",
    },
  ];

  const methods = useForm({
    defaultValues: {},
  });

  const { data } = useQuery({
    queryKey: ["get-detail-direct-submission", id],
    queryFn: async () => {
      const res = await getDirectSubmissionDetail(id);

      return res.data.data;
    },
  });

  return (
    <Fragment>
      <FormProvider {...methods}>
        <div className="mb-5">
          <Breadcrumbs items={links} />
        </div>
        <div className="flex flex-col gap-5">
          <Card>
            <CardHeader>Detail</CardHeader>
            <CardBody>
              {/* Form Section */}
              <div className="w-full flex flex-col gap-3">
                {data?.status === "Ditolak" && (
                  <div className="flex bg-[#fff5f5] self-stretch p-4 gap-3 border border-[#f57070] rounded-xl mb-5">
                    <FiAlertTriangle color="#BE600E" size={20} />
                    <div className="flex flex-col gap-1">
                      <p className="font-medium text-[#8F480A] text-sm">
                        Alasan Ditolak
                      </p>
                      <p className="text-sm">{data?.reason}</p>
                    </div>
                  </div>
                )}

                <div className="flex justify-between items-center w-full">
                  <div>
                    <p className="font-semibold">
                      Nama/ Judul Program/ Kegiatan
                    </p>
                    <p>{data?.program_name}</p>
                  </div>
                  <div>
                    <BadgeStatus value={data?.status} />
                  </div>
                </div>
                <div className="w-full">
                  <p className="font-semibold">Deskripsi Program/ Kegiatan</p>
                  <p>{data?.desc}</p>
                </div>
                <div className="w-full grid grid-cols-2">
                  <div>
                    <p className="font-semibold">Sektor</p>
                    <p>{data?.sector?.label}</p>
                  </div>
                  <div>
                    <p className="font-semibold">
                      Periode Pelaksanaan Program/ Kegiatan
                    </p>
                    <p>
                      {moment(data?.periode?.[0]).format("DD/MM/YYYY")} -{" "}
                      {moment(data?.periode?.[1]).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <p className="font-semibold">Tujuan program/ kegiatan</p>
                  <p>{data?.program_goal}</p>
                </div>
                <div className="w-full">
                  <p className="font-semibold">Ruang Lingkup</p>
                  <p>{data?.scope}</p>
                </div>
                <p className="font-bold text-lg">Keterlibatan</p>
                <div className="grid grid-cols-2">
                  <div>
                    <p className="font-semibold">Jumlah Gender Laki-laki</p>
                    <p>{data?.involvement?.total_man}</p>
                  </div>
                  <div>
                    <p className="font-semibold">Jumlah Gender Perempuan</p>
                    <p>{data?.involvement?.total_woman}</p>
                  </div>
                </div>
                <hr className="my-5" />
                <p className="text-lg font-bold">Lokasi Pelaksanaan</p>
                <div className="grid grid-cols-2">
                  {data?.location_address?.map((item, index) => (
                    <div key={index}>
                      <div>
                        <p className="font-semibold">Provinsi</p>
                        <p>{item?.province.label}</p>
                      </div>
                      <div>
                        <p className="font-semibold">Kota/ Kabupaten</p>
                        <p>{item?.city.label}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <hr className="my-5" />
                <div>
                  <p className="font-semibold">Proposal Program/ Kegiatan</p>
                  <div className="grid grid-cols-3 gap-3">
                    {data?.proposal?.map((item, index) => (
                      <FileDetail key={index} file={item} hideDelete />
                    ))}
                  </div>
                </div>
                <div>
                  <p className="font-semibold">Dokumen Pendukung Lainnya</p>
                  <div className="grid grid-cols-3 gap-3">
                    {data?.doc?.map((item, index) => (
                      <FileDetail key={index} file={item} hideDelete />
                    ))}
                  </div>
                </div>
                <hr className="my-5" />
                <div>
                  <p className="font-semibold">
                    Ringkasan Pelaksanaan Kegiatan
                  </p>
                  <p>{data?.activity_summary}</p>
                </div>
                <div className="p-5 w-full flex items-center bg-primary-600 font-bold text-white">
                  Data Kegiatan
                </div>
                {/* Activity */}
                {data?.activity?.map((item, idx) => (
                  <div key={idx} className="p-3 flex flex-col gap-3">
                    <p className="font-bold text-lg">Kegiatan {idx + 1}</p>
                    <div>
                      <p className="font-semibold">Kegiatan Utama</p>
                      <p>{item?.main_activity.label}</p>
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                      <div className="col-span-2">
                        <p className="font-semibold">
                          Tanggal Rencana Kegiatan
                        </p>
                        <p>
                          {moment(item?.activity_date).format("DD/MM/YYYY")}
                        </p>
                      </div>
                      <div>
                        <p className="font-semibold">Hambatan</p>
                        <p>{item?.obstacle || "-"}</p>
                      </div>
                      <div>
                        <p className="font-semibold">Solusi</p>
                        <p>{item?.solution || "-"}</p>
                      </div>
                    </div>
                    <hr />
                    {item?.detail_activity?.map((item, index) => (
                      <div key={index}>
                        <div className="flex flex-col">
                          <p className="font-semibold">Detail Kegiatan</p>
                          <p>{item?.name?.label}</p>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-semibold">Pengeluaran Biaya</p>
                          <p>{formattedValue(item?.budget)}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
                {/* End of Activity */}
                <hr className="my-5" />
                <div>
                  <p className="font-semibold">Catatan</p>
                  <p>{data?.note || "-"}</p>
                </div>
                <div>
                  <p className="font-semibold">
                    Dokumentasi Pelaksanaan Projek
                  </p>
                  <div className="grid grid-cols-3 gap-3">
                    {data?.activity_doc.map((item, index) => (
                      <FileDetail key={index} file={item} hideDelete />
                    ))}
                  </div>
                </div>
              </div>
              {/* End Form Section */}
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Hasil Kegiatan</CardHeader>
            <CardBody>
              <div className="flex flex-col gap-3">
                <p className="font-bold text-lg">Capaian Output</p>
                {data?.activity_result?.map((item, idx) => (
                  <div key={idx}>
                    <div className="p-5 w-full flex items-center bg-primary-600 font-bold text-white">
                      Output {idx + 1}
                    </div>
                    <div className="p-5">
                      {item?.output.indicator?.map((itemDetail, index) => (
                        <div key={index} className="grid grid-cols-2 gap-3">
                          <div>
                            <p className="font-semibold">
                              Indicator {index + 1}
                            </p>
                            <p>{itemDetail?.name}</p>
                          </div>
                          <div>
                            <p className="font-semibold">
                              Update Kontribusi Capaian
                            </p>
                            <p>{itemDetail?.achived_contribution}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="mt-6">
          <Approval2LevelProgress
            module="penyaluran-langsung"
            credentials={data?.credentials}
            rejectReason={data?.reason}
          />
        </div>

        <div className="w-full flex justify-between mt-10 bg-white p-5 rounded-md border">
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate("/approval-penyaluran-langsung")}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>

          {searchParams.get("mode") === "review" && (
            <div className="flex gap-3">
              <Approval2LevelActions
                module="penyaluran-langsung"
                id={id}
                status={data?.status}
              />
            </div>
          )}
        </div>
      </FormProvider>
    </Fragment>
  );
};

export default DetailPenyaluranLangsung;

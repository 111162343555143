import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import _ from "lodash";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { Button } from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormSubgrant from "./Form/FormSubgrant";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import {
  getCollaborationFields,
} from "services/danaProgram/masterDataService";

const arrRadio = [
  {
    label: "Belum Ada",
    value: 0,
  },
  {
    label: "1 Lembaga",
    value: 1,
  },
  {
    label: "2 Lembaga",
    value: 2,
  },
  {
    label: "3 Lembaga",
    value: 3,
  },
  {
    label: "4 Lembaga",
    value: 4,
  },
  {
    label: "5 Lembaga",
    value: 5,
  }
]

const Subgrant = ({
  setActiveTab,
  activeTab = 1,
  data = {},
  allData = {},
  setData = () => {}
}) => {

  const masterData = useSelector((state) => state?.draftMasterData?.data || {})
  const [listBidangKerjasama, setListBidangKerjasama] = useState([]);
  const getCollaboration = useMutation(async () => {
    try {
      const collabResponse = await getCollaborationFields();
      setListBidangKerjasama(_mapMasterData(collabResponse?.data?.data.map((e) => {
        return {
          id: e?.nama,
          name: e?.nama,
        }
      })));
    } catch (error) {
      throw error;
    }
  });

  const _mapMasterData = useCallback((data) => {
    const transformedData = data?.map((item) => ({
      label: item?.nama ?? item?.name,
      value: item?.id,
      description: item?.deskripsi,
      extraId: item?.extraId,
    }));
    return transformedData;
  }, []);

  const hasRun = useRef(false);
  useEffect(() => {
    if (!hasRun.current) {
      getCollaboration.mutate();
      hasRun.current = true;
    }
  }, []);

  const [isEdit, setIsEdit] = useState(false);
  const toogleIsEdit = () => setIsEdit(!isEdit);

  const validationSchema = useMemo(() => Yup.object().shape({
    sub_grant: Yup.object().shape({
      status_approval: Yup.string().required('Hasil verifikasi wajib diisi'),
      notes_approval: Yup.string().nullable(true),
      partners_total: Yup.mixed().required('partners total should be choose'),
      partners: Yup.array().of(
        Yup.object().shape({
          partner_name: Yup.string().trim().required('Nama Lembaga Mitra wajib diisi'),
          partner_field: Yup.mixed(),
        })
      ),
    })
  }));

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      sub_grant: {
        status_approval: "APPROVED",
      }
    }
  });

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    setError,
    trigger,
  } = methods;

  const {
    fields: partners,
    append: appendPartners,
    remove: removePartners,
  } = useFieldArray({
    control,
    name: 'sub_grant.partners'
  })

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((keys) => {
          if(keys === "partners"){
            let newDataPartners = []
            Object.keys(data[key][keys]).forEach((keyss) => {
              let newDataArrPartners = {}
              Object.keys(data[key][keys][keyss]).forEach((keysss) => {
                if (keysss === 'partner_field') {
                  const newMap = data[key][keys][keyss][keysss]?.map((e) => {
                    return {
                      label: e?.label || e, value: e?.value || e
                    }
                  })
                  newDataArrPartners[keysss] = newMap
                } else {
                  newDataArrPartners[keysss] = data[key][keys][keyss][keysss]
                }
              })
              newDataPartners.push(newDataArrPartners)
            })
            setValue(`${key}.${keys}`, newDataPartners);
          } else if(keys === "partners_total") {
            if(arrRadio.map((e) => e?.value).includes(data[key][keys])){
              setValue(`${key}.${keys}`, data[key][keys]);
            }
          } else {
            setValue(`${key}.${keys}`, data[key][keys]);
          }
          // console.log(`${key}.${keys}`, data[key][keys])
        })
      });
      trigger();
    }
  }, [data, setValue, methods]);

  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const saveData = async () => {
    const isValid = await trigger()
    const allValues = getValues()
    allValues['sub_grant']['partners'] = allValues?.sub_grant?.partners.map((e) => {
      let objTemp = { ...e }
      objTemp['partner_field'] = objTemp?.partner_field?.map((ee) => ee?.value)
      return {
        ...objTemp
      }
    })
    allData['sub_grant'] = allValues.sub_grant
    setData(allData)
    return isValid;
  }

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <FormSubgrant
          openModal={() => setIsOpenModal(true)} 
          isEdit={isEdit}
          toogleIsEdit={toogleIsEdit}
          partners={partners}
          appendPartners={appendPartners}
          removePartners={removePartners}
          arrDataPartnersTotal={arrRadio}
          listBidangKerjasama={listBidangKerjasama}
        />
        {/* <Card>
          <CardHeader>
            <div>Rekomendasi Verificator</div>
          </CardHeader>
          <CardBody>
            <div className="space-y-5">
              <div className="space-y-2">
                <TitleText className="font-bold">
                  Rekomendasi Verificator
                </TitleText>
                <InputForm
                  controllerName={"verificatorRecomendation"}
                  className={`py-2 border w-full rounded-md flex-1`}
                  label={''}
                  placeholder={'Verifikasi Remokendasi'}
                  textArea
                />
              </div>
            </div>
          </CardBody>
        </Card> */}
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => activeTab === 1 ? navigate(-1) : setActiveTab(activeTab - 1)}
            >
              <RiArrowLeftLine/>
              Kembali
            </Button>
          </div>
          {sessionStorage.getItem('isLemtaraFormDisabled') !== "true" && (
            <div className="block md:flex items-center justify-between gap-2">
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={async () => {
                  const validData = await saveData()
                  if (validData)
                    setActiveTab(activeTab + 1)
                }}
              >
                Selanjutnya
                <RiArrowRightLine/>
              </Button>
            </div>
          )}
        </div>
        {/* form modal */}
        {/* <FormModal 
          title={``}
          open={isOpenModal}
          onClose={() => setIsOpenModal(!isOpenModal)}
          onSubmit={() => {
            setIsOpenModal(!isOpenModal)
          }}
          positiveConfirm={`Ya`}
          negativeConfirm={`Tidak`}
          size={`w-3/12`}
        /> */}
      </FormProvider>
    </div>
  );
};

export default Subgrant;

import config from "app/config";
import axiosInstance from "app/interceptors";

export const getList = (params) => {
  return axiosInstance.get(
    `${config.DAPROG_API_URL}/institutional-ministry/list`,
    {
      params,
    }
  );
};

export const getDetail = (params) => {
  return axiosInstance.get(
    `${config.DAPROG_API_URL}/institutional-ministry/detail`,
    {
      params,
    }
  );
};

export const createInstitutionalMinistry = (params) => {
  return axiosInstance.post(
    `${config.DAPROG_API_URL}/institutional-ministry/submit`,
    params
  );
};

export const deleteInstitutionalMinistry = (params) => {
  return axiosInstance.delete(
    `${config.DAPROG_API_URL}/institutional-ministry/delete`,
    { data: params }
  );
};

import { useFormContext } from "react-hook-form"
import { CgRadioChecked } from "react-icons/cg"
import { FiCircle } from "react-icons/fi"

const OptionRadioBadge = ({
  label = 'option',
  active = false,
  value = 1,
  name,
  disabled = false,
  ...props
}) => {
  const { setValue, trigger } = useFormContext();
  
  return (
    <div 
      className={`${!disabled ? `cursor-pointer` : `cursor-not-allowed`} flex items-center border-[2px] 
      ${active ? `border-[#00BD52] bg-[#EEFFF4]` : `border-[#D0D5DD]`} rounded-[12px] gap-[8px] p-4 w-full`}
      onClick={() => {
        if (!disabled){
          setValue(name, value)
          trigger()
        }
      }}
      {...props}
    >
      {active ? <CgRadioChecked className="text-[20px] text-[#00BD52]" /> : <FiCircle className="text-[20px]"/>}
      <div className="text-[16px] font-[600]">{label}</div>
    </div>
  )
}

export default OptionRadioBadge
import { useMutation, useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import { enqueueSnackbar } from "notistack";
import {
  submitTorStepTwoMutations,
  submitTorStepTwoParticipans,
  submitTorStepTwoItems,
} from "services/danaProgram/torService";
import { getListBudgetAccount } from "services/masterData/budgetAccount";

export const useRabMutations = () => {
  const submitResources = useMutation({
    mutationFn: (params) => {
      return submitTorStepTwoMutations(params);
    },
  });

  const submitParticipants = useMutation({
    mutationFn: (params) => {
      return submitTorStepTwoParticipans(params);
    },
  });

  const submitItems = useMutation({
    mutationFn: (params) => {
      return submitTorStepTwoItems(params);
    },
  });

  const {
    data: dataBudgetAccount,
    isLoading: recapIsLoading,
    isFetching: recapIsFetching,
    refetch: refetchBudgetAccountData,
  } = useQuery(["getListBudgetAccount"], () => getListBudgetAccount(), {
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return {
    submitResources,
    submitParticipants,
    submitItems,
    dataBudgetAccount:
      dataBudgetAccount?.data?.data.map((data) => ({
        label: data?.nama,
        value: data?.id,
        ...data,
      })) || [],
    refetchBudgetAccountData,
  };
};

import { useQuery } from "@tanstack/react-query";
import { Pagination } from "components";
import { getErrorMessage } from "helpers";
import { useSnackbar } from "notistack"
import { useEffect } from "react";
import { getList } from "services/danaProgram/pembayaranResourceService";

export const usePembayaranResources = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: dataPembayaranResources,
    isLoading: isLoadingPembayaranResources,
    isFetching: isFetchingPembayaranResources,
    refetch: refetchPembayaranResources,
    isError,
    error
  } = useQuery({
    queryKey: ["application-get-pembayaran-resources-project"],
    queryFn: async () => {
      const res = await getList(params)

      return res?.data?.data || []
    },
  })

  useEffect(() => {
    if(isError) {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: 'error'
      })
    }
  }, [isError]);

  return {
    dataPembayaranResources: dataPembayaranResources?.data || [],
    paginationPembayaranResources: {
      lastPage: dataPembayaranResources?.lastPage,
      page: dataPembayaranResources?.page,
      perPage: dataPembayaranResources?.perPage,
      total: dataPembayaranResources?.total
    },
    isLoadingPembayaranResources,
    isFetchingPembayaranResources,
    refetchPembayaranResources
  }
}
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import { useSnackbar } from "notistack";
import {
  getDetail,
  getList,
  updateStatusImplementingPartnerPayment,
} from "services/danaProgram/implementingPartnerPaymentServices";
import { getListInvestmentPlan } from "services/danaProgram/implementingPartnerProjectServices";

import {
  getCities,
  getProvinces,
  getSubdistricts,
  getVillages,
  taxType,
} from "services/danaProgram/masterDataService";
import { getBankList } from "services/danaProgram/procurementsService";
import { getListMainActivity } from "services/masterData/mainActivity";

export const useImplementingPartnerPaymentList = (params) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["implementing-partner-payment-lists", params],
    () => getList(queryParams),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
    }
  );

  const invalidate = () => {
    queryClient.invalidateQueries("implementing-partner-payment-lists");
  };

  return {
    list: data?.data?.data?.data || [],
    pagination:
      {
        lastPage: data?.data?.data?.lastPage,
        page: data?.data?.data?.page,
        perPage: data?.data?.data?.perPage,
        total: data?.data?.data?.total,
      } || {},
    isLoading,
    isFetching,
    refetch,
    invalidate,
  };
};

export const useInvestmentPlanList = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["investment-plan-lists", params],
    () => getListInvestmentPlan(queryParams),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
    }
  );

  return {
    list: data?.data?.data?.data || [],
    pagination:
      {
        lastPage: data?.data?.data?.lastPage,
        page: data?.data?.data?.page,
        perPage: data?.data?.data?.perPage,
        total: data?.data?.data?.total,
      } || {},
    isLoading,
    isFetching,
    refetch,
  };
};

export const useImplementingPartnerPaymentDetail = (id) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["implementing-partner-payment-detail", id],
    () => getDetail({ id: id }),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  return {
    detail: data?.data?.data,
    detailIsLoading: isLoading,
    refetch,
  };
};

export const useProvinceList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["province-lists"],
    () => getProvinces(),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
    }
  );

  return {
    provinceList: data?.data?.data || [],
    provinceListIsLoading: isLoading,
    provinceListIsFetching: isFetching,
    refetch,
  };
};

export const useCityList = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["city-lists", params],
    () => getCities(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: !!params,
    }
  );

  return {
    cityList: data?.data?.data || [],
    cityListIsLoading: isLoading,
    cityListIsFetching: isFetching,
    cityRefresh: refetch,
  };
};

export const useDistrictList = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["district-lists", params],
    () => getSubdistricts(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: !!params,
    }
  );

  return {
    districtList: data?.data?.data || [],
    districtListIsLoading: isLoading,
    districtListIsFetching: isFetching,
    refetch,
  };
};

export const useSubdistrictList = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["subdistrict-lists", params],
    () => getVillages(params),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
      enabled: !!params,
    }
  );

  return {
    subdistrictList: data?.data?.data || [],
    subdistrictListIsLoading: isLoading,
    subdistrictListIsFetching: isFetching,
    refetch,
  };
};

export const useBankList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["bank-lists"],
    () => getBankList(),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
    }
  );

  return {
    bankList: data?.data?.data || [],
    bankListIsLoading: isLoading,
    bankListIsFetching: isFetching,
    refetch,
  };
};

export const useMainActivityList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["main-activity"],
    () => getListMainActivity(),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
    }
  );

  return {
    list: data?.data?.data || [],
    isLoading,
    isFetching,
    refetch,
  };
};

export const useTaxTypeList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["tax-type"],
    () => taxType(),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
    }
  );

  return {
    list: data?.data?.data || [],
    isLoading,
    isFetching,
    refetch,
  };
};

export const useUpdateStatusImplementingPartnerPayment = () => {
  const submitMutation = useMutation({
    mutationFn: (params) => {
      return updateStatusImplementingPartnerPayment(params);
    },
  });

  return {
    submitMutation,
  };
};

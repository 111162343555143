import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import { useEffect, useMemo } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import InstitutionalFinancialReport from "./Form";
import { BiCheck } from "react-icons/bi";
import { useFinancialProfileContext } from "../FinancialProfileState";

const InformasiKeuanganLembaga = ({
  setActiveTab,
  activeTab = 1,
  data = null,
  setFormData,
  isProfit,
}) => {
  const { FinancialProfileState, setFinancialProfileState } =
    useFinancialProfileContext();
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      tools: Yup.array().of(
        Yup.object().shape({
          financial_report: Yup.array().of(
            Yup.object().shape({
              result_verification: Yup.string().required(
                `Hasil verifikasi wajib diisi`
              ),
              reason: Yup.string().nullable(true),
            })
          ),
        })
      ),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const { control, trigger } = { ...methods };

  const {
    fields: fieldsTools,
    append: appendTools,
    remove: removeTools,
  } = useFieldArray({
    control,
    name: "tools",
  });

  useEffect(() => {
    appendTools({
      financial_report: data?.financial_report,
    });
    removeTools(1);
    trigger();
  }, []);

  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <InstitutionalFinancialReport
          tools={fieldsTools}
          methods={methods}
          isProfit={isProfit}
        />
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => setActiveTab(activeTab - 1)}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={async () => {
                const isValid = await trigger();
                const formData = methods.getValues();
                setFormData((prevData) => ({
                  ...prevData,
                  step_3: formData,
                }));
                setFinancialProfileState((prevState) => ({
                  ...prevState,
                  step_2: formData,
                }));
                if (isValid) setActiveTab(activeTab + 1);
              }}
            >
              Selanjutnya
              <RiArrowRightLine />
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default InformasiKeuanganLembaga;

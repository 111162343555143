import config from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

const headers = {
  accept: "application/json",
  "Content-Type": "multipart/form-data",
};

const secondHeaders = {
  accept: "application/json",
  "Content-Type": "application/json",
};

export const fileUpload = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/file-service/v1/files`,
    params,
    {
      headers,
    }
  );
};
export const getUrlFile = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/file-service/v1/files/generate-url`,
    params,
    { secondHeaders }
  );
};
export const getFile = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/file-service/v1/files/` + params,
    params,
    { secondHeaders }
  );
};

import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { Button, Card, CardBody, CardHeader, InputForm, TitleText } from "components";
import { FormProvider, useForm } from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormInstitutionalGovernance from "./Form/FormInstitutionalGovernance";
import { FormModal } from "components/molecules/Modal";

const InstitutionalGovernance = ({
  setActiveTab,
  activeTab = 1,
  data = {},
  allData = {},
  setData = () => {}
}) => {

  const [isEdit, setIsEdit] = useState(false);
  const toogleIsEdit = () => setIsEdit(!isEdit);

  const validationSchema = useMemo(() => Yup.object().shape({
    institutional_governance: Yup.object().shape({
      status_approval: Yup.string().required(`Hasil verifikasi wajib diisi`),
      notes_approval: Yup.string().nullable(true),
      institutional_transparency: Yup.string().trim().required(`institutional transparency should be choose`),
      anti_corruption: Yup.string().trim().required(`anti corruption should be choode`),
    })
  }));

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      institutional_governance: {
        status_approval: "APPROVED",
      }
    }
  });

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    trigger,
  } = methods;

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((keys) => {
          if (["institutional_transparency", "anti_corruption"].includes(keys)) {
            if (new Array(5).fill(``).map((e, i) => i + 1).includes(data[key][keys])){
              setValue(`${key}.${keys}`, data[key][keys]);
            }
          } else {
            setValue(`${key}.${keys}`, data[key][keys]);
          }
          // console.log(`${key}.${keys}`, data[key][keys])
        })
      });
      trigger();
    }
  }, [data, setValue, methods]);

  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const saveData = async () => {
    const isValid = await trigger()
    const allValues = getValues()
    allData['institutional_governance'] = allValues.institutional_governance
    setData(allData)
    return isValid;
  }

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <FormInstitutionalGovernance
          openModal={() => setIsOpenModal(true)} 
          isEdit={isEdit}
          toogleIsEdit={toogleIsEdit}
        />
        {/* <Card>
          <CardHeader>
            <div>Rekomendasi Verificator</div>
          </CardHeader>
          <CardBody>
            <div className="space-y-5">
              <div className="space-y-2">
                <TitleText className="font-bold">
                  Rekomendasi Verificator
                </TitleText>
                <InputForm
                  controllerName={"verificatorRecomendation"}
                  className={`py-2 border w-full rounded-md flex-1`}
                  label={''}
                  placeholder={'Verifikasi Remokendasi'}
                  textArea
                />
              </div>
            </div>
          </CardBody>
        </Card> */}
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => activeTab === 1 ? navigate(-1) : setActiveTab(activeTab - 1)}
            >
              <RiArrowLeftLine/>
              Kembali
            </Button>
          </div>
          {sessionStorage.getItem('isLemtaraFormDisabled') !== "true" && (
            <div className="block md:flex items-center justify-between gap-2">
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={async () => {
                  const validData = await saveData()
                  if (validData)
                    setActiveTab(activeTab + 1)
                }}
              >
                Selanjutnya
                <RiArrowRightLine/>
              </Button>
            </div>
          )}
        </div>
        {/* form modal */}
        {/* <FormModal 
          title={``}
          open={isOpenModal}
          onClose={() => setIsOpenModal(!isOpenModal)}
          onSubmit={() => {
            setIsOpenModal(!isOpenModal)
          }}
          positiveConfirm={`Ya`}
          negativeConfirm={`Tidak`}
          size={`w-3/12`}
        /> */}
      </FormProvider>
    </div>
  );
};

export default InstitutionalGovernance;

const { create } = require("zustand");

export const useCreateStore = create((set) => ({
  step: 0,
  torId: null,
  setTorId: (id) => set({ torId: id }),
  setStep: (currStep) => set(() => ({ step: currStep })),
  nextStep: () => set((state) => ({ step: state.step + 1 })),
  prevStep: () => set((state) => ({ step: state.step - 1 })),
}));

import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  DateInput,
  InputForm,
  Select,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const NPWP = ({ tools, isEdit = false }) => {
  return (
    <div className="flex flex-col">
      {tools.map((field, index) => {
        if (index === 1) {
          return (
            <div className="mt-2" key={field.id}>
              <div className="space-y-6">
                <InputForm
                  controllerName={`tools.${1}.npwp_personal`}
                  className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"NPWP"}
                  placeholder={"NPWP"}
                  disabled={!isEdit}
                />
                <InputForm
                  controllerName={`tools.${1}.npwp_address`}
                  className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Alamat NPWP"}
                  placeholder={"Alamat NPWP"}
                  disabled={!isEdit}
                  textArea={true}
                />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <InputForm
                    controllerName={`tools.${1}.no_certificate_domicile`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Nomor Surat Keterangan Domisili"}
                    placeholder={"Nomor Surat Keterangan Domisili"}
                    disabled={!isEdit}
                  />
                  <InputForm
                    controllerName={`tools.${1}.expire_date`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Tanggal Kadaluarsa"}
                    placeholder={"Tanggal Kadaluarsa"}
                    disabled={!isEdit}
                  />
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default NPWP;

import axiosInstance from "app/interceptors";
import config from "app/config";

export const getDirectSubmissionList = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/direct-submission/list`,
    {
      params,
    }
  );
};

export const getDirectSubmissionDetail = (id) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/direct-submission/detail`,
    {
      params: {
        id,
      },
    }
  );
};

export const postDirectSubmission = (body) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/direct-submission/submit`,
    body
  );
};

export const reviewDirectSubmission = (body) => {
  return axiosInstance.put(
    `${config.BASE_URL}/api/dana-program/v1/cms/direct-submission/change-status`,
    body
  );
};

export const deleteDirectSubmission = (id) => {
  return axiosInstance.delete(
    `${config.BASE_URL}/api/dana-program/v1/cms/direct-submission`,
    {
      data: {
        id,
      },
    }
  );
};

export const approvalEligiblityDirectSubmission = ({ queryKey }) => {
  const [, directSubmissionId] = queryKey;

  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/direct-submission/approval-eligiblity`,
    {
      id: directSubmissionId,
    }
  );
};

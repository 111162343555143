import { Button, Card, CardBody, CardForm, CardHeader } from "components";
import { useMemo } from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import Profitabilitas from "./Profitabilitas";
import Likuiditas from "./Likuiditas";
import Solvabilitas from "./Solvabilitas";

const ProfitRasioValue = ({
  data = {}
}) => {

  return (
    <div>
      <Card>
        <CardHeader>
          <div>Profit/Mencari Keuntungan</div>
        </CardHeader>
        <CardBody>
          <div classNamee="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col gap-[20px]">
              <Profitabilitas data={data?.profitability_ratio} />
              <Likuiditas data={data?.liquidity_ratio} />
              <Solvabilitas data={data?.solvability_ratio} />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default ProfitRasioValue;
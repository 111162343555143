import { useFormContext } from "react-hook-form";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsCircleFill } from "react-icons/bs";
import { CgRadioChecked } from "react-icons/cg";
import { FiCircle } from "react-icons/fi";
import { RiCheckboxCircleFill } from "react-icons/ri";

const OptionRadioVerificatorBadge = ({
  trueString = "Data Terverifikasi",
  falseString = "Data Belum Sesuai/ Ada Kesalahan",
  status = true,
  type,
  name,
  disabled = false,
  ...props
}) => {
  const { setValue, trigger } = useFormContext();
  let componentReturn;

  switch (type) {
    case "approve":
      switch (status) {
        case true:
          componentReturn = (
            <div
              className={`flex items-center border-[2px] border-[#00BD52] rounded-[12px] gap-[8px] p-4 w-full bg-[#EEFFF4] ${
                !disabled ? `cursor-pointer` : `cursor-not-allowed`
              }`}
            >
              <CgRadioChecked className="text-[20px] text-[#00BD52]" />
              <RiCheckboxCircleFill className="text-[20px] text-[#00BD52]" />
              <div className="text-[14px] font-bold">Data Terverifikasi</div>
            </div>
          );
          break;
        case false:
          componentReturn = (
            <div
              className={`flex items-center border-[2px] border-[#D0D5DD] rounded-[12px] gap-[8px] p-4 w-full ${
                !disabled ? `cursor-pointer` : `cursor-not-allowed`
              }`}
              onClick={() => {
                if (!disabled) {
                  setValue(name, "APPROVED");
                  trigger()
                }
              }}
            >
              <BsCircleFill className="text-[20px] text-[#EAECF0]" />
              <RiCheckboxCircleFill className="text-[20px] text-[#00BD52]" />
              <div className="text-[14px]">Data Terverifikasi</div>
            </div>
          );
          break;
        default:
          return false;
      }
      break;
    case "reject":
      switch (status) {
        case true:
          componentReturn = (
            <div
              className={`flex items-center border-[2px] border-[#F04438] bg-[#FEF3F2] rounded-[12px] gap-[8px] p-4 w-full ${
                !disabled ? `cursor-pointer` : `cursor-not-allowed`
              }`}
            >
              <CgRadioChecked className="text-[20px] text-[#D92D20]" />
              <AiFillCloseCircle className="text-[20px] text-[#D92D20]" />
              <div className="text-[14px] font-bold">
                Data Belum Sesuai/ Ada Kesalahan
              </div>
            </div>
          );
          break;
        case false:
          componentReturn = (
            <div
              className={`flex items-center border-[2px] border-[#D0D5DD] rounded-[12px] gap-[8px] p-4 w-full ${
                !disabled ? `cursor-pointer` : `cursor-not-allowed`
              }`}
              onClick={() => {
                if (!disabled) {
                  setValue(name, "REJECTED");
                  trigger()
                }
              }}
            >
              <FiCircle className="text-[20px]" />
              <AiFillCloseCircle className="text-[20px] text-[#D92D20]" />
              <div className="text-[14px]">
                Data Belum Sesuai/ Ada Kesalahan
              </div>
            </div>
          );
          break;
        default:
          return false;
      }
      break;
    default:
      return false;
  }
  return componentReturn;
};

export default OptionRadioVerificatorBadge;

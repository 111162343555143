import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import AtomSelect from "components/atoms/Select";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import RadioBadge from "components/molecules/Badge/RadioBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";

const FormSubgrant = ({
  openModal = () => console.log(`open`),
  isEdit = false,
  toogleIsEdit = () => { },
  partners = [],
  appendPartners = () => { },
  removePartners = () => { },
  arrDataPartnersTotal = [],
  listBidangKerjasama,
  allData = {},
  setData = () => {},
  onSubmit = () => {},
}) => {
  const masterData = useSelector((state) => state?.draftMasterData?.data || {});

  const navigate = useNavigate();

  const {
    watch,
    getValues,
    trigger,
    formState: { errors, isValid },
  } = useFormContext();
  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  const saveData = async () => {
    const isValid = await trigger()
    const allValues = getValues()

    console.log(allValues)
    setData(allData)
    return isValid;
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Daftar Rekanan/ Mitra</div>
          <div className={"flex-1 text-right"}>
            {sessionStorage.getItem(`isLemtaraFormDisabled`) !== "true" && (isEdit ? (
              <Button
                type="button"
                className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                onClick={async () => {
                  toogleIsEdit();
                  const validData = await saveData()
                  if (validData)
                    onSubmit(allData)
                }}
              >
                <BiPencil />
                Simpan Perubahan
              </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Ubah
              </Button>
            ))}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col">
          <QuestionBadge text="Berapa Banyak Lembaga/ Perusahaan yang Bekerja Sama dengan Lembaga/ Institusi/ Perusahaan Anda. Terutama di Daerah Tempat Pendistribusian Dana Program" />
          <RadioBadge
            arrData={arrDataPartnersTotal}
            isEdit={isEdit}
            name={`sub_grant.partners_total`}
            className={`mt-[11px] flex gap-[18px]`}
            errors={errors?.sub_grant?.partners_total ? errors?.sub_grant?.partners_total : false}
          />
        </div>
        <div className={"mt-[31px]"}>
          {partners?.length > 0 &&
            partners?.map((e, i) => {
              return (
                <CardForm
                  key={e.id}
                  label={`Lembaga ${i + 1}`}
                  buttonCollapse={true}
                >
                  <div className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <InputForm
                        controllerName={`sub_grant.partners.${i}.partner_name`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                        label={`Lembaga/Perusahaan`}
                        placeholder={`Lembaga/Perusahaan`}
                        required
                        disabled={!isEdit}
                      />
                      <AtomSelect
                        controllerName={`sub_grant.partners.${i}.partner_field`}
                        options={listBidangKerjasama}
                        className="basic-single mt-2"
                        label="Bidang Kerjasama"
                        multiple={true}
                        required
                        disable={!isEdit}
                      />

                    </div>
                  </div>
                </CardForm>
              );
            })}
        </div>
        {/* button Verification */}
        <RadioVerificationBadge
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"sub_grant.status_approval"}
          nameNotes={"sub_grant.notes_approval"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={errors?.sub_grant?.status_approval ? errors?.sub_grant?.status_approval : false}
        />
      </CardBody>
    </Card>
  );
};

export default FormSubgrant;

import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";
import _ from "lodash";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { Button } from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormPartnership from "./Form/FormPartnership";
import { useMutation } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";
import useProfileOrganization from "../hooks/useProfileOrganization";
import {
  getCollaborationFields,
  getCountries,
  getProvinces,
  getCities,
  getSubdistricts,
  getPostalCodes,
  getVillages,
} from "services/danaProgram/masterDataService";
import { updateMasterData } from "app/actions";

export const optCountExpMitra = [
  {
    label: "Belum Ada",
    value: "Belum Ada",
  },
  {
    label: "1-5 Mitra",
    value: "1-5 Mitra",
  },
  {
    label: "6-10 Mitra",
    value: "6-10 Mitra",
  },
  {
    label: "lebih dari 10 Mitra",
    value: "lebih dari 10 Mitra",
  },
];


const Partnership = ({
  setActiveTab,
  activeTab = 1,
  data = {},
  allData = {},
  setData = () => { },
}) => {

  const masterData = useSelector((state) => state?.draftMasterData?.data || {})
  const selectorData = useSelector((state) => state || {})

  const [listBidangKerjasama, setListBidangKerjasama] = useState([]);
  const getCollaboration = useMutation(async () => {
    try {
      const collabResponse = await getCollaborationFields();
      setListBidangKerjasama(_mapMasterData(collabResponse?.data?.data.map((e) => {
        return {
          id: e?.nama,
          name: e?.nama,
        }
      })));
    } catch (error) {
      throw error;
    }
  });

  const [listNegara, setListNegara] = useState([]);
  const getCountry = useMutation(() => getCountries(),
    {
      onSuccess: (data) => {
        setListNegara(_mapMasterData(data?.data?.data.map((e) => {
          return {
            id: e?.id,
            name: e?.namaNegara,
          }
        }))
        )
      },
      onError: (error) => {
        console.log('failed get master data from api/master/v1/Negara/list', error)
      }
    })

  const [listProvinsi, setListProvinsi] = useState([]);
  const getProvince = useMutation(() => getProvinces(),
    {
      onSuccess: (data) => {
        setListProvinsi(_mapMasterData(data?.data?.data.map((e) => {
          return {
            id: e?.id,
            name: e?.namaProvinsi,
            extraId: e?.idNegara,
          }
        })))
    },
      onError: (error) => {
        console.log('failed get master data from api/master/v1/Provinsi/list', error)
      }
    })

  const [listCity, setListCity] = useState([]);
  const getCity = useMutation(() => getCities(),
    {
      onSuccess: (data) => {
        setListCity(_mapMasterData(data?.data?.data.map((e) => {
          return {
            id: e?.id,
            name: e?.namaKabupatenKota,
            extraId: e?.idProvinsi,
          }
        })))
      },
      onError: (error) => {
        console.log('failed get master data from api/master/v1/KabupatenKota/list', error)
      }
    })

  const [listDistrict, setListDistrict] = useState([]);
  const getSubdistrict = useMutation(() => getSubdistricts(),
    {
      onSuccess: (data) => {
        setListDistrict(_mapMasterData(data?.data?.data.map((e) => {
          return {
            id: e?.id,
            name: e?.namaKecamatan,
            extraId: e?.idKabupatenKota,
          }
        })))
      },
      onError: (error) => {
        console.log('failed get master data from api/master/v1/Kecamatan/list', error)
      }
    })

  const [listKelurahan, setListKelurahan] = useState([]);
  const getVillage = useMutation(() => getVillages(),
    {
      onSuccess: (data) => {
        setListKelurahan(_mapMasterData(data?.data?.data.map((e) => {
          return {
            id: e?.id,
            name: e?.namaKelurahan,
            extraId: e?.idKecamatan,
          }
        }))
        )
      },
      onError: (error) => {
        console.log('failed get master data from api/master/v1/Kelurahan/list', error)
      }
    })

  const [listKodePos, setListKodePos] = useState([]);
  const getPostalCode = useMutation(() => getPostalCodes(),
    {
      onSuccess: (data) => {
        setListKodePos(_mapMasterData(data?.data?.data.map((e) => {
          return {
            id: e?.id,
            name: e?.kodePos,
            extraId: e?.idKelurahan
          }
        }))
        )
      },
      onError: (error) => {
        console.log('failed get master data from api/master/v1/KodePos/list', error)
      }
    })


  const _mapMasterData = useCallback((data) => {
    const transformedData = data?.map((item) => ({
      label: item?.nama ?? item?.name,
      value: item?.id,
      description: item?.deskripsi,
      extraId: item?.extraId,
    }));
    return transformedData;
  }, []);

  const hasRun = useRef(false);
  useEffect(() => {
    if (!hasRun.current) {
      getCollaboration.mutate();
      getCountry.mutate();
      getProvince.mutate();
      getCity.mutate();
      getSubdistrict.mutate();
      getVillage.mutate();
      getPostalCode.mutate();
      hasRun.current = true;
    }
  }, []);


  const [partnershipAreaMasterData, setPartnershipAreaMasterData] = useState([])

  const [isEdit, setIsEdit] = useState(false);
  const toogleIsEdit = () => setIsEdit(!isEdit);

  const validationSchema = useMemo(() => Yup.object().shape({
    partnership: Yup.object().shape({
      status_approval: Yup.string().required(`Hasil verifikasi wajib diisi`),
      notes_approval: Yup.string().nullable(true),
      partners_total: Yup.mixed().required('partners total should be choose'),
      level_anti_korupsi: Yup.mixed().required(`level anti korupsi should be choose`),
      partner: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().trim().required('Nama wajib diisi'),
          cooperation: Yup.mixed(),
          start_date: Yup.string().trim().required('Start date wajib diisi'),
          end_date: Yup.string().trim().required('End date wajib diisi'),
          nation: Yup.string().trim().required(),
          province_id: Yup.string().trim().required(),
          city_id: Yup.string().trim().required(),
          district_id: Yup.string().trim().required(),
          village_id: Yup.string().trim().required(),
          zip_code: Yup.string().trim().required(),
          address: Yup.string().trim().required(),
        })
      ),
    })
  }));

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      partnership: {
        status_approval: "APPROVED",
      }
    }
  });

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    trigger,
  } = methods;

  const {
    fields: partner,
    append: appendPartner,
    remove: removePartner,
  } = useFieldArray({
    control,
    name: 'partnership.partner'
  })

  // const {
  //   ProvincesData,
  //   refetchProvinces,
  //   CitiesData,
  //   refetchCities,
  //   DistrictsData,
  //   refetchDistricts,
  // } = useProfileOrganization({
  //   id: JSON.parse(sessionStorage.getItem("idData")).organization_profile,
  // });

  // useEffect(() => {
  //   refetchProvinces();
  //   refetchCities();
  //   refetchDistricts();
  // }, []);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((keys) => {
          if (keys === "partner") {
            let arrMasterTemp = []
            // set cooperation multi select drop down
            let newDataPartner = []
            Object.keys(data[key][keys]).forEach((keyss) => {
              let newDataArrPartner = {}
              Object.keys(data[key][keys][keyss]).forEach((keysss) => {
                if (keysss === 'cooperation') {
                  const newMap = data[key][keys][keyss][keysss]?.map((e) => {
                    return { value: e, label: e }
                  })
                  newDataArrPartner[keysss] = newMap
                  // newDataArrPartner[keysss] = data[key][keys][keyss][keysss]

                } else {
                  newDataArrPartner[keysss] = data[key][keys][keyss][keysss]
                }
              })
              newDataPartner.push(newDataArrPartner)
              // push initial master data province, etc. to temporary array
              arrMasterTemp.push({
                negara: listNegara,
                provinsi: listProvinsi,
                kotaKabupaten: listCity,
                kecamatan: listDistrict,
                kelurahan: listKelurahan,
                kodePos: listKodePos,
                bidangKerjasama: listBidangKerjasama
              })
            })

            // set initial master data province, etc.
            setPartnershipAreaMasterData(arrMasterTemp)
            setValue(`${key}.${keys}`, newDataPartner);
          } else if (keys === "partners_total") {
            if (optCountExpMitra.map((e) => e?.value).includes(data[key][keys])) {
              setValue(`${key}.${keys}`, data[key][keys]);
            }
          } else if (keys === "level_anti_korupsi") {
            if (new Array(11).fill('').map((e, i) => i).includes(data[key][keys])) {
              setValue(`${key}.${keys}`, data[key][keys]);
            }
          } else {
            setValue(`${key}.${keys}`, data[key][keys]);
          }
        })
      });
      trigger();
    }
  }, [data, setValue, methods]);

  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const saveData = async () => {
    const isValid = await trigger()
    const allValues = getValues()
    allValues['partnership']['partner'] = allValues?.partnership?.partner.map((e) => {
      let objTemp = { ...e }
      objTemp['cooperation'] = objTemp?.cooperation?.map((ee) => ee?.value)
      return {
        ...objTemp
      }
    })
    allData['partnership'] = allValues.partnership
    setData(allData)
    return isValid;
  }

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <FormPartnership
          openModal={() => setIsOpenModal(true)}
          isEdit={isEdit}
          toogleIsEdit={toogleIsEdit}
          partner={partner}
          appendPartner={appendPartner}
          removePartner={removePartner}
          masterData={masterData}
          partnershipAreaMasterData={partnershipAreaMasterData}
          listBidangKerjasama={listBidangKerjasama}
          setPartnershipAreaMasterData={setPartnershipAreaMasterData}
          optCountExpMitra={optCountExpMitra}

        />
        {/* <Card>
          <CardHeader>
            <div>Rekomendasi Verificator</div>
          </CardHeader>
          <CardBody>
            <div className="space-y-5">
              <div className="space-y-2">
                <TitleText className="font-bold">
                  Rekomendasi Verificator
                </TitleText>
                <InputForm
                  controllerName={"verificatorRecomendation"}
                  className={`py-2 border w-full rounded-md flex-1`}
                  label={''}
                  placeholder={'Verifikasi Remokendasi'}
                  textArea
                />
              </div>
            </div>
          </CardBody>
        </Card> */}
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => activeTab === 1 ? navigate(-1) : setActiveTab(activeTab - 1)}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          {sessionStorage.getItem('isLemtaraFormDisabled') !== "true" && (
            <div className="block md:flex items-center justify-between gap-2">
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={async () => {
                  const validData = await saveData()
                  if (validData)
                    setActiveTab(activeTab + 1)
                }}
              >
                Selanjutnya
                <RiArrowRightLine />
              </Button>
            </div>
          )}
        </div>
        {/* form modal */}
        {/* <FormModal 
          title={``}
          open={isOpenModal}
          onClose={() => setIsOpenModal(!isOpenModal)}
          onSubmit={() => {
            setIsOpenModal(!isOpenModal)
          }}
          positiveConfirm={`Ya`}
          negativeConfirm={`Tidak`}
          size={`w-3/12`}
        />` */}
      </FormProvider>
    </div>
  );
};

export default Partnership;

import React, { useState } from "react";

import {
    Breadcrumbs,
    Button,
    Card,
    CardBody,
    CardHeader,
    Label,
    TitleText,
} from "components";
import { useNavigate } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine, RiCheckboxCircleLine, RiCloseCircleLine, RiFile2Line, RiHistoryLine } from "react-icons/ri";
import ProposalEvaluatedDetailFundAttachment from "./fundAttachment";
import ProposalEvaluatedDetailGeneralInformation from "./generalInformation";
import ProposalEvaluatedDetailIndicator from "./indicator";
import ProposalEvaluatedDetailRab from "./rab";
import ProposalEvaluatedDetailWorkplan from "./workplan";
import ProposalEvaluatedDetailSafeguard from "./safeguard";

const ProposalEvaluatedDetail = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);

    const links = [
        {
            label: "Menilai Proposal",
            path: "/menilai-proposal",
        },
        {
            label: "Detail",
        },
    ];

    const TabList = [
        {
            label: "Lampiran Dokumen",
            id: 1,
        },
        {
            label: "General Informasi",
            id: 2,
        },
        {
            label: "Indikator",
            id: 3,
        },
        {
            label: "Safeguard",
            id: 4,
        },
        {
            label: "Rencana Kerja",
            id: 5,
        },
        {
            label: "RAB",
            id: 6,
        },
    ]
    return (
        <div className="space-y-6">
            <Breadcrumbs items={links} />
            <div className="md:flex block items-center my-3">
                <TitleText className="flex-1">Daftar Proposal yang Perlu Dinilai</TitleText>
            </div>
            <div className="tabs flex">
                {
                    TabList.map((data) => (
                        <a className={`tab flex-1 tab-bordered ${activeTab === data.id ? 'border-b-primary-600 text-primary-600 bg-primary-100 font-bold': ''} hover:text-primary-600 hover:border-primary-700`} onClick={()=> setActiveTab(data.id)}>
                            {data.label}
                        </a>
                    ))
                }
            </div>
            <div>
                {activeTab === 1 && <ProposalEvaluatedDetailFundAttachment/>}
                {activeTab === 2 && <ProposalEvaluatedDetailGeneralInformation/>}
                {activeTab === 3 && <ProposalEvaluatedDetailIndicator/>}
                {activeTab === 4 && <ProposalEvaluatedDetailSafeguard/>}
                {activeTab === 5 && <ProposalEvaluatedDetailWorkplan/>}
                {activeTab === 6 && <ProposalEvaluatedDetailRab/>}
            </div>
        </div>
    );
};

export default ProposalEvaluatedDetail;

import config from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const getOrganizationProfile = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/profile-organization/findById/${params.id}`,
    params,
    { headers }
  );
};

export const submitOrganizationProfile = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/profile-organization/verification`,
    params,
    {
      headers,
    }
  );
};

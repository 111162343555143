import React from "react";

import { Header, Sidebar } from "components";
import { motion } from "framer-motion";

const BasicLayout = ({ children }) => {
  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="flex flex-1 overflow-hidden">
          <Sidebar>{children}</Sidebar>
          <div className="flex-1 max-w-[80%]">
            <Header />
            <motion.main
              className="w-full h-full paragraph p-8 bg-gray-50"
              key={window.location.pathname}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.05 }}
            >
              <div className="h-full">{children}</div>
            </motion.main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicLayout;

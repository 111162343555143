import { Button, Card, CardBody, CardForm, CardHeader, InputForm, TitleText } from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { useNavigate } from "react-router";

const FormCompetence = ({
  openModal = () => console.log(`open`),
  isEdit = false,
  toogleIsEdit = () => {},
  competence = [],
  appendcompetence = () => {},
  removecompetence = () => {},
}) => {
  const navigate = useNavigate();

  const { 
    watch, 
    getValues,
    formState: { errors, isValid },
  } = useFormContext();
  const [isEditVerification, setIsEditVerification] = useState(false)

  const toogleIsEditVerification = () => setIsEditVerification(!isEditVerification)
  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Daftar Rekanan/ Mitra</div>
          <div className={"flex-1 text-right"}>
            {sessionStorage.getItem(`isLemtaraFormDisabled`) !== "true" && (isEdit ? (
              <Button
              type="button"
              className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
              onClick={toogleIsEdit}
            >
              <BiPencil/>
              Simpan Perubahan
            </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil/>
                Ubah
              </Button>
            ))}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <QuestionBadge text="Apa Saja Kompetensi Utama yang Lembaga/ Institusi/ Perusahaan Anda Miliki? (Minimal 5 Kompetensi)" />
        {competence?.length > 0 && (
          competence?.map((e, i) => {
            return (
              <div key={e.id} className={"mt-[31px]"}>
                <CardForm label={`Rekanan/ Mitra ${i + 1}`} buttonCollapse={true}>
                  <div className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <InputForm 
                        controllerName={`competence.${i}.competency`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                        label={`Bidang Kompetensi`}
                        placeholder={`Bidang Kompetensi`}
                        required
                        disabled={!isEdit}
                      />
                      <InputForm 
                        controllerName={`competence.${i}.desc`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                        label={"Keterangan"}
                        placeholder={"Keterangan"}
                        rows="5"
                        textArea
                        disabled={!isEdit}
                      />
                    </div>
                  </div>
                </CardForm>
              </div>
            )
          })
        )}
        {/* button Verification */}
        <RadioVerificationBadge 
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"status_approval_competences"}
          nameNotes={"notes_approval_competences"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={errors?.status_approval_competences ? errors?.status_approval_competences : false}
        />
      </CardBody>
    </Card>
  )
}

export default FormCompetence
import { useSnackbar } from "notistack";
import { getTorList } from "services/danaProgram/torService";
import { useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";

const useTorService = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  const {
    data,
    isLoading: torListIsLoading,
    isFetching: torListIsFetching,
    refetch,
  } = useQuery(["list-tor", params], () => getTorList(queryParams), {
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
  });

  return {
    torList: data?.data?.data?.data || [],
    pagination:
      {
        lastPage: data?.data?.data?.lastPage,
        page: data?.data?.data?.page,
        perPage: data?.data?.data?.perPage,
        total: data?.data?.data?.total,
      } || {},
    torListIsLoading,
    torListIsFetching,
    refetch,
  };
};

export default useTorService;

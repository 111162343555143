import { Button, Card, CardBody, CardHeader } from "components";
import { RiArrowLeftLine, RiArrowRightLine, RiSave3Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import { useCreateStore } from "pages/Tor/Create/store";
import { useRecapMutations } from "../../hooks/useRecapMutations";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import { formatTotalCost } from "pages/Tor/helpers";
import { useEffect, useState } from "react";
import { convertMoney, deconvertMoney } from "helpers";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";

const RecapForm = ({ setSavedStep, isEdit }) => {
  const { step, nextStep, prevStep, torId } = useCreateStore();
  const userSelector = useSelector((state) => state?.auth?.user || {});
  const [recapId, setRecapId] = useState(sessionStorage.getItem("torId"));
  const [rabResource, setRabResource] = useState([]);
  const [rabParticipant, setRabParticipant] = useState([]);
  const [rabItem, setRabItem] = useState([]);
  const [TotalCost, setTotalCost] = useState(0);

  const navigate = useNavigate();

  const { submitRecap, lists, refetchDetailTor } = useRecapMutations(
    torId ? torId : recapId ? recapId : ""
  );

  const formatData = (data) => ({
    total_cost: data?.total_cost || 0,
    detail_activity_id: data?.required_items?.[0]?.detail_activity_id || "",
    detail_activity_name: data?.required_items?.[0]?.detail_activity_name || "",
    budget_accounts:
      data?.required_items?.map((item) => ({
        budget_account_id: item?.budget_account_id || "",
        budget_account_name: item?.budget_account_name || "",
        item_id: item?.item_id || "",
        item_name: item?.item_name || "",
        quantity: item?.quantity || 0,
        cost: item?.cost || 0,
        subtotal: item?.subtotal || 0,
      })) || [],
  });

  const handleSubmitForm = async (isBack) => {
    try {
      const [temp, tempParticipant, tempItem] = [
        rabResource,
        rabParticipant,
        rabItem,
      ].map(formatData);
      let params = {
        id: recapId,
        user_id: userSelector?.UserId,
        recap: [temp, tempParticipant, tempItem],
      };

      submitRecap.mutate(params, {
        onSuccess: () => {
          if (!isBack) {
            sessionStorage.setItem("step", 4);
            nextStep();
          }
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    refetchDetailTor();
  }, []);

  useEffect(() => {
    setRabResource(lists?.resource);
    setRabParticipant(lists?.participant);
    setRabItem(lists?.item);

    if (
      !Array.isArray(lists) &&
      setTotalCost(
        parseInt(deconvertMoney(lists?.resource?.total_cost)) +
          parseInt(deconvertMoney(lists?.participant?.total_cost)) +
          parseInt(deconvertMoney(lists?.item?.total_cost))
      )
    );
  }, [lists]);

  const [isOpen, setIsOpen] = useState({
    resource: true,
    item: true,
    participant: true,
  });

  const handleCloseTab = (key) => {
    setIsOpen((prevIsOpen) => ({
      ...prevIsOpen,
      [key]: !prevIsOpen[key],
    }));
  };

  return (
    <>
      <div className="flex flex-col gap-6">
        <Card>
          <CardHeader>
            <span className="font-semibold text-sm text-[#E34F13] bg-[#FFF4ED] rounded-full px-2 py-1">
              Estimasi kebutuhan biaya : Rp.{convertMoney(TotalCost)}
            </span>
          </CardHeader>
          <CardBody>
            {rabResource && (
              <Table
                data={rabResource}
                obj="resource"
                isOpen={isOpen}
                handleCloseTab={handleCloseTab}
              />
            )}
            {rabParticipant && (
              <Table
                data={rabParticipant}
                obj="participant"
                isOpen={isOpen}
                handleCloseTab={handleCloseTab}
              />
            )}
            {rabItem && (
              <Table
                data={rabItem}
                obj="item"
                isOpen={isOpen}
                handleCloseTab={handleCloseTab}
              />
            )}
          </CardBody>
        </Card>
      </div>

      <div className="items-center justify-between block px-6 py-5 mt-4 mb-10 bg-white border border-gray-200 rounded-lg md:flex">
        <div>
          <Button
            type="button"
            className="px-8 text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
            onClick={() => {
              setSavedStep(2);
              sessionStorage.setItem("step", 2);
            }}
          >
            <RiArrowLeftLine className="text-white" />
            Kembali
          </Button>
        </div>

        <div className="items-center justify-between block gap-2 md:flex">
          {isEdit && (
            <Button
              type="button"
              className="text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
              onClick={() => {
                handleSubmitForm(true);
                navigate("/tor");
              }}
            >
              <RiSave3Line />
              Simpan & Draft
            </Button>
          )}
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={() => {
              handleSubmitForm(false);
            }}
          >
            Selanjutnya
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
    </>
  );
};

const Table = ({ data, obj, isOpen, handleCloseTab }) => {
  return (
    <>
      {!Array.isArray(data) && (
        <div key={data} className={`flex rounded-lg border-[1px] mb-5`}>
          <div className={`flex flex-col w-full`}>
            <div
              className={`flex justify-between px-6 py-[11px] items-center bg-[#E1F1D6] rounded-md`}
            >
              <div className="flex">
                <span className={"text-[#01A24A] font-semibold text-lg"}>
                  <b>Detail Kegiatan :</b>{" "}
                  {data?.required_items
                    ? data?.required_items?.length > 0 &&
                      typeof data?.required_items[0]?.detail_activity_name ===
                        "object"
                      ? data?.required_items[0]?.detail_activity_name?.label
                      : data?.required_items[0]?.detail_activity_name
                    : ""}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <div className="text-white flex items-center text-xs px-2 py-0.5 rounded-full bg-[#12B76A]">
                  Nilai : <b className="pl-1"> Rp.{data.total_cost}</b>
                </div>
                <button>
                  {!isOpen[obj] && (
                    <CgChevronDown
                      className={`text-2xl text-primary-700 `}
                      onClick={() => handleCloseTab(obj)}
                    />
                  )}
                </button>
                <button>
                  {isOpen[obj] && (
                    <CgChevronUp
                      className={`text-2xl text-primary-700 `}
                      onClick={() => handleCloseTab(obj)}
                    />
                  )}
                </button>
              </div>
            </div>
            <div
              className={`flex flex-col w-full p-6 divide-y ${
                isOpen[obj] === false && "hidden"
              }`}
            >
              <div className="overflow-hidden border rounded-lg">
                <table class="table-auto text-sm w-full">
                  <thead>
                    <tr className="text-left uppercase bg-[#077439] text-white ">
                      <th className="px-4 py-5">No</th>
                      <th>Budget Account</th>
                      {obj !== "item" && <th>qty</th>}
                      {obj !== "item" ? <th>Volume</th> : <th>Qty/Volume</th>}
                      <th>Satuan biaya</th>
                      <th>sub total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.required_items?.map((item, key) => (
                      <tr key={item} className="border-b last:border-none">
                        <td className="p-4">{key + 1}</td>
                        <td>
                          {typeof item.budget_account_name === "object"
                            ? item.budget_account_name?.label
                            : item.budget_account_name}
                        </td>
                        {obj !== "item" && <td>{data?.total_person}</td>}
                        <td>{item.quantity}</td>
                        <td>Rp.{item.cost}</td>
                        <td>Rp.{item.subtotal}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RecapForm;

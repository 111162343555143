import OptionRadioBadge from "components/molecules/Badge/OptionRadioBadge"
import { useFormContext } from "react-hook-form"

const RadioBadge = ({
  arrData = [],
  isEdit = false,
  name = "",
  className = "",
  errors = false
}) => {

  const {
    watch
  } = useFormContext()

  return (
    <>
      <div className={`${className}`}>
        { arrData.length && arrData.map((e, i) => {
          return (
            <OptionRadioBadge
              label={e?.label}
              active={watch(name) === e?.value}
              value={e?.value}
              key={i}
              name={name}
              disabled={!isEdit}
            />
          )
        }) }
      </div>
      {errors && (
        <label className="label">
          <div className="text-xs mt-2 label-text-alt text-error text-[#F04438]">
            {errors?.message}
          </div>
        </label>
      )}
    </>
  )
}

export default RadioBadge
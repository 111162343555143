import React, { useState } from "react";
import { CiCircleCheck } from "react-icons/ci";

import { Button, InputFormUpload } from "components";
import { ConfirmationModal } from "components/molecules/Modal";

const ConfirmApprove = ({
  confirmText = "apakah anda yakin akan menyetujui pengajuan ini?",
  disabled = false,
  onConfirm,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        type="button"
        className="px-10 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
        disabled={disabled}
        onClick={() => setShowModal(true)}
      >
        <CiCircleCheck className="text-lg" />
        Setujui
      </Button>

      <ConfirmationModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={onConfirm}
      >
        {confirmText}

        <div className="pt-4">
          <InputFormUpload
            controllerName={"credentials.documents"}
            maxSize={10240}
            label="Dokumen Pendukung"
            labelClassname="font-semibold text-sm"
            isMulti
            listFiles={[]}
          />
        </div>
      </ConfirmationModal>
    </>
  );
};

export default ConfirmApprove;

import { enqueueSnackbar, useSnackbar } from "notistack";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import {
  createInstitutionalMinistry,
  deleteInstitutionalMinistry,
  getDetail,
  getList,
} from "services/danaProgram/institutionalMinistryServices";

const useInstitutionalMinistryService = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["institutional-ministry-lists", params],
    () => getList(queryParams),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
    }
  );

  return {
    list: data?.data?.data?.data || [],
    pagination:
      {
        lastPage: data?.data?.data?.lastPage,
        page: data?.data?.data?.page,
        perPage: data?.data?.data?.perPage,
        total: data?.data?.data?.total,
      } || {},
    isLoading,
    isFetching,
    refetch,
  };
};

export default useInstitutionalMinistryService;

export const useCreateInstitutionalMinistry = () => {
  const submitMutation = useMutation({
    mutationFn: (params) => {
      return createInstitutionalMinistry(params);
    },
  });

  return {
    submitMutation,
  };
};

export const useInstitutionalMinistryDetail = (id) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ["institutional-ministry-detail", id],
    () => getDetail({ id: id }),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: data?.data?.data,
    isLoading,
  };
};

export const useDeletetInstitutionalMinistry = () => {
  const deleteData = useMutation({
    mutationFn: (params) => {
      return deleteInstitutionalMinistry(params);
    },
  });

  return {
    deleteData,
  };
};

import config from "app/config";
import axiosInstance from "app/interceptors";

export const getList = (params) => {
  return axiosInstance.get(
    `${config.DAPROG_API_URL}/implementing-partner/project/list`,
    {
      params,
    }
  );
};

export const getDetail = (params) => {
  return axiosInstance.get(
    `${config.DAPROG_API_URL}/implementing-partner/project/detail`,
    {
      params,
    }
  );
};

export const createImplementingPartnerProject = (params) => {
  return axiosInstance.post(
    `${config.DAPROG_API_URL}/implementing-partner/project/submit`,
    params
  );
};

export const deleteImplementingPartnerProject = (params) => {
  return axiosInstance.delete(
    `${config.DAPROG_API_URL}/implementing-partner/project/delete`,
    { data: params }
  );
};

export const getListInvestmentPlan = (params) => {
  return axiosInstance.get(`${config.DAPROG_API_URL}/investment-plan/list`, {
    params,
  });
};

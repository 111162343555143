import { useMutation, useQuery } from "@tanstack/react-query";
import { ToastContext } from "components/atoms/Toast";
import { getErrorMessage } from "helpers";
import { enqueueSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  getCities,
  getProvinces,
  getSubdistricts,
} from "services/danaProgram/masterDataService";
import {
  getGeneralProfile,
  submitGeneralProfile,
} from "services/danaProgram/profilUmum";

const useProfileUmum = ({ id = "" }) => {
  const [temp, setTemp] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmBackModal, setConfirmBackModal] = useState(false);
  const [confirmDraftModal, setConfirmDraftModal] = useState(false);
  const [profileUmumDraftData, setProfileUmumDraftData] = useState();
  const { showToastMessage, showToastDescription } = useContext(ToastContext);
  const navigate = useNavigate();
  const form_id = JSON.parse(sessionStorage.getItem("idData"));
  const userSelector = useSelector((state) => state?.auth?.user || {});

  const {
    data: profileUmumData,
    isLoading: isLoadingProfileUmum,
    isFetching: isFetchingProfileUmum,
    refetch: refetchProfileUmum,
  } = useQueryProfileUmum(id);

  const {
    data: ProvincesData,
    isLoading: isLoadingProvinces,
    isFetching: isFetchingProvinces,
    refetch: refetchProvinces,
  } = useQuery(["data-Provinces"], () => getProvinces(), {
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: CitiesData,
    isLoading: isLoadingCities,
    isFetching: isFetchingCities,
    refetch: refetchCities,
  } = useQuery(["data-Cities"], () => getCities(), {
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: DistrictsData,
    isLoading: isLoadingDistricts,
    isFetching: isFetchingDistricts,
    refetch: refetchDistricts,
  } = useQuery(["data-Districts"], () => getSubdistricts(), {
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const formMutation = useFormProfileUmum();

  useEffect(() => {
    const flatData = profileUmumData?.data?.data;
    setTemp(flatData);
    if (flatData) {
      if (flatData?.legal_foundation?.date_deed) {
        let fulldateModif = new Date(
          new Date(flatData?.legal_foundation?.date_deed).getTime()
        );
        let monthModif = `0${fulldateModif?.getMonth() + 1}`;
        monthModif = monthModif?.substring(monthModif.length - 2);
        let dateModif = `0${fulldateModif?.getDate()}`;
        dateModif = dateModif?.substring(dateModif?.length - 2);
        fulldateModif = `${fulldateModif?.getFullYear()}-${monthModif}-${dateModif}`;
        flatData["legal_foundation"]["date_deed"] = fulldateModif;
      }
    }
    setProfileUmumDraftData(flatData);
  }, [profileUmumData]);

  const onDraftSubmit = async (data) => {
    setTemp(data);
    setConfirmDraftModal(true);
  };

  const onBack = async (data) => {
    setTemp(data);
    setConfirmModal(true);
  };

  const onSubmit = async (data) => {
    setConfirmModal(true);
  };

  const consumeAPI = async (data) => {
    try {
      data["legal_foundation"]["date_deed"] = new Date(
        data.legal_foundation.date_deed
      );
      const {
        general_information,
        working_area,
        legal_foundation,
        general_profile_document,
        ...restData
      } = data;

      let temp = JSON.parse(sessionStorage.getItem("generalProfileContext"));

      const newParams = {
        ...restData,
        general_informations: temp?.general_information,
        working_areas: temp?.working_area,
        general_profile_document: {
          ...general_profile_document,
          branch_data_document:
            temp?.general_profile_document?.edited_files_branch_data_document
              ?.fileId ?? data?.general_profile_document?.branch_data_document,
          proof_document_of_deed:
            temp?.general_profile_document?.edited_files_proof_document_of_deed
              ?.fileId ??
            data?.general_profile_document?.proof_document_of_deed,
          proof_document_of_nib:
            temp?.general_profile_document?.edited_files_proof_document_of_nib
              ?.fileId ?? data?.general_profile_document?.proof_document_of_nib,
          _id: data?.general_profile_document?._id,
          notes_approval:
            temp?.general_profile_document?.notes_approval ??
            data?.general_profile_document?.notes_approval,
          status_approval:
            temp?.general_profile_document?.status_approval ??
            data?.general_profile_document?.status_approval,
        },
        legal_foundations: temp?.legal_foundation,
        _id: data?._id,
        user_id: data?.user_id,
        status: "VERIFIED",
      };
      console.log("params :", data, temp);
      const res = await formMutation.mutateAsync(newParams);
      return res;
    } catch (error) {
      console.error(error, error.response?.message);
      enqueueSnackbar(error.response.data.message, {
        autoHideDuration: 2500,
        variant: "error",
      });
    }
  };

  const onSubmitConfirmModal = async () => {
    const response = await consumeAPI(profileUmumDraftData);
    if (response.status === 200) {
      showToastMessage(
        "Data Berhasil Disimpan!",
        "Data yang anda masukan telah berhasil disimpan."
      );
      setConfirmModal(false);
      navigate("/verification/detail");
    } else {
      showToastMessage(
        "Data gagal Disimpan!",
        "Data yang anda masukan telah gagal1 disimpan."
      );
    }
  };

  const onSubmitConfirmBackModal = async () => {
    setConfirmBackModal(false);
    showToastMessage(
      "Data Berhasil Disimpan!",
      "Data yang anda masukan telah berhasil disimpan."
    );
    navigate("/verification/detail");
  };

  const onSubmitConfirmDraftModal = async () => {
    setConfirmDraftModal(false);
    navigate("/verification/detail");
    showToastMessage(
      "Data Berhasil Disimpan!",
      "Data yang anda masukan telah berhasil disimpan."
    );
  };

  return {
    profileUmumDraftData,
    setProfileUmumDraftData,
    onDraftSubmit,
    onBack,
    onSubmit,
    onSubmitConfirmModal,
    onSubmitConfirmBackModal,
    onSubmitConfirmDraftModal,
    temp,
    setTemp,
    confirmModal,
    setConfirmModal,
    confirmBackModal,
    setConfirmBackModal,
    confirmDraftModal,
    setConfirmDraftModal,
    ProvincesData: ProvincesData?.data?.data,
    refetchProvinces,
    CitiesData: CitiesData?.data?.data,
    refetchCities,
    DistrictsData: DistrictsData?.data?.data,
    refetchDistricts,
  };
};

export const useQueryProfileUmum = (id) => {
  return useQuery(["list-profile-umum"], () => getGeneralProfile({ id }), {
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
  });
};

export const useFormProfileUmum = () => {
  return useMutation(async (payload) => submitGeneralProfile(payload), {
    onSuccess: () => {
      sessionStorage.removeItem("generalProfileContext");
      enqueueSnackbar({
        message: "Success",
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });
};

export default useProfileUmum;

import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import { Breadcrumbs } from 'components';
import Stepper from './form/stepper';
import ActivityResult from './form/ActivityResult';
import CostRealization from './form/CostRealization';
import moment from 'moment';

const links = [
  {
    path: '/safeguard-implementation',
    label: 'Daftar Laporan Implementasi Safeguard',
  },
  {
    label: 'Detail',
  },
];

const SafeguardImplementationDetail = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [payload, setPayload] = useState({});
  const [activityResultValues, setActivityResultValues] = useState({});
  const [costRealizationValues, setCostRealizationValues] = useState({});

  const onBack = useCallback(
    (data) => {
      if (currentStep > 0) {
        setCostRealizationValues((curr) => ({
          ...curr,
          ...data,
        }));
        setCurrentStep(0);
      }
    },
    [currentStep]
  );

  const onSubmit = useCallback(
    (data) => {
      if (currentStep < 1) {
        setPayload((curr) => ({
          ...curr,
          ...data,
        }));
        setActivityResultValues((curr) => ({
          ...curr,
          ...data,
        }));
        setCurrentStep(1);
      } else {
        if (!isEmpty(payload)) {
          const dataToSend = {
            ...payload,
            ...data,
          };

          console.log('data to send ', dataToSend);
        }
      }
    },
    [currentStep, payload]
  );

  const activityResultDefaultValues = useMemo(
    () => ({
      main_activity: null,
      status: 1,
      activity_goals: [],
      partners: [],
      involvement: {
        male: null,
        female: null,
      },
      safeguards: [],
      activity_result: {
        activity_result: '',
        attachment: [],
      },
      ...activityResultValues,
      start_date: activityResultValues?.start_date
        ? moment(activityResultValues?.start_date).toDate()
        : null,
      end_date: activityResultValues?.end_date
        ? moment(activityResultValues?.end_date).toDate()
        : null,
    }),
    [activityResultValues]
  );

  return (
    <Fragment>
      <Breadcrumbs items={links} />

      <div className="h-6" />

      <ActivityResult
        defaultValues={activityResultDefaultValues}
        onNext={onSubmit}
      />
    </Fragment>
  );
};

export default SafeguardImplementationDetail;

import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
} from "components";
import { useNavigate, useParams } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { getDetailProposal } from "services/danaProgram/callForProposalService";

const GeneralInformationDetail = ({onNext, onPrev}) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  let { id } = useParams();

  const fetchDetailCfp = async (params) => {
    const res = await getDetailProposal(id, params);
    setData(res.data.data);
  }

  useEffect(() => {
    fetchDetailCfp({category : 'general-information'})
  }, [])

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <div>General Information</div>
        </CardHeader>
        <CardBody>
          <div className="space-y-5">
            <CardForm label="Informasi Dasar" buttonCollapse={true}>
              <div className="flex flex-col space-y-5">
                <div className="flex flex-col">
                  <span className="flex-1 font-bold">Proyek</span>
                  <span className="flex-1">{data?.cfp_detail?.program?.name}</span>
                </div>
                <div className="flex flex-col">
                  <span className="flex-1 font-bold">Thematic Programmes</span>
                  {
                    data?.thematic_programmes.length > 0 ? data?.thematic_programmes.map((item, index) => {
                      return (<span key={`thematic_programmes-${index}`} className="flex-1">- {item}</span>)
                    }) : (<span className="flex-1">-</span>)
                  }
                </div>
                <div className="flex flex-col">
                  <span className="flex-1 font-bold">Outcome</span>
                  {
                    data?.outcome.length > 0 ? data?.outcome.map((item, index) => {
                      return (<span key={`outcome-${index}`} className="flex-1">- {item}</span>)
                    }) : (<span className="flex-1">-</span>)
                  }
                </div>
                <div className="flex flex-col">
                  <span className="flex-1 font-bold">Output</span>
                  {
                    data?.output.length > 0 ? data?.output.map((item, index) => {
                      return (<span key={`output-${index}`} className="flex-1">- {item}</span>)
                    }) : (<span className="flex-1">-</span>)
                  }
                </div>
              </div>
            </CardForm>
            <CardForm label="Informasi Umum Proposal" buttonCollapse={true}>
              <div className="flex flex-col space-y-5">
                <div className="flex flex-col">
                  <span className="flex-1 font-bold">Judul Proposal</span>
                  <span className="flex-1">
                    {data?.cfp_detail?.proposal_title}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="flex-1 font-bold">Deskripsi Proposal</span>
                  <span className="flex-1">
                    {data?.proposal_description}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="flex-1 font-bold">Durasi Pelaksanaan</span>
                  <span className="flex-1">
                    {data?.duration_start} - {data?.duration_end}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="flex-1 font-bold">Aktivitas Utama Proyek</span>
                  {
                    data?.main_activity_project.length > 0 ? data?.main_activity_project.map((item, index) => {
                      return (<span key={`main_activity_project-${index}`} className="flex-1">- {item}</span>)
                    }) : (<></>)
                  }
                </div>
                <div className="flex flex-col">
                  <span className="flex-1 font-bold">Tujuan Proyek/ Kegiatan</span>
                  {
                    data?.objective_project.length > 0 ? data?.objective_project.map((item, index) => {
                      return (<span key={`objective_project-${index}`} className="flex-1">- {item}</span>)
                    }) : (<></>)
                  }
                </div>
                <div className="flex flex-col">
                  <span className="flex-1 font-bold">Strategi untuk menjamin keberlanjutan progam atau kegiatan yang diusulkan</span>
                  {
                    data?.strategy_program.length > 0 ? data?.strategy_program.map((item, index) => {
                      return (<span key={`strategy_program-${index}`} className="flex-1">- {item}</span>)
                    }) : (<></>)
                  }
                </div>
              </div>
            </CardForm>
            <CardForm label="Informasi Pengusul" buttonCollapse={true}>
              <div className="flex flex-col space-y-5">
                  {
                    data?.proposer_information.length > 0 ? data?.proposer_information.map((item, index) => {
                      return (
                        <div className="flex flex-col">
                          <div className="flex w-full">
                            <span className="flex-1 font-bold">Nama kegiatan</span>
                            <span className="flex-1 font-bold">NIDN / NIP</span>
                            <span className="flex-1 font-bold">Deskripsi</span>
                          </div>
                          <div className="flex">
                            <span className="flex-1">{item.name}</span>
                            <span className="flex-1">{item.NIDN_NIP}</span>
                            <span className="flex-1">{item.description}</span>
                          </div>
                        </div>)
                    }) : (<></>)
                  }
              </div>
            </CardForm>
          </div>
        </CardBody>
      </Card>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={onPrev}
          >
            <RiArrowLeftLine/>
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
        <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={() => onNext()}
          >
            Selanjutnya
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GeneralInformationDetail;
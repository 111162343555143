import { AtomButton, CardForm, InputForm } from "components";
import AtomSelect from "components/atoms/Select";
import { convertMoney, deconvertMoney } from "helpers";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";

const Resources = ({ methods, disabled, activityDetails }) => {
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "fund_resources",
  });

  const [formatedTotal, setFormatedTotal] = useState("Rp. 0,-");

  const onChangeItem = (e, key) => {
    const updateSubtotals = () => {
      const otherAssets = methods.watch(`fund_resources`) || [];
      const otherAssetSum = otherAssets
        .map((item) => parseInt(deconvertMoney(item?.remaining_limit || "0")))
        .reduce((sum, remaining_limit) => sum + remaining_limit, 0);

      setFormatedTotal(otherAssetSum);
    };
    updateSubtotals();
  };

  useEffect(() => {
    onChangeItem();
  }, []);

  return (
    <CardForm
      label={
        <div className="flex items-center w-full gap-3">
          Sumber Dana / Pembiayaan
          <span className="font-semibold text-sm text-[#E34F13] bg-[#FFF4ED] rounded-full px-2 py-1">
            Total Sisa PAGU : {convertMoney(formatedTotal)}
          </span>
        </div>
      }
      buttonCollapse={true}
      childerForm={true}
    >
      <div className="flex flex-col gap-6">
        {fields?.length > 0 &&
          fields?.map((field, key) => (
            <div key={field.id} className="grid grid-cols-11 gap-6">
              <div className="col-span-5">
                <AtomSelect
                  className={`py-2 w-full rounded-md focus-within:border-primary-700`}
                  options={activityDetails}
                  controllerName={`fund_resources.${key}.awp_detail_activity_name`}
                  label={"Detail Kegiatan"}
                  placeholder={"Detail Kegiatan"}
                  required={false}
                  disable={disabled}
                />
              </div>
              <div className="col-span-5">
                <InputForm
                  controllerName={`fund_resources.${key}.remaining_limit`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Sisa Pagu"}
                  placeholder={"Sisa Pagu"}
                  required={false}
                  onChangeInput={(e) => {
                    methods.setValue(
                      `fund_resources.${key}.remaining_limit`,
                      convertMoney(e.target.value)
                    );
                    onChangeItem(e, key);
                  }}
                  disabled={disabled}
                />
              </div>
              <div className="flex items-end h-full w-max">
                <button
                  className={`w-11 h-11 p-3 ${
                    key !== 0 ? "bg-[#D92D20]" : "hidden"
                  } text-white rounded-md flex justify-center items-center`}
                  onClick={() => remove(key)}
                >
                  <RiDeleteBin6Line
                    className={`${key === 0 ? "hidden" : ""} `}
                  />
                </button>
              </div>
            </div>
          ))}
        <div className="text-end">
          <AtomButton
            theme="ghost"
            disabled={disabled}
            className="p-4 normal-case border border-gray-300 rounded-lg"
            label={
              <div className="flex items-center gap-2 font-[14px]">
                <RiAddLine />
                Tambah Sumber Dana
              </div>
            }
            onClick={() =>
              append({
                awp_detail_activity_id: "659cf4cddd4c031704aa7b0f",
                awp_detail_activity_name: "",
                remaining_limit: "",
              })
            }
          />
        </div>
      </div>
    </CardForm>
  );
};

export default Resources;

import React, { useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { Breadcrumbs } from "components";
import GeneralInformation from "./GeneralInformation";
import InstitutionalLegalBasis from "./InstitutionalLegalBasis";
import WorkingArea from "./WorkingArea";
import SupportingDocuments from "./SupportingDocuments";
import { createPortal } from "react-dom";
import { FormModal } from "components/molecules/Modal";
import useProfileUmum from "./hooks/useProfileUmum";
import { RiErrorWarningLine } from "react-icons/ri";
import { GeneralProfileProvider } from "./hooks/generalProfileState";

const ProfileUmum = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const links = [
    {
      label: "Daftar LEMTARA",
      path: "/verification",
    },
    {
      label: "Detail",
      path: "/verification/detail",
    },
    {
      label: "Profile Umum",
    },
  ];

  const TabList = [
    {
      label: "Dokumen Pendukung",
      id: 1,
    },
    {
      label: "Informasi Umum",
      id: 2,
    },
    {
      label: "Landasan Hukum Lembaga",
      id: 3,
    },
    {
      label: "Wilayah Kerja",
      id: 4,
    },
  ];

  const {
    profileUmumDraftData,
    setProfileUmumDraftData,
    onDraftSubmit,
    onBack,
    onSubmit,
    onSubmitConfirmModal,
    onSubmitConfirmBackModal,
    onSubmitConfirmDraftModal,
    temp,
    setTemp,
    confirmModal,
    setConfirmModal,
    confirmBackModal,
    setConfirmBackModal,
    confirmDraftModal,
    setConfirmDraftModal,
  } = useProfileUmum({
    id: JSON.parse(sessionStorage.getItem("idData")).general_profile,
  });

  return (
    <>
      <GeneralProfileProvider>
        {createPortal(
          <FormModal
            open={confirmModal}
            onClose={setConfirmModal}
            onSubmit={async () => await onSubmitConfirmModal()}
            size={"w-2/8"}
            positiveConfirm="Ya"
            negativeConfirm="Tidak"
            withHeader={false}
          >
            <div>
              <div className="p-[5px] bg-[#FCE4CF] w-[50px] h-[50px] flex justify-center items-center rounded-full">
                <RiErrorWarningLine className={"text-[#DC6803] text-[38px]"} />
              </div>
            </div>
            <div className="text-lg font-[600] mt-[10px]">
              Ubah Data Di Section
            </div>
            <div className="text-sm font-[400]">
              Apakah Anda yakin akan mengubah data di section ini?
            </div>
          </FormModal>,
          document.body
        )}
        {createPortal(
          <FormModal
            open={confirmDraftModal}
            onClose={setConfirmDraftModal}
            onSubmit={async () => await onSubmitConfirmDraftModal()}
            size={"w-2/8"}
            positiveConfirm="Ya"
            negativeConfirm="Tidak"
            withHeader={false}
          >
            <div>
              <div className="p-[5px] bg-[#FCE4CF] w-[50px] h-[50px] flex justify-center items-center rounded-full">
                <RiErrorWarningLine className={"text-[#DC6803] text-[38px]"} />
              </div>
            </div>
            <div className="text-lg font-[600] mt-[10px]">Simpan draft</div>
            <div className="text-sm font-[400]">
              Apakah Anda yakin menyimpan sebagai draft?
            </div>
          </FormModal>,
          document.body
        )}
        {createPortal(
          <FormModal
            open={confirmBackModal}
            onClose={setConfirmBackModal}
            onSubmit={async () => await onSubmitConfirmBackModal()}
            size={"w-2/8"}
            positiveConfirm="Ya"
            negativeConfirm="Tidak"
            withHeader={false}
          >
            <div>
              <div className="p-[5px] bg-[#FCE4CF] w-[50px] h-[50px] flex justify-center items-center rounded-full">
                <RiErrorWarningLine className={"text-[#DC6803] text-[38px]"} />
              </div>
            </div>
            <div className="text-lg font-[600] mt-[10px]">Kembali</div>
            <div className="text-sm font-[400]">
              Setelah anda kembali semua data akan disimpan pada draft
            </div>
          </FormModal>,
          document.body
        )}
        <div className="space-y-6">
          <Breadcrumbs items={links} />
          <div className="tabs">
            {TabList.map((data) => (
              <a
                className={`tab px-8 tab-bordered ${
                  activeTab === data.id
                    ? "border-b-primary-600 text-primary-600 bg-primary-100 font-bold"
                    : ""
                } hover:text-primary-600 hover:border-primary-700`}
                onClick={() => setActiveTab(data.id)}
              >
                {data.label}
              </a>
            ))}
          </div>
          <div>
            {activeTab === 1 && (
              <SupportingDocuments
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                data={{
                  general_profile_document:
                    profileUmumDraftData?.general_profile_document,
                }}
                allData={profileUmumDraftData}
                setData={setProfileUmumDraftData}
              />
            )}
            {activeTab === 2 && (
              <GeneralInformation
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                data={{
                  general_information:
                    profileUmumDraftData?.general_information,
                }}
                allData={profileUmumDraftData}
                setData={setProfileUmumDraftData}
              />
            )}
            {activeTab === 3 && (
              <InstitutionalLegalBasis
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                data={{
                  legal_foundation: profileUmumDraftData?.legal_foundation,
                }}
                allData={profileUmumDraftData}
                setData={setProfileUmumDraftData}
              />
            )}
            {activeTab === 4 && (
              <WorkingArea
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                data={{ working_area: profileUmumDraftData?.working_area }}
                allData={profileUmumDraftData}
                setData={setProfileUmumDraftData}
                onSubmit={onSubmit}
              />
            )}
          </div>
        </div>
      </GeneralProfileProvider>
    </>
  );
};

export default ProfileUmum;

import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import NPWP from "./NPWP";
import SPT from "./SPT";
import PPN from "./PPN";
import PPH from "./PPH";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const InstitutionalFinancialReport = ({ tools, data }) => {
  console.log("data", data, tools);
  const navigate = useNavigate();

  const {
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();

  const [isEdit, setIsEdit] = useState(false);
  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEdit = () => setIsEdit(!isEdit);
  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Pajak</div>
          <div className="flex-1 text-right">
            {isEdit ? (
              <Button
                type="button"
                className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Simpan Perubahan
              </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Ubah
              </Button>
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col">
          {tools && (
            <>
              <NPWP tools={tools} isEdit={isEdit} />
              <div className="my-4 w-full border-t border-black-500"></div>
              <PPN tools={tools} datas={data} isEdit={isEdit} />
              <SPT tools={tools} isEdit={isEdit} />
              <PPH tools={tools} datas={data} isEdit={isEdit} />
            </>
          )}
          {/* button Verification */}
          <RadioVerificationBadge
            className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
            name={"result_verification"}
            nameNotes={"reason"}
            isEditVerification={isEditVerification}
            toogleIsEditVerification={toogleIsEditVerification}
            errors={
              errors?.result_verification ? errors?.result_verification : false
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default InstitutionalFinancialReport;

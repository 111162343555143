import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
} from "components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useCreateInstitutionalMinistry } from "../hooks/useInstitutionalMinistryService";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const links = [
  {
    label: "Daftar End Beneficiaries",
    path: "/institutional-ministry",
  },
  {
    label: "Tambah Data End Beneficiaries",
  },
];

const InstitutionalMinistryCreate = () => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Nama End Beneficiaries is required"),
    desc: Yup.string().required("Deskripsi is required"),
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      desc: "",
    },
  });

  const {
    formState: { errors, isValid },
  } = methods;
  const navigate = useNavigate();
  const { submitMutation } = useCreateInstitutionalMinistry();

  const handleSubmitForm = async () => {
    try {
      const params = methods.getValues();
      submitMutation.mutate(params, {
        onSuccess: (res) => {
          navigate("/institutional-ministry");
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Breadcrumbs items={links} />
      <FormProvider {...methods}>
        <div className="flex flex-col gap-6 pt-8">
          <Card>
            <CardHeader>Tambah Data</CardHeader>
            <CardBody>
              <div className="flex flex-col gap-5">
                <InputForm
                  controllerName={`name`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Nama end beneficiaries"}
                  placeholder={"Nama end beneficiaries"}
                  required={true}
                />
                <InputForm
                  textArea={true}
                  controllerName={"desc"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Deskripsi"}
                  placeholder={"Deskripsi"}
                  required={true}
                />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="items-center justify-between block px-6 py-5 mt-4 mb-10 bg-white border border-gray-200 rounded-lg md:flex">
          <div>
            <Button
              type="button"
              className="px-8 text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
              onClick={() => {
                navigate("/institutional-ministry");
              }}
            >
              Batal
            </Button>
          </div>

          <div className="items-center justify-between block gap-2 md:flex">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={() => {
                handleSubmitForm();
              }}
              disabled={!isValid}
            >
              {submitMutation.isLoading ? "Tunggu Sebentar" : "Submit"}
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default InstitutionalMinistryCreate;

import React from "react";
import { Input, Label } from "components";
import AtomSelect from "components/atoms/Select";

const AssignForm = () => {
  return (
    <div className="space-y-5">
        <div className="flex flex-col space-y-5">
             <div className="flex w-full">
                <span className="flex-1 font-bold">Program</span>
                <span className="flex-1 font-bold">Judul Proposal</span>
                <span className="flex-1 font-bold">Tanggal Terverifikasi</span>
            </div>
            <div className="flex">
                <span className="flex-1">Need Expert</span>
                <span className="flex-1">Proposal A</span>
                <span className="flex-1">20 Februari 2023</span>
            </div>
        </div>
        <div className="flex flex-col space-y-5">
             <div className="flex w-full">
                <span className="flex-1 font-bold">Nilai Pengajuan</span>
                <span className="flex-1 font-bold">Program</span>
                <span className="flex-1 font-bold">Tematik</span>
            </div>
            <div className="flex">
                <span className="flex-1">Cuti Tahunan</span>
                <span className="flex-1">Proposal A</span>
                <span className="flex-1">Sampah</span>
            </div>
        </div>
        <AtomSelect
            controllerName={'assignExperts'}
            options={[
            { value: '1', label: 'Bob ' },
            { value: '2', label: 'Jamal ' },
        ]} className="basic-single mt-2"
            label="Assign Tenaga Ahli untuk menilai"
            isMulti
        />
    </div>
  );
};

export default AssignForm;

import { Button, CustomSelect, SearchInput } from "components";
import { BasicList } from "components/molecules/ListPage";
import _, { debounce } from "lodash";
import { useCallback, useMemo, useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router";
import useAnnualWorkPlan from "./hooks/useAnnualWorkPlan";
import moment from "moment";
import { formattedValue } from "helpers";

const ListAnnualWorkPlan = () => {
  const navigate = useNavigate();
  const methods  = useForm();

  const [keyword, setKeyword] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const params = {
    proposal_number: keyword,
    status: statusFilter,
    page: page,
    limit: limit
  };

  const { annualWorkPlanData, paginationAwsData, refetch } = useAnnualWorkPlan({ params });
  

  useEffect(() => {
    refetch();
  }, [keyword, statusFilter, page, limit]);

  const links = [
    {
      label: "Daftar Annual Work Plan",
    },
  ];

  const onSearch = debounce(
    useCallback(
      (event) => {
        setKeyword(event?.target?.value || '');
      },
      [setKeyword]
    ),
    1000
  );

  const statusOptions =  useMemo(() => {
    return [
      { value: null, label: "Semua" },
      { value: "draft", label: "Draft" },
      { value: "dikirim", label: "Dikirim" },
    ]
  });

  const onChangeStatus = useCallback((obj) => {
    setStatusFilter(obj.value || undefined);
  }, []);

  const onChangePage = useCallback((val) => setPage(val), []);
  const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(paginationAwsData?.page) * parseInt(paginationAwsData?.perPage) -
            parseInt(paginationAwsData?.perPage) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "submissionNumber",
        title: "No Pengajuan",
        dataIndex: "proposal_number",
        className: "",
        columnClassName: "w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: "createdAt",
        title: "Tanggal Submit AWP",
        dataIndex: "created_at",
        className: "",
        columnClassName: "w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value ? moment(value).format("DD/MM/YYYY") : "-"}
          </div>
        ),
      },
      {
        id: "workPlanYear",
        title: "Rencana Kerja Tahun",
        dataIndex: "planned_year",
        className: "",
        columnClassName: "w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => {
          return(
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value ? !isNaN(value) ? value : moment(value).format("YYYY") : "-"}
          </div>
        )},
      },
      {
        id: "activityTotal",
        title: "Total Kegiatan",
        dataIndex: "total_activities",
        className: "",
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: "fundingNeeds",
        title: "Kebutuhan Dana",
        dataIndex: "funding_needs",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces",
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {_.isNumber(value) ? formattedValue(value) : value}
          </div>
        ),
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        columnClassName: "w-[125px] text-ellipsis overflow-hidden",
        sortable: true,
        fixed: "right",
        render: (value) => (
          <div className="text-ellipsis w-full max-w-[200px]">
            {value === "draft" && (
              <div className="w-fit py-1 px-2 bg-[#F2F4F7] rounded-[25px] flex gap-[1px] items-center">
                <div className="text-[#344054] font-medium text-xs">
                  Draft
                </div>
              </div>
            )}
            {value === "dikirim" && (
              <div className="w-fit py-1 px-2 bg-[#ECFDF3] rounded-[25px] flex gap-[1px] p-1 items-center">
                <div className="text-[#00BD52] font-medium text-xs">
                  Dikirim
                </div>
              </div>
            )}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "status",
        className: "",
        sortable: true,
        columnClassName: "w-[250px] text-center text-ellipsis overflow-hidden",
        fixed: "right",
        render: (value, data, index) => {
          return (
            <>
              {value === "draft" && (
                <>
                  <div className="flex flex-col justify-center items-center gap-[12px]">
                    {/* 
                     */}
                    <div className="space-x-3 justify-center flex w-full">
                      <Button
                        type="button"
                        size="sm"
                        className={`
                            text-white w-40 text-xs
                              bg-[#F58A42]
                            `}
                        onClick={() => {continueDraft(data)}}
                      >
                        Lanjutkan Draft
                      </Button>
                    </div>
                  </div>
                </> 
              )} 
              {value === "dikirim"  && (
                <>
                  <div className="flex flex-col justify-center items-center gap-[12px]">
                    <FaEye
                      className="text-gray-600 cursor-pointer flex-1"
                      size={20}
                      onClick={() => navigate(`/annual-work-plan/${data?._id}`)}
                    />
                  </div>
                </>
              )}
            </>
          ) 
        },
      },
    ];
  }, [paginationAwsData]);

  const continueDraft = (data) => {
    if (data.current_step === 1) {
      navigate('/annual-work-plan/add/', { state: {idAwp: data._id, idInvestment: data.investment_id, typeForm: 'edit', currentStep: '2' } });
    } else if (data.current_step === 2) {
      navigate('/annual-work-plan/add/', { state: {idAwp: data._id, idInvestment: data.investment_id, typeForm: 'edit', currentStep: '3' } });
    } else if (data.current_step === 3) {
      navigate('/annual-work-plan/add/', { state: {idAwp: data._id, idInvestment: data.investment_id, typeForm: 'edit', currentStep: '4' } });
    } else if (data.current_step === 4) {
      navigate('/annual-work-plan/add/', { state: {idAwp: data._id, idInvestment: data.investment_id, typeForm: 'edit', currentStep: '5' } });
    }
    
  }

  const onCreate = () => {
    navigate("/annual-work-plan/add/");
  };

  return (
    <>
      <BasicList
        links={links}
        title={"Daftar Annual Work Plan"}
        columns={tableColumns}
        dataSource={annualWorkPlanData}
        onCreate={onCreate}
        customTextAddButton="Ajukan AWP"
        customIconAddButton={null}
        customClassnameAddButton="bg-[#E34F13] text-white hover:bg-[#F58A42]"
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        pagination={paginationAwsData}
        customFilter={
          <FormProvider {...methods}>
            <div className="space-x-5 flex">
              <SearchInput placeholder="Cari" onChange={onSearch} />
              <CustomSelect
                optionsData={statusOptions}
                placeholder="Status"
                className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none items-center self-center"
                onChange={onChangeStatus} 
              />
            </div>
          </FormProvider>
        }
      />
    </>
  );
};

export default ListAnnualWorkPlan;

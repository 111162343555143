import React from "react";
import classNames from "classnames";
import TableColumn from "./TableColumn";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";
import { Spinner, Pagination } from "components";
import { BiLoader } from "react-icons/bi";

const CustomTable = ({
  columns = [],
  dataSource = [],
  bgColumns = "",
  isLoading = false,
  stripped = false,
  wrapperClassName = "",
  headerClassName = "",
  className = "",
  layout = "auto",
  defaultSortOrder = undefined,
  pagination = undefined,
  onChangeRowsPerPage = undefined,
  onChangePage = undefined,
  isPagination = "",
  sortMethod = "",
  setSortMethod = {},
  setSortBy = {},
}) => {
  return (
    <div className="border-[1px] border-solid border-gray-200 rounded-md">
      <div
        className={classNames(
          ["overflow-x-auto", "overflow-y-auto", "max-h-screen"],
          [...wrapperClassName.split(" ")]
        )}
      >
        <table
          className={classNames(
            ["table min-w-full"],
            { "table-auto": layout === "auto" },
            { "table-fixed": layout === "fixed" },
            {
              "table-striped": stripped,
            },
            [...className.split(" ")]
          )}
        >
          {/* {layout === "fixed" && <TableColumn items={columns} />} */}
          <TableHeader
            items={columns}
            bgColumns={bgColumns}
            headerClassName={headerClassName}
            defaultSortOrder={defaultSortOrder}
            sortMethod={sortMethod}
            setSortMethod={setSortMethod}
            setSortBy={setSortBy}
          />
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length}>
                  <div className="w-100 h-[300px]">
                    <BiLoader className="text-xl animate-spin" />
                  </div>
                </td>
              </tr>
            ) : (
              <TableRow columns={columns} datas={dataSource} />
            )}
          </tbody>
        </table>
      </div>
      {isPagination === "" && (
        <Pagination
          pagination={pagination}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
        />
      )}
    </div>
  );
};

export default React.memo(CustomTable);

import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  DateInput,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine, RiSave3Line } from "react-icons/ri";
import axios from "axios";
import config from "app/config";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import _, { isEmpty } from "lodash";
import {
  InvestmentPlanProvider,
  useInvestmentPlanContext,
} from "../../../context/InvestmentPlanContext";
import { formattedValue } from "helpers";
import axiosInstance from "app/interceptors";

const validationSchema = yup.object().shape({
  // benfitValue: yup.string().required('wajib'),
});

const AddBenefit = () => {
  const location = useLocation();
  const {
    idInvestment,
    idProposal,
    setCurrentStep,
    dataInvestment,
    setData,
    currentStep,
    fetchedData,
    setDataInvestment,
    type,
  } = useInvestmentPlanContext();

  const navigate = useNavigate();
  const [beneficiary, setBeneficiary] = useState([]);
  const [benefitCiaryData, setBenefitCiaryData] = useState("");
  const [benefitValue, setBenefitValue] = useState([]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { fields: step2Field } = useFieldArray({
    control: methods.control,
    name: "step_2",
  });

  const fetchInvestment = async () => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan?id=${idInvestment}`
    );

    setDataInvestment({
      dataInvestment: res.data.data,
    });
  };

  const fetchPPD = async () => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/api/perhimpunan-dana/v1/integration/daprog/proposal/${idProposal}`
    );

    let value = 0
    if(dataInvestment?.step_2?.length > 0 ){
      dataInvestment?.step_2.forEach((v, i) => {
        value = formattedValue(dataInvestment.step_2[i].benefitValue)
      });
    }

    methods.reset({
      step_2: res.data.result.beneficiary.map((el) => ({
        benefitCiaryData: el,
        benefitValue: value,
      })),
    });
  };

  const onSave = async (data) => {
    const value = data.step_2.map((v, i) => ({
      ...data.step_2[i],
      benefitValue: data.step_2[i].benefitValue.replace(/[^0-9]/g, ""),
    }));

    const payload = {
      id: idInvestment,
      step_2: value,
    };

    try {
      axiosInstance
        .post(
          `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/store/second-step`,
          payload
        )
        .then((res) => {
          navigate("/investment-plan/");
        });
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = async (data) => {
    if (type === "show") {
      setCurrentStep({ currentStep: "3" });
    } else {
      const value = data.step_2.map((v, i) => ({
        ...data.step_2[i],
        benefitValue: !isEmpty(data.step_2[i].benefitValue) ? data.step_2[i].benefitValue.replace(/[^0-9]/g, "") : 0,
      }));

      const payload = {
        id: idInvestment,
        step_2: value,
      };

      try {
        await axiosInstance
          .post(
            `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/store/second-step`,
            payload
          )
          .then((res) => {
            setCurrentStep({ currentStep: "3" });
          });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const links = [
    {
      label: "Daftar Investmen Plan",
    },
    {
      label: "Penyusunan Investment Plan",
    },
  ];

  const handleInputCurrency = (event, key) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "");

    // Memastikan bahwa nilai yang diinputkan tidak kosong
    const numericValue = inputValue === "" ? 0 : parseInt(inputValue, 10);

    const newFormat = formattedValue(numericValue);

    methods.setValue(`step_2.${key}.benefitValue`, newFormat);

    // Register nilai yang sudah diformat ke dalam formulir menggunakan React Hook Form
    // methods.register(`step_2.${key}.benefitValue`, { value: numericValue, formattedValue: formattedValue });
  };

  useEffect(() => {
    //if create new data must hit this function
    if (!idProposal) return;
    fetchPPD();
  }, [idProposal]);

  useEffect(() => {
    fetchInvestment();
  }, [currentStep]);

  useEffect(() => {
    // condition for edit from draft or show detail
    if (!_.isEmpty(dataInvestment?.step_2)) {
      // step_2 state must be array
      let step_2 = dataInvestment.step_2;

      const value = dataInvestment.step_2.map((v, i) => ({
        ...dataInvestment.step_2[i],
        benefitValue: formattedValue(dataInvestment.step_2[i].benefitValue)
      }));

      methods.reset({
        step_2: value,
      });
    }
  }, [dataInvestment, type]);

  return (
    <div className="sm:max-w-[480px] md:max-w-[608px] lg:max-w-[864px] xl:max-w-[81vw]">
      {/* <Breadcrumbs items={links} /> */}
      {/* <ProgressStepForm items={stepForm} step={step} /> */}
      <div className="flex flex-col gap-4 mt-3"></div>
      <Card>
        <CardHeader>
          <div>Penerima Manfaat</div>
        </CardHeader>
        <CardBody>
          {step2Field?.map((el, key) => (
            <>
              <div key={"benefit-" + key} className="flex flex-row">
                <div className="w-1/2 flex flex-col gap-y-2">
                  <label>Penerima Manfaat</label>
                  <Input
                    value={el.benefitCiaryData}
                    className="w-[95%]"
                    disabled
                  />
                </div>
                <div className="w-1/2 flex flex-col gap-y-2">
                  <label>Jumlah Alokasi Dana 
                    <span className="ml-1 text-error-500 text-sm">*</span>
                  </label>
                  <Input
                    {...methods.register(`step_2.${key}.benefitValue`, {
                      onChange: (e) => handleInputCurrency(e, key),
                    })}
                    // name={`step_2.${key}.benefitValue`}
                    // ref={methods.register(`step_2.${key}.benefitValue`)}
                    // onChange={ (e) => handleInputCurrency(e, key)}
                    disabled={type === "show"}
                    className="w-[95%]"
                  />
                </div>
              </div>
            </>
          ))}
        </CardBody>
      </Card>

      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => setCurrentStep({ currentStep: "1" })}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
          <Button
            type="button"
            className="text-black border-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={methods.handleSubmit(onSave)}
            disabled={type === "show"}
          >
            <RiSave3Line className="text-black" />
            Simpan & Draft
          </Button>
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            // onClick={() => navigate("/investment-plan/add/3")}
            onClick={methods.handleSubmit(onSubmit)}
          >
            Selanjutnya
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddBenefit;

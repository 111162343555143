import { useQuery } from "@tanstack/react-query";

const randomDate = (start, end) => {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
};
const randomNumber = (max, min) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
const DUMMY_DATA = Array.from(Array(25)).map((x, idx) => ({
  updated_date: randomDate(new Date(2022, 0, 1), new Date()),
  output: "Output A",
  activity: "Kegiatan Utama",
  activity_detail: "Detail Kegiatan",
  project: `Proyek ${Math.floor(Math.random() * (25 - 1 + 1) + 1)}`,
  reporter: "John Doe",
  realisation_value: randomNumber(100000000, 10000000),
}));

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const useActivityReport = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      const limit = params.limit;
      const page = params.page;
      let newData = paginate(DUMMY_DATA, limit, page);
      const total = DUMMY_DATA.length;
      const pageCount = total > 0 && limit > 0 ? Math.ceil(total / limit) : 1;
      const next_pages = page < pageCount ? page + 1 : null;
      const newObject = {
        data: newData,
        total,
        page,
        perPage: limit,
        lastPage: pageCount,
        previous_pages: page - 1 === 0 ? null : page - 1,
        next_pages,
      };
      return new Promise((resolve, reject) => {
        setTimeout(() => resolve(newObject), 2500);
      });
    },
    ...options,
  });
};

import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";

const BarChart = ({ data }) => {
  const chartRef = useRef(null);
  useEffect(() => {
    let chartOptions = {
      series: [
        {
          data: Object.values(data),
        },
      ],
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          distributed: true,
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },

      xaxis: {
        categories: Object.keys(data),
        labels: {
          show: true,
          rotate: -20,
          trim: false,
          style: {
            fontSize: "10px",
            fontFamily: "Poppins, Arial, sans-serif",
            fontWeight: 400,
          },
        },
      },
      grid: {
        show: true,
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        stepSize: 1,
      },
      legend: {
        position: "bottom",
        offsetX: 40,
      },
      dataLabels: {
        enabled: false,
      },
    };

    if (chartRef.current) {
      const chart = new ApexCharts(chartRef.current, chartOptions);
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [data]);

  return <div ref={chartRef}></div>;
};

export default BarChart;

import { Card, CardBody, CardHeader, Button } from "components";
import { Select as AtomSelect } from "components";
import { InputForm } from "components";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useForm, useFormContext } from "react-hook-form";

const FormTimPengelolaProyek = ({
  title = {},
  listAllEmployee,
  data,
  isValid,
  onSubmit,
}) => {
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const formData = getValues();
      onSubmit(formData);
    } catch (error) {
      console.error(error);
    }
  };

  const { watch, setValue, getValues, control, register } = useFormContext();

  useForm({
    defaultValues: {
      proposal_name: "",
      position_1: "Project Director",
    },
    data,
  });

  useEffect(() => {
    for (let key in data) {
      if (
        key.includes("name") &&
        listAllEmployee?.length > 0 &&
        key !== "proposal_name"
      ) {
        setValue(
          key,
          listAllEmployee.find((obj) => obj.value === data[key])
        );
      } else if (key === "proposal_name") {
        setValue(key, data[key]);
      }
    }
  }, [data, listAllEmployee]);

  const filterEmployeeByDepartment = (department) => {
    function checkDepartment(dept) {
      return dept.jabatanProject === department;
    }
    let temp = listAllEmployee.filter(checkDepartment);
    return temp;
  };

  return (
    <div className="">
      <div className="flex flex-col gap-4 mt-3">
        <Card>
          <CardHeader>
            <div>{title}</div>
          </CardHeader>
          <CardBody>
            <div className="flex flex-col gap-4">
              <div className="flex-1">
                <InputForm
                  controllerName="proposal_name"
                  className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Nama Proposal"}
                  disabled
                />
              </div>
              <div className="pb-6   border-b border-grey-400">
                <p className={`py-3 font-semibold text-md`}>
                  Tim Pengelola Proyek
                </p>
                <div className="flex gap-4 flex-row">
                  <div className="flex-1 mb-1">
                    <InputForm
                      controllerName="position_1"
                      className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Jabatan"}
                      disabled
                    />
                  </div>
                  <div className="flex-1 mb-1">
                    <AtomSelect
                      controllerName={`project_director_name`}
                      label="User"
                      required
                      className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                      options={listAllEmployee ? listAllEmployee : []}
                    />
                  </div>
                </div>
                <div className="flex gap-4 flex-row">
                  <div className="flex-1">
                    <InputForm
                      controllerName="position_2"
                      className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Jabatan"}
                      disabled
                    />
                  </div>
                  <div className="flex-1">
                    <AtomSelect
                      controllerName={`project_manager_name`}
                      label="User"
                      required
                      className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                      options={listAllEmployee ? listAllEmployee : []}
                    />
                  </div>
                </div>
              </div>
              <p className={`m-[6px] font-semibold text-md`}>Tim PMU</p>
              <div className="flex gap-4 flex-row">
                <div className="flex-1">
                  <InputForm
                    controllerName="position_3"
                    className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Jabatan"}
                    disabled
                  />
                </div>
                <div className="flex-1">
                  <AtomSelect
                    controllerName={`project_leader_name`}
                    label="User"
                    className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                    options={
                      listAllEmployee
                        ? filterEmployeeByDepartment("Project Leader")
                        : []
                    }
                  />
                </div>
              </div>
              <div className="flex gap-4 flex-row">
                <div className="flex-1">
                  <InputForm
                    controllerName="position_4"
                    className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Jabatan"}
                    disabled
                  />
                </div>
                <div className="flex-1">
                  <AtomSelect
                    controllerName={`safeguard_specialist_name`}
                    label="User"
                    className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                    options={
                      listAllEmployee
                        ? filterEmployeeByDepartment("Safeguard Specialist")
                        : []
                    }
                  />
                </div>
              </div>
              <div className="flex gap-4 flex-row">
                <div className="flex-1">
                  <InputForm
                    controllerName="position_5"
                    className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Jabatan"}
                    disabled
                  />
                </div>
                <div className="flex-1">
                  <AtomSelect
                    controllerName={`pum_name`}
                    label="User"
                    className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                    options={
                      listAllEmployee ? filterEmployeeByDepartment("PUM") : []
                    }
                  />
                </div>
              </div>
              <div className="flex gap-4 flex-row">
                <div className="flex-1">
                  <InputForm
                    controllerName="position_6"
                    className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Jabatan"}
                    disabled
                  />
                </div>
                <div className="flex-1">
                  <AtomSelect
                    controllerName={`procurement_specialist_name`}
                    label="User"
                    className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                    options={
                      listAllEmployee
                        ? filterEmployeeByDepartment("Procurement Specialist")
                        : []
                    }
                  />
                </div>
              </div>
              <div className="flex gap-4 flex-row">
                <div className="flex-1">
                  <InputForm
                    controllerName="position_7"
                    className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Jabatan"}
                    disabled
                  />
                </div>
                <div className="flex-1">
                  <AtomSelect
                    controllerName={`finance_specialist_name`}
                    label="User"
                    className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                    options={
                      listAllEmployee
                        ? filterEmployeeByDepartment("Finance Specialist")
                        : []
                    }
                  />
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>

      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate("/project-management-team/")}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>

        <div className="block md:flex items-center justify-between gap-2">
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={handleSubmit}
            disabled={!isValid}
          >
            Submit
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormTimPengelolaProyek;

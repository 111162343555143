import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Breadcrumbs } from 'components';
import * as yup from 'yup';
import useProjectManagementTeam from '../hooks/useHooks';
import Child from './form';
import useProgressReport from '../hooks/useHooks';

const DetailLaporanKemajuan = () => {
  const id = sessionStorage.getItem('ProgressReportsReviewId');
  const location = useLocation();
  const currentPath = location.pathname;
  const [edit, setEdit] = useState(true);
  const idInvestment = JSON.parse(localStorage.getItem('id_investment'));

  const params = { id: id };

  const [listAllEmployee, setListAllEmployee] = useState();
  const {
    ProgressReport,
    refetchProgressReport,
    employeeList,
    refetchEmployeeList,
  } = useProgressReport({ params });

  useEffect(() => {
    refetchEmployeeList();
  }, []);

  useEffect(() => {
    refetchProgressReport();
  }, [id]);

  function mapOption(data) {
    let temp = data?.map((obj) => ({
      value: obj.name,
      label: obj.name,
      ...obj,
    }));
    setListAllEmployee(temp);
  }

  useEffect(() => {
    mapOption(employeeList?.data?.data);
  }, [employeeList]);

  useEffect(() => {
    if (currentPath.includes('detail')) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  }, []);

  const validationSchema = yup.object().shape({
    // work_plan_name: yup.mixed().required("wajib"),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const links = [
    {
      label: 'Laporan kemajuan',
      path: `/progress-report-review`,
    },
    {
      label: 'Review Laporan kemajuan',
    },
  ];

  return (
    <div>
      {/* start Breadcrumb */}
      <div className="bg-transparent flex items-center flex-wrap flex-1 mb-[24px]">
        <Breadcrumbs items={links} />
      </div>
      <FormProvider {...methods}>
        <Child
          permission={edit}
          title={'Review Laporan Kemajuan'}
          listAllEmployee={listAllEmployee}
          data={ProgressReport ? ProgressReport : {}}
        />
      </FormProvider>
    </div>
  );
};

export default DetailLaporanKemajuan;

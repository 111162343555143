import React, { useCallback, useEffect, useMemo, useState } from "react";
// import ProgressStepForm from "components/molecules/ProgressStepForm";
// import Step1 from '../addInformation'
// import Step2 from '../addBenefit'
// import Step3 from '../addResultChain'
// import Step4 from '../addSupportFile'
import axios from "axios";
import config from "app/config";
import { Link, useParams } from "react-router-dom";
import { RiHome6Line } from "react-icons/ri";
import { FiChevronRight } from "react-icons/fi";
import { useLocation } from "react-router-dom";
// import TimeWorkPlanForm from "./form";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
} from "components";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import moment from "moment";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getSpecificTimeWorkPlanDetail } from "services/danaProgram/specificTimeWorkPlanService";
import _ from "lodash";

const DetailRencanaKerjaWaktu = () => {
  const { id } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const idInvestment = JSON.parse(localStorage.getItem("id_investment"));
  const [detailData, setDetailData] = useState();

  const { data: timeWorkPlanDataDetail, refetch: refetchInvestmentPlanData } =
    useQuery(
      [`detail-timeWorkPlan-${id}`, id],
      () => getSpecificTimeWorkPlanDetail(id),
      {
        onError: (error) => {
          // enqueueSnackbar(getErrorMessage(error), { variant: "error" });
        },
        refetchOnWindowFocus: false,
        enabled: false,
      }
    );

  useEffect(() => {
    refetchInvestmentPlanData();
  }, [detailData, id]);

  useEffect(() => {
    if(!_.isEmpty(timeWorkPlanDataDetail)) {
      setDetailData(timeWorkPlanDataDetail?.data?.data);
    }
  }, [timeWorkPlanDataDetail]);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "awp_detail_activity_name",
        title: "Detail Kegiatan",
        dataIndex: "awp_detail_activity_name",
        className: "overflow-hidden",

        columnClassName: "w-[calc(100vw/7)] text-ellipsis overflow-hidden",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full ">{value}</div>
          );
        },
      },
      {
        id: "awp_fund",
        title: "Dana di AWP",
        dataIndex: "awp_fund",
        className: "",

        fixed: "center",
        columnClassName: "w-[calc(100vw/7)] text-ellipsis overflow-hidden",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[calc(100vw/7)] ">
            {formatCurrency(parseInt(value))}
          </div>
        ),
      },
      {
        id: "requirement_fund",
        title: "Kebutuhan Dana",
        dataIndex: "requirement_fund",
        className: "overflow-hidden text-ellipsis",
        sortable: true,

        fixed: "center",
        columnClassName: "w-[calc(100vw/7)] text-ellipsis overflow-hidden",
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[calc(100vw/7)] ">
            {formatCurrency(parseInt(value))}
          </div>
        ),
      },
    ];
  }, []);

  const methods = useForm({
    resolver: yupResolver(),
    mode: "onChange",
  });

  const links = [
    {
      label: "Rencana Kerja Waktu Tertentu",
      path: "/rencana-kerja-waktu",
    },
    {
      label: "Detail",
    },
  ];

  function formatCurrency(amount) {
    if (isNaN(amount)) {
      return "Invalid input";
    }
    const [integerPart, decimalPart] = amount.toFixed(0).split(".");
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const formattedCurrency = `Rp. ${formattedInteger}${
      decimalPart ? `,${decimalPart}` : ""
    }`;

    return formattedCurrency;
  }

  let temp = [
    {
      detailKegiatan: "Rapat Koordinasi",
      danaDiAWP: formatCurrency(39850000),
      kebutuhanDana: formatCurrency(25000000),
    },
    {
      detailKegiatan: "Sosialisasi Pelaksanaan",
      danaDiAWP: formatCurrency(9100000),
      kebutuhanDana: formatCurrency(5000000),
    },
    {
      detailKegiatan: "Rapat Selesai Acara",
      danaDiAWP: formatCurrency(11000000),
      kebutuhanDana: formatCurrency(15000000),
    },
  ];

  function parseCurrency(currencyString) {
    const cleanedString = currencyString.replace(/[^\d]/g, "");
    const parsedValue = parseFloat(cleanedString);
    return isNaN(parsedValue) ? null : parsedValue;
  }

  let total = temp.reduce(
    (accumulator, row) => accumulator + parseCurrency(row.kebutuhanDana),
    0
  );

  return (
    <div>
      {/* start Breadcrumb */}
      <div className="bg-transparent flex items-center flex-wrap flex-1 mb-[24px]">
        <Breadcrumbs items={links} />
      </div>

      <div className="flex flex-col gap-4 mt-3">
        <Card>
          <CardHeader>Detail Rencana Kerja Waktu</CardHeader>
          <CardBody>
            <div className="flex flex-col gap-6">
              <div className="space-y-[9px]">
                <label className="text-[#1D2939] font-semibold text-md">
                  Nama Rencana Kerja Waktu Tertentu
                </label>
                <span className="block text-md">
                  {detailData?.work_plan_name}
                </span>
              </div>
              <div className="space-y-[9px]">
                <label className="text-[#1D2939] font-semibold text-md">
                  Periode Rencana Kegiatan
                </label>
                <span className="block text-md">
                  {moment(
                    detailData?.start_date
                  ).format("DD/MM/YYYY")}{" "}
                  -&nbsp;
                  {moment(detailData?.end_date).format(
                    "DD/MM/YYYY"
                  )}
                </span>
              </div>
              <div className="space-y-[9px]">
                <label className="text-[#1D2939] font-semibold text-md">
                  Nama Proyek
                </label>
                <span className="block text-md">
                  {detailData?.awp_project_name}
                </span>
              </div>
              <div className="space-y-[9px]">
                <label className="text-[#1D2939] font-semibold text-md">
                  Kegiatan Utama
                </label>
                <span className="block text-md">
                  {" "}
                  {
                    detailData
                      ?.awp_project_activity_name
                  }
                </span>
              </div>

              <div className="border-b" />

              <div>
                <Table
                  bordered
                  stripped
                  layout="fixed"
                  bgColumns={`bg-[#01A24A]`}
                  className="mb-4"
                  columns={tableColumns}
                  dataSource={
                    detailData
                      ?.awp_detail_activities !== null ||
                    detailData?.awp_detail_activities
                      ?.length > 0
                      ? detailData
                          ?.awp_detail_activities
                      : []
                  }
                  isPagination={false}
                  // isLoading={isLoading}
                  // onChangePage={onChangePage}
                  // onChangeRowsPerPage={onChangeRowsPerPage}
                  // pagination={pagination}
                />
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex-1 pt-2"></div>
              <div className="flex-1 pt-1"></div>
              <div className="flex-1 pt-2 text-sm">
                <span className="self-center text-md pr-3 font-semibold">
                  Total
                </span>
                <div className="bg-[#EFEFEF] rounded py-3 px-3 w-full">
                  <span className="text-gray-400 text-md">
                    {detailData?.total_requirement_fund ? 
                      formatCurrency(
                        detailData?.total_requirement_fund
                      ) : '-'}
                  </span>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default DetailRencanaKerjaWaktu;

import config from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const getList = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/list`,
    { params, headers }
  );
};
export const getDetail = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan?id=${params}`,
    headers
  );
};

export const postProjectClosure = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/closing-project`,
    params
  );
};

export const postSafeguardClosure = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/closing-safeguard`,
    params
  );
};

import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  Select,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const InformasiRekening = ({ tools, methods, data }) => {
  const navigate = useNavigate();

  const {
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();

  const [isEdit, setIsEdit] = useState(false);
  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEdit = () => setIsEdit(!isEdit);
  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Informasi Rekening</div>
          <div className="flex-1 text-right">
            {isEdit ? (
              <Button
                type="button"
                className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Simpan Perubahan
              </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Ubah
              </Button>
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col">
          <div className="mt-[20px]">
            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputForm
                  controllerName={`account_name`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Nama Rekening"}
                  placeholder={"Nama Rekening"}
                  disabled={!isEdit}
                />
                <InputForm
                  controllerName={`account_number`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Nomor Rekening"}
                  placeholder={"Nomor Rekening"}
                  disabled={!isEdit}
                />
                <InputForm
                  controllerName={`bank_name`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Nama Bank"}
                  placeholder={"Nama Bank"}
                  disabled={!isEdit}
                />
                <InputForm
                  controllerName={`branch_name`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Kantor Cabang"}
                  placeholder={"Kantor Cabang"}
                  disabled={!isEdit}
                />
                <Select
                  controllerName={`account_type`}
                  className={`py-2  w-full rounded-md flex-1 `}
                  label={"Jenis Rekening"}
                  placeholder={"Jenis Rekening"}
                  returnObject={
                    typeof methods.getValues("account_type") === "object"
                  }
                  options={[
                    { value: "Diamond", label: "Diamond" },
                    { value: "Gold", label: "Gold" },
                    { value: "Silver", label: "Silver" },
                  ]}
                  disable={!isEdit}
                />
              </div>
            </div>
          </div>

          {/* button Verification */}
          <RadioVerificationBadge
            className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
            name={"result_verification"}
            nameNotes={"reason"}
            isEditVerification={isEditVerification}
            toogleIsEditVerification={toogleIsEditVerification}
            errors={
              errors?.result_verification ? errors?.result_verification : false
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default InformasiRekening;

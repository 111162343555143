import React, { Fragment, useEffect, useMemo, useState } from "react";
import { RiFile2Line, RiArrowLeftLine } from "react-icons/ri";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router";
import moment from "moment";

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  Table,
} from "components";
import { BadgeStatus as ApprovalBadgeStatus } from "components/organisms/Approval2Level";

import { formattedValue } from "helpers";

import { getDetail } from "services/danaProgram/pembayaranResourceService";
import { getFile } from "services/danaProgram/fileService";

const PembayaranResourcesDetail = () => {
  const navigate = useNavigate();
  const [dataReviewer, setDataReviewer] = useState([]);
  const links = [
    {
      label: "Daftar Pembayaran Resources Proyek",
      path: "/pembayaran-resources",
    },
    {
      label: "Detail",
    },
  ];
  const { id } = useParams();

  const { data: dataDetail } = useQuery({
    queryKey: ["detail-pembayaran-resources"],
    queryFn: async () => {
      const res = await getDetail({ id: id });
      return res?.data?.data || [];
    },
  });

  const onPreviewFile = useMutation({
    mutationKey: [`get-detail-file`],
    mutationFn: async (id) => {
      const res = await getFile(id);
      return res.data.data;
    },
    onSuccess: (res) => {
      window.open(res.url, `_blank`);
    },
  });

  useEffect(() => {
    if (dataDetail) {
      setDataReviewer(dataDetail?.project_resources_payment_reviewer);
    }
  }, [dataDetail]);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "updated_at",
        title: "Tanggal Review",
        dataIndex: "updated_at",
        className: "overflow-hidden",

        columnClassName: "w-[calc(100vw/7)] text-ellipsis overflow-hidden",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full ">
              {value ? moment.utc(value).local().format("DD/MM/YYYY") : "-"}
            </div>
          );
        },
      },
      {
        id: "status",
        title: "Hasil Review",
        dataIndex: "status",
        className: "",
        fixed: "center",
        columnClassName: "w-[calc(100vw/7)] text-ellipsis overflow-hidden",
        sortable: true,
        render: (value, data, index) => <ApprovalBadgeStatus value={value} />,
      },
      {
        id: "approver_name",
        title: "Reviewer",
        dataIndex: "approver_name",
        className: "overflow-hidden text-ellipsis",
        sortable: true,

        fixed: "center",
        columnClassName: "w-[calc(100vw/7)] text-ellipsis overflow-hidden",
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[calc(100vw/7)] ">
            {value ?? "-"}
          </div>
        ),
      },
      {
        id: "reason",
        title: "Alasan Penolakan",
        dataIndex: "reason",
        className: "overflow-hidden text-ellipsis",
        sortable: true,

        fixed: "center",
        columnClassName: "w-[calc(100vw/7)] text-ellipsis overflow-hidden",
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[calc(100vw/7)] ">
            {value ?? "-"}
          </div>
        ),
      },
    ];
  }, [dataReviewer]);

  return (
    <Fragment>
      <div className="flex flex-col gap-8">
        <Breadcrumbs items={links} />
        <Card>
          <CardHeader>Detail</CardHeader>
          <CardBody>
            <div className="flex flex-col gap-6">
              <div className="grid grid-cols-3 gap-4">
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-md">
                    Nama Proyek
                  </label>
                  <span className="block text-md">
                    {dataDetail?.project_name ?? "-"}
                  </span>
                </div>
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-md">
                    Uraian Pengajuan
                  </label>
                  <span className="block text-md">
                    {dataDetail?.description ?? "-"}
                  </span>
                </div>
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-md">
                    Status
                  </label>
                  <span className="block text-md">
                    <ApprovalBadgeStatus
                      align="left"
                      fit
                      value={dataDetail?.status}
                    />
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-md">
                    Tanggal SPJ/ Tagihan/ Transaksi
                  </label>
                  <span className="block text-md">
                    {dataDetail?.transaction_date
                      ? moment
                          .utc(dataDetail?.transaction_date)
                          .local()
                          .format("DD/MM/YYYY")
                      : "-"}
                  </span>
                </div>
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-md">
                    Nomor Invoice
                  </label>
                  <span className="block text-md">
                    {dataDetail?.invoice_number ?? "-"}
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-md">
                    Dokumen Daftar Nominatif Resources Proyek
                  </label>
                  <div className="grid grid-cols-3 gap-4">
                    {dataDetail?.project_resource_data?.document?.map?.(
                      (doc, i) => (
                        <div
                          key={"project-resource-data-doc-" + i}
                          className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl"
                        >
                          <div className="flex gap-4">
                            <div>
                              <div className="bg-[#EEFFF4] rounded-full p-4">
                                <RiFile2Line className="text-primary-600" />
                              </div>
                            </div>
                            <div className="flex flex-col gap-2">
                              <span className="font-medium">
                                {doc?.fileName}
                              </span>
                              <span>
                                {`${parseFloat(doc?.fileSize / 1000, 2)} KB`}
                              </span>
                              <span
                                onClick={() =>
                                  onPreviewFile.mutate(doc?.fileId)
                                }
                                className="font-semibold text-primary-600 cursor-pointer"
                              >
                                Lihat Dokumen
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-5 gap-4">
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-md">
                    Nilai
                  </label>
                  <span className="block text-md">
                    {/* {dataDetail?.project_resource_data?.value
                      ? formattedValue(
                          dataDetail?.project_resource_data?.value
                        )
                      : '-'} */}
                    {dataDetail?.project_resource_data?.value
                      ? `Rp ${dataDetail?.project_resource_data?.value}`
                      : "-"}
                  </span>
                </div>
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-md">
                    Pajak
                  </label>
                  <span className="block text-md">
                    {dataDetail?.project_resource_data?.tax_type
                      ? dataDetail?.project_resource_data?.tax_type
                      : "-"}
                  </span>
                </div>
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-md">
                    Nilai Pajak
                  </label>
                  <span className="block text-md">
                    {dataDetail?.project_resource_data?.tax_type
                      ? formattedValue(
                          dataDetail?.project_resource_data?.tax_value
                        )
                      : "-"}
                  </span>
                </div>
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-md">
                    Kode Billing
                  </label>
                  <span className="block text-md">
                    {dataDetail?.project_resource_data?.billing_code
                      ? dataDetail?.project_resource_data?.billing_code
                      : "-"}
                  </span>
                </div>
                <div className="space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-md">
                    Total Pembayaran (Rp)
                  </label>
                  <span className="block text-md">
                    {/* {dataDetail?.project_resource_data?.payment_total
                      ? formattedValue(
                          dataDetail?.project_resource_data?.payment_total
                        )
                      : '-'} */}

                    {dataDetail?.project_resource_data?.payment_total
                      ? `Rp ${dataDetail?.project_resource_data?.payment_total}`
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="space-y-[9px]">
                <label className="text-[#1D2939] font-semibold text-md">
                  Kegiatan Utama
                </label>
                <span className="block text-md">
                  {dataDetail?.main_activity_name
                    ? dataDetail?.main_activity_name
                    : "-"}
                </span>
              </div>
              <div className="space-y-[9px]">
                <label className="text-[#1D2939] font-semibold text-md">
                  Detail Kegiatan
                </label>
                <span className="block text-md">
                  {dataDetail?.detail_activity_name
                    ? dataDetail?.detail_activity_name
                    : "-"}
                </span>
              </div>
              <div className="space-y-[9px]">
                <label className="text-[#1D2939] font-semibold text-md">
                  Mata Anggaran
                </label>
                <span className="block text-md">
                  {dataDetail?.currency
                    ? dataDetail?.currency?.label || dataDetail?.currency
                    : "-"}
                </span>
              </div>
              <div className="grid grid-cols-3 gap-4">
                {dataDetail?.support_document?.map?.((doc, i) => (
                  <div
                    key={"support-document-" + i}
                    className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl"
                  >
                    <div className="flex gap-4">
                      <div>
                        <div className="bg-[#EEFFF4] rounded-full p-4">
                          <RiFile2Line className="text-primary-600" />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="font-medium">{doc?.fileName}</span>
                        <span>
                          {`${parseFloat(doc?.fileSize / 1000, 2)} KB`}
                        </span>
                        <span
                          onClick={() => onPreviewFile.mutate(doc?.fileId)}
                          className="font-semibold text-primary-600 cursor-pointer"
                        >
                          Lihat Dokumen
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CardBody>
          <CardBody>
            <CardForm label="Riwayat Review" buttonCollapse actionButton>
              <Table
                bordered
                stripped
                layout="fixed"
                bgColumns={`bg-[#01A24A]`}
                className="mb-4"
                columns={tableColumns}
                dataSource={dataReviewer}
              />
            </CardForm>
          </CardBody>
        </Card>
        <div className="block md:flex items-center justify-between px-10 py-5 border-[2px] border-gray-200 rounded-3xl mt-4 bg-white mb-10">
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate("/pembayaran-resources")}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default PembayaranResourcesDetail;

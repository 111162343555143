import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { convertMoney } from "helpers";

const DonutChart = ({
  data,
  params = { rp: true, percentage: true, legendValue: true },
}) => {
  const chartRef = useRef(null);
  useEffect(() => {
    let chartOptions = {
      chart: {
        type: "donut",
        height: 350,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
      series: Object.values(data),
      labels: Object.keys(data),
      legend: {
        position: "right",
        formatter: function (val, opts) {
          const value = params.rp
            ? `Rp ${convertMoney(opts.w.globals.series[opts.seriesIndex])}`
            : `${opts.w.globals.series[opts.seriesIndex]}`;

          return params.legendValue ? `${val}: ${value}` : `${val}`;
        },
      },
    };

    if (!params.percentage) {
      chartOptions = {
        ...chartOptions,
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return opts.w.globals.series[opts.seriesIndex];
          },
          style: {
            fontSize: "16px",
            colors: ["#FFF"], // Customize as needed
          },
        },
      };
    }

    if (chartRef.current) {
      const chart = new ApexCharts(chartRef.current, chartOptions);
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [data]);

  return <div ref={chartRef}></div>;
};

export default DonutChart;

import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import {
  addProgressReport,
  getProgressReport,
  getProgressReportList,
} from "services/danaProgram/danaProgramService";
import { getAllEmployee } from "services/danaProgram/masterDataService";

const useProgressReport = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: ProgressReportList,
    isLoading: isLoadingProgressReportList,
    isFetching: isFetchingProgressReportList,
    refetch: refetchProgressReportList,
  } = useQuery(
    ["list-ProgressReportList"],
    () => getProgressReportList(params),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: ProgressReport,
    isLoading: isLoadingProgressReport,
    isFetching: isFetchingProgressReport,
    refetch: refetchProgressReport,
  } = useQuery(["data-ProgressReport"], () => getProgressReport(params?.id), {
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: employeeList,
    isLoading: isLoadingEmployeeList,
    isFetching: isFetchingEmployeeList,
    refetch: refetchEmployeeList,
  } = useQuery(["data-AllEmployee"], () => getAllEmployee(), {
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const reviewProgressReport = useMutation({
    mutationFn: (params) => {
      return addProgressReport(params);
    },
  });
  return {
    ProgressReportList: ProgressReportList?.data?.data?.data,
    ProgressReport: ProgressReport?.data?.data,
    paginationProgressReport: {
      lastPage: ProgressReportList?.data?.data?.lastPage,
      page: ProgressReportList?.data?.data?.page,
      perPage: ProgressReportList?.data?.data?.perPage,
      total: ProgressReportList?.data?.data?.total,
    },
    submitProgressReport: reviewProgressReport,
    employeeList: employeeList,
    isLoadingProgressReportList,
    isFetchingProgressReportList,
    refetch: refetchProgressReportList,
    refetchProgressReport: refetchProgressReport,
    refetchEmployeeList: refetchEmployeeList,
  };
};

export default useProgressReport;

import { Breadcrumbs } from "components";
import { useState } from "react";
import { useNavigate } from "react-router";
import SumberDayaManusia from "./SumberDayaManusia";
import PeralatanPerlengkapan from "./PeralatanPerlengkapan";
import SupportingDocuments from "./SupportingDocument";
import useSumberDaya from "./hooks/useSumberDaya";
import { createPortal } from "react-dom";
import { FormModal } from "components/molecules/Modal";
import { RiErrorWarningLine } from "react-icons/ri";

const SumberDaya = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const links = [
    {
      label: "Daftar Lemtara",
      path: "/verification",
    },
    {
      label: "Detail",
      path: "/verification/detail",
    },
    {
      label: "Sumber Daya",
    },
  ];

  const TabList = [
    {
      label: "Dokumen Pendukung",
      id: 1,
    },
    {
      label: "Sumber Daya Manusia",
      id: 2,
    },
    {
      label: "Peralatan/Perlengkapan",
      id: 3,
    },
  ];

  const {
    sumberDayaDraftData,
    setSumberDayaDraftData,
    onDraftSubmit,
    onBack,
    onSubmit,
    onSubmitConfirmModal,
    onSubmitConfirmBackModal,
    onSubmitConfirmDraftModal,
    temp,
    setTemp,
    confirmModal,
    setConfirmModal,
    confirmBackModal,
    setConfirmBackModal,
    confirmDraftModal,
    setConfirmDraftModal,
  } = useSumberDaya({
    id: JSON.parse(sessionStorage.getItem('idData'))?.resource
  })

  return (
    <>
      {createPortal(
        <FormModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={async () => await onSubmitConfirmModal()}
          size={"w-2/8"}
          positiveConfirm="Ya"
          negativeConfirm="Tidak"
          withHeader={false}
        >
          <div>
            <div className="p-[5px] bg-[#FCE4CF] w-[50px] h-[50px] flex justify-center items-center rounded-full">
              <RiErrorWarningLine className={"text-[#DC6803] text-[38px]"} />
            </div>
          </div>
          <div className="text-lg font-[600] mt-[10px]">Ubah Data Di Section</div>
          <div className="text-sm font-[400]">Apakah Anda yakin akan mengubah data di section ini?</div>
        </FormModal>,
        document.body
      )}
      {createPortal(
        <FormModal
          open={confirmDraftModal}
          onClose={setConfirmDraftModal}
          onSubmit={async () => await onSubmitConfirmDraftModal()}
          size={"w-2/8"}
          positiveConfirm="Ya"
          negativeConfirm="Tidak"
          withHeader={false}
        >
          <div>
            <div className="p-[5px] bg-[#FCE4CF] w-[50px] h-[50px] flex justify-center items-center rounded-full">
              <RiErrorWarningLine className={"text-[#DC6803] text-[38px]"} />
            </div>
          </div>
          <div className="text-lg font-[600] mt-[10px]">Simpan draft</div>
          <div className="text-sm font-[400]">Apakah Anda yakin menyimpan sebagai draft?</div>
        </FormModal>,
        document.body
      )}
      {createPortal(
        <FormModal
          open={confirmBackModal}
          onClose={setConfirmBackModal}
          onSubmit={async () => await onSubmitConfirmBackModal()}
          size={"w-2/8"}
          positiveConfirm="Ya"
          negativeConfirm="Tidak"
          withHeader={false}
        >
          <div>
            <div className="p-[5px] bg-[#FCE4CF] w-[50px] h-[50px] flex justify-center items-center rounded-full">
              <RiErrorWarningLine className={"text-[#DC6803] text-[38px]"} />
            </div>
          </div>
          <div className="text-lg font-[600] mt-[10px]">Kembali</div>
          <div className="text-sm font-[400]">Setelah anda kembali semua data akan disimpan pada draft</div>
        </FormModal>,
        document.body
      )}
      <div className="space-y-6">
        <Breadcrumbs items={links} />
        <div className="tabs">
          {TabList.map((data) => (
            <a
              className={`tab px-8 tab-bordered ${
                activeTab === data.id
                  ? "border-b-primary-600 text-primary-600 bg-primary-100 font-bold"
                  : ""
              } hover:text-primary-600 hover:border-primary-700`}
              onClick={() => setActiveTab(data.id)}
            >
              {data.label}
            </a>
          ))}
        </div>
        <div>
          {activeTab === 1 && (
            <SupportingDocuments
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              data={{
                resource_document: sumberDayaDraftData?.resource_document
              }}
              allData={sumberDayaDraftData}
              setData={setSumberDayaDraftData}
            />
          )}
          {activeTab === 2 && (
            <SumberDayaManusia
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              data={{
                step_1: sumberDayaDraftData?.step_1
              }}
              allData={sumberDayaDraftData}
              setData={setSumberDayaDraftData}
            />
          )}
          {activeTab === 3 && (
            <PeralatanPerlengkapan
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              data={{
                step_2: sumberDayaDraftData?.step_2
              }}
              allData={sumberDayaDraftData}
              setData={setSumberDayaDraftData}
              onSubmit={onSubmit}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SumberDaya;

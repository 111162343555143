import { useCallback, useEffect, useMemo as UseMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { FaEye } from 'react-icons/fa';
import moment from 'moment';
import { debounce } from 'lodash';

import { SearchInput, Button } from 'components';
import { BasicList } from 'components/molecules/ListPage';

import useCallForProposal from './hooks/useCallForProposal';

const CommitmentSafeguard = () => {
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      date_of_filling: [null, null],
    },
  });

  const [keyword, setKeyword] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { watch } = methods;

  const params = {
    name: keyword,
    status: statusFilter,
    page: page,
    limit: limit,
    start_date: watch('date_of_filling')?.[0]
      ? moment.utc(watch('date_of_filling')[0]).local().format('YYYY-MM-DD')
      : null,
    end_date: watch('date_of_filling')?.[1]
      ? moment.utc(watch('date_of_filling')[1]).local().format('YYYY-MM-DD')
      : null,
  };

  const { callForProposalData, paginationCallForProposalData, refetch } =
    useCallForProposal({ params });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [keyword, statusFilter, page, limit, watch('date_of_filling')]);

  const links = [
    {
      label: 'Daftar Komitmen Safeguard',
    },
  ];

  const paginator = UseMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) => {
        setKeyword(event?.target?.value || '');
      },
      [setKeyword]
    ),
    1000
  );

  const tableColumns = UseMemo(() => {
    return [
      {
        id: 'no',
        title: 'No',
        dataIndex: 'no',
        className: 'overflow-hidden',
        columnClassName: 'w-[50px]',
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: 'ProposalTitle',
        title: 'Judul Proposal',
        dataIndex: 'proposal_title',
        className: '',
        columnClassName:
          'w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: 'created_by_name',
        title: 'Pengaju',
        dataIndex: 'created_by_name',
        className: '',
        columnClassName:
          'w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: 'project_name',
        title: 'Proyek',
        dataIndex: 'project_name',
        className: 'overflow-hidden text-ellipsis',
        sortable: true,
        columnClassName:
          'w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces',
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: 'actions',
        title: 'Aksi',
        dataIndex: 'status',
        className: '',
        sortable: true,
        columnClassName: 'w-[125px] text-center text-ellipsis overflow-hidden',
        fixed: 'right',
        render: (value, data, index) => {
          return (
            <div className="flex flex-row gap-2">
              <Button
                type="button"
                className={`
                    text-white w-30
                      bg-[#00BD52]
                    `}
                onClick={() =>
                  navigate(`/commitment-safeguard/set/${data?._id}`)
                }
              >
                Set Komitmen
              </Button>
            </div>
          );
        },
      },
    ];
  }, [paginationCallForProposalData]);

  return (
    <BasicList
      links={links}
      title={'Daftar Komitmen Full Proposal'}
      columns={tableColumns}
      dataSource={callForProposalData}
      onChangePage={(page) => setPage(page)}
      onChangeRowsPerPage={(row) => setLimit(row)}
      pagination={paginationCallForProposalData}
      customFilter={
        <FormProvider {...methods}>
          <div className="space-x-5 flex">
            <SearchInput placeholder="Cari" onChange={onSearch} />
          </div>
        </FormProvider>
      }
    />
  );
};

export default CommitmentSafeguard;

import config from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const getAllForm = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/lemtara/get-all-form`,
    {
      params,
    },
    { headers }
  );
};
export const getDetailData = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/lemtara/detail?id=${params}`,
    { headers }
  );
};

export const updateUnperform = (params) => {
  return axiosInstance.put(
    `${config.BASE_URL}/api/dana-program/v1/lemtara/unperform?id=${params.id}`,
    { headers }
  );
};

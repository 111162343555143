import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { debounce } from 'lodash';

import { formatCurrency } from 'helpers';
import { APPROVAL_STATUSSES } from 'components/organisms/Approval2Level/constant';
import { Button, SearchInput } from 'components';
import { BadgeStatus as ApprovalBadgeStatus } from 'components/organisms/Approval2Level';
import { TableWrapper } from 'components/molecules/ListPage';
import { useImplementingPartnerPaymentList } from './hooks/useImplementingPartnerPaymentServices';

import CustomSelectForm from 'components/molecules/CustomSelectForm';

const links = [
  {
    label: 'Daftar Pembayaran Implementing Partner',
  },
];

const ApprovalImplementingPartnerPayment = () => {
  const methods = useForm();
  const navigate = useNavigate();

  const [qParams, setQParams] = useState({
    name: '',
    limit: 10,
    page: 1,
    orderMethod: 'desc',
    orderBy: 'created_at',
  });

  const { list, pagination, refetch, invalidate } =
    useImplementingPartnerPaymentList(qParams);

  const tableColumns = useMemo(() => {
    return [
      {
        id: 'no',
        title: 'No',
        dataIndex: 'no',
        className: 'overflow-hidden',
        columnClassName: 'w-[50px]',
        render: (value, data, index) => {
          const no =
            parseInt(qParams.page) * parseInt(qParams.limit) -
            parseInt(qParams.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: 'name',
        title: 'Nama Proyek',
        dataIndex: 'implementing_partner_name',
        className: 'overflow-hidden text-ellipsis',
        sortable: true,
        fixed: '',
        columnClassName: 'text-ellipsis overflow-hidden w-60',
        render: (value, row) => (
          <div className="overflow-hidden text-ellipsis">{value}</div>
        ),
      },
      {
        id: 'city',
        title: 'Pengajuan Pembayaran',
        dataIndex: 'implementing_partner_project_name',
        className: '',
        fixed: '',
        headerClassName: 'text-left',
        columnClassName: 'w-[250px] text-ellipsis overflow-hidden',
        sortable: true,
        render: (value, row) => (
          <div className="overflow-hidden text-ellipsis">{value}</div>
        ),
      },
      {
        id: 'trx_date',
        title: 'Tanggal Transaksi',
        dataIndex: 'transaction_date',
        className: '',
        fixed: '',
        headerClassName: 'text-left',
        columnClassName: 'whitespace-break-spaces',
        sortable: true,
        render: (value, row) => (
          <div className="overflow-hidden text-ellipsis">{value}</div>
        ),
      },
      {
        id: 'institution',
        title: 'total nilai transaksi',
        dataIndex: 'total_payment',
        className: '',
        fixed: '',
        headerClassName: 'text-left',
        columnClassName: 'whitespace-break-spaces',
        sortable: true,
        render: (value, row) => (
          <div className="overflow-hidden text-ellipsis">
            {formatCurrency(value)}
          </div>
        ),
      },
      {
        id: 'status',
        title: 'Status',
        dataIndex: 'status',
        className: '',
        columnClassName: 'text-center whitespace-break-spaces',
        sortable: true,
        fixed: 'center',
        render: (value) => <ApprovalBadgeStatus value={value} />,
      },

      {
        id: 'actions',
        title: 'Aksi',
        dataIndex: '_id',
        className: '',
        sortable: true,
        columnClassName: 'text-center text-ellipsis overflow-hidden',
        fixed: 'center',
        render: (value, data) => {
          if (!value) return <></>;

          return (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                {['NEED_APPROVAL', 'NEED_APPROVAL_DIRECTOR'].includes(
                  data.status
                ) ? (
                  <Button
                    type="button"
                    className="w-full p-2 text-xs text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                    onClick={() =>
                      navigate(
                        `/approval-pembayaran-implementing-partner/${value}`
                      )
                    }
                  >
                    Review
                  </Button>
                ) : (
                  <div className="flex items-center justify-center w-full space-x-3">
                    <MdOutlineRemoveRedEye
                      className="text-gray-600 cursor-pointer"
                      size={20}
                      onClick={() =>
                        navigate(
                          `/approval-pembayaran-implementing-partner/${value}`
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </>
          );
        },
      },
    ];
  }, []);

  const onChangePage = useCallback(
    (val) => setQParams({ ...qParams, page: val }),
    []
  );

  const onChangeRowsPerPage = useCallback(
    (val) => setQParams({ ...qParams, limit: val }),
    []
  );

  const onSearch = debounce(
    useCallback(
      (event) => {
        setQParams({ ...qParams, name: event?.target?.value || '' });
      },
      [setQParams]
    ),
    1000
  );

  useEffect(() => {
    refetch();

    return () => invalidate();
  }, [qParams]);

  return (
    <div>
      <TableWrapper
        links={links}
        title={'Daftar Pembayaran Implementing Partner'}
        btnSize={'md'}
        columns={tableColumns}
        dataSource={list}
        onCreate={false}
        sortMethod={'desc'}
        setSortMethod={() => console.log('first')}
        setSortBy={() => console.log('first')}
        customTextAddButton="Tambah"
        pagination={pagination}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        customFilter={
          <FormProvider {...methods}>
            <div className="grid grid-cols-3 gap-3">
              <SearchInput
                controllerName={`search`}
                placeholder="Cari"
                className={'w-full'}
                onChange={onSearch}
              />
              {/* <CustomSelectForm
                name="payment_type"
                placeholder="Jenis pembayaran"
                options={[
                  { value: 1, label: "Bayar" },
                  { value: 2, label: "Tidak Bayar" },
                ]}
                onChange={(e) => {
                  setQParams((prev) => ({
                    ...prev,
                    payment_type: e?.value ?? null,
                  }));
                }}
                isClearable={true}
              /> */}
              <CustomSelectForm
                name="status"
                placeholder="Status"
                options={APPROVAL_STATUSSES}
                onChange={(e) => {
                  setQParams((prev) => ({ ...prev, status: e?.value ?? null }));
                }}
                isClearable={true}
              />
            </div>
          </FormProvider>
        }
      />
    </div>
  );
};

export default ApprovalImplementingPartnerPayment;

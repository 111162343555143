import { SAVE_WORK_PLAN, RESET_WORK_PLAN, UPDATE_WORK_PLAN } from "../actions";

const initialState = {
  data: {
    specificTimePlanId: "",
    withdrawalWorkPlan: "",
    withdrawalWork: "",
  },
};

export const specificTimePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_WORK_PLAN:
      return {
        ...state,
        data: action.payload,
      };

    case UPDATE_WORK_PLAN:
      return {
        ...state,
        ...action.payload,
      };

    case RESET_WORK_PLAN:
      return {
        ...state,
        data: {
          specificTimePlanId: "",
          withdrawalWorkPlan: "",
          withdrawalWork: "",
        },
      };
    default:
      return state;
  }
};

import { RiQuestionLine } from "react-icons/ri";

const QuestionBadge = ({
  text = "The Question"
}) => {
  return (
    <div
      className="flex items-center gap-[12px] border-[#D0D5DD] border-[2px] p-4 rounded-[12px]"
    >
      <RiQuestionLine className="text-[25px] text-[#475467]"/>
      <div className="text-[16px] font-[600]">{text}</div>
    </div>
  )
}

export default QuestionBadge;
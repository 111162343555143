import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  DateInput,
  InputForm,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useNavigate } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine, RiSave3Line } from "react-icons/ri";
import axios from "axios";
import config from "app/config";
import { Controller, useForm, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import {
  InvestmentPlanProvider,
  useInvestmentPlanContext,
} from "../../../context/InvestmentPlanContext";
import moment from "moment";
import { formattedValue } from "helpers";
import axiosInstance from "app/interceptors";
import { ErrorToast } from "components/atoms/Toast";

const validationSchema = yup.object().shape({
  proposal_id: yup.string().required("wajib diisi"),
  name: yup.string().required("wajib diisi"),
  reporting_period: yup.string().required("wajib diisi"),
  safeguards: yup.array().required("wajib diisi"),
  monitoring_plan: yup.string().required("wajib diisi"),
});

const AddIformation = () => {
  let isAdd = useLocation()?.pathname.includes("add");
  const navigate = useNavigate();
  const [optionProposal, setOptionProposal] = useState([]);
  const [optionThematic, setOptionThematic] = useState([]);
  const [optionProvince, setOptionProvince] = useState([]);
  const [optionPeriod, setOptionPeriod] = useState([]);
  const [optionDistributionMechanism, setOptionDistributionMechanism] =
    useState([]);
  const [optionAllocationMechanism, setOptionAllocationMechanism] = useState(
    []
  );
  const [optionISP, setOptionISP] = useState([]);
  const [investName, setInvestmentName] = useState([]);
  const [optionProjectGoal, setOptionProjectGoal] = useState([]);
  const [startPeriod, setStartPeriod] = useState([]);
  const [endPeriod, setEndPeriod] = useState([]);
  const {
    idInvestment,
    idProposal,
    currentStep,
    type,
    fetchedData,
    dataInvestment,
    setData,
    setCurrentStep,
  } = useInvestmentPlanContext();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { control, handleSubmit, formState: { errors } } = methods;

  const links = [
    {
      label: "Daftar Investmen Plan",
    },
    {
      label: "Penyusunan Investment Plan",
    },
  ];

  const fetchBPD = async () => {
    try {
      const res = await axiosInstance.get(
        `${config.BASE_URL}/api/perhimpunan-dana/v1/integration/daprog/proposal`
      );
      // console.log(res.data);
      const option = res.data.result.map((el) => ({
        value: el._id,
        label: el.proposalTitle,
      }));

      setOptionProposal(option);
    } catch (err) {
      console.error(err);
    }
  };

  const fecthPeriod = async () => {
    try {
      // const res = await axiosInstance.get(`${config.BASE_URL}/api/master/v1/SiklusKerjaProduksi/list`);
      const res = [
        {
          label: "quartal",
        },
        {
          label: "semester",
        },
        {
          label: "tahun",
        },
      ];
      // console.log(res.data);
      const option = res.map((el) => ({
        value: el.label,
        label: el.label,
      }));

      setOptionPeriod(option);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchLocation = async () => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/api/master/v1/Provinsi/list`
    );

    const option = res.data.data.map((el) => ({
      value: el.namaProvinsi,
      label: el.namaProvinsi,
    }));

    option.splice(0, 0, { value: "All Province", label: "All Province" });

    setOptionProvince(option);
  };

  const fetchMasterThematic = async () => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/api/master/v1/ThematicProgrammes/list`
    );

    const option = res.data.data.map((el) => ({
      value: el.nama,
      label: el.nama,
    }));

    setOptionThematic(option);
  };

  const fetchDistributionMech = async () => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/api/master/v1/MekanismePenyaluran/list`
    );

    const option = res.data.data.map((el) => ({
      value: el.nama,
      label: el.nama,
    }));

    setOptionDistributionMechanism(option);
  };

  const fetchAllocationMech = async () => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/api/master/v1/MekanismeAlokasi/list`
    );

    const option = res.data.data.map((el) => ({
      value: el.nama,
      label: el.nama,
    }));

    setOptionAllocationMechanism(option);
  };

  const fetchDonor = async (params) => {
    try {
      const res = await axiosInstance.get(
        `${config.BASE_URL}/api/perhimpunan-dana/v1/integration/daprog/proposal/${params.value}`
      );
      setInvestmentName(res.data.result.donor.name);
      setStartPeriod(res.data.result.period.start);
      setEndPeriod(res.data.result.period.end);
      const optionThematic = res.data.result.thematicProgrammes.map((el) => ({
        value: el,
        label: el,
      }));

      const optionGoal = res.data.result.programGoals.map((el) => ({
        value: el,
        label: el,
      }));

      methods.setValue("immediate_project", optionGoal);

      const optionProvince = res.data.result.location.map((el) => ({
        value: el,
        label: el,
      }));

      let funding_total = res.data.result?.funding_total;
      funding_total = funding_total.toString().replace(/[^0-9]/g, "");

      const numericValue =
        funding_total === "" ? 0 : parseInt(funding_total, 10);

      if (type == "create") {
        methods.setValue("programmes", optionThematic);
        methods.setValue("location", [
          {
            value: res.data.result.location[0],
            label: res.data.result.location[0],
          },
        ]);
        methods.setValue(
          "start_period",
          moment(res.data.result?.period?.start).format("YYYY-MM-DD")
        );
        methods.setValue(
          "end_period",
          moment(res.data.result?.period?.end).format("YYYY-MM-DD")
        );
        methods.setValue(`total_funding`, formattedValue(numericValue));
      }

      methods.setValue(
        "allocation_mechanism",
        res.data.result.allocationMechanism[0]
      );

      methods.setValue(
        "distribution_mechanism",
        res.data.result.distributionMechanism[0]
      );

      methods.setValue("currency", res.data.result?.currency);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchISP = async () => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/api/master/v1/Isp/list`
    );
    const data = res?.data?.data;

    if (res?.status == 200) {
      setOptionISP(
        data.map((el) => ({
          value: el?.id,
          label: el?.nama,
        }))
      );
    }
  };

  const onSave = async (data) => {
    data.source = investName;
    data.currency = "USD";

    if (data.start_period === "" || data.start_period === startPeriod) {
      data.start_period = startPeriod;
    }

    if (data.end_period === "" || data.end_period === endPeriod) {
      data.end_period = endPeriod;
    }

    data.total_funding = data.total_funding.replace(/[^0-9]/g, "");
    data.goals = data.immediate_project;

    try {
      await axiosInstance
        .post(
          `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/store/first-step`,
          data
        )
        .then((response) => {
          navigate("/investment-plan/");
        });
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = async (data) => {
    if (type === "show") {
      setCurrentStep({ currentStep: "2" });
    } else {
      if (dataInvestment?._id) data.id = dataInvestment?._id;
      data.source = investName;

      if (data.start_period === "" || data.start_period === startPeriod) {
        data.start_period = startPeriod;
      }

      if (data.end_period === "" || data.end_period === endPeriod) {
        data.end_period = endPeriod;
      }

      data.total_funding = data.total_funding.replace(/[^0-9]/g, "");
      data.goals = data.immediate_project;
      
      try {
        await axiosInstance
          .post(
            `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/store/first-step`,
            data
          )
          .then((response) => {
            if (!_.isEmpty(dataInvestment)) {
              setCurrentStep({ currentStep: "2" });
            } else {
              setData({
                idInvestment: response.data.data.id,
                idProposal: data.proposal_id,
                currentStep: "2",
                type: "create",
              });
            }
          })
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleInputCurrency = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "");

    const numericValue = inputValue === "" ? 0 : parseInt(inputValue, 10);

    const newFormat = formattedValue(numericValue, false);

    methods.setValue(`total_funding`, newFormat);
  };

  useEffect(() => {
    fetchBPD();
    fecthPeriod();
    fetchMasterThematic();
    fetchDistributionMech();
    fetchAllocationMech();
    fetchLocation();
    fetchISP();
  }, []);

  const stepForm = [
    "Informasi Umum",
    "Penerima Manfaat",
    "Result Chain",
    "Dokumen Pendukung",
  ];

  useEffect(() => {
    if (!_.isEmpty(methods.watch("proposal_id"))) {
      fetchDonor({ value: methods.watch("proposal_id") });
    }
  }, [methods.watch("proposal_id")]);

  useEffect(() => {
    if (!_.isEmpty(dataInvestment)) {
      fetchDonor({ value: dataInvestment?.step_1?.proposal_id });

      methods.reset({
        proposal_id: dataInvestment?.step_1?.proposal_id,
        proposal: dataInvestment?.step_1?.proposal_type,
        name: dataInvestment?.step_1?.project_name,
        source: dataInvestment?.step_1?.fund_source,
        programmes: dataInvestment?.step_1?.thematic_programmes,
        immediate_project: dataInvestment?.step_1?.project_goals ?? [],
        location: _.isString(dataInvestment?.step_1?.location)
          ? [
              {
                value: dataInvestment?.step_1?.location,
                label: dataInvestment?.step_1?.location,
              },
            ]
          : dataInvestment?.step_1?.location,
        reporting_period: dataInvestment?.step_1?.reporting_period,
        start_period: moment(
          dataInvestment?.step_1?.project_period_from
        ).format("YYYY-MM-DD"),
        end_period: moment(dataInvestment?.step_1?.project_period_to).format(
          "YYYY-MM-DD"
        ),
        total_funding: dataInvestment?.step_1?.total_funding_required
          ? formattedValue(
              dataInvestment?.step_1?.total_funding_required,
              false
            )
          : null,
        distribution_mechanism: dataInvestment?.step_1?.distribution_mechanism,
        allocation_mechanism: dataInvestment?.step_1?.allocation_mechanism,
        risk_management: dataInvestment?.step_1?.risk_management ?? [],
        monitoring_plan: dataInvestment?.step_1?.monitoring_plan ?? [],
        safeguards: dataInvestment?.safeguards ?? [],
        safeguard_other: dataInvestment?.safeguard_other,
      });
    }
  }, [dataInvestment]);

  return (
    <div className="sm:max-w-[480px] md:max-w-[608px] lg:max-w-[864px] xl:max-w-[81vw]">
      {/* <Breadcrumbs items={links} /> */}
      {/* <ProgressStepForm items={stepForm} step={step} /> */}
      <div className="flex flex-col gap-4 mt-3">
        <Card>
          <CardHeader>
            <div>Informasi Umum</div>
          </CardHeader>
          <CardBody>
            <div className="flex flex-col gap-6">
              <div className="space-y-[9px]">
                <label className="text-[#1D2939] font-semibold text-sm">
                  Pilih Proposal
                  <span className="ml-1 text-error-500 text-sm">*</span>
                </label>
                <Controller
                  name="proposal_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                      options={optionProposal}
                      isDisabled={type === "show"}
                      value={{
                        value: methods.watch("proposal_id"),
                        label: methods.watch("proposal"),
                      }}
                      onChange={(e) => {
                        fetchDonor(e);
                        methods.setValue("proposal_id", e.value);
                        methods.setValue("proposal", e.label);                      }}
                    />
                  )}
                />
                {errors.proposal_id && (
                  <p className="text-error-500 text-sm">{errors.proposal_id.message}</p>
                )}
              </div>
              <div className="space-y-[9px]">
                <label className="text-[#1D2939] font-semibold text-sm">
                  Sumber Pendanaan
                  <span className="ml-1 text-error-500 text-sm">*</span>
                </label>
                <Input
                  defaultValue={investName}
                  {...methods.register("source")}
                  className="w-full text-sm"
                  disabled
                />
              </div>
              <div className="space-y-[9px]">
                <label className="text-[#1D2939] font-semibold text-sm">
                  Nama Project
                  <span className="ml-1 text-error-500 text-sm">*</span>
                </label>
                <Input
                  className="w-full text-sm"
                  {...methods.register("name")}
                  disabled={type === "show"}
                  value={dataInvestment?.data?.name}
                />
              </div>
              <div className="space-y-[9px]">
                <label className="text-[#1D2939] font-semibold text-sm">
                  Thematic Programmes
                  <span className="ml-1 text-error-500 text-sm">*</span>
                </label>
                <Select
                  options={optionThematic}
                  name="thematicProgrammers"
                  className="remove-input-txt-border-react-select"
                  isMulti
                  // isDisabled={type === "show"}
                  isDisabled={true}
                  value={methods.watch("programmes")}
                  onChange={(e) => methods.setValue("programmes", e)}
                />
              </div>
              <div className="space-y-[8px]">
                <label className="text-[#1D2939] font-semibold text-sm">
                  Tujuan Project
                  <span className="ml-1 text-error-500 text-sm">*</span>
                </label>
                <CreatableSelect
                  className="remove-input-txt-border-react-select"
                  options={optionProjectGoal}
                  isMulti
                  // isDisabled={type === "show"}
                  isDisabled={true}
                  value={methods.watch("immediate_project")}
                  onChange={(e) => methods.setValue("immediate_project", e)}
                />
                {/* <textarea
                  className="w-full mt-2 rounded text-sm"
                  {...methods.register("other_goal")}
                  disabled={type === "show"}
                /> */}
              </div>
              <div className="flex flex-row justify-between">
                <div className="w-[45%] space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-sm">
                    Lokasi (Provinsi)
                    <span className="ml-1 text-error-500 text-sm">*</span>
                  </label>
                  <Select
                    className="text-sm remove-input-txt-border-react-select"
                    options={optionProvince}
                    onChange={(e) => methods.setValue("location", e)}
                    isDisabled={type === "show"}
                    isMulti
                    value={methods.watch("location")}
                  />
                </div>
                <div className="w-[50%] space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-sm">
                    Periode Pelaporan Dana Project (Ke Donor)
                    <span className="ml-1 text-error-500 text-sm">*</span>
                  </label>
                    <Controller
                      name="reporting_period"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={optionPeriod}
                          onChange={(selectedOption) => field.onChange(selectedOption.value)}
                          className="text-sm remove-input-txt-border-react-select"
                          isDisabled={type === "show"}
                          value={optionPeriod.find(option => option.value === field.value)}
                        />
                      )}
                    />
                    {errors.reporting_period && (
                      <p className="text-error-500 text-sm">{errors.reporting_period.message}</p>
                    )}
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="w-[45%] space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-sm">
                    Periode Pelaksanaan Project
                    <span className="ml-1 text-error-500 text-sm">*</span>
                  </label>
                  <div className="grid grid-cols-2 gap-3 w-full">
                    <Input
                      className="text-sm w-full"
                      type="date"
                      {...methods.register("start_period")}
                      disabled={type === "show"}
                    />
                    <Input
                      className="text-sm w-full"
                      type="date"
                      {...methods.register("end_period")}
                      disabled={type === "show"}
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-3 w-[50%]">
                  <div className="flex flex-col space-y-3 w-full">
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Jumlah Kebutuhan Dana
                      <span className="ml-1 text-error-500 text-sm">*</span>
                    </label>
                    <Input
                      name="total_funding"
                      className="w-full text-sm"
                      {...methods.register("total_funding", {
                        onChange: (e) => handleInputCurrency(e),
                      })}
                      disabled={type === "show"}
                    />
                  </div>
                  <div className="flex flex-col space-y-3">
                    <label className="text-[#1D2939] font-semibold text-sm whitespace-nowrap">
                      Mata Uang
                      <span className="ml-1 text-error-500 text-sm">*</span>
                    </label>
                    <Input
                      name="currency"
                      defaultValue="USD"
                      className="w-full text-sm"
                      disabled
                      {...methods.register("currency")}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-roe justify-between">
                <div className="w-[45%] space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-sm">
                    Mekanisme Penyaluran
                    <span className="ml-1 text-error-500 text-sm">*</span>
                  </label>
                  <Select
                    options={optionDistributionMechanism}
                    name="options_mechanism"
                    onChange={(e) =>
                      methods.setValue("distribution_mechanism", e.label)
                    }
                    className="text-sm remove-input-txt-border-react-select"
                    isDisabled={type === "show"}
                    value={{
                      value: methods.watch("distribution_mechanism"),
                      label: methods.watch("distribution_mechanism"),
                    }}
                  />
                </div>
                <div className="w-1/2 space-y-[9px]">
                  <label className="text-[#1D2939] font-semibold text-sm">
                    Mekanisme Alokasi
                    <span className="ml-1 text-error-500 text-sm">*</span>
                  </label>
                  <Select
                    options={optionAllocationMechanism}
                    name="allocation_mechanism"
                    onChange={(e) =>
                      methods.setValue("allocation_mechanism", e.label)
                    }
                    className="text-sm remove-input-txt-border-react-select"
                    isDisabled={type === "show"}
                    value={{
                      value: methods.watch("allocation_mechanism"),
                      label: methods.watch("allocation_mechanism"),
                    }}
                  />
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <div>Kerangka Pengamanan</div>
          </CardHeader>
          <CardBody>
            <div className="space-y-2">
              <label className="text-[#1D2939] text-sm font-semibold">
                Standar Safeguard BPDLH
                <span className="ml-1 text-error-500 text-sm">*</span>
              </label>
              <Controller
                name="safeguards"
                control={control}
                render={({ field }) => (
                  <Select
                  {...field}
                  className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                  isDisabled={type === "show"}
                  options={optionISP}
                  isMulti
                  value={methods.watch("safeguards")}
                  onChange={(e) => methods.setValue("safeguards", e)}
                />
                )}
              />
              {errors.safeguards && (
                <p className="text-error-500 text-sm">{errors.safeguards.message}</p>
              )}
            </div>
            <div className="space-y-2">
              <label className="text-[#1D2939] text-sm font-semibold">
                Standar Safeguard Lainnya
              </label>
              <Input
                className="w-full text-sm"
                {...methods.register("safeguard_other")}
                disabled={type === "show"}
              />
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <div>Monitoring & Evaluasi</div>
          </CardHeader>
          <CardBody>
            <div className="space-y-2">
              <label className="text-[#1D2939] text-sm font-semibold">
                Rencana Monitoring & Evaluasi
                <span className="ml-1 text-error-500 text-sm">*</span>
              </label>
              {/* <CreatableSelect
                name="monitoringEvaluation"
                isMulti
                onChange={(e) => methods.setValue("monitoring_plan", e)}
                className="text-sm remove-input-txt-border-react-select"
                isDisabled={type === "show"}
                value={methods.watch("monitoring_plan")}
              /> */}
              <Controller
                name="monitoring_plan"
                control={control}
                render={({ field }) => (
                  <div className={`form-control w-full`}>
                  <textarea
                    {...field}
                    className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 ${
                      type === "show"
                        ? `cursor-not-allowed border-none bg-[#F2F2F2]`
                        : ``
                    }`}
                    disabled={type === "show"}
                    // {...methods.register("monitoring_plan")}
                    rows={5}
                  />
                  </div>
                )}
              />
              {errors.monitoring_plan && (
                <p className="text-error-500 text-sm">{errors.monitoring_plan.message}</p>
              )}

            </div>
          </CardBody>
        </Card>
      </div>

      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate("/investment-plan/")}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
          <Button
            type="button"
            className="text-black border-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={methods.handleSubmit(onSave)}
            // disabled
            disabled={type === "show"}
          >
            <RiSave3Line className="text-black" />
            Simpan & Draft
          </Button>
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={methods.handleSubmit(onSubmit)}
          >
            Selanjutnya
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddIformation;

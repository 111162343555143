import { Button, Card, CardBody, CardHeader, InputForm } from "components";
import ActivityTarget from "./ArrayField/ActivityTarget";
import Resources from "./ArrayField/Resources";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine, RiSave3Line } from "react-icons/ri";
import { useTorMutations } from "../../hooks/useTorMutations";
import { useCreateStore } from "pages/Tor/Create/store";
import useTimeWorkPlan from "../../../../../RencanaKerjaWaktu/hooks/useTimeWorkPlan";
import { useEffect, useState } from "react";
import AtomSelect from "components/atoms/Select";
import { useRecapMutations } from "../../hooks/useRecapMutations";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const TorForm = ({
  setSavedStep,
  isEdit,
  activityDetails,
  setActivityDetail,
}) => {
  const { step, nextStep, prevStep, setTorId } = useCreateStore();
  let isCreate = useLocation()?.pathname.includes("create");
  const userSelector = useSelector((state) => state?.auth?.user || {});
  const navigate = useNavigate();
  const TORID = sessionStorage.getItem("torId");
  const { submitMutation } = useTorMutations();
  const [projectArr, setProjectArr] = useState();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      activity_targets: [
        {
          target: "",
          total: null,
        },
      ],
      fund_resources: [
        {
          awp_detail_activity_id: "",
          awp_detail_activity_name: "",
          remaining_limit: null,
        },
      ],
    },
  });

  const {
    formState: { errors, isValid },
  } = methods;

  const { lists, refetchDetailTor } = useRecapMutations(TORID ? TORID : null);

  const handleSubmitForm = async (type) => {
    try {
      const data = methods.getValues();
      const detailParams = {
        id: TORID ? TORID : null,
        user_id: userSelector?.UserId,
        ...data,
      };
      submitMutation.mutate(detailParams, {
        onSuccess: (res) => {
          if (type === "next") {
            setTorId(res.data.data._id);
            if (isCreate) {
              sessionStorage.setItem("torId", res.data.data._id);
            }
            sessionStorage.setItem("step", parseInt(step) + 1);
            nextStep();
          } else {
            navigate("/tor");
          }
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const params = {};

  const { timeWorkPlanData, refetch } = useTimeWorkPlan({ params });

  useEffect(() => {
    methods.register("work_plan_id");
    methods.register("awp_activity_id");
    methods.register("output_id");

    refetch();

    if (TORID) {
      refetchDetailTor();
    }
  }, []);

  useEffect(() => {
    setProjectArr(
      timeWorkPlanData.map((data) => ({
        value: data?.awp_project_id,
        label: data?.awp_project_name,
        ...data,
      }))
    );
  }, [timeWorkPlanData]);

  useEffect(() => {
    if (TORID) {
      for (let key in lists?.tor) {
        methods.setValue(key, lists?.tor[key]);
      }
    }
  }, [lists]);

  useEffect(() => {
    let temp = methods.watch("work_plan_name");
    methods.setValue("awp_activity_name", temp?.awp_project_activity_name);
    methods.setValue("awp_activity_id", temp?.awp_project_activity_id);
    methods.setValue(
      "work_plan_id",
      temp?.awp_detail_activities[0]?.awp_detail_activity_id
    );
    methods.setValue(
      "output_name",
      temp?.awp_detail_activities[0]?.awp_output_name || null
    );
    methods.setValue(
      "output_id",
      temp?.awp_detail_activities[0]?.awp_output_id || null
    );
    let activityDetails = temp?.awp_detail_activities.map((data, index) => ({
      label: data?.awp_detail_activity_name,
      value: data?.awp_detail_activity_id,
      ...temp.awp_detail_activities[index],
    }));
    setActivityDetail(activityDetails);
  }, [methods.watch("work_plan_name")]);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-6">
        <Card>
          <CardHeader>Informasi Proyek</CardHeader>
          <CardBody>
            <div className="flex flex-col gap-5">
              <AtomSelect
                controllerName={`work_plan_name`}
                className={`py-2 w-full rounded-md focus-within:border-primary-700`}
                label={"Nama Proyek"}
                placeholder={"Pilih Project"}
                options={projectArr || []}
                required={true}
                disable={!isEdit}
              />
              <InputForm
                controllerName={"awp_activity_name"}
                className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                label={"Kegiatan Utama yang diusulkan (berdasarkan AWP)"}
                placeholder={"Pilih Kegiatan"}
                required={true}
                disabled
              />
              <InputForm
                controllerName={"output_name"}
                className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                label={"Output Proyek"}
                placeholder={"Output Kegiatan AWP"}
                disabled={true}
              />
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Tor Kegiatan</CardHeader>
          <CardBody>
            <div className="flex flex-col gap-5">
              <InputForm
                controllerName={"background"}
                className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                label={"Latar Belakang"}
                placeholder={"Detail Kegiatan"}
                required={true}
                disabled={!isEdit}
                textArea={true}
              />
              <InputForm
                controllerName={"purpose"}
                className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                label={"Maksud Dan Tujuan"}
                placeholder={"Detail Kegiatan"}
                required={true}
                textArea={true}
                disabled={!isEdit}
              />
              <ActivityTarget methods={methods} disabled={!isEdit} />
              <Resources
                methods={methods}
                disabled={!isEdit}
                activityDetails={activityDetails}
              />
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="items-center justify-between block px-6 py-5 mt-4 mb-10 bg-white border border-gray-200 rounded-lg md:flex">
        <div>
          <Button
            type="button"
            className="px-8 text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
            onClick={() => {
              navigate("/tor");
              sessionStorage.removeItem("step");
            }}
          >
            Batal
          </Button>
        </div>

        <div className="items-center justify-between block gap-2 md:flex">
          {isEdit && (
            <Button
              type="button"
              className="text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
              onClick={() => {
                handleSubmitForm("draft");
              }}
            >
              <RiSave3Line />
              Simpan & Draft
            </Button>
          )}
          <Button
            type="button"
            disabled={!isValid}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={() => {
              handleSubmitForm("next");
            }}
          >
            {submitMutation.isLoading ? "Tunggu Sebentar" : "Selanjutnya"}
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

const validationSchema = yup.object().shape({
  work_plan_name: yup.object().required("Nama Proyek wajib diisi"),
  awp_activity_name: yup.mixed().required("Kegiatan Utama wajib diisi"),
  output_name: yup.mixed().nullable(),
  background: yup.string().required("Latar Belakang wajib diisi"),
  purpose: yup.string().required("Maksud Dan Tujuan wajib diisi"),
  activity_targets: yup.array().of(
    yup.object().shape({
      target: yup.string().required("Target wajib diisi"),
      total: yup
        .string()
        .required("Total wajib diisi")
        .matches(/^\d{1,3}(.\d{3})*(\.\d+)?$/, "Total must be a number"),
    })
  ),
  fund_resources: yup.array().of(
    yup.object().shape({
      awp_detail_activity_name: yup
        .object()
        .shape({
          label: yup.string().required("Label is required"),
          value: yup.string().required("Value is required"),
        })
        .required("Activity Name wajib diisi"),
      remaining_limit: yup
        .string()
        .required("Remaining Limit wajib diisi")
        .matches(
          /^\d{1,3}(.\d{3})*(\.\d+)?$/,
          "Remaining Limit must be a number"
        ),
    })
  ),
});

export default TorForm;

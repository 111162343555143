/* eslint-disable no-useless-computed-key */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AiOutlineEye } from "react-icons/ai";
import { debounce } from "lodash";
import { useApprovalPembayaranResources } from "./hooks";
import { useNavigate } from "react-router";
import moment from "moment";

import {
  Breadcrumbs,
  Button,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from "components";

import { APPROVAL_STATUSSES } from "components/organisms/Approval2Level/constant";
import { BadgeStatus as ApprovalBadgeStatus } from "components/organisms/Approval2Level";
import { convertMoney } from "helpers";

const ApprovalPembayaranResources = () => {
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortMethod, setSortMethod] = useState("desc");

  const navigate = useNavigate();
  const links = [
    {
      label: "Daftar Permohonan Pembayaran Resources Proyek",
    },
  ];

  const params = {
    page: page,
    limit: limit,
    search: keyword,
    status: status,
    order_method: sortMethod,
    order_by: sortBy,
  };

  const {
    data: dataSource,
    pagination,
    isLoading,
    refetch,
  } = useApprovalPembayaranResources(params);

  useEffect(() => {
    refetch();
  }, [keyword, status, page, limit, sortBy, sortMethod]);

  const onSearch = debounce(
    useCallback((event) => {
      setKeyword(() => {
        if (event?.target?.value) return event.target.value;
        return undefined;
      });
    }, []),
    1000
  );

  const onChangeStatus = useCallback((obj) => {
    setStatus(obj.value || undefined);
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "",
        sortable: true,
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {index + 1}
            </div>
          );
        },
      },
      {
        id: "created_at",
        title: "Tanggal Permohonan",
        dataIndex: "created_at",
        className: "",
        sortable: true,
        sorter: (method, column) => {
          setSortBy(column);
          setSortMethod(method);
        },
        columnClassName: "w-[180px]",
        render: (value) => {
          const Date = moment.utc(value).local().format("DD/MM/YYYY");

          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
              {Date}
            </div>
          );
        },
      },
      {
        id: "project_name",
        title: "Nama Proyek",
        dataIndex: "project_name",
        className: "",
        sortable: true,
        sorter: (method, column) => {
          setSortBy(column);
          setSortMethod(method);
        },
        columnClassName: "w-[180px]",
        render: (value) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
              {value}
            </div>
          );
        },
      },
      {
        id: "payment_total",
        title: "Total Nilai Pengajuan",
        dataIndex: "payment_total",
        className: "",
        sortable: true,
        sorter: (method, column) => {
          setSortBy(column);
          setSortMethod(method);
        },
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        render: (value) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
              Rp. {convertMoney(value)}
            </div>
          );
        },
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        className: "",
        sortable: true,
        sorter: (method, column) => {
          setSortBy(column);
          setSortMethod(method);
        },
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        render: (value) => <ApprovalBadgeStatus value={value} />,
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        width: 160,
        columnClassName: "text-center w-[150px] text-ellipsis overflow-hidden",
        fixed: "center",
        render: (value, data, index) => {
          return (
            <div className="space-x-3 flex items-center w-full justify-center">
              {["NEED_APPROVAL", "NEED_APPROVAL_DIRECTOR"].includes(
                data?.status
              ) ? (
                <div className="space-x-3 justify-center flex w-full">
                  <Button
                    type="button"
                    className={`
                      text-white w-40
                        bg-[#00BD52]
                      `}
                    onClick={() =>
                      navigate(
                        `/approval-pembayaran-resources/review/${data?._id}`
                      )
                    }
                  >
                    Review
                  </Button>
                </div>
              ) : (
                <AiOutlineEye
                  className="text-gray-600 cursor-pointer"
                  size={20}
                  onClick={() =>
                    navigate(`/approval-pembayaran-resources/${data?._id}`)
                  }
                />
              )}
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <Fragment>
      <div className="sm:max-w-[480px] md:max-w-[608px] lg:max-w-[864px] xl:max-w-[75vw]">
        <Breadcrumbs items={links} />
        <div className="md:flex block items-center my-3">
          <TitleText className="flex-1 text-gray-900 text-lg font-semibold">
            Daftar Permohonan Pembayaran Resources Proyek
          </TitleText>
        </div>
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-3">
            <div className="space-y-4">
              <div className="flex space-x-4">
                <div className="flex-1">
                  <SearchInput placeholder="Cari" onChange={onSearch} />
                </div>
                <CustomSelect
                  optionsData={APPROVAL_STATUSSES}
                  placeholder="Status"
                  className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
                  onChange={onChangeStatus}
                />
              </div>
              <Table
                bordered
                stripped
                layout="fixed"
                className="mb-4"
                columns={tableColumns}
                dataSource={dataSource}
                isLoading={isLoading}
                onChangePage={setPage}
                onChangeRowsPerPage={setLimit}
                pagination={pagination}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
                setSortBy={setSortBy}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ApprovalPembayaranResources;

import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const AktivaAset = ({ data, index, methods }) => {
  const navigate = useNavigate();

  const { watch, getValues } = useFormContext();

  const [isEdit, setIsEdit] = useState(false);
  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEdit = () => setIsEdit(!isEdit);
  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  return (
    <div className="flex flex-col">
      <div className="mt-[20px]">
        <CardForm label={`Aktiva/Aset`} buttonCollapse={true}>
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputForm
                controllerName={`tools.0.financial_report.${index}.total_net_worth`}
                className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Total Aktiva/Aset"}
                placeholder={"0"}
                prefix={"Rp."}
                disabled={!isEdit}
              />
            </div>
            <div className="mt-4">
              <span className="text-[#01A24A] font-[600]">
                A. Aktiva Lancar
              </span>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputForm
                controllerName={`tools.0.financial_report.${index}.current_asset.total`}
                className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Jumlah (A)"}
                placeholder={"0"}
                prefix={"Rp."}
                disabled={!isEdit}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputForm
                controllerName={`tools.0.financial_report.${index}.current_asset.cash`}
                className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Kas dan Setara Kas"}
                desc={
                  "Setara Kas termasuk saldo rekening di bank dan deposito yang dapat dicairkan dalam waktu kurang dari tiga bulan"
                }
                placeholder={"0"}
                prefix={"Rp."}
                disabled={!isEdit}
              />
              <InputForm
                controllerName={`tools.0.financial_report.${index}.current_asset.equipment`}
                className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Perlengkapan"}
                placeholder={"0"}
                prefix={"Rp."}
                desc={
                  "Barang habis pakai yang digunakan untuk kegiatan operasional sehari-hari seperti ATK, alat kebersihan, dan suku cadang"
                }
                disabled={!isEdit}
              />
              <InputForm
                controllerName={`tools.0.financial_report.${index}.current_asset.supply`}
                className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Persediaan"}
                placeholder={"0"}
                prefix={"Rp."}
                desc={"Barang yang akan diperjualbelikan"}
                disabled={!isEdit}
              />
              <InputForm
                controllerName={`tools.0.financial_report.${index}.current_asset.debts`}
                className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Piutang"}
                placeholder={"0"}
                prefix={"Rp."}
                desc={
                  "Jumlah seluruh piutang baik dari pelanggan/nasabah/anggota maupun pegawai"
                }
                disabled={!isEdit}
              />
            </div>
          </div>

          {data?.current_asset?.other_asset?.length > 0 &&
            data?.current_asset?.other_asset.map((datas, i) => {
              return (
                <div className="space-y-2">
                  <span className="text-[#01A24A] font-[600] mt-4">
                    Data Aset Lancar Lainnya
                  </span>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <InputForm
                      controllerName={`tools.0.financial_report.${index}.current_asset.other_asset.${i}.name`}
                      className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Nama Aktiva Lainnya"}
                      placeholder={"Nama Aktiva Lainnya"}
                      disabled={!isEdit}
                    />
                    <InputForm
                      controllerName={`tools.0.financial_report.${index}.current_asset.other_asset.${i}.value`}
                      className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Nilai"}
                      placeholder={"0"}
                      prefix={"Rp."}
                      disabled={!isEdit}
                    />
                  </div>
                </div>
              );
            })}

          <div className="space-y-6">
            <span className="text-[#01A24A] font-[600] mt-4">
              B. Aktiva Tetap
            </span>
            <InputForm
              controllerName={`tools.0.financial_report.${index}.fixed_asset.total`}
              className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
              label={"Jumlah (B)"}
              placeholder={"0"}
              prefix={"Rp."}
              disabled={!isEdit}
            />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <InputForm
                controllerName={`tools.0.financial_report.${index}.fixed_asset.equipment`}
                className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Peralatan dan Mesin"}
                placeholder={"0"}
                prefix={"Rp."}
                desc={"Seperti Furnitur, Kendaraan, dan Peralatan Elektronik"}
                disabled={!isEdit}
              />
              <InputForm
                controllerName={`tools.0.financial_report.${index}.fixed_asset.wip`}
                className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Perkejaan Dalam Proses"}
                desc={"Seperti Pekerjaan yang masih dalam proses pengerjaan"}
                placeholder={"0"}
                prefix={"Rp."}
                disabled={!isEdit}
              />
              <InputForm
                controllerName={`tools.0.financial_report.${index}.fixed_asset.land_and_buildings`}
                className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Tanah dan Bangunan"}
                desc={"Seperti Asset baik Tanah maupun Bangunan"}
                placeholder={"0"}
                prefix={"Rp."}
                disabled={!isEdit}
              />
            </div>
          </div>

          {data?.fixed_asset?.other_asset?.length > 0 &&
            data?.fixed_asset?.other_asset.map((datas, i) => {
              return (
                <div className="space-y-2">
                  <span className="text-[#01A24A] font-[600] mt-4">
                    Data Aset Lancar Lainnya
                  </span>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <InputForm
                      controllerName={`tools.0.financial_report.${index}.fixed_asset.other_asset.${i}.name`}
                      className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Nama Aktiva Lainnya"}
                      placeholder={"Nama Aktiva Lainnya"}
                      disabled={!isEdit}
                    />
                    <InputForm
                      controllerName={`tools.0.financial_report.${index}.fixed_asset.other_asset.${i}.value`}
                      className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Nilai"}
                      placeholder={"0"}
                      prefix={"Rp."}
                      disabled={!isEdit}
                    />
                  </div>
                </div>
              );
            })}
          {data?.other_asset?.length > 0 && (
            <div className="space-y-6 mt-4">
              <span className="text-[#01A24A] font-[600]">
                C. AKTIVA LAINNYA{" "}
                <span className="font-[400]">
                  (Selain Aset Lancar dan Aset Tetap, contohnya Aset Tidak
                  Berwujud)
                </span>
              </span>
              <InputForm
                controllerName={`tools.0.financial_report.${index}.other_asset_amount`}
                className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Jumlah (C)"}
                placeholder={"0"}
                prefix={"Rp."}
                disabled={!isEdit}
              />
              {data?.other_asset.map((datas, i) => {
                return (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <InputForm
                      controllerName={`tools.0.financial_report.${index}.other_asset.${i}.name`}
                      className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Nama Aktiva Lainnya"}
                      placeholder={"Nama Aktiva Lainnya"}
                      disabled={!isEdit}
                    />
                    <InputForm
                      controllerName={`tools.0.financial_report.${index}.other_asset.${i}.value`}
                      className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Nilai"}
                      placeholder={"0"}
                      prefix={"Rp."}
                      disabled={!isEdit}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </CardForm>
      </div>
    </div>
  );
};

export default AktivaAset;

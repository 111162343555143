import React, { useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { Button, Card, CardBody, CardForm, CardHeader, InputForm, TitleText, } from "components";
import { BiPencil } from "react-icons/bi";
import AtomSelect from "components/atoms/Select";
import { RiFile2Line } from "react-icons/ri";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import { useFormContext } from "react-hook-form";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";

const Commisioner = ({
  openModal = () => console.log(`open`),
  isEdit = false,
  toogleIsEdit = () => { },
  dataCommisioners = [],
  appendDataCommisioners = () => { },
  removeDataCommisioners = () => { },
}) => {
  const navigate = useNavigate();

  const {
    watch,
    getValues,
    formState: { errors, isValid }
  } = useFormContext();
  const [isEditVerification, setIsEditVerification] = useState(false);
  const toogleIsEditVerification = () => setIsEditVerification(!isEditVerification)

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Komisaris/ Dewan Pengawas/ Dewan Pembinaan</div>
          <div className="flex-1 text-right">
            {sessionStorage.getItem(`isLemtaraFormDisabled`) != "true" && (isEdit ? (
              <Button
                type="button"
                className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Simpan Perubahan
              </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Ubah
              </Button>
            ))}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        {dataCommisioners?.length > 0 && (
          dataCommisioners?.map((e, index) => {
            return (
              <CardForm key={e.id} label={`Komisaris/ Dewan Pengawas/ Dewan Pembinaan ${index + 1}`} buttonCollapse={true}>
                <div className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <InputForm
                      controllerName={`structure_organization.commissioners.${index}.name`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={'Nama'}
                      placeholder={'Nama'}
                      required
                      disabled={!isEdit}
                    />
                    <InputForm
                      controllerName={`structure_organization.commissioners.${index}.nik`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={'Nomer KTP'}
                      placeholder={'Nomer KTP'}
                      required
                      disabled={!isEdit}
                    />
                    <AtomSelect
                      controllerName={`structure_organization.commissioners.${index}.gender`}
                      options={[
                        { value: "L", label: 'Laki-Laki ' },
                        { value: "P", label: 'Perempuan ' },
                      ]} className="basic-single mt-3"
                      label={"Jenis Kelamin"}
                      returnObject={typeof getValues(`structure_organization.commissioners.${index}.gender`) === "object"}
                      disable={!isEdit}
                      value={e.gender}
                    />
                    <InputForm
                      controllerName={`structure_organization.commissioners.${index}.email`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={'Email'}
                      placeholder={'Email'}
                      required
                      disabled={!isEdit}
                    />
                  </div>
                </div>
              </CardForm>
            )
          })
        )}
        {/* button Verification */}
        <RadioVerificationBadge
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"structure_organization.status_approval_commissioners"}
          nameNotes={"structure_organization.notes_approval_commissioners"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={errors?.structure_organization?.status_approval_commissioners ? errors?.structure_organization?.status_approval_commissioners : false}
        />
      </CardBody>
    </Card>
  );
};

export default Commisioner;

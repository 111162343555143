import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

const SuccessToast = ({ message, message_description, onClose }) => (
  <div
    className={`fixed right-5 w-[30%] top-5 shadow-[0_2px_4px_rgba(0,0,0,0.2)] px-4 py-3 border-solid border-[1px] border-emerald-500 rounded-[12px]`}
    style={{ backgroundColor: "#F7FFFA", zIndex: 9999 }}
  >
    <button
      className="absolute text-lg font-semibold top-2 right-4 text-emerald-400 hover:text-emerald-500 focus:outline-none"
      onClick={onClose}
    >
      x
    </button>
    <p className={`text-emerald-400 font-semibold text-base `}>{message}</p>
    <p className={`text-black text-sm`}>{message_description}</p>
  </div>
);

const ErrorToast = ({ message, message_description, onClose }) => (
  <div
    className={`fixed right-5 w-[30%] top-5 shadow-[0_2px_4px_rgba(0,0,0,0.2)] px-4 py-3 border-solid border-[1px] border-red-500 rounded-[12px]`}
    style={{ backgroundColor: "#fbc2bf", zIndex: 9999 }}
  >
    <button
      className="absolute text-lg font-semibold top-2 right-4 text-red-400 hover:text-red-500 focus:outline-none"
      onClick={onClose}
    >
      x
    </button>
    <p className={`text-red-400 font-semibold text-base `}>{message}</p>
    <p className={`text-black text-sm`}>{message_description}</p>
  </div>
);

SuccessToast.propTypes = {
  message: PropTypes.string.isRequired,
  message_description: PropTypes.string.isRequired,
};

ErrorToast.propTypes = {
  message: PropTypes.string.isRequired,
  message_description: PropTypes.string.isRequired,
};

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const initialShowToast = { success: false, error: false };
  const [toastMessage, setToastMessage] = useState("");
  const [toastDescription, setToastDescription] = useState("");
  const [showToast, setShowToast] = useState(initialShowToast);

  const showToastMessage = (message, description) => {
    setToastMessage(message);
    setToastDescription(description);
    setTimeout(() => {
      setShowToast(initialShowToast);
    }, 7000);
  };

  const hideToastMessage = () => {
    setShowToast(initialShowToast);
  };

  return (
    <ToastContext.Provider
      value={{
        toastMessage,
        toastDescription,
        showToast,
        setShowToast,
        showToastMessage,
        hideToastMessage,
        initialShowToast
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export { SuccessToast, ErrorToast };

import { AtomButton, Button, Card, CardBody, CardHeader } from "components";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  RiAddLine,
  RiArrowLeftLine,
  RiArrowRightLine,
  RiSave3Line,
} from "react-icons/ri";
import ResourcePerson from "./ArrayField/ResourcePerson";
import AddResourcePersonModal from "./Modals/AddResourcePersonModal";
import { useNavigate } from "react-router";
import { useCreateStore } from "pages/Tor/Create/store";
import Participant from "./ArrayField/Participant";
import Items from "./ArrayField/Items";
import { useRabMutations } from "../../hooks/useRabMutations";
import { AuthContext } from "providers/authProvider";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { useRecapMutations } from "../../hooks/useRecapMutations";

const subList = [
  { id: 1, label: "RAB Narasumber" },
  { id: 2, label: "RAB Peserta" },
  { id: 3, label: "RAB Barang" },
];

const RabForm = ({ setSavedStep, setStep, isEdit, activityDetails }) => {
  const userSelector = useSelector((state) => state?.auth?.user || {});
  const { step, nextStep, prevStep, torId } = useCreateStore();
  const navigate = useNavigate();
  const [activeList, setActiveList] = useState(1);
  const [showModal, setShowModal] = useState({
    personModal: false,
  });
  const TORID = sessionStorage.getItem("torId");

  const [totalFunding, setTotalFunding] = useState(0);

  const methods = useForm();

  const { lists, refetchDetailTor } = useRecapMutations(TORID ? TORID : null);

  const {
    submitResources,
    submitParticipants,
    submitItems,
    refetchBudgetAccountData,
    dataBudgetAccount,
  } = useRabMutations();

  const handleSubmitForm = async (params, isBack) => {
    try {
      const { required_items, ...restParams } = params;
      let temp = {
        id: TORID,
        user_id: userSelector?.UserId,
        total_person:
          activeList === 1
            ? params?.resource?.total_person
            : activeList === 2
            ? params?.participant?.total_person
            : null,
        required_items:
          activeList === 1
            ? params?.resource?.required_items
            : activeList === 2
            ? params?.participant?.required_items
            : params?.items?.required_items,
        ...restParams,
      };

      switch (activeList) {
        case 1:
          submitResources.mutate(temp, {
            onSuccess: () => {
              if (!isBack) {
                setActiveList(activeList + 1);
                totalFunding(0);
                sessionStorage.setItem("step_rab_tor", parseInt(step) + 1);
              }
            },
          });
          break;
        case 2:
          submitParticipants.mutate(temp, {
            onSuccess: () => {
              if (!isBack) {
                setActiveList(activeList + 1);
                totalFunding(0);
                sessionStorage.setItem("step_rab_tor", parseInt(step) + 1);
              }
            },
          });
          break;
        case 3:
          submitItems.mutate(temp, {
            onSuccess: () => {
              if (!isBack) {
                sessionStorage.removeItem("step_rab_tor");
                sessionStorage.setItem("step", 3);
                nextStep();
                setSavedStep(3);
              }
            },
          });
          break;

        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    refetchBudgetAccountData();
    refetchDetailTor();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    for (let key in lists) {
      if (key === "item" || key === "resource" || key === "participant") {
        methods?.setValue(key, lists[key]);
      }
    }
    setIsLoading(false);
  }, [lists]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-6">
        <div className="flex border-b border-gray-400">
          {subList.map((list) => (
            <div
              key={list.id}
              className={`relative p-3 text-sm font-semibold ${
                activeList === list.id ? "text-[#01A24A] bg-[#EEFFF4]" : ""
              }`}
            >
              {list.label}
              {activeList === list.id && (
                <span className="w-full absolute bottom-0 left-0 inset-x h-0.5 bg-[#01A24A]"></span>
              )}
            </div>
          ))}
        </div>
        <Card>
          <CardHeader>
            <span className="font-semibold text-sm text-[#E34F13] bg-[#FFF4ED] rounded-full px-2 py-1">
              Estimasi kebutuhan biaya : Rp. {totalFunding}
            </span>
          </CardHeader>
          <CardBody>
            {activeList === 1 && (
              <ResourcePerson
                setTotalFunding={setTotalFunding}
                methods={methods}
                disabled={!isEdit}
                TORID={TORID}
                datas={lists}
                activityDetails={activityDetails}
                budgetAccountOptions={dataBudgetAccount}
              />
            )}
            {activeList === 2 && (
              <Participant
                setTotalFunding={setTotalFunding}
                methods={methods}
                disabled={!isEdit}
                TORID={TORID}
                datas={lists}
                activityDetails={activityDetails}
                budgetAccountOptions={dataBudgetAccount}
              />
            )}
            {activeList === 3 && (
              <Items
                setTotalFunding={setTotalFunding}
                methods={methods}
                disabled={!isEdit}
                TORID={TORID}
                datas={lists}
                activityDetails={activityDetails}
                budgetAccountOptions={dataBudgetAccount}
              />
            )}
          </CardBody>
        </Card>
      </div>

      <div className="items-center justify-between block px-6 py-5 mt-4 mb-10 bg-white border border-gray-200 rounded-lg md:flex">
        <div>
          <Button
            type="button"
            className="px-8 text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
            onClick={() => {
              if (activeList !== 1) {
                setActiveList(activeList - 1);
              } else {
                sessionStorage.setItem("step", 1);
                setSavedStep(1);
              }
              sessionStorage.removeItem("step_rab_tor");
            }}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>

        <div className="items-center justify-between block gap-2 md:flex">
          {isEdit && (
            <Button
              type="button"
              className="text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
              onClick={() => {
                methods.register("total_estimated_cost");
                methods.setValue("total_estimated_cost", totalFunding);
                let values = methods.getValues();
                handleSubmitForm(values, true);
                navigate("/tor");
              }}
            >
              <RiSave3Line />
              Simpan & Draft
            </Button>
          )}
          <Button
            type="button"
            disabled={
              submitResources.isLoading ||
              submitParticipants.isLoading ||
              submitItems.isLoading
            }
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={() => {
              if (!isEdit) {
                console.log(isEdit);
                switch (activeList) {
                  case 1:
                    setActiveList(activeList + 1);
                    sessionStorage.setItem("step_rab_tor", parseInt(step) + 1);
                    break;
                  case 2:
                    setActiveList(activeList + 1);
                    sessionStorage.setItem("step_rab_tor", parseInt(step) + 1);
                    break;
                  case 3:
                    sessionStorage.removeItem("step_rab_tor");
                    sessionStorage.setItem("step", 3);
                    nextStep();
                    setSavedStep(3);
                    break;
                  default:
                    break;
                }
              } else {
                methods.register("total_estimated_cost");
                methods.setValue("total_estimated_cost", totalFunding);
                let values = methods.getValues();
                handleSubmitForm(values, false);
              }
            }}
          >
            {submitResources.isLoading ||
            submitParticipants.isLoading ||
            submitItems.isLoading
              ? "Tunggu Sebentar"
              : "Selanjutnya"}
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>

      {/* MODAL */}
      {showModal.personModal && (
        <AddResourcePersonModal
          close={() =>
            setShowModal({ ...showModal, personModal: !showModal.personModal })
          }
        />
      )}
      {/* END MODAL */}
    </FormProvider>
  );
};

export default RabForm;
